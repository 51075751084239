import { FieldConstruct, TypeKeysEnum } from "types";

export const getSearchFields = <T>(entitySchema: TypeKeysEnum<T>) => {
  const searchFields: (keyof T)[] = Object.entries<FieldConstruct<T>>(
    entitySchema,
  )
    .filter(([_, value]) => value.isSearchable)
    .map(([key]) => key as keyof T);

  return searchFields;
};

import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllHosterList } from "modules/hosters/api";
import { useHosterListFilterAndSort } from "../useHosterListFilterAndSort";

export const useHosterAllList = (filter = true) => {
  const filterAndSortHosters = useHosterListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Hosters, "all"],
    queryFn: async () => {
      const { hosterList } = await getAllHosterList();

      return hosterList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const hosterList = useMemo(
    () => filterAndSortHosters(data ?? []),
    [data, filterAndSortHosters],
  );

  return {
    hosterList,
    isLoading,
  };
};

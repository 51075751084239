import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllSystemList } from "modules/systems/api";
import { useSystemListFilterAndSort } from "../useSystemListFilterAndSort";

export const useSystemAllList = (filter = true) => {
  const filterAndSortSystems = useSystemListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Systems, "all"],
    queryFn: async () => {
      const { systemList } = await getAllSystemList();

      return systemList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const systemList = useMemo(
    () => filterAndSortSystems(data ?? []),
    [data, filterAndSortSystems],
  );

  return {
    systemList,
    isLoading,
  };
};

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  PaperProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Draggable from "react-draggable";
import { ButtonColor, CustomButton } from "core";
import { CloseIcon } from "modules/icons";
import useStyles from "./styles";

type CustomDialogProps = {
  dialogOpen: boolean;
  confirmButtonLoading?: boolean;
  confirmButtonColor?: ButtonColor;
  confirmButtonLoadingTime?: number;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  titleText?: React.ReactNode;
  positive?: boolean;
  showConfirm: boolean;
  confirmText?: string;
  confirmAction?: (e: React.MouseEvent<HTMLElement>) => void;
  showDecline: boolean;
  declineText?: string;
  declineAction?: (e: React.MouseEvent<HTMLElement>) => void;
  confirmDisabled?: boolean;
  children: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  minWidth?: number;
  fullWidth?: boolean;
  dialogDisabled?: boolean;
  dialogDisabledContent?: React.ReactNode;
  contentPadding?: number | string;
  dialogContentStyle?: React.CSSProperties | undefined;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#responsive-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  dialogOpen,
  confirmButtonLoading = false,
  confirmButtonColor,
  confirmButtonLoadingTime,
  setDialogOpen,
  titleText,
  positive = true,
  showConfirm = true,
  confirmText,
  confirmAction,
  showDecline = true,
  declineText = "Abbrechen",
  declineAction,
  confirmDisabled = false,
  children,
  maxWidth = false,
  minWidth,
  fullWidth = false,
  dialogDisabled = false,
  dialogDisabledContent,
  contentPadding = 40,
  dialogContentStyle,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isDesktop = useMediaQuery("@media (pointer: fine)");

  const { classes, cx } = useStyles();

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperComponent={isDesktop ? PaperComponent : undefined}
      classes={{ paperScrollPaper: classes.scrollRoot }}
    >
      <DialogTitle
        className={classes.titleRoot}
        style={{ cursor: "move" }}
        id="responsive-dialog-title"
      >
        <Typography variant="h6" className={classes.title} id="test">
          {titleText}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={cx(
          classes.scrollRoot,
          dialogDisabled ? classes.dialogDisabledWrapper : undefined,
        )}
        style={{
          minWidth: minWidth ?? "unset",
          padding: contentPadding,
          ...dialogContentStyle,
        }}
      >
        {dialogDisabled && (
          <div className={classes.dialogDisabledContent}>
            {dialogDisabledContent}
          </div>
        )}
        <div className={dialogDisabled ? classes.dialogDisabled : undefined}>
          {children}
        </div>
      </DialogContent>
      {(showConfirm || showDecline) && (
        <DialogActions className={classes.actionsRoot}>
          {showDecline && (
            <CustomButton
              text={declineText}
              onClick={declineAction || handleClose}
              color={positive ? "red" : "default"}
            />
          )}
          {showConfirm && (
            <CustomButton
              loading={confirmButtonLoading}
              disabled={confirmDisabled}
              text={confirmText}
              onClick={confirmAction}
              // size="small"
              color={
                confirmButtonColor
                  ? confirmButtonColor
                  : positive
                    ? "default"
                    : "red"
              }
              style="filled"
              loadingTime={confirmButtonLoadingTime}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

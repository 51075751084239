import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getSystem } from "modules/systems/api";

type SystemReadParamsType = {
  systemID: string;
};

export const useSystemRead = () => {
  const authContext = useAuthContext();

  const { systemID } = useParams<SystemReadParamsType>();

  console.log("Loading system with ID: ", systemID);

  const { data: system, isLoading } = useQuery({
    queryKey: [QueryKeys.Systems, systemID],
    queryFn: async () => {
      if (!systemID) return null;

      const systemFromAPI = await getSystem(systemID);

      if (systemFromAPI) {
        authContext.setSystem(systemFromAPI);

        authContext.setCurrentData({
          id: systemFromAPI.id,
          name: systemFromAPI.name,
        });
      }

      return systemFromAPI;
    },
  });

  return {
    systemID,
    system: system ?? null,
    isLoading,
  };
};

import React, { useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateEffortRuleInput, EffortRule, System, TaskType } from "types";
import {
  CustomButton,
  CustomButtonHandles,
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  LabeledTypography,
  SelectionButton,
} from "core";
import { SystemIcon, TaskTypeIcon } from "modules/icons";
import { WebsiteTypeOption } from "modules/shared/options/WebsiteTypeOption";
import { SystemSelectionDialog } from "modules/systems/systemselection/SystemSelectionDialog";
import { TaskTypeSelectionDialog } from "modules/tasktypes/tasktypeselection/TaskTypeSelectionDialog";
import { useEffortRuleForm } from "../useEffortRuleForm";
import useStyles from "./styles";

type EffortRuleFormProps = {
  formIntent: "create" | "edit";
  effortRule: EffortRule | null | undefined;
};

export type EffortRuleFormHandles = {
  validateEffortRuleForm(): CreateEffortRuleInput | null;
};

const EffortRuleFormComponent: React.ForwardRefRenderFunction<
  EffortRuleFormHandles,
  EffortRuleFormProps
> = ({ formIntent, effortRule }, effortRuleFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    websiteType,
    setWebsiteType,
    system,
    setSystem,
    taskType,
    setTaskType,
    effort,
    setEffort,
    resetEffortRuleForm,
  } = useEffortRuleForm(formIntent, effortRule);

  const [selectTaskTypeDialogOpen, setSelectTaskTypeDialogOpen] =
    useState<boolean>(false);

  const taskTypeSelectionHandler = (taskType: TaskType) => {
    setTaskType(taskType);
    setSelectTaskTypeDialogOpen(false);
  };

  const [selectSystemDialogOpen, setSelectSystemDialogOpen] =
    useState<boolean>(false);

  const systemSelectionHandler = (system: System) => {
    setSystem(system);
    setSelectSystemDialogOpen(false);
  };

  const websiteTypeInputRef = useRef<CustomSelectHandles>(null);
  const systemInputRef = useRef<CustomButtonHandles>(null);
  const taskTypeInputRef = useRef<CustomButtonHandles>(null);
  const effortInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(effortRuleFormRef, () => ({
    validateEffortRuleForm: () => {
      if (!websiteType) {
        enqueueSnackbar("Bitte wähle ein Website-Typ aus!");
        websiteTypeInputRef.current?.highlight();
        return null;
      }

      if (!system) {
        enqueueSnackbar("Bitte wähle ein System aus!");
        systemInputRef.current?.highlight();
        return null;
      }

      if (!taskType) {
        enqueueSnackbar("Bitte wähle ein Aufgaben-Typ aus!");
        taskTypeInputRef.current?.highlight();
        return null;
      }

      if (effort === "") {
        enqueueSnackbar("Bitte gib den Aufwand in Minuten ein!");
        effortInputRef.current?.highlight();
        return null;
      }

      const effortRuleFormInput: CreateEffortRuleInput = {
        websiteType: websiteType,
        systemID: system.id,
        taskTypeID: taskType.id,
        effort: effort,
      };

      resetEffortRuleForm();

      return effortRuleFormInput;
    },
  }));

  return (
    <>
      <TaskTypeSelectionDialog
        dialogOpen={selectTaskTypeDialogOpen}
        setDialogOpen={setSelectTaskTypeDialogOpen}
        taskTypeSelectionHandler={taskTypeSelectionHandler}
      />
      <SystemSelectionDialog
        dialogOpen={selectSystemDialogOpen}
        setDialogOpen={setSelectSystemDialogOpen}
        systemSelectionHandler={systemSelectionHandler}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <WebsiteTypeOption
            websiteType={websiteType}
            setWebsiteType={setWebsiteType}
            websiteTypeInputRef={websiteTypeInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          {system ? (
            <SelectionButton
              label="System"
              name={system.name}
              onClick={() => setSelectSystemDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="System"
              content={
                <CustomButton
                  text="System auswählen"
                  onClick={() => setSelectSystemDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <SystemIcon className={classes.selectButtonIcon} />
                  }
                  ref={systemInputRef}
                />
              }
            />
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          {taskType ? (
            <SelectionButton
              label="Aufgaben-Typ"
              name={taskType.name}
              onClick={() => setSelectTaskTypeDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Aufgaben-Typ"
              content={
                <CustomButton
                  text="Aufgaben-Typ auswählen"
                  onClick={() => setSelectTaskTypeDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <TaskTypeIcon className={classes.selectButtonIcon} />
                  }
                  ref={taskTypeInputRef}
                />
              }
            />
          )}
        </Grid>
        <Grid item md={4}>
          <CustomNumberField
            label="Aufwand in m"
            number={effort}
            setNumber={setEffort}
            minValue={1}
            ref={effortInputRef}
            required={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const EffortRuleForm = React.forwardRef(EffortRuleFormComponent);

import React, { useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateTaskInput, Task, TaskType, Website } from "types";
import {
  CustomButton,
  CustomButtonHandles,
  CustomDatePicker,
  CustomDatePickerHandles,
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  CustomSwitch,
  CustomTimePicker,
  LabeledTypography,
  SelectionButton,
} from "core";
import { TaskTypeIcon, WebsiteIcon } from "modules/icons";
import { EmployeeOption } from "modules/shared/modeloptions/EmployeeOption";
import { BillTypeOption } from "modules/shared/options/BillTypeOption";
import { TaskStatusOption } from "modules/shared/options/TaskStatusOption";
import { TaskTypeSelectionDialog } from "modules/tasktypes/tasktypeselection/TaskTypeSelectionDialog";
import { WebsiteSelectionDialog } from "modules/websites/websiteselection/WebsiteSelectionDialog";
import { useTaskForm } from "../useTaskForm";
import utils from "utils";
import useStyles from "./styles";

type TaskFormProps = {
  formIntent: "create" | "edit";
  task: Task | null | undefined;
};

export type TaskFormHandles = {
  validateTaskForm(): CreateTaskInput | null;
};

const TaskFormComponent: React.ForwardRefRenderFunction<
  TaskFormHandles,
  TaskFormProps
> = ({ formIntent, task }, taskFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    taskType,
    setTaskType,
    website,
    setWebsite,
    effort,
    setEffort,
    date,
    setDate,
    time,
    setTime,
    deadlineDate,
    setDeadlineDate,
    deadlineTime,
    setDeadlineTime,
    billType,
    setBillType,
    billed,
    setBilled,
    taskStatus,
    setTaskStatus,
    employee,
    setEmployee,
    resetTaskForm,
  } = useTaskForm(formIntent, task);

  const [selectTaskTypeDialogOpen, setSelectTaskTypeDialogOpen] =
    useState<boolean>(false);

  const taskTypeSelectionHandler = (taskType: TaskType) => {
    setTaskType(taskType);
    setSelectTaskTypeDialogOpen(false);
  };

  const [selectWebsiteDialogOpen, setSelectWebsiteDialogOpen] =
    useState<boolean>(false);

  const websiteSelectionHandler = (website: Website) => {
    setWebsite(website);
    setSelectWebsiteDialogOpen(false);
  };

  const taskTypeInputRef = useRef<CustomButtonHandles>(null);
  const websiteInputRef = useRef<CustomButtonHandles>(null);
  const effortInputRef = useRef<CustomNumberFieldHandles>(null);
  const deadlineDateInputRef = useRef<CustomDatePickerHandles>(null);
  const deadlineTimeInputRef = useRef<CustomDatePickerHandles>(null);
  const dateInputRef = useRef<CustomDatePickerHandles>(null);
  const timeInputRef = useRef<CustomDatePickerHandles>(null);
  const billTypeInputRef = useRef<CustomSelectHandles>(null);
  const taskStatusInputRef = useRef<CustomSelectHandles>(null);
  const employeeInputRef = useRef<CustomSelectHandles>(null);

  useImperativeHandle(taskFormRef, () => ({
    validateTaskForm: () => {
      if (!taskType) {
        enqueueSnackbar(
          "Bitte wähle den Aufgaben-Typ der Pauschalaufgabe aus!",
        );
        taskTypeInputRef.current?.highlight();
        return null;
      }
      if (!website) {
        enqueueSnackbar("Bitte wähle die Webseite der Pauschalaufgabe aus!");
        websiteInputRef.current?.highlight();
        return null;
      }
      if (effort === "") {
        enqueueSnackbar("Bitte gib das Aufwand in Minuten ein!");
        effortInputRef.current?.highlight();
        return null;
      }
      if (deadlineDate === null) {
        enqueueSnackbar(
          "Bitte gib das Frist-Datum der Ausführung der Pauschalaufgabe ein!",
        );
        deadlineDateInputRef.current?.highlight();
        return null;
      }
      if (!(deadlineDate instanceof Date) || isNaN(deadlineDate.getTime())) {
        enqueueSnackbar(
          "Bitte gib ein gültiges Frist-Datum der Ausführung der Pauschalaufgabe ein!",
        );
        deadlineDateInputRef.current?.highlight();
        return null;
      }
      if (deadlineTime === null) {
        enqueueSnackbar(
          "Bitte gib das Frist-Datum der Ausführung der Pauschalaufgabe ein!",
        );
        deadlineTimeInputRef.current?.highlight();
        return null;
      }
      if (!(deadlineTime instanceof Date) || isNaN(deadlineTime.getTime())) {
        enqueueSnackbar(
          "Bitte gib ein gültiges Frist-Datum der Ausführung der Pauschalaufgabe ein!",
        );
        deadlineTimeInputRef.current?.highlight();
        return null;
      }
      if (!billType) {
        enqueueSnackbar(
          "Bitte wähle die Abrechnungsart der Pauschalaufgabe aus!",
        );
        billTypeInputRef.current?.highlight();
        return null;
      }
      if (!taskStatus) {
        enqueueSnackbar("Bitte wähle den Status der Pauschalaufgabe aus!");
        taskStatusInputRef.current?.highlight();
        return null;
      }

      if (taskStatus === "finished" && date === null) {
        enqueueSnackbar(
          "Bitte gib das Datum der Ausführung der Pauschalaufgabe ein, wenn der Status auf 'Fertig' steht!",
        );
        dateInputRef.current?.highlight();
        return null;
      }

      if (
        taskStatus === "finished" &&
        (!(date instanceof Date) || isNaN(date.getTime()))
      ) {
        enqueueSnackbar(
          "Bitte gib ein gültiges Datum der Ausführung der Pauschalaufgabe ein, wenn der Status auf 'Fertig' steht!",
        );
        dateInputRef.current?.highlight();
        return null;
      }

      if (taskStatus === "finished" && time === null) {
        enqueueSnackbar(
          "Bitte gib die Uhrzeit der Ausführung der Pauschalaufgabe ein, wenn der Status auf 'Fertig' steht!",
        );
        timeInputRef.current?.highlight();
        return null;
      }

      if (
        taskStatus === "finished" &&
        (!(time instanceof Date) || isNaN(time.getTime()))
      ) {
        enqueueSnackbar(
          "Bitte gib eine gültige Uhrzeit der Ausführung der Pauschalaufgabe ein, wenn der Status auf 'Fertig' steht!",
        );
        timeInputRef.current?.highlight();
        return null;
      }

      if (!employee) {
        enqueueSnackbar(
          "Bitte wähle einen Standard-Mitarbeiter für den Aufgaben-Typ ein!",
        );
        employeeInputRef.current?.highlight();
        return null;
      }

      const taskFormInput: CreateTaskInput = {
        model: "Task",
        taskTypeID: taskType.id,
        websiteID: website.id,
        effort: effort,
        date: date ? utils.dates.convertDateToAWSDateFormat(date) : null,
        time: time ? utils.dates.convertDateToAWSTimeFormat(time) : null,
        deadlineDate: utils.dates.convertDateToAWSDateFormat(deadlineDate),
        deadlineTime: utils.dates.convertDateToAWSTimeFormat(deadlineTime),
        billType: billType,
        billed: billed,
        status: taskStatus,
        employee: employee.id,
      };

      resetTaskForm();

      return taskFormInput;
    },
  }));

  return (
    <>
      <TaskTypeSelectionDialog
        dialogOpen={selectTaskTypeDialogOpen}
        setDialogOpen={setSelectTaskTypeDialogOpen}
        taskTypeSelectionHandler={taskTypeSelectionHandler}
      />
      <WebsiteSelectionDialog
        dialogOpen={selectWebsiteDialogOpen}
        setDialogOpen={setSelectWebsiteDialogOpen}
        websiteSelectionHandler={websiteSelectionHandler}
        routeKey="websites"
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          {taskType ? (
            <SelectionButton
              label="Aufgaben-Typ"
              name={taskType.name}
              onClick={() => setSelectTaskTypeDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Aufgaben-Typ"
              content={
                <CustomButton
                  text="Aufgaben-Typ auswählen"
                  onClick={() => setSelectTaskTypeDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <TaskTypeIcon className={classes.selectButtonIcon} />
                  }
                  ref={taskTypeInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item md={4}>
          {website ? (
            <SelectionButton
              label="Webseite"
              name={website.url}
              onClick={() => setSelectWebsiteDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Webseite"
              content={
                <CustomButton
                  text="Webseite auswählen"
                  onClick={() => setSelectWebsiteDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <WebsiteIcon className={classes.selectButtonIcon} />
                  }
                  ref={websiteInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Aufwand in m"
            number={effort}
            setNumber={setEffort}
            minValue={1}
            ref={effortInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomDatePicker
            value={date}
            label="Datum der Ausführung"
            minDate={new Date(2000, 1, 1)}
            ref={dateInputRef}
            required={taskStatus === "finished"}
            onChange={(date) =>
              setDate(
                date
                  ? new Date(
                      date!.getFullYear(),
                      date!.getMonth(),
                      date!.getDate(),
                    )
                  : null,
              )
            }
          />
        </Grid>

        <Grid item md={4}>
          <CustomTimePicker
            label="Uhrzeit der Ausführung"
            value={time}
            ref={timeInputRef}
            required={taskStatus === "finished"}
            onChange={(date) => {
              console.log("Selected date is: ", date);
              setTime(
                date instanceof Date && !isNaN(date.getTime())
                  ? new Date(date!.toISOString())
                  : null,
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomDatePicker
            value={deadlineDate}
            label="Frist-Datum"
            minDate={new Date(2000, 1, 1)}
            ref={deadlineDateInputRef}
            required={true}
            onChange={(date) =>
              setDeadlineDate(
                date
                  ? new Date(
                      date!.getFullYear(),
                      date!.getMonth(),
                      date!.getDate(),
                    )
                  : null,
              )
            }
          />
        </Grid>

        <Grid item md={4}>
          <CustomTimePicker
            label="Frist-Uhrzeit"
            value={deadlineTime}
            ref={deadlineTimeInputRef}
            required={true}
            onChange={(date) => {
              console.log("Selected date is: ", date);
              setDeadlineTime(
                date instanceof Date && !isNaN(date.getTime())
                  ? new Date(date!.toISOString())
                  : null,
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <BillTypeOption
            billType={billType}
            setBillType={setBillType}
            billTypeInputRef={billTypeInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <TaskStatusOption
            taskStatus={taskStatus}
            setTaskStatus={setTaskStatus}
            taskStatusInputRef={taskStatusInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomSwitch
            label="Abgerechnet?"
            switchLabel={billed ? "JA" : "NEIN"}
            name="Abgerechnet?"
            checkedValue={billed}
            onChange={(_e, checked) => setBilled(checked)}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={6}>
          <EmployeeOption
            employee={employee}
            setEmployee={setEmployee}
            employeeInputRef={employeeInputRef}
            required={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const TaskForm = React.forwardRef(TaskFormComponent);

import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { queryClient } from "queryClient";
import { useDebounce } from "react-use";
import { SearchTextParamName } from "types";
import { TextInputField } from "core";
import useStyles from "./styles";

type SearchTextFilterProps = {
  searchTextParamName: SearchTextParamName;
  resetQueryFields?: string[];
  placeholder?: string;
  isLoading?: boolean;
};

export const SearchTextFilter: React.FC<SearchTextFilterProps> = ({
  searchTextParamName,
  resetQueryFields,
  placeholder,
  isLoading,
}) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState<string>("");

  const [,] = useDebounce(
    () => {
      if (searchText === "") {
        searchParams.delete(searchTextParamName);
      } else {
        searchParams.set(searchTextParamName, searchText);
      }
      sessionStorage.setItem(pathname, searchParams.toString());
      setSearchParams(searchParams, {
        replace: true,
      });
      if (resetQueryFields && searchText !== "") {
        queryClient.invalidateQueries({
          queryKey: [...resetQueryFields, searchText],
        });
      }
    },
    500,
    [searchText],
  );

  useEffect(() => {
    const searchTextParam = searchParams.get(searchTextParamName);
    const searchedText = searchTextParam === null ? "" : searchTextParam;
    setSearchText(searchedText);
  }, [searchParams, searchTextParamName]);

  return (
    <TextInputField
      label="Textsuche"
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      maxWidth={200}
      className={classes.searchText}
      placeholder={placeholder}
      isLoading={isLoading}
    />
  );
};

import React from "react";
import { CognitoUser, User } from "types";
import { CustomButton } from "core";
import {
  BlockButton,
  DeblockButton,
  DeleteButton,
  EditButton,
  RowActions,
} from "components";

type ActionsColumnFormatterProps = {
  user: User;
  cognitoUserList: CognitoUser[];
  listIntent: "list" | "selection";
  openEditRowPage: (row: User) => void;
  setDeleteRowDialogOpen: (value: React.SetStateAction<boolean>) => void;
  setRowToDelete: (value: React.SetStateAction<User | undefined>) => void;
  setBlockCognitoUserDialogOpen: (value: React.SetStateAction<boolean>) => void;
  setCognitoUserToBlock: (
    value: React.SetStateAction<CognitoUser | undefined>,
  ) => void;
  rowSelectionHandler?: (row: User) => void;
  minWidth?: number | "unset";
};

export const ActionsColumnFormatter: React.FC<ActionsColumnFormatterProps> = ({
  user,
  cognitoUserList,
  listIntent = "list",
  openEditRowPage,
  setDeleteRowDialogOpen,
  setRowToDelete,
  setBlockCognitoUserDialogOpen,
  setCognitoUserToBlock,
  rowSelectionHandler,
  minWidth,
}) => {
  const cognitoUser = cognitoUserList.find(
    (cognitoUserItem) => cognitoUserItem.userSUB === user.userSUB,
  );

  return (
    <RowActions
      permanentVisible={listIntent === "selection"}
      minWidth={minWidth}
    >
      {listIntent === "list" ? (
        <>
          <EditButton onClick={() => openEditRowPage(user)} />

          {cognitoUser && cognitoUser.enabled && (
            <BlockButton
              onClick={() => {
                setCognitoUserToBlock(cognitoUser);
                setBlockCognitoUserDialogOpen(true);
              }}
            />
          )}

          {cognitoUser && !cognitoUser.enabled && (
            <DeblockButton
              onClick={() => {
                setCognitoUserToBlock(cognitoUser);
                setBlockCognitoUserDialogOpen(true);
              }}
            />
          )}

          <DeleteButton
            onClick={() => {
              setRowToDelete(user);
              setDeleteRowDialogOpen(true);
            }}
          />
        </>
      ) : (
        <CustomButton
          text="Auswählen"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            rowSelectionHandler && rowSelectionHandler(user);
          }}
          style="outlined"
          size="small"
          disabled={false}
        />
      )}
    </RowActions>
  );
};

import { useEffect, useState } from "react";
import { useEmployeeAllList, usePersistedState } from "hooks";
import { LexicalEditor, createEditor } from "lexical";
import { Employee, TaskType } from "types";

export const useTaskTypeForm = (
  formIntent: "create" | "edit",
  taskType: TaskType | null | undefined,
) => {
  const { employeeList } = useEmployeeAllList(false);

  const [name, setName] = usePersistedState<string>(
    "taskType_name",
    "",
    formIntent,
  );
  const [icon, setIcon] = usePersistedState<string>(
    "taskType_icon",
    "",
    formIntent,
  );
  const [effort, setEffort] = usePersistedState<number | "">(
    "taskType_effort",
    1,
    formIntent,
  );
  const [deadlineDays, setDeadlineDays] = usePersistedState<number | "">(
    "taskType_deadlineDays",
    1,
    formIntent,
  );
  const [employee, setEmployee] = usePersistedState<Employee | null>(
    "taskType_employee",
    null,
    formIntent,
  );
  const [description, setDescription] = useState<LexicalEditor>(createEditor());

  const resetTaskTypeForm = () => {
    localStorage.removeItem("taskType_name");
    localStorage.removeItem("taskType_icon");
    localStorage.removeItem("taskType_effort");
    localStorage.removeItem("taskType_deadlineDays");
    localStorage.removeItem("taskType_employee");
    localStorage.removeItem("taskType_description");
  };

  useEffect(() => {
    if (!taskType) {
      return;
    }

    setName(taskType.name);
    setIcon(taskType.icon);
    setEffort(taskType.effort);
    setDeadlineDays(taskType.deadlineDays);

    const employeeFromAPI =
      taskType.employee === null
        ? null
        : employeeList.find(
            (employeeItem) => employeeItem.id === taskType.employee,
          );

    setEmployee(employeeFromAPI ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskType]);

  return {
    name,
    setName,
    icon,
    setIcon,
    effort,
    setEffort,
    deadlineDays,
    setDeadlineDays,
    employee,
    setEmployee,
    description,
    setDescription,
    resetTaskTypeForm,
  };
};

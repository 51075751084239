import { EffortRulePage } from "modules/effortrules/effortruleDetails/EffortRulePage";
import { EffortRuleFormPage } from "modules/effortrules/effortruleForm/EffortRuleFormPage";
import { EffortRuleListPage } from "modules/effortrules/effortrulelist/EffortRuleListPage";
import { EditIcon, EffortRuleIcon, InfoIcon } from "modules/icons";
import { USER_GROUPS } from "utils/constants";

export const effortrules = {
  key: "effortrules",
  path: "/effortrules",
  section: "grunddaten",
  title: "Aufwandsregeln",
  view: <EffortRuleListPage routeKey="effortrules" />,
  icon: <EffortRuleIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: false,
  indented: false,
  isWithParam: false,
  subNavigations: {
    effortrulecreate: {
      key: "effortrulecreate",
      path: "/effortrules/create",
      section: "grunddaten",
      title: "Aufwandsregel anlegen",
      view: <EffortRuleFormPage routeKey="effortrules" formIntent="create" />,
      icon: <EffortRuleIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    effortrule: {
      key: "effortrule",
      path: "/effortrules/:effortRuleID",
      section: "effortrule",
      title: "Aufwandsregel",
      view: <EffortRulePage routeKey="effortrules" />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        effortruleedit: {
          key: "effortruleedit",
          path: "/effortrules/:effortRuleID/edit",
          section: "effortRule",
          title: "Aufwandsregel bearbeiten",
          view: <EffortRuleFormPage routeKey="effortrules" formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

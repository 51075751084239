import { client } from "queryClient";
import { InfiniteTaskList, Task, TaskSelectionSet } from "types";

export const getTaskList = async (
  nextTokenParam: string | null = null,
): Promise<{ taskList: Task[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Task.listTasksSorted(
    {
      model: "Task",
    },
    {
      limit: 100,
      nextToken: nextTokenParam,
      selectionSet: TaskSelectionSet,
      sortDirection: "DESC",
    },
  );

  return {
    taskList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllTaskList = async (
  nextTokenParam: string | null = null,
  prevTaskList: Task[] = [],
): Promise<InfiniteTaskList> => {
  const { data, nextToken } = await client.models.Task.list({
    limit: 500,
    nextToken: nextTokenParam,
    selectionSet: TaskSelectionSet,
  });

  const taskList = [...prevTaskList, ...data];

  return nextToken
    ? getAllTaskList(nextToken, taskList)
    : { taskList, nextToken: null };
};

export const getTask = async (id: string): Promise<Task | null> => {
  const { data } = await client.models.Task.get(
    {
      id,
    },
    {
      selectionSet: TaskSelectionSet,
    },
  );

  return data;
};

export const getTaskListByWebsiteByTaskType = async (
  websiteID: string,
  taskTypeID: string,
  nextTokenParam: string | null = null,
  prevTaskList: Task[] = [],
): Promise<Task[]> => {
  const { data, nextToken } = await client.models.Task.tasksByWebsiteByTaskType(
    {
      websiteID,
      taskTypeID: {
        eq: taskTypeID,
      },
    },
    {
      limit: 500,
      nextToken: nextTokenParam,
      selectionSet: TaskSelectionSet,
      sortDirection: "DESC",
    },
  );

  const taskList = [...prevTaskList, ...data];

  return nextToken
    ? getTaskListByWebsiteByTaskType(websiteID, taskTypeID, nextToken, taskList)
    : taskList;
};

export const getTaskListByWebsite = async (
  websiteID: string,
  nextTokenParam: string | null = null,
  prevTaskList: Task[] = [],
): Promise<InfiniteTaskList> => {
  const { data, nextToken } = await client.models.Task.tasksByWebsite(
    {
      websiteID,
    },
    {
      limit: 500,
      nextToken: nextTokenParam,
      selectionSet: TaskSelectionSet,
      sortDirection: "DESC",
    },
  );

  const taskList = [...prevTaskList, ...data];

  return nextToken
    ? getTaskListByWebsite(websiteID, nextToken, taskList)
    : { taskList, nextToken: null };
};

export const getClosedTaskListByWebsiteAndDate = async (
  websiteID: string,
  dateFrom: string, // eg. 2021-06-01
  dateTo: string, // eg. 2021-06-30
  nextTokenParam: string | null = null,
  prevTaskList: Task[] = [],
): Promise<Task[]> => {
  const { data, nextToken } = await client.models.Task.tasksByWebsite(
    {
      websiteID,
      date: {
        between: [dateFrom, dateTo],
      },
    },
    {
      limit: 500,
      filter: {
        billed: { eq: false },
        billType: { eq: "maintenance" },
      },
      nextToken: nextTokenParam,
      selectionSet: TaskSelectionSet,
    },
  );

  const taskList = [...prevTaskList, ...data];

  return nextToken
    ? getClosedTaskListByWebsiteAndDate(
        websiteID,
        dateFrom,
        dateTo,
        nextToken,
        taskList,
      )
    : taskList;
};

export const isTaskUsed = async (task: Task) => {
  return task.reportEntries.length > 0;
};

import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { SelectOption } from "types";
import { CustomSelect, CustomTimePicker } from "core";
import utils from "utils";

type TimeFilterProps = {
  param: string;
  paramName: string;
};

export const TimeFilter: React.FC<TimeFilterProps> = ({ param, paramName }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const comparisonParamName = useMemo(() => "comparison_" + param, [param]);

  const [time, setTime] = useState<Date | null>(null);

  const [comparisonNumber, setComparisonNumber] = useState<SelectOption | null>(
    {
      label: "Gleich",
      value: "eq",
    },
  );

  const comparisonOptionList = useMemo(
    () => [
      {
        label: "Gleich",
        value: "eq",
      },
      {
        label: "Größer",
        value: "gt",
      },
      {
        label: "Kleiner",
        value: "lt",
      },
    ],
    [],
  );

  useEffect(() => {
    const dateParam = searchParams.get(param);
    const dateParamInput =
      dateParam === null
        ? null
        : utils.dates.getDateFromAWSTimeFormat(dateParam);
    setTime(dateParamInput);

    const comparisonParam = searchParams.get(comparisonParamName);

    const selectedComparison = comparisonOptionList.find(
      (customerOption) => customerOption.value === comparisonParam,
    );

    const selectedComparisonOption =
      comparisonParam === null ||
      comparisonParam === "eq" ||
      !selectedComparison
        ? {
            label: "Gleich",
            value: "eq",
          }
        : selectedComparison;

    setComparisonNumber(selectedComparisonOption);
  }, [comparisonOptionList, comparisonParamName, param, searchParams]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <CustomSelect<SelectOption>
          label={paramName + " ist:"}
          options={comparisonOptionList}
          onChange={(comparisonBookedMinutesOption) => {
            setComparisonNumber(comparisonBookedMinutesOption);
            if (comparisonBookedMinutesOption!.value === "eq") {
              searchParams.delete(comparisonParamName);
            } else {
              searchParams.set(
                comparisonParamName,
                comparisonBookedMinutesOption!.value,
              );
            }
            sessionStorage.setItem(pathname, searchParams.toString());
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
          value={comparisonNumber}
          minWidth={130}
        />
      </Grid>
      <Grid item>
        <CustomTimePicker
          label="als"
          value={time}
          onChange={(date) => {
            const inputDate =
              date && date instanceof Date && !isNaN(date.getTime())
                ? new Date(date!.toISOString())
                : null;
            console.log("inputDate is: ", inputDate);
            setTime(inputDate);
            if (!inputDate) {
              searchParams.delete(param);
            } else {
              searchParams.set(
                param,
                utils.dates.convertDateToAWSTimeFormat(inputDate),
              );
            }
            sessionStorage.setItem(pathname, searchParams.toString());
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

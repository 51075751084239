import React from "react";
import { client } from "queryClient";
import { SelectOption, WebsiteType } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface WebsiteTypeOptionProps
  extends Partial<CustomSelectOptionProps<WebsiteType>> {
  websiteType: WebsiteType | null;
  setWebsiteType: (value: React.SetStateAction<WebsiteType | null>) => void;
  websiteTypeInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getWebsiteTypeName = (value: WebsiteType) => {
  switch (value) {
    case "website":
      return "Webseite";
    case "webshop":
      return "Webshop";
    case "application":
      return "Anwendung";
    case "server":
      return "Server";
  }
};

export const getWebsiteTypeEnumByName = (value: string): WebsiteType => {
  switch (value) {
    case "Webseite":
      return "website";
    case "Webshop":
      return "webshop";
    case "Anwendung":
      return "application";
    case "Server":
      return "server";
    default:
      return "website";
  }
};

export const getWebsiteTypeOptions = (): SelectOption[] => {
  const values = client.enums.WebsiteType?.values() ?? [];

  return values.map((websiteType) => ({
    value: websiteType,
    label: getWebsiteTypeName(websiteType),
  }));
};

export const WebsiteTypeOption: React.FC<WebsiteTypeOptionProps> = ({
  websiteType,
  setWebsiteType,
  websiteTypeInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Website-Typ"
    options={getWebsiteTypeOptions()}
    onChange={(websiteTypeOption) =>
      setWebsiteType(websiteTypeOption.value as WebsiteType)
    }
    value={
      websiteType
        ? {
            value: websiteType,
            label: getWebsiteTypeName(websiteType),
          }
        : null
    }
    placeholder="Website-Typ auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={websiteTypeInputRef}
  />
);

import React, { useMemo } from "react";
import { Ticket } from "types";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";

type DomainFilterProps = {
  ticketList: Ticket[];
};

export const DomainFilter: React.FC<DomainFilterProps> = ({ ticketList }) => {
  const domainOptionList = useMemo(() => {
    const domainOptionListUnique = new Set(
      ticketList
        .map((ticket) => (ticket?.cf_domain ? ticket.cf_domain : "- Leer -"))
        .sort((a, b) => {
          if (a === "- Leer -") {
            return -1;
          } else if (b === "- Leer -") {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        }),
    );

    return Array.from(domainOptionListUnique.values()).map((domainOption) => {
      return {
        label: domainOption,
        value: encodeURIComponent(domainOption),
      };
    });
  }, [ticketList]);

  return (
    <OptionsFilter
      param="domain"
      paramName="Domain"
      options={domainOptionList}
    />
  );
};

import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTaskTypeRead, useTaskTypeRoutes } from "hooks";
import { TaskType } from "types";
import { CustomAvatar, CustomButton, LabeledTypography, Loading } from "core";
import { DescriptionFormatter } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { DeleteIcon, EditPenIcon } from "modules/icons";
import { TaskTypeDeleteDialog } from "../../tasktypeDelete/TaskTypeDeleteDialog";
import useStyles from "./styles";

export const TaskTypePage: React.FC = () => {
  const { classes, cx } = useStyles();

  const { taskType, isLoading, employee } = useTaskTypeRead();
  const { navigateToEditTaskTypePage } = useTaskTypeRoutes();

  const [deleteTaskTypeDialogOpen, setDeleteTaskTypeDialogOpen] =
    useState<boolean>(false);
  const [taskTypeToDelete, setTaskTypeToDelete] = useState<
    TaskType | undefined
  >();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="TaskType Details">
        <Loading
          description="Bitte warten. TaskType wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (taskType === undefined || taskType === null) {
    return (
      <BoxHeadlineContainer boxTitle="TaskType Details">
        TaskType nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={!isLoading && taskType ? taskType.name : "Aufgaben-Typ-Details"}
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditPenIcon />}
            onClick={() => navigateToEditTaskTypePage(taskType)}
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setTaskTypeToDelete(taskType);
              setDeleteTaskTypeDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <TaskTypeDeleteDialog
        dialogOpen={deleteTaskTypeDialogOpen}
        setDialogOpen={setDeleteTaskTypeDialogOpen}
        taskType={taskTypeToDelete}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Name" content={taskType.name} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Aufwand in m" content={taskType.effort} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Standardfrist in Tagen"
            content={taskType.deadlineDays}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Icon"
            content={
              <span>
                <i className={cx(taskType.icon, classes.icon)}></i>
                {taskType.icon}
              </span>
            }
          />
        </Grid>
      </Grid>

      <Grid className={classes.gridRow} container direction="row">
        <Grid item>
          <Typography variant="h3">Beschreibung</Typography>
          <DescriptionFormatter description={taskType.description} />
        </Grid>
      </Grid>

      {employee ? (
        <>
          <Typography variant="h3">Mitarbeiter</Typography>
          <Grid
            className={classes.gridRow}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {employee.photoURL && (
              <Grid item>
                <CustomAvatar
                  showBadge={false}
                  size="100px"
                  alternativeImage={employee.photoURL}
                />
              </Grid>
            )}
            <Grid item justifyContent="flex-start">
              <Typography variant="h4" className={classes.assigneeName}>
                {employee.firstName + " " + employee.lastName}
              </Typography>
              <a className={classes.email} href={`mailto:${employee.emailId}`}>
                {employee.emailId}
              </a>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container direction="row">
          <Grid item>
            <LabeledTypography
              label="Mitarbeiter"
              content={taskType.employee}
            />
          </Grid>
        </Grid>
      )}
    </BoxHeadlineContainer>
  );
};

import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Hoster, Website } from "types";
import { CustomButton } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import utils from "utils";

type HosterStatisticsProps = {
  websiteList: Website[];
  hosterList: Hoster[];
};

export const HosterStatistics: React.FC<HosterStatisticsProps> = ({
  websiteList,
  hosterList,
}) => {
  const [colors, setColors] = useState<string[]>(
    hosterList.map(() => utils.colors.getRandomColor()),
  );

  const loadNewColors = () => {
    setColors(hosterList.map(() => utils.colors.getRandomColor()));
  };

  const hosterStatisticsData = useMemo(() => {
    const hosterWebsites = hosterList.map((hoster) => ({
      hoster,
      websites: websiteList.filter((website) => website.hosterID === hoster.id),
    }));

    return hosterWebsites.map((hosterWebsite, index) => ({
      name: hosterWebsite.hoster.name,
      menge: hosterWebsite.websites.length,
      fill: colors[index],
    }));
  }, [hosterList, websiteList, colors]);

  console.log("hosterStatisticsData: ", hosterStatisticsData);

  return (
    <BoxHeadlineContainer
      boxTitle="Hoster-Statistiken"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Neue Farben"
          onClick={() => loadNewColors()}
          size="small"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <BarChart height={300} data={hosterStatisticsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="menge" />
        </BarChart>
      </ResponsiveContainer>
    </BoxHeadlineContainer>
  );
};

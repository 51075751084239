import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  rowActions: {
    transition: "0.4s",
  },
  visibleOnHover: {
    opacity: 0,
  },
  permanentVisible: {
    opacity: 1,
  },
}));

export default useStyles;

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useHosterDelete, useHosterRoutes } from "hooks";
import { Hoster } from "types";
import { DeleteDialog } from "components";
import { isHosterUsed } from "../../api";

type HosterDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  hoster: Hoster | undefined;
};

export const HosterDeleteDialog: React.FC<HosterDeleteDialogProps> = ({
  hoster,
  dialogOpen,
  setDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteHosterMutation = useHosterDelete();
  const { navigateToHosterListPage } = useHosterRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || hoster === undefined) {
    return null;
  }

  const deleteHosterRequest = async () => {
    setDeleteLoading(true);

    const hosterIsUsed = await isHosterUsed(hoster);

    if (hosterIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Dieser Hoster kann nicht gelöscht werden, da er in Verwendung ist!",
      );
      return;
    }

    let deletedHoster = await deleteHosterMutation.mutateAsync(hoster);

    console.log("deletedHoster: ", deletedHoster);
    setDeleteLoading(false);
    setDialogOpen(false);

    navigateToHosterListPage();
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteHosterRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Hoster Löschen"
      itemName={hoster.name}
      itemDeleteDescription="den Hoster"
    />
  );
};

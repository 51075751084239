import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  editButton: {
    marginRight: 15,
  },
}));

export default useStyles;

import { useNavigate, useParams } from "react-router-dom";
import { EffortRule } from "types";
import { Routes } from "routes";

type EffortRuleRoutesParamsType = {
  systemID: string;
  taskTypeID: string;
};

export const useEffortRuleRoutes = () => {
  const navigate = useNavigate();

  const EffortRuleIcon = Routes.effortrules.icon;

  const { systemID, taskTypeID } = useParams<EffortRuleRoutesParamsType>();

  const getCreateEffortRulePageRoute = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
  ) =>
    routeKey === "systemeffortrules" && systemID !== undefined
      ? Routes.systems.subNavigations.system.subNavigations.systemeffortrules.subNavigations.systemeffortrulecreate.path.replace(
          ":systemID",
          systemID,
        )
      : routeKey === "tasktypeeffortrules" && taskTypeID !== undefined
        ? Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeeffortrules.subNavigations.tasktypeeffortrulecreate.path.replace(
            ":taskTypeID",
            taskTypeID,
          )
        : Routes.effortrules.subNavigations.effortrulecreate.path;

  const getEditEffortRulePageRoute = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
    effortRule: EffortRule,
  ) =>
    routeKey === "systemeffortrules" && systemID !== undefined
      ? Routes.systems.subNavigations.system.subNavigations.systemeffortrules.subNavigations.systemeffortrule.subNavigations.systemeffortruleedit.path
          .replace(":systemID", systemID)
          .replace(":effortRuleID", effortRule.id)
      : routeKey === "tasktypeeffortrules" && taskTypeID !== undefined
        ? Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeeffortrules.subNavigations.tasktypeeffortrule.subNavigations.tasktypeeffortruleedit.path
            .replace(":taskTypeID", taskTypeID)
            .replace(":effortRuleID", effortRule.id)
        : Routes.effortrules.subNavigations.effortrule.subNavigations.effortruleedit.path.replace(
            ":effortRuleID",
            effortRule.id,
          );

  const getEffortRulePageRoute = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
    effortRule: EffortRule,
  ) =>
    routeKey === "systemeffortrules" && systemID !== undefined
      ? Routes.systems.subNavigations.system.subNavigations.systemeffortrules.subNavigations.systemeffortrule.path
          .replace(":systemID", systemID)
          .replace(":effortRuleID", effortRule.id)
      : routeKey === "tasktypeeffortrules" && taskTypeID !== undefined
        ? Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeeffortrules.subNavigations.tasktypeeffortrule.path
            .replace(":taskTypeID", taskTypeID)
            .replace(":effortRuleID", effortRule.id)
        : Routes.effortrules.subNavigations.effortrule.path.replace(
            ":effortRuleID",
            effortRule.id,
          );

  const getEffortRuleListPageRoute = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
  ) =>
    routeKey === "systemeffortrules" && systemID !== undefined
      ? Routes.systems.subNavigations.system.subNavigations.systemeffortrules.path.replace(
          ":systemID",
          systemID,
        )
      : routeKey === "tasktypeeffortrules" && taskTypeID !== undefined
        ? Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeeffortrules.path.replace(
            ":taskTypeID",
            taskTypeID,
          )
        : Routes.effortrules.path;

  const navigateToCreateEffortRulePage = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
  ) => {
    navigate(getCreateEffortRulePageRoute(routeKey));
  };

  const navigateToEditEffortRulePage = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
    effortRule: EffortRule,
  ) => {
    navigate(getEditEffortRulePageRoute(routeKey, effortRule));
  };

  const navigateToEffortRulePage = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
    effortRule: EffortRule,
  ) => {
    navigate(getEffortRulePageRoute(routeKey, effortRule));
  };

  const navigateToEffortRuleListPage = (
    routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules",
  ) => {
    const searchParams = sessionStorage.getItem(
      getEffortRuleListPageRoute(routeKey),
    );

    navigate(
      searchParams
        ? getEffortRuleListPageRoute(routeKey) + "?" + searchParams
        : getEffortRuleListPageRoute(routeKey),
    );
  };

  return {
    EffortRuleIcon,
    getCreateEffortRulePageRoute,
    getEditEffortRulePageRoute,
    getEffortRulePageRoute,
    navigateToCreateEffortRulePage,
    navigateToEditEffortRulePage,
    navigateToEffortRulePage,
    navigateToEffortRuleListPage,
  };
};

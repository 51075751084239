import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  imgPreview: {
    display: "block",
    width: "100%",
    height: "auto",
  },
}));

export default useStyles;

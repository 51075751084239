import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateTaskTypeInput } from "types";
import { getTaskType } from "modules/tasktypes/api";

export const useTaskTypeUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (taskTypeInput: UpdateTaskTypeInput) => {
      const { data: updatedTaskType } =
        await client.models.TaskType.update(taskTypeInput);

      if (!updatedTaskType) return null;

      const taskType = await getTaskType(updatedTaskType.id);

      return taskType;
    },
    // When mutate is called:
    onMutate: async (newTaskType) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.TaskTypes, newTaskType.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.TaskTypes],
      });

      // Snapshot the previous value
      const previousTaskType = queryClient.getQueryData([
        QueryKeys.TaskTypes,
        newTaskType.id,
      ]);

      // Optimistically update to the new value
      if (previousTaskType) {
        queryClient.setQueryData(
          [QueryKeys.TaskTypes, newTaskType.id],
          /**
           * `newTaskType` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousTaskType, ...newTaskType },
        );
      }

      // Return a context with the previous and new taskType
      return {
        previousTaskType,
        newTaskType,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newTaskType, context) => {
      console.error("Error updating record:", err, newTaskType);
      if (context?.previousTaskType) {
        queryClient.setQueryData(
          [QueryKeys.TaskTypes, context.newTaskType.id],
          context.previousTaskType,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newTaskType) => {
      if (newTaskType) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TaskTypes, newTaskType.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TaskTypes],
        });
      }
    },
  });

  return updateMutation;
};

import { EffortRulePage } from "modules/effortrules/effortruleDetails/EffortRulePage";
import { EffortRuleFormPage } from "modules/effortrules/effortruleForm/EffortRuleFormPage";
import { EffortRuleListPage } from "modules/effortrules/effortrulelist/EffortRuleListPage";
import { EditIcon, EffortRuleIcon } from "modules/icons";
import { USER_GROUPS } from "utils/constants";

export const tasktypeeffortrules = {
  key: "tasktypeeffortrules",
  path: "/tasktypes/:taskTypeID/effortrules",
  section: "taskType",
  title: "Aufwandsregeln",
  view: <EffortRuleListPage routeKey="tasktypeeffortrules" />,
  icon: <EffortRuleIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    tasktypeeffortrulecreate: {
      key: "tasktypeeffortrulecreate",
      path: "/tasktypes/:taskTypeID/effortrules/create",
      section: "taskType",
      title: "Aufwandsregel anlegen",
      view: (
        <EffortRuleFormPage
          formIntent="create"
          routeKey="tasktypeeffortrules"
        />
      ),
      icon: <EffortRuleIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    tasktypeeffortrule: {
      key: "tasktypeeffortrule",
      path: "/tasktypes/:taskTypeID/effortrules/:effortRuleID",
      section: "taskType",
      title: "Aufwandsregel",
      view: <EffortRulePage routeKey="tasktypeeffortrules" />,
      icon: <EffortRuleIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        tasktypeeffortruleedit: {
          key: "tasktypeeffortruleedit",
          path: "/tasktypes/:taskTypeID/effortrules/:effortRuleID/edit",
          section: "taskType",
          title: "Aufwandsregel bearbeiten",
          view: (
            <EffortRuleFormPage
              formIntent="edit"
              routeKey="tasktypeeffortrules"
            />
          ),
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

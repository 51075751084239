import { useEffect, useState } from "react";
import { usePersistedState } from "hooks";
import { LexicalEditor, createEditor } from "lexical";
import { System } from "types";

export const useSystemForm = (
  formIntent: "create" | "edit",
  system: System | null | undefined,
) => {
  const [name, setName] = usePersistedState<string>(
    "system_name",
    "",
    formIntent,
  );

  const [rating, setRating] = usePersistedState<number | "">(
    "system_rating",
    1,
    formIntent,
  );
  const [complexity, setComplexity] = usePersistedState<number | "">(
    "system_complexity",
    1,
    formIntent,
  );
  const [updatesNeeded, setUpdatesNeeded] = usePersistedState<boolean>(
    "system_updatesNeeded",
    false,
    formIntent,
  );
  const [description, setDescription] = useState<LexicalEditor>(createEditor());

  const resetSystemForm = () => {
    localStorage.removeItem("system_name");
    localStorage.removeItem("system_rating");
    localStorage.removeItem("system_complexity");
    localStorage.removeItem("system_updatesNeeded");
    localStorage.removeItem("hoster_description");
  };

  useEffect(() => {
    if (!system) {
      return;
    }

    setName(system.name);
    setRating(system.rating);
    setComplexity(system.complexity);
    setUpdatesNeeded(system.updatesNeeded);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system]);

  return {
    name,
    setName,
    rating,
    setRating,
    complexity,
    setComplexity,
    updatesNeeded,
    setUpdatesNeeded,
    description,
    setDescription,
    resetSystemForm,
  };
};

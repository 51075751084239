import React from "react";
import useStyles from "./styles";

type DescriptionFormatterProps = {
  description: string | null | undefined;
  descriptionClassName?: string;
};

export const DescriptionFormatter: React.FC<DescriptionFormatterProps> = ({
  description,
  descriptionClassName,
}) => {
  const { classes, cx } = useStyles();

  return description && description.trim() !== "" ? (
    <div
      className={cx(classes.description, descriptionClassName)}
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    ></div>
  ) : (
    <span className={classes.notAvailable}>N/A</span>
  );
};

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateGlobalSettingsInput } from "types";

export const useGlobalSettingsUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (globalSettingsInput: UpdateGlobalSettingsInput) => {
      const { data: updatedGlobalSettings } =
        await client.models.GlobalSettings.update(globalSettingsInput);

      return updatedGlobalSettings;
    },

    // When mutate is called:
    onMutate: async (newGlobalSettings) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.GlobalSettings],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.GlobalSettings],
      });

      // Snapshot the previous value
      const previousGlobalSettings = queryClient.getQueryData([
        QueryKeys.GlobalSettings,
      ]);

      // Optimistically update to the new value
      if (previousGlobalSettings) {
        queryClient.setQueryData(
          [QueryKeys.GlobalSettings],
          /**
           * `newGlobalSettings` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousGlobalSettings, ...newGlobalSettings },
        );
      }

      // Return a context with the previous and new globalSettings
      return {
        previousGlobalSettings,
        newGlobalSettings,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newGlobalSettings, context) => {
      console.error("Error updating record:", err, newGlobalSettings);
      if (context?.previousGlobalSettings) {
        queryClient.setQueryData(
          [QueryKeys.GlobalSettings],
          context.previousGlobalSettings,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newGlobalSettings) => {
      if (newGlobalSettings) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.GlobalSettings],
        });
      }
    },
  });

  return updateMutation;
};

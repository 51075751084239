import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { System, Website } from "types";
import { CustomButton } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import utils from "utils";

type SystemStatisticsProps = {
  websiteList: Website[];
  systemList: System[];
};

export const SystemStatistics: React.FC<SystemStatisticsProps> = ({
  websiteList,
  systemList,
}) => {
  const [colors, setColors] = useState<string[]>(
    systemList.map(() => utils.colors.getRandomColor()),
  );

  const loadNewColors = () => {
    setColors(systemList.map(() => utils.colors.getRandomColor()));
  };

  const systemStatisticsData = useMemo(() => {
    const systemWebsites = systemList.map((system) => ({
      system,
      websites: websiteList.filter((website) => website.systemID === system.id),
    }));

    return systemWebsites.map((systemWebsite, index) => ({
      name: systemWebsite.system.name,
      menge: systemWebsite.websites.length,
      fill: colors[index],
    }));
  }, [systemList, websiteList, colors]);

  console.log("systemStatisticsData: ", systemStatisticsData);

  return (
    <BoxHeadlineContainer
      boxTitle="System-Statistiken"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Neue Farben"
          onClick={() => loadNewColors()}
          size="small"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <BarChart height={300} data={systemStatisticsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="menge" />
        </BarChart>
      </ResponsiveContainer>
    </BoxHeadlineContainer>
  );
};

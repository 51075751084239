import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { QueryKeys } from "queryClient";
import { LastRunWebsiteTask, Website } from "types";
import { getTaskListByWebsiteByTaskType } from "modules/tasks/api";
import { useListQueryParams } from "../../../utility/useListQueryParams";
import { useWebsiteAllList } from "../useWebsiteAllList";

type WebsiteStatusListParamsType = {
  taskTypeID: string;
};

const getLastRunWebsiteTask = async (website: Website, taskTypeID: string) => {
  const lastRunTasks = await getTaskListByWebsiteByTaskType(
    website.id,
    taskTypeID,
  );

  const lastRunWebsiteTask: LastRunWebsiteTask = {
    id: nanoid(),
    website: website,
    task: lastRunTasks[0] ?? null,
  };

  return lastRunWebsiteTask;
};

export const useWebsiteStatusList = () => {
  const searchParams = useListQueryParams();

  const { taskTypeID } = useParams<WebsiteStatusListParamsType>();

  const { websiteList, isLoading: isLoadingWebsites } = useWebsiteAllList(true);

  const websiteIDs = useMemo(
    () => websiteList.map((website) => website.id),
    [websiteList],
  );

  const { data, isLoading: isLoadingLastRunTasks } = useQuery({
    queryKey: [QueryKeys.Websites, "status", websiteIDs],
    queryFn: async () => {
      if (!taskTypeID || websiteList.length === 0) {
        return [];
      }

      const lastRunWebsiteTaskPromises = websiteList.map((website) =>
        getLastRunWebsiteTask(website, taskTypeID),
      );

      const lastRunWebsiteTasks = await Promise.all(lastRunWebsiteTaskPromises);

      return lastRunWebsiteTasks;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const sortAndFilterByQueryParams = useCallback(
    (lastRunWebsiteTaskList: LastRunWebsiteTask[]) => {
      const lastRunParam = searchParams.get("lastRun");
      const filteredWebsiteList = lastRunWebsiteTaskList.filter((website) => {
        if (lastRunParam && lastRunParam !== "all") {
          if (lastRunParam === "withLastRun" && !website.task) {
            return false;
          }
          if (lastRunParam === "withoutLastRun" && website.task) {
            return false;
          }
        }

        return true;
      });

      if (
        searchParams.get("sortField") !== "websiteOwner" &&
        searchParams.get("sortField") !== "websitePayer" &&
        searchParams.get("sortField") !== "lastRun"
      ) {
        return filteredWebsiteList;
      }

      return filteredWebsiteList.sort((a, b) => {
        switch (searchParams.get("sortField")) {
          case "lastRun":
            let dateA = a.task?.date;
            let dateB = b.task?.date;

            if (dateA && !dateB) {
              return -1;
            }
            if (dateB && !dateA) {
              return 1;
            }
            if (!dateA || !dateB) {
              return 0;
            }
            return searchParams.get("sortOrder") === "asc"
              ? dateB.localeCompare(dateA)
              : dateA.localeCompare(dateB);
        }
        return 0;
      });
    },
    [searchParams],
  );

  const lastRunWebsiteTaskList = useMemo(
    () => sortAndFilterByQueryParams(data ?? []),
    [data, sortAndFilterByQueryParams],
  );

  return {
    lastRunWebsiteTaskList,
    isLoading: isLoadingWebsites || isLoadingLastRunTasks,
  };
};

import React from "react";
import { Grid } from "@mui/material";
import { SearchTextParamName, Ticket } from "types";
import { FilterRow, FilterWrapper } from "components";
import { BooleanFilter } from "modules/shared/filters/BooleanFilter";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";
import { getBillTypeOptions } from "modules/shared/options/BillTypeOption";
import { priorityOptionList } from "modules/shared/options/PriorityOption";
import { ticketStatusOptionList } from "modules/shared/options/TicketStatusOption";
import { CustomerFilter } from "./CustomerFilter";
import { DomainFilter } from "./DomainFilter";

type TicketListFilterProps = {
  ticketList: Ticket[];
};

export const TicketListFilter: React.FC<TicketListFilterProps> = ({
  ticketList,
}) => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <CustomerFilter ticketList={ticketList} />
      </Grid>
      <Grid item>
        <DomainFilter ticketList={ticketList} />
      </Grid>
      <Grid item>
        <OptionsFilter
          param="priority"
          paramName="Priorität"
          options={priorityOptionList}
        />
      </Grid>
    </FilterRow>
    <FilterRow>
      <Grid item>
        <OptionsFilter
          param="status"
          paramName="Status"
          options={ticketStatusOptionList}
        />
      </Grid>
      <Grid item>
        <OptionsFilter
          param="billType"
          paramName="Abrechnungsart"
          options={getBillTypeOptions()}
        />
      </Grid>
      <Grid item>
        <BooleanFilter param="billed" paramName="Abgerechnet" />
      </Grid>
      <Grid item>
        <SearchTextFilter
          searchTextParamName={SearchTextParamName.SearchTicket}
        />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

import { EditIcon, WebsiteIcon } from "modules/icons";
import { WebsitePage } from "modules/websites/websiteDetails/WebsitePage";
import { WebsiteFormPage } from "modules/websites/websiteForm/WebsiteFormPage";
import { WebsiteListPage } from "modules/websites/websitelist/WebsiteListPage";
import { USER_GROUPS } from "utils/constants";

export const customerwebsites = {
  key: "customerwebsites",
  path: "/customers/:customerID/websites",
  section: "customer",
  title: "Webseiten",
  view: <WebsiteListPage routeKey="customerwebsites" />,
  icon: <WebsiteIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    customerwebsitecreate: {
      key: "customerwebsitecreate",
      path: "/customers/:customerID/websites/create",
      section: "customer",
      title: "Webseite anlegen",
      view: <WebsiteFormPage formIntent="create" routeKey="customerwebsites" />,
      icon: <WebsiteIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    customerwebsite: {
      key: "customerwebsite",
      path: "/customers/:customerID/websites/:websiteID",
      section: "customer",
      title: "Webseite",
      view: <WebsitePage routeKey="customerwebsites" />,
      icon: <WebsiteIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        customerwebsiteedit: {
          key: "customerwebsiteedit",
          path: "/customers/:customerID/websites/:websiteID/edit",
          section: "customer",
          title: "Webseite bearbeiten",
          view: (
            <WebsiteFormPage formIntent="edit" routeKey="customerwebsites" />
          ),
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

import React from "react";
import logo from "modules/icons/graphics/Logo.svg";
import useStyles from "./styles";

export const LogoRotation: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.logoWrapper}>
      <img src={logo} className={classes.appLogo} alt="logo" />
    </div>
  );
};

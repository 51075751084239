import { EditIcon, InfoIcon, SystemIcon } from "modules/icons";
import { SystemPage } from "modules/systems/systemDetails/SystemPage";
import { SystemFormPage } from "modules/systems/systemForm/SystemFormPage";
import { SystemListPage } from "modules/systems/systemlist/SystemListPage";
import { systemeffortrules } from "./systems/systemeffortrules";
import { USER_GROUPS } from "utils/constants";

export const systems = {
  key: "systems",
  path: "/systems",
  section: "grunddaten",
  title: "Systems",
  view: <SystemListPage />,
  icon: <SystemIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    systemcreate: {
      key: "systemcreate",
      path: "/systems/create",
      section: "grunddaten",
      title: "System anlegen",
      view: <SystemFormPage formIntent="create" />,
      icon: <SystemIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    system: {
      key: "system",
      path: "/systems/:systemID",
      section: "system",
      title: "System",
      view: <SystemPage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        systemedit: {
          key: "systemedit",
          path: "/systems/:systemID/edit",
          section: "system",
          title: "System bearbeiten",
          view: <SystemFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
        systemeffortrules: systemeffortrules,
      },
    },
  },
};

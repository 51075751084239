import { useNavigate, useParams } from "react-router-dom";
import { Website } from "types";
import { Routes } from "routes";

type WebsiteRoutesParamsType = {
  customerID: string;
  templateID: string;
};

export const useWebsiteRoutes = () => {
  const navigate = useNavigate();

  const { customerID, templateID } = useParams<WebsiteRoutesParamsType>();

  const WebsiteIcon = Routes.websitemanagement.icon;

  const WebsiteNavigationRoot = Routes.websitemanagement;

  const getCreateWebsitePageRoute = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
  ) =>
    routeKey === "customerwebsites" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.subNavigations.customerwebsitecreate.path.replace(
          ":customerID",
          customerID,
        )
      : routeKey === "templatewebsites" && templateID !== undefined
        ? Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templatewebsites.subNavigations.templatewebsitecreate.path.replace(
            ":templateID",
            templateID,
          )
        : Routes.websitemanagement.subNavigations.websites.subNavigations
            .websitecreate.path;

  const getEditWebsitePageRoute = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
    website: Website,
  ) =>
    routeKey === "customerwebsites" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.subNavigations.customerwebsite.subNavigations.customerwebsiteedit.path
          .replace(":customerID", customerID)
          .replace(":websiteID", website.id)
      : routeKey === "templatewebsites" && templateID !== undefined
        ? Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templatewebsites.subNavigations.templatewebsite.subNavigations.templatewebsiteedit.path
            .replace(":templateID", templateID)
            .replace(":websiteID", website.id)
        : Routes.websitemanagement.subNavigations.websites.subNavigations.website.subNavigations.websiteedit.path.replace(
            ":websiteID",
            website.id,
          );

  const getWebsitePageRoute = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
    website: Website,
  ) =>
    routeKey === "customerwebsites" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.subNavigations.customerwebsite.path
          .replace(":customerID", customerID)
          .replace(":websiteID", website.id)
      : routeKey === "templatewebsites" && templateID !== undefined
        ? Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templatewebsites.subNavigations.templatewebsite.path
            .replace(":templateID", templateID)
            .replace(":websiteID", website.id)
        : Routes.websitemanagement.subNavigations.websites.subNavigations.website.path.replace(
            ":websiteID",
            website.id,
          );

  const getWebsiteListPageRoute = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
  ) =>
    routeKey === "customerwebsites" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.path.replace(
          ":customerID",
          customerID,
        )
      : routeKey === "templatewebsites" && templateID !== undefined
        ? Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templatewebsites.path.replace(
            ":templateID",
            templateID,
          )
        : Routes.websitemanagement.subNavigations.websites.path;

  const navigateToCreateWebsitePage = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
  ) => {
    navigate(getCreateWebsitePageRoute(routeKey));
  };

  const navigateToEditWebsitePage = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
    website: Website,
  ) => {
    navigate(getEditWebsitePageRoute(routeKey, website));
  };

  const navigateToWebsitePage = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
    website: Website,
  ) => {
    navigate(getWebsitePageRoute(routeKey, website));
  };

  const navigateToWebsiteListPage = (
    routeKey: "websites" | "customerwebsites" | "templatewebsites",
  ) => {
    const searchParams = sessionStorage.getItem(
      getWebsiteListPageRoute(routeKey),
    );

    navigate(
      searchParams
        ? getWebsiteListPageRoute(routeKey) + "?" + searchParams
        : getWebsiteListPageRoute(routeKey),
    );
  };

  const openWebsiteInBrowserHandler = (website: Website) => {
    window.open(
      website.url.match(/^https?:\/\//i)
        ? website.url
        : "http://" + website.url,
      "_blank",
    );
  };

  return {
    WebsiteIcon,
    WebsiteNavigationRoot,
    getCreateWebsitePageRoute,
    getEditWebsitePageRoute,
    getWebsitePageRoute,
    navigateToCreateWebsitePage,
    navigateToEditWebsitePage,
    navigateToWebsitePage,
    navigateToWebsiteListPage,
    openWebsiteInBrowserHandler,
  };
};

import React, { useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "context";
import { useCustomerAllList, useTaskAllList, useWebsiteAllList } from "hooks";
import { getBooking } from "modules/bookings/api";
import { getCustomer } from "modules/customers/api";
import { getHoster } from "modules/hosters/api";
import { getPermanentTask } from "modules/permanenttasks/api";
import { getSystem } from "modules/systems/api";
import { getTaskType } from "modules/tasktypes/api";
import { getTemplate } from "modules/templates/api";
import { getWebsite } from "modules/websites/api";

type StateContainerProps = {
  children: React.ReactNode;
};

type StateContainerParamsType = {
  customerID: string;
  systemID: string;
  templateID: string;
  hosterID: string;
  bookingID: string;
  taskTypeID: string;
  permanentTaskID: string;
  websiteID: string;
};

export const StateContainer: React.FC<StateContainerProps> = ({ children }) => {
  const authContext = useContext(AuthContext);

  const { customerList } = useCustomerAllList();
  const { taskList } = useTaskAllList();
  const { websiteList } = useWebsiteAllList();

  console.log("customerList: ", customerList);
  console.log("taskList: ", taskList);
  console.log("websiteList: ", websiteList);

  const {
    customerID,
    systemID,
    templateID,
    hosterID,
    bookingID,
    taskTypeID,
    permanentTaskID,
    websiteID,
  } = useParams<StateContainerParamsType>();

  const loadStateEntities = useCallback(async () => {
    authContext.setIsLoadingStateEntities(true);

    if (customerID && !authContext.customer) {
      console.log("Loading customer with ID: ", customerID);
      const customer = await getCustomer(customerID);
      authContext.setCustomer(customer);
    }

    if (systemID && !authContext.system) {
      console.log("Loading system with ID: ", systemID);
      const system = await getSystem(systemID);
      authContext.setSystem(system);
    }

    if (templateID && !authContext.template) {
      console.log("Loading template with ID: ", templateID);
      const template = await getTemplate(templateID);
      authContext.setTemplate(template);
    }

    if (hosterID && !authContext.hoster) {
      console.log("Loading hoster with ID: ", hosterID);
      const hoster = await getHoster(hosterID);
      authContext.setHoster(hoster);
    }

    if (bookingID && !authContext.booking) {
      console.log("Loading booking with ID: ", bookingID);
      const booking = await getBooking(bookingID);
      authContext.setBooking(booking);
    }

    if (taskTypeID && !authContext.taskType) {
      console.log("Loading taskType with ID: ", taskTypeID);
      const taskType = await getTaskType(taskTypeID);
      authContext.setTaskType(taskType);
    }

    if (permanentTaskID && !authContext.permanentTask) {
      console.log("Loading permanentTask with ID: ", permanentTaskID);
      const permanentTask = await getPermanentTask(permanentTaskID);
      authContext.setPermanentTask(permanentTask);
    }

    if (websiteID && !authContext.website) {
      console.log("Loading website with ID: ", websiteID);
      const website = await getWebsite(websiteID);
      authContext.setWebsite(website);
    }

    authContext.setIsLoadingStateEntities(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerID,
    systemID,
    templateID,
    hosterID,
    bookingID,
    permanentTaskID,
    websiteID,
  ]);

  useEffect(() => {
    loadStateEntities();
  }, [loadStateEntities]);

  return <>{children}</>;
};

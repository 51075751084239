import React, { useState } from "react";
import { useBookingUpdate, useReportEntryDelete, useTaskUpdate } from "hooks";
import {
  Booking,
  ReportEntry,
  UpdateBookingInput,
  UpdateTaskInput,
} from "types";
import { DeleteDialog } from "components";
import { setTicketsAsBilled } from "modules/tickets/api";

type CareReportDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  reportEntryList: ReportEntry[];
  booking: Booking | undefined;
};

export const CareReportDeleteDialog: React.FC<CareReportDeleteDialogProps> = ({
  booking,
  reportEntryList,
  dialogOpen,
  setDialogOpen,
}) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const updateBookingMutation = useBookingUpdate();
  const updateTaskMutation = useTaskUpdate();
  const deleteReportEntryMutation = useReportEntryDelete();

  if (!dialogOpen || !booking || !reportEntryList) {
    return null;
  }

  const deleteCareReportRequest = async () => {
    setDeleteLoading(true);

    const deleteReportEntryPromises = reportEntryList.map((reportEntry) =>
      deleteReportEntryMutation.mutateAsync(reportEntry),
    );

    const taskBilledPromises = reportEntryList
      .filter((reportEntry) => reportEntry.originalType === "task")
      .map((reportEntry) => {
        const updateTaskInput: UpdateTaskInput = {
          id: reportEntry.taskID!,
          billed: false,
        };

        return updateTaskMutation.mutateAsync(updateTaskInput);
      });

    const ticketIDs = reportEntryList
      .filter((reportEntry) => reportEntry.originalType === "ticket")
      .map((reportEntry) => reportEntry.ticketID) as string[];

    const updateBookingInput: UpdateBookingInput = {
      id: booking.id,
      completedDate: null,
    };

    await Promise.all([
      ...deleteReportEntryPromises,
      ...taskBilledPromises,
      updateBookingMutation.mutateAsync(updateBookingInput),
      setTicketsAsBilled(ticketIDs, false),
    ]);

    setDeleteLoading(false);
    setDialogOpen(false);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteCareReportRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Bericht Löschen"
      itemName={booking.month + "/" + booking.year}
      itemDeleteDescription="den Bericht"
    />
  );
};

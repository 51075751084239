import React, { useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { Booking, CreateBookingInput, Customer } from "types";
import {
  CustomButton,
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  CustomSwitch,
  LabeledTypography,
  SelectionButton,
} from "core";
import { CustomerSelectionDialog } from "modules/customers/customerselection/CustomerSelectionDialog";
import { CompanyIcon } from "modules/icons";
import { MonthOption } from "modules/shared/options/MonthOption";
import { useBookingForm } from "../useBookingForm";
import useStyles from "./styles";

type BookingFormProps = {
  formIntent: "create" | "edit";
  booking: Booking | null | undefined;
};

export type BookingFormHandles = {
  validateBookingForm(): CreateBookingInput | null;
};

const BookingFormComponent: React.ForwardRefRenderFunction<
  BookingFormHandles,
  BookingFormProps
> = ({ formIntent, booking }, bookingFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    customer,
    setCustomer,
    year,
    setYear,
    month,
    setMonth,
    bookedMinutes,
    setBookedMinutes,
    previousMinutes,
    setPreviousMinutes,
    finalMinutes,
    setFinalMinutes,
    paid,
    setPaid,
    resetBookingForm,
  } = useBookingForm(formIntent, booking);

  const [selectCustomerDialogOpen, setSelectCustomerDialogOpen] =
    useState<boolean>(false);

  const customerSelectionHandler = (customer: Customer) => {
    setCustomer(customer);
    setSelectCustomerDialogOpen(false);
  };

  const customerInputRef = useRef<CustomSelectHandles>(null);
  const monthInputRef = useRef<CustomSelectHandles>(null);
  const yearInputRef = useRef<CustomNumberFieldHandles>(null);
  const bookedMinutesInputRef = useRef<CustomNumberFieldHandles>(null);
  const previousMinutesInputRef = useRef<CustomNumberFieldHandles>(null);
  const finalMinutesInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(bookingFormRef, () => ({
    validateBookingForm: () => {
      if (!customer) {
        enqueueSnackbar("Bitte wähle einen Kunden aus!");
        customerInputRef.current?.highlight();
        return null;
      }

      if (year === "") {
        enqueueSnackbar("Bitte gib das Jahr ein!");
        yearInputRef.current?.highlight();
        return null;
      }

      if (!month) {
        enqueueSnackbar("Bitte wähle einen Monat aus!");
        monthInputRef.current?.highlight();
        return null;
      }

      if (bookedMinutes === "") {
        enqueueSnackbar("Bitte gib die gebuchten Minuten ein!");
        bookedMinutesInputRef.current?.highlight();
        return null;
      }

      if (previousMinutes === "") {
        enqueueSnackbar("Bitte gib die vorherigen Minuten ein!");
        previousMinutesInputRef.current?.highlight();
        return null;
      }

      if (finalMinutes === "") {
        enqueueSnackbar("Bitte gib die finalen Minuten ein!");
        finalMinutesInputRef.current?.highlight();
        return null;
      }

      const bookingFormInput: CreateBookingInput = {
        customerID: customer.id,
        year: year,
        month: parseInt(month.value),
        bookedMinutes: bookedMinutes,
        previousMinutes: previousMinutes,
        finalMinutes: finalMinutes,
        paid: paid,
      };

      resetBookingForm();

      return bookingFormInput;
    },
  }));

  return (
    <>
      <CustomerSelectionDialog
        dialogOpen={selectCustomerDialogOpen}
        setDialogOpen={setSelectCustomerDialogOpen}
        customerSelectionHandler={customerSelectionHandler}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          {customer ? (
            <SelectionButton
              label="Kunde"
              name={customer.Account_Name}
              onClick={() => setSelectCustomerDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Kunde"
              content={
                <CustomButton
                  text="Kunden auswählen"
                  onClick={() => setSelectCustomerDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <CompanyIcon className={classes.selectButtonIcon} />
                  }
                  ref={customerInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Jahr"
            number={year}
            setNumber={setYear}
            minValue={2000}
            ref={yearInputRef}
            required={true}
            thousandSeparator={false}
          />
        </Grid>

        <Grid item md={4}>
          <MonthOption
            month={month}
            setMonth={setMonth}
            monthInputRef={monthInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomNumberField
            label="Gebuchte Minuten"
            number={bookedMinutes}
            setNumber={setBookedMinutes}
            minValue={1}
            ref={bookedMinutesInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Minuten aus Vormonat"
            number={previousMinutes}
            setNumber={setPreviousMinutes}
            minValue={-99999999}
            ref={previousMinutesInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Finaler Minutenstand"
            number={finalMinutes}
            setNumber={setFinalMinutes}
            minValue={-99999999}
            ref={finalMinutesInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomSwitch
            label="Bezahlt?"
            switchLabel={paid ? "JA" : "NEIN"}
            name="Bezahlt?"
            checkedValue={paid}
            onChange={(_e, checked) => setPaid(checked)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const BookingForm = React.forwardRef(BookingFormComponent);

import React from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import {
  MaterialDesignContent,
  SnackbarKey,
  SnackbarProvider,
} from "notistack";
import {
  CloseIcon,
  FeatherAlertCircleIcon,
  FeatherAlertTriangleIcon,
  FeatherCheckCircleIcon,
  FeatherInfoIcon,
} from "modules/icons";
import useStyles from "./styles";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  top: 99,
  pointerEvents: "all !important" as "all",
  boxShadow:
    "0px 3px 5px -1px rgba(0,0,0,0.05),0px 6px 10px 0px rgba(0,0,0,0.1),0px 1px 18px 0px rgba(0,0,0,0.1)",
  paddingRight: 20,
  "& div": {
    fontSize: 15,
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: "#2D7738",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#970C0C",
  },
}));

type MessagesProviderProps = {
  children: React.ReactNode;
};

export const MessagesProvider: React.FC<MessagesProviderProps> = ({
  children,
}) => {
  const { classes } = useStyles();

  const notistackRef = React.createRef<SnackbarProvider>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    if (!notistackRef.current) {
      return;
    }

    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      preventDuplicate
      variant="error"
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      transitionDuration={{ enter: 400, exit: 500 }}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClickDismiss(key)}
        >
          <CloseIcon className={classes.closeIcon} fontSize="inherit" />
        </IconButton>
      )}
      iconVariant={{
        success: <FeatherCheckCircleIcon className={classes.icon} />,
        error: <FeatherAlertCircleIcon className={classes.icon} />,
        warning: <FeatherAlertTriangleIcon className={classes.icon} />,
        info: <FeatherInfoIcon className={classes.icon} />,
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

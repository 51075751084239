import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Template } from "types";
import { CustomButton } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import utils from "utils";

type UsageStatisticsProps = {
  templateList: Template[];
  templatesWithMissingLicences: Template[];
  templatesWithoutUsage: Template[];
};

export const UsageStatistics: React.FC<UsageStatisticsProps> = ({
  templateList,
  templatesWithMissingLicences,
  templatesWithoutUsage,
}) => {
  const [colors, setColors] = useState<string[]>(
    Array(3)
      .fill(1)
      .map(() => utils.colors.getRandomColor()),
  );

  const loadNewColors = () => {
    setColors(
      Array(3)
        .fill(1)
        .map(() => utils.colors.getRandomColor()),
    );
  };

  const usageStatisticsData = useMemo(
    () => [
      {
        name: "insgesamt",
        menge: templateList.length,
        fill: colors[0],
      },
      {
        name: "fehlende Lizenzen",
        menge: templatesWithMissingLicences.length,
        fill: colors[1],
      },
      {
        name: "ohne Verwendung",
        menge: templatesWithoutUsage.length,
        fill: colors[2],
      },
    ],
    [
      templateList.length,
      colors,
      templatesWithMissingLicences.length,
      templatesWithoutUsage.length,
    ],
  );

  console.log("usageStatisticsData: ", usageStatisticsData);

  return (
    <BoxHeadlineContainer
      boxTitle="Verwendung-Statistiken"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Neue Farben"
          onClick={() => loadNewColors()}
          size="small"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={500} height={250} data={usageStatisticsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="menge" />
        </BarChart>
      </ResponsiveContainer>
    </BoxHeadlineContainer>
  );
};

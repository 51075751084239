import React from "react";
import { useLocation } from "react-router-dom";
import { Drawer, Slide } from "@mui/material";
import { useAuthContext } from "context";
import { HeaderLogo } from "layout/Header/HeaderLogo";
import {
  RouteArrayType,
  Routes,
  RoutesArray,
  Sections,
  checkBookingRoute,
  checkCustomerBookingRoute,
  checkCustomerRoute,
  checkCustomerWebsiteRoute,
  checkHosterRoute,
  checkPermanentTaskRoute,
  checkSystemRoute,
  checkTaskTypeRoute,
  checkTemplateRoute,
  checkWebsiteRoute,
  getSubNavigationsAsArray,
} from "routes";
import { SidebarBackLink } from "./SidebarBackLink";
import { SidebarLockButton } from "./SidebarLockButton";
import { SidebarSubmenu } from "./SidebarSubmenu";
import { SidebarSubmenuHeader } from "./SidebarSubmenuHeader";
import { SidebarSubmenuLink } from "./SidebarSubmenuLink";
import { SidebarSubmenuList } from "./SidebarSubmenuList";
import { USER_GROUPS } from "utils/constants";
import useStyles from "./styles";

export const Sidebar: React.FC = () => {
  const { classes, cx } = useStyles();
  const authContext = useAuthContext();
  const { pathname } = useLocation();

  // const currentRoute = getCurrentRoute(pathname);

  const isCustomerRoute = checkCustomerRoute(pathname);

  const isWebsiteRoute = checkWebsiteRoute(pathname);
  const isCustomerWebsiteRoute = checkCustomerWebsiteRoute(pathname);

  const isCustomerBookingRoute = checkCustomerBookingRoute(pathname);
  const isBookingRoute = checkBookingRoute(pathname);

  const isSystemRoute = checkSystemRoute(pathname);
  const isTemplateRoute = checkTemplateRoute(pathname);
  const isHosterRoute = checkHosterRoute(pathname);
  const isPermanentTaskRoute = checkPermanentTaskRoute(pathname);

  const isTaskTypeRoute = checkTaskTypeRoute(pathname);

  const getSectionMenu = (
    subNavigations: RouteArrayType[],
    sectionKey: string,
    index: number | string,
    paramName?: string,
    paramValue?: string,
    param2Name?: string,
    param2Value?: string,
    param3Name?: string,
    param3Value?: string,
  ) => (
    <SidebarSubmenu key={index} submenuTitle={Sections[sectionKey]}>
      {subNavigations
        .filter((route) => route.section === sectionKey && route.navigation)
        .map((route, i) => {
          if (
            route.subNavigations.length &&
            route.subNavigations.some(
              (subNavigation) => subNavigation.navigation,
            )
          ) {
            return (
              <SidebarSubmenuList key={i} route={route}>
                {route.subNavigations
                  .filter((subRoute) => subRoute.navigation)
                  .map((subRoute, j) => (
                    <SidebarSubmenuLink
                      key={i + " " + j}
                      inSubmenuList={true}
                      route={subRoute}
                      param1Name={paramName}
                      param1Value={paramValue}
                      param2Name={param2Name}
                      param2Value={param2Value}
                      param3Name={param3Name}
                      param3Value={param3Value}
                    />
                  ))}
              </SidebarSubmenuList>
            );
          }

          return (
            <SidebarSubmenuLink
              key={i}
              inSubmenuList={false}
              route={route}
              param1Name={paramName}
              param1Value={paramValue}
              param2Name={param2Name}
              param2Value={param2Value}
              param3Name={param3Name}
              param3Value={param3Value}
            />
          );
        })}
    </SidebarSubmenu>
  );

  const getCustomerMenu = () => {
    const customerSubNavigations = getSubNavigationsAsArray(
      Routes.customers.subNavigations.customer.subNavigations,
    );
    const customerSubNavigationSections = customerSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = customerSubNavigationSections.filter(
      (section, index) =>
        customerSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        customerSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          customerSubNavigations,
          sectionKey,
          i,
          ":customerID",
          authContext.customer?.id,
        ),
      );
  };

  const getWebsiteMenu = () => {
    const websiteSubNavigations = getSubNavigationsAsArray(
      isCustomerWebsiteRoute
        ? Routes.customers.subNavigations.customer.subNavigations
            .customerwebsites.subNavigations.customerwebsite.subNavigations
        : Routes.websitemanagement.subNavigations.websites.subNavigations
            .website.subNavigations,
    );
    const websiteSubNavigationSections = websiteSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = websiteSubNavigationSections.filter(
      (section, index) =>
        websiteSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        websiteSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          websiteSubNavigations,
          sectionKey,
          i,
          ":websiteID",
          authContext.website?.id,
          ":customerID",
          authContext.customer?.id,
        ),
      );
  };

  const getBookingMenu = () => {
    const bookingSubNavigations = getSubNavigationsAsArray(
      isCustomerBookingRoute
        ? Routes.customers.subNavigations.customer.subNavigations
            .customerbookings.subNavigations.customerbooking.subNavigations
        : Routes.carebookings.subNavigations.bookings.subNavigations.booking
            .subNavigations,
    );
    const bookingSubNavigationSections = bookingSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = bookingSubNavigationSections.filter(
      (section, index) =>
        bookingSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        bookingSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          bookingSubNavigations,
          sectionKey,
          i,
          ":bookingID",
          authContext.booking?.id,
          ":customerID",
          authContext.customer?.id,
        ),
      );
  };

  const getSystemMenu = () => {
    const systemSubNavigations = getSubNavigationsAsArray(
      Routes.systems.subNavigations.system.subNavigations,
    );
    const systemSubNavigationSections = systemSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = systemSubNavigationSections.filter(
      (section, index) =>
        systemSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        systemSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          systemSubNavigations,
          sectionKey,
          i,
          ":systemID",
          authContext.system?.id,
        ),
      );
  };

  const getTemplateMenu = () => {
    const templateSubNavigations = getSubNavigationsAsArray(
      Routes.templatemanagement.subNavigations.templates.subNavigations.template
        .subNavigations,
    );
    const templateSubNavigationSections = templateSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = templateSubNavigationSections.filter(
      (section, index) =>
        templateSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        templateSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          templateSubNavigations,
          sectionKey,
          i,
          ":templateID",
          authContext.template?.id,
        ),
      );
  };

  const getHosterMenu = () => {
    const hosterSubNavigations = getSubNavigationsAsArray(
      Routes.hosters.subNavigations.hoster.subNavigations,
    );
    const hosterSubNavigationSections = hosterSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = hosterSubNavigationSections.filter(
      (section, index) =>
        hosterSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        hosterSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          hosterSubNavigations,
          sectionKey,
          i,
          ":hosterID",
          authContext.hoster?.id,
        ),
      );
  };

  const getPermanentTaskMenu = () => {
    const permanentTaskSubNavigations = getSubNavigationsAsArray(
      Routes.permanenttasks.subNavigations.permanenttask.subNavigations,
    );
    const permanentTaskSubNavigationSections = permanentTaskSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = permanentTaskSubNavigationSections.filter(
      (section, index) =>
        permanentTaskSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        permanentTaskSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          permanentTaskSubNavigations,
          sectionKey,
          i,
          ":permanentTaskID",
          authContext.permanentTask?.id,
        ),
      );
  };

  const getTaskTypeMenu = () => {
    const taskTypeSubNavigations = getSubNavigationsAsArray(
      Routes.tasktypes.subNavigations.tasktype.subNavigations,
    );
    const taskTypeSubNavigationSections = taskTypeSubNavigations.map(
      (subNavigation) => subNavigation.section,
    );
    const sections = taskTypeSubNavigationSections.filter(
      (section, index) =>
        taskTypeSubNavigationSections.indexOf(section) === index,
    );

    return sections
      .filter((sectionKey) =>
        taskTypeSubNavigations.some(
          (route) => route.section === sectionKey && route.navigation,
        ),
      )
      .map((sectionKey, i) =>
        getSectionMenu(
          taskTypeSubNavigations,
          sectionKey,
          i,
          ":taskTypeID",
          authContext.taskType?.id,
        ),
      );
  };

  const isNotSubMenuRoute =
    !isCustomerRoute &&
    !isWebsiteRoute &&
    !isBookingRoute &&
    !isSystemRoute &&
    !isTemplateRoute &&
    !isHosterRoute &&
    !isPermanentTaskRoute &&
    !isTaskTypeRoute;

  return (
    <Drawer
      className={cx(classes.drawer, {
        [classes.drawerOpen]: authContext.menuOpen,
        [classes.drawerClose]: !authContext.menuOpen,
      })}
      variant="permanent"
      classes={{
        paper: cx(classes.drawerPaper, {
          [classes.drawerOpen]: authContext.menuOpen,
          [classes.drawerClose]: !authContext.menuOpen,
        }),
      }}
      onMouseEnter={() =>
        !authContext.menuLocked &&
        !authContext.menuOpen &&
        authContext.setMenuOpen(true)
      }
      onMouseLeave={() =>
        !authContext.menuLocked && authContext.setMenuOpen(false)
      }
      anchor="left"
    >
      <HeaderLogo layoutType="layout" />

      <SidebarLockButton />
      <div className={classes.drawerMenuItems}>
        {(authContext.cognitoUser?.currentGroup.groupID === USER_GROUPS.ADMIN ||
          authContext.cognitoUser?.currentGroup.groupID ===
            USER_GROUPS.OFFICE) &&
        isNotSubMenuRoute
          ? RoutesArray.filter(
              (route) =>
                route.section === "general" &&
                route.navigation &&
                (!authContext.cognitoUser ||
                  route.groups.includes(
                    authContext.cognitoUser.currentGroup.groupID,
                  )),
            ).map((route) => (
              <SidebarSubmenuLink
                inSubmenuList={false}
                key={route.key}
                route={route}
              />
            ))
          : null}

        {isCustomerRoute ? (
          <Slide
            direction="right"
            in={isCustomerRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.customers.path}
                title="Zurück zur Kundenliste"
              />
              <SidebarSubmenuHeader
                icon={Routes.customers.icon}
                link={Routes.customers.subNavigations.customer.path.replace(
                  ":customerID",
                  authContext.customer?.id ?? "",
                )}
                title={authContext.customer?.Account_Name}
                subTitle={authContext.customer?.Kundennummer}
              />
              {getCustomerMenu()}
            </div>
          </Slide>
        ) : null}

        {isWebsiteRoute ? (
          <Slide
            direction="right"
            in={isWebsiteRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={
                  isCustomerWebsiteRoute
                    ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.path.replace(
                        ":customerID",
                        authContext.customer?.id ?? "",
                      )
                    : Routes.websitemanagement.subNavigations.websites.path
                }
                title="Zurück zur Webseitenliste"
              />
              <SidebarSubmenuHeader
                icon={Routes.websitemanagement.subNavigations.websites.icon}
                link={Routes.websitemanagement.subNavigations.websites.subNavigations.website.path.replace(
                  ":websiteID",
                  authContext.website?.id ?? "",
                )}
                title={authContext.website?.url}
                subTitle={authContext.website?.websiteOwner}
              />
              {getWebsiteMenu()}
            </div>
          </Slide>
        ) : null}

        {isBookingRoute ? (
          <Slide
            direction="right"
            in={isBookingRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={
                  isCustomerBookingRoute
                    ? Routes.customers.subNavigations.customer.subNavigations.customerbookings.path.replace(
                        ":customerID",
                        authContext.customer?.id ?? "",
                      )
                    : Routes.carebookings.subNavigations.bookings.path
                }
                title="Zurück zu Pflegebuchungen"
              />
              <SidebarSubmenuHeader
                icon={Routes.carebookings.subNavigations.bookings.icon}
                link={Routes.carebookings.subNavigations.bookings.subNavigations.booking.path.replace(
                  ":bookingID",
                  authContext.booking?.id ?? "",
                )}
                title={
                  authContext.booking?.month + "/" + authContext.booking?.year
                }
                subTitle={authContext.booking?.customerID}
              />
              {getBookingMenu()}
            </div>
          </Slide>
        ) : null}

        {isSystemRoute ? (
          <Slide
            direction="right"
            in={isSystemRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.systems.path}
                title="Zurück zu Systeme"
              />
              <SidebarSubmenuHeader
                icon={Routes.systems.icon}
                link={Routes.systems.subNavigations.system.path.replace(
                  ":systemID",
                  authContext.system?.id ?? "",
                )}
                title={authContext.system?.name}
                subTitle={"Komplexität: " + authContext.system?.complexity}
              />
              {getSystemMenu()}
            </div>
          </Slide>
        ) : null}

        {isTemplateRoute ? (
          <Slide
            direction="right"
            in={isTemplateRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.templatemanagement.subNavigations.templates.path}
                title="Zurück zu Templates"
              />
              <SidebarSubmenuHeader
                icon={Routes.templatemanagement.subNavigations.templates.icon}
                link={Routes.templatemanagement.subNavigations.templates.subNavigations.template.path.replace(
                  ":templateID",
                  authContext.template?.id ?? "",
                )}
                title={authContext.template?.name}
                subTitle={
                  "Lizenzen: " + authContext.template?.purchasedLicences
                }
              />
              {getTemplateMenu()}
            </div>
          </Slide>
        ) : null}

        {isHosterRoute ? (
          <Slide
            direction="right"
            in={isHosterRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.hosters.path}
                title="Zurück zu Hoster"
              />
              <SidebarSubmenuHeader
                icon={Routes.hosters.icon}
                link={Routes.hosters.subNavigations.hoster.path.replace(
                  ":hosterID",
                  authContext.hoster?.id ?? "",
                )}
                title={authContext.hoster?.name}
                subTitle={"Bewertung: " + authContext.hoster?.rating}
              />
              {getHosterMenu()}
            </div>
          </Slide>
        ) : null}

        {isPermanentTaskRoute ? (
          <Slide
            direction="right"
            in={isPermanentTaskRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.permanenttasks.path}
                title="Zurück zu Daueraufgaben"
              />
              <SidebarSubmenuHeader
                icon={Routes.permanenttasks.icon}
                link={Routes.permanenttasks.subNavigations.permanenttask.path.replace(
                  ":permanentTaskID",
                  authContext.permanentTask?.id ?? "",
                )}
                title={authContext.permanentTask?.taskType.name}
                subTitle={authContext.permanentTask?.website.url}
              />
              {getPermanentTaskMenu()}
            </div>
          </Slide>
        ) : null}

        {isTaskTypeRoute ? (
          <Slide
            direction="right"
            in={isTaskTypeRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              <SidebarBackLink
                link={Routes.tasktypes.path}
                title="Zurück zu Aufgaben-Typen"
              />
              <SidebarSubmenuHeader
                icon={Routes.tasktypes.icon}
                link={Routes.tasktypes.subNavigations.tasktype.path.replace(
                  ":taskTypeID",
                  authContext.taskType?.id ?? "",
                )}
                title={authContext.taskType?.name}
                subTitle={"Aufwand: " + authContext.taskType?.effort + " m"}
              />
              {getTaskTypeMenu()}
            </div>
          </Slide>
        ) : null}

        {/* ADMIN and OFFICE */}
        {(authContext.cognitoUser?.currentGroup.groupID === USER_GROUPS.ADMIN ||
          authContext.cognitoUser?.currentGroup.groupID ===
            USER_GROUPS.OFFICE) &&
        isNotSubMenuRoute ? (
          <Slide
            direction="right"
            in={!isCustomerRoute}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <div>
              {Object.keys(Sections)
                .filter((sectionKey) =>
                  RoutesArray.some(
                    (route) =>
                      route.section === sectionKey &&
                      route.navigation &&
                      (!authContext.cognitoUser ||
                        route.groups.includes(
                          authContext.cognitoUser.currentGroup.groupID,
                        )),
                  ),
                )
                .map((sectionKey, i) =>
                  getSectionMenu(RoutesArray, sectionKey, i),
                )}
            </div>
          </Slide>
        ) : null}
      </div>
    </Drawer>
  );
};

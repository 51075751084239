import { TransferProgressEvent, getUrl, uploadData } from "aws-amplify/storage";
import { S3Resource } from "types";
import awsconfig from "../../../amplify_outputs.json";
import utils from "utils";

type SafeSearchAnnotation = {
  adult: string;
  medical: string;
  racy: string;
  spoof: string;
  violence: string;
};

export const uploadS3Resource = async (
  image: S3Resource | null,
  imageFolder: string,
  setUploading: (value: React.SetStateAction<boolean>) => void,
  setUploadProgress: (value: React.SetStateAction<number>) => void,
) => {
  if (!image) {
    return null;
  }
  console.log("image to upload is: ", image);
  if (!image.fromDatabase) {
    setUploading(true);

    const s3Key = `${imageFolder}/${image.s3ResourceID}.${image.extension}`;

    await uploadData({
      key: s3Key,
      data: image as unknown as Blob,
      options: {
        onProgress: (progress: TransferProgressEvent) => {
          if (!progress.totalBytes) return;

          console.log(
            "Setting uploadProgress to: ",
            (progress.transferredBytes / progress.totalBytes) * 100,
          );
          setUploadProgress(
            (progress.transferredBytes / progress.totalBytes) * 100,
          );
        },
      },
    });

    image.bucket = awsconfig.storage.bucket_name;
    image.region = awsconfig.storage.aws_region;
    image.key = s3Key;

    setUploadProgress(10);
    setUploading(false);
  }

  console.log("image: ", image);

  const uploadedResource: S3Resource = {
    s3ResourceID: image.s3ResourceID,
    type: image.type,
    extension: image.extension,
    size: image.size,
    preview: image.preview,
    bucket: image.bucket,
    key: image.key,
    region: image.region,
    fromDatabase: image.fromDatabase,
    uploadStatus: image.uploadStatus,
    uploading: image.uploading,
    processing: image.processing,
    safeSearchRequestSuccess: image.safeSearchRequestSuccess,
    isSafeSearch: image.isSafeSearch,
  };

  uploadedResource.preview = image.extension.includes("svg")
    ? await getFileContentAsString(image as unknown as Blob)
    : getImage(image, 500, 500);

  return uploadedResource;
};

export const getFileSrc = async (file: S3Resource) => {
  if (file) {
    const fileSrc = await getUrl({
      key: file.key,
    });

    if (!fileSrc || typeof fileSrc !== "string") {
      return "/img/no-image.svg";
    }
    return fileSrc;
  }
  return "/img/no-image.svg";
};

export const loadS3Resource = async (
  s3Resource: S3Resource | null | undefined,
) => {
  if (s3Resource) {
    const fileSrc = await getUrl({
      key: s3Resource.key,
    });

    s3Resource.preview =
      !fileSrc || typeof fileSrc !== "string" ? "/img/no-image.svg" : fileSrc;
  }
  return s3Resource;
};

export const loadDefinedS3Resource = async (s3Resource: S3Resource) => {
  const fileSrc = await getUrl({
    key: s3Resource.key,
  });

  s3Resource.preview =
    !fileSrc || typeof fileSrc !== "string" ? "/img/no-image.svg" : fileSrc;
  return s3Resource;
};

export const getS3ResourceFileSrc = async (s3Resource: S3Resource) => {
  const fileSrc = await getUrl({
    key: s3Resource.key,
  });

  return fileSrc;
};

export const getFileContentAsString = (file: Blob): Promise<string> => {
  return new Promise(function (resolve) {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      if (evt.target?.result) {
        resolve(typeof evt.target.result === "string" ? evt.target.result : "");
      } else {
        resolve("");
      }
    };
    reader.onerror = function () {
      resolve("");
    };
  });
};

export const getImage = (
  s3Image: S3Resource,
  width?: number,
  height?: number,
  fit?: "cover" | "contain" | "inside" | "outside" | "fill",
) => {
  const cloudFrontURL =
    "https://ub6c0tligf.execute-api.eu-central-1.amazonaws.com/image/";
  const input: { [k: string]: any } = {
    bucket: s3Image.bucket,
    key: "public/" + s3Image.key,
  };

  if (width || height || fit) {
    input.edits = {
      resize: {
        width: width,
        height: height,
        fit: fit ?? "cover",
      },
    };
  }

  const imageRequest = JSON.stringify(input);

  return `${cloudFrontURL}/${btoa(imageRequest)}`;
};
export const loadImagePreview = async (
  image: S3Resource | null | undefined,
  fromDatabase: boolean,
) => {
  if (!image) {
    return null;
  }

  const imageWithPreview: S3Resource = {
    ...image,
    fromDatabase: fromDatabase,
  };

  if (!image.extension.includes("svg")) {
    const imageSrc = await getFileSrc(image);
    imageWithPreview.preview = imageSrc;
  }

  return imageWithPreview;
};

export const loadImagePreviews = async (
  images: S3Resource[] | null | undefined,
  fromDatabase: boolean,
) => {
  if (!images) {
    return [];
  }

  const imagePromises = images.map((image) =>
    loadImagePreview(image, fromDatabase),
  );

  const imagePreviews = await Promise.all(imagePromises);

  const imagePreviewsFiltered = utils.graphql.getDefinedItems(imagePreviews);

  return imagePreviewsFiltered;
};

export const safeSearchImage = async (imageFile: S3Resource) => {
  try {
    const imageBase64 = await toBase64(imageFile as unknown as File);

    utils.logger.info("imageBase64: ", imageBase64);

    const safeSearchRequest = await fetch(
      "https://vision.googleapis.com/v1/images:annotate?&key=AIzaSyA5L7r4gSvYvpXbOcNQfBzNEOw8Z-mII8E",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          requests: [
            {
              image: {
                content: imageBase64,
              },
              features: [
                {
                  type: "SAFE_SEARCH_DETECTION",
                },
              ],
            },
          ],
        }),
      },
    );

    const safeSearchResponse = await safeSearchRequest.json();

    utils.logger.info("safeSearchResponse: ", safeSearchResponse);

    if (!safeSearchResponse?.responses[0]?.safeSearchAnnotation) {
      throw new Error("Malformed response from safeSearchImage response!");
    }

    const safeSearchAnnotation =
      safeSearchResponse.responses[0].safeSearchAnnotation;

    const isSafeSearch = checkSafeSearchAnnotation(safeSearchAnnotation);

    imageFile.safeSearchRequestSuccess = true;
    imageFile.isSafeSearch = isSafeSearch;
  } catch (err) {
    utils.errorHandling.logToSentry(
      "Error on safeSearchImage request!",
      "utils -> images",
      err,
      null,
    );
    imageFile.safeSearchRequestSuccess = false;
  }
};

export const safeSearchPDF = async (imageFile: S3Resource) => {
  try {
    const imageBase64 = await toBase64(imageFile as unknown as File);

    utils.logger.info("imageBase64: ", imageBase64);

    const safeSearchRequest = await fetch(
      "https://vision.googleapis.com/v1/files:annotate?&key=API_KEYHERE",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          requests: [
            {
              inputConfig: {
                content: imageBase64,
                mimeType: "application/pdf",
              },
              features: [
                {
                  type: "SAFE_SEARCH_DETECTION",
                },
              ],
            },
          ],
        }),
      },
    );

    const safeSearchResponse = await safeSearchRequest.json();

    // PDF could have multiple pages -> multiple responses in first response
    if (!safeSearchResponse?.responses[0]?.responses?.length) {
      throw new Error("Malformed response from safeSearchImage response!");
    }

    // If a single page is not safe search, set result to false and return
    for (const pdfPage of safeSearchResponse.responses[0].responses) {
      const safeSearchAnnotation = pdfPage.safeSearchAnnotation;
      const isSafeSearch = checkSafeSearchAnnotation(safeSearchAnnotation);

      if (!isSafeSearch) {
        imageFile.safeSearchRequestSuccess = true;
        imageFile.isSafeSearch = false;
        return;
      }
    }

    imageFile.safeSearchRequestSuccess = true;
    imageFile.isSafeSearch = true;
  } catch (err) {
    utils.errorHandling.logToSentry(
      "Error on safeSearchPDF request!",
      "utils -> images",
      err,
      null,
    );
    imageFile.safeSearchRequestSuccess = false;
  }
};

const checkSafeSearchAnnotation = (
  safeSearchAnnotation: SafeSearchAnnotation,
) => {
  console.log("safeSearchAnnotation: ", safeSearchAnnotation);
  // if (
  //   safeSearchAnnotation.adult !== "VERY_UNLIKELY" &&
  //   safeSearchAnnotation.adult !== "UNLIKELY"
  // ) {
  //   return false;
  // }
  // if (
  //   safeSearchAnnotation.medical !== "VERY_UNLIKELY" &&
  //   safeSearchAnnotation.medical !== "UNLIKELY" &&
  //   safeSearchAnnotation.medical !== "POSSIBLE" &&
  //   safeSearchAnnotation.medical !== "UNKNOWN"
  // ) {
  //   return false;
  // }
  // if (
  //   safeSearchAnnotation.racy !== "VERY_UNLIKELY" &&
  //   safeSearchAnnotation.racy !== "UNLIKELY" &&
  //   safeSearchAnnotation.racy !== "UNKNOWN"
  // ) {
  //   return false;
  // }
  // if (
  //   safeSearchAnnotation.spoof !== "VERY_UNLIKELY" &&
  //   safeSearchAnnotation.spoof !== "UNLIKELY" &&
  //   safeSearchAnnotation.spoof !== "UNKNOWN"
  // ) {
  //   return false;
  // }
  // if (
  //   safeSearchAnnotation.violence !== "VERY_UNLIKELY" &&
  //   safeSearchAnnotation.violence !== "UNLIKELY"
  // ) {
  //   return false;
  // }
  return true;
};

export const toBase64 = (file: File, keepMimeType = false) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (keepMimeType) {
        resolve(reader.result);
      } else {
        const base64WithMimeType = reader.result as string;
        const base64 = base64WithMimeType.split(",")[1];
        resolve(base64);
      }
    };
    reader.onerror = (error) => reject(error);
  });

export const s3ResourceIsVideo = (
  s3Resource: S3Resource | null | undefined,
) => {
  if (!s3Resource) {
    return false;
  }

  const extensions = [
    "webm",
    "mkv",
    "flv",
    "vob",
    "ogv",
    "ogg",
    "rrc",
    "gifv",
    "mng",
    "mov",
    "avi",
    "qt",
    "wmv",
    "yuv",
    "rm",
    "asf",
    "amv",
    "mp4",
    "m4p",
    "m4v",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "m4v",
    "svi",
    "3gp",
    "3g2",
    "mxf",
    "roq",
    "nsv",
    "flv",
    "f4v",
    "f4p",
    "f4a",
    "f4b",
    "mod",
  ];

  return extensions.includes(s3Resource.extension.toLowerCase());
};

export const s3ResourceIsImage = (
  s3Resource: S3Resource | null | undefined,
) => {
  if (!s3Resource) {
    return false;
  }

  const extensions = [
    "jpg",
    "jpeg",
    "jpe jif",
    "jfif",
    "jfi",
    "png",
    "gif",
    "webp",
    "tiff",
    "tif",
    "raw",
    "arw",
    "cr2",
    "nrw",
    "k25",
    "bmp",
    "dib",
    "heif",
    "heic",
    "jp2",
    "j2k",
    "jpf",
    "jpx",
    "jpm",
    "mj2",
    "svg",
    "svgz",
  ];

  return extensions.includes(s3Resource.extension.toLowerCase());
};

import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CustomReactTable } from "core";
import { CustomReportEntryType } from "modules/carereport/api";

type WebsiteReportListTableProps = {
  customReportEntryList: CustomReportEntryType[];
};

const WebsiteReportListTable: React.FC<WebsiteReportListTableProps> = ({
  customReportEntryList,
}) => {
  const defaultColumns = React.useMemo<ColumnDef<CustomReportEntryType>[]>(
    () => [
      {
        header: "Urpsrünglicher Typ",
        accessorKey: "entryType",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Ticket",
        accessorKey: "ticket",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Pauschalaufgabe",
        accessorKey: "task",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Typbezeichnung",
        accessorKey: "type",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwand in m",
        accessorKey: "effort",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  return (
    <>
      <CustomReactTable<CustomReportEntryType>
        data={customReportEntryList}
        columns={defaultColumns}
        selectable={false}
      />
    </>
  );
};

export default WebsiteReportListTable;

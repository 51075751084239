import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import utils from "utils";

export const requestAddressInfo = async (address: string) => {
  utils.logger.info("requestAddressInfo called");
  const geoResult = {
    addressResult: "",
    houseNumberResult: "",
    postalCodeResult: "",
    cityResult: "",
    stateResult: "",
    countryResult: "",
    latitude: "",
    longitude: "",
  };

  try {
    utils.logger.info("address: ", address);
    const results = await geocodeByAddress(address);

    if (!results.length || !("address_components" in results[0])) {
      return geoResult;
    }

    const adressComponents = results[0].address_components;
    utils.logger.info("adressComponents: ", adressComponents);
    let administrativeAreaLevel = -1;

    for (const adressComponent of adressComponents) {
      if (adressComponent.types.includes("street_number")) {
        if ("long_name" in adressComponent) {
          geoResult.houseNumberResult = adressComponent.long_name;
        }
      }
      if (adressComponent.types.includes("route")) {
        if ("long_name" in adressComponent) {
          geoResult.addressResult = adressComponent.long_name;
        }
      }
      if (adressComponent.types.includes("postal_code")) {
        if ("long_name" in adressComponent) {
          geoResult.postalCodeResult = adressComponent.long_name;
        }
      }
      if (adressComponent.types.includes("country")) {
        if ("long_name" in adressComponent) {
          geoResult.countryResult = adressComponent.long_name;
        }
      }

      if (adressComponent.types.includes("locality")) {
        if ("long_name" in adressComponent) {
          geoResult.cityResult = adressComponent.long_name;
          administrativeAreaLevel = 4;
        }
      }
      if (
        adressComponent.types.includes("administrative_area_level_3") &&
        administrativeAreaLevel < 3
      ) {
        if ("long_name" in adressComponent) {
          geoResult.cityResult = adressComponent.long_name;
          administrativeAreaLevel = 3;
        }
      }
      if (
        adressComponent.types.includes("administrative_area_level_2") &&
        administrativeAreaLevel < 2
      ) {
        if ("long_name" in adressComponent) {
          geoResult.cityResult = adressComponent.long_name;
          administrativeAreaLevel = 2;
        }
      }
      if (adressComponent.types.includes("administrative_area_level_1")) {
        if ("long_name" in adressComponent) {
          if (administrativeAreaLevel < 1) {
            geoResult.cityResult = adressComponent.long_name;
            administrativeAreaLevel = 1;
          }
          geoResult.stateResult = adressComponent.long_name;
        }
      }
    }

    const latLng = await getLatLng(results[0]);

    geoResult.latitude = latLng.lat + "";
    geoResult.longitude = latLng.lng + "";

    return geoResult;
  } catch (err) {
    utils.errorHandling.logToSentry(
      "Error on requestAddressInfo!",
      "utils -> geo",
      err,
      null
    );
    return geoResult;
  }
};

export const getDistanceValue = (distance: string) => {
  const distanceValue = parseFloat(distance);

  if (distanceValue < 1000) {
    return distanceValue.toFixed(0) + " m";
  }

  return (distanceValue / 1000).toFixed(1) + " km";
};

import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Website } from "types";
import { CustomButton } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { getBacklinkStatusOptions } from "modules/shared/options/BacklinkStatusOption";
import utils from "utils";

type BacklinkStatisticsProps = {
  websiteList: Website[];
};

export const BacklinkStatistics: React.FC<BacklinkStatisticsProps> = ({
  websiteList,
}) => {
  const backlinkStatusOptionList = getBacklinkStatusOptions();

  const backlinkStatisticsData = useMemo(() => {
    const backlinkErstellung: { [key: string]: number | string } = {
      name: "GW Backlink Erstellung",
    };
    const backlinkPflege: { [key: string]: number | string } = {
      name: "GW Backlink Pflege",
    };
    const formiloBacklink: { [key: string]: number | string } = {
      name: "Formilo Backlink",
    };

    for (const backlinkStatusOption of backlinkStatusOptionList) {
      backlinkErstellung[backlinkStatusOption.label] = websiteList.filter(
        (website) => website.backlinkErstellung === backlinkStatusOption.value,
      ).length;
      backlinkPflege[backlinkStatusOption.label] = websiteList.filter(
        (website) => website.backlinkPflege === backlinkStatusOption.value,
      ).length;
      formiloBacklink[backlinkStatusOption.label] = websiteList.filter(
        (website) => website.formiloBacklink === backlinkStatusOption.value,
      ).length;
    }

    return [backlinkErstellung, backlinkPflege, formiloBacklink];
  }, [websiteList]);

  console.log("backlinkStatisticsData: ", backlinkStatisticsData);

  const [colors, setColors] = useState<string[]>(
    backlinkStatusOptionList.map(() => utils.colors.getRandomColor()),
  );

  const loadNewColors = () => {
    setColors(
      backlinkStatusOptionList.map(() => utils.colors.getRandomColor()),
    );
  };

  return (
    <BoxHeadlineContainer
      boxTitle="Backlink-Statistiken"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Neue Farben"
          onClick={() => loadNewColors()}
          size="small"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <BarChart height={300} data={backlinkStatisticsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          {backlinkStatusOptionList.map((backlinkStatusOption, index) => (
            <Bar
              dataKey={backlinkStatusOption.label}
              stackId="a"
              fill={colors[index]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </BoxHeadlineContainer>
  );
};

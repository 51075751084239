import { EffortRulePage } from "modules/effortrules/effortruleDetails/EffortRulePage";
import { EffortRuleFormPage } from "modules/effortrules/effortruleForm/EffortRuleFormPage";
import { EffortRuleListPage } from "modules/effortrules/effortrulelist/EffortRuleListPage";
import { EditIcon, EffortRuleIcon } from "modules/icons";
import { USER_GROUPS } from "utils/constants";

export const systemeffortrules = {
  key: "systemeffortrules",
  path: "/systems/:systemID/effortrules",
  section: "system",
  title: "Aufwandsregeln",
  view: <EffortRuleListPage routeKey="systemeffortrules" />,
  icon: <EffortRuleIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    systemeffortrulecreate: {
      key: "systemeffortrulecreate",
      path: "/systems/:systemID/effortrules/create",
      section: "system",
      title: "Aufwandsregel anlegen",
      view: (
        <EffortRuleFormPage formIntent="create" routeKey="systemeffortrules" />
      ),
      icon: <EffortRuleIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    systemeffortrule: {
      key: "systemeffortrule",
      path: "/systems/:systemID/effortrules/:effortRuleID",
      section: "system",
      title: "Aufwandsregel",
      view: <EffortRulePage routeKey="systemeffortrules" />,
      icon: <EffortRuleIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        systemeffortruleedit: {
          key: "systemeffortruleedit",
          path: "/systems/:systemID/effortrules/:effortRuleID/edit",
          section: "system",
          title: "Aufwandsregel bearbeiten",
          view: (
            <EffortRuleFormPage
              formIntent="edit"
              routeKey="systemeffortrules"
            />
          ),
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

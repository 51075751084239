import React from "react";
import { useTicketList, useTicketRoutes } from "hooks";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { TicketListFilter } from "../TicketListFilter";
import { TicketListTable } from "../TicketListTable";

export const TicketListPage: React.FC = () => {
  const { ticketList, isLoading, hasNextPage, fetchNextPage } = useTicketList();
  const { TicketIcon } = useTicketRoutes();

  return (
    <BoxHeadlineContainer
      boxTitle="Tickets"
      boxIcon={TicketIcon}
      boxSubTitle={ticketList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
    >
      <TicketListFilter ticketList={ticketList} />
      <TicketListTable
        listIntent="list"
        ticketList={ticketList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  searchText: {
    "& input": {
      marginBottom: "0px !important",
    },
  },
}));

export default useStyles;

import React, { useImperativeHandle, useRef, useState } from "react";
import { Collapse, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { CognitoUser, CreateUserInput, SelectOption, User } from "types";
import {
  AvatarDropZone,
  CustomAutoComplete,
  CustomAutoCompleteHandles,
  CustomButton,
  CustomSelect,
  CustomSelectHandles,
  CustomSwitch,
  LabeledTypography,
  Phone,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { SalutationOption } from "modules/shared/options/SalutationOption";
import { TableHeaderColorOption } from "modules/shared/options/TableHeaderColorOption";
import { TableSpacingOption } from "modules/shared/options/TableSpacingOption";
import { TableThemeOption } from "modules/shared/options/TableThemeOption";
import { getCognitoUserList } from "../../api";
import { useUserForm } from "../useUserForm";
import { normalizePhone } from "utils/phone";
import utils from "utils";
import useStyles from "./styles";

type UserFormProps = {
  formIntent: "create" | "edit";
  user: User | null;
  cognitoUser: CognitoUser | null;
};

export type UserFormResult = {
  userFormInput: CreateUserInput;
  userRoles: string[];
  password: string;
  changePassword: boolean;
};

export type UserFormHandles = {
  validateUserForm(): Promise<UserFormResult | null>;
};

const UserFormComponent: React.ForwardRefRenderFunction<
  UserFormHandles,
  UserFormProps
> = ({ formIntent, user, cognitoUser }, userFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    username,
    setUsername,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    salutation,
    setSalutation,
    email,
    setEmail,
    phone,
    setPhone,
    phonePrefix,
    setPhonePrefix,
    fax,
    setFax,
    faxPrefix,
    setFaxPrefix,
    avatar,
    setAvatar,
    tableTheme,
    setTableTheme,
    tableHeaderColor,
    setTableHeaderColor,
    tableSpacing,
    setTableSpacing,
    tableSticky,
    setTableSticky,
    unitType,
    setUnitType,
    userRoles,
    setUserRoles,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    uploading,
    setUploading,
    uploadProgress,
    setUploadProgress,
    resetUserForm,
  } = useUserForm(formIntent, user, cognitoUser);

  const [changePassword, setChangePassword] = useState<boolean>(false);

  const usernameInputRef = useRef<TextInputFieldHandles>(null);
  const firstNameInputRef = useRef<TextInputFieldHandles>(null);
  const lastNameInputRef = useRef<TextInputFieldHandles>(null);
  const emailInputRef = useRef<TextInputFieldHandles>(null);
  const unitTypeInputRef = useRef<CustomSelectHandles>(null);
  const salutationInputRef = useRef<CustomSelectHandles>(null);
  const userRolesInputRef = useRef<CustomAutoCompleteHandles>(null);
  const passwordInputRef = useRef<TextInputFieldHandles>(null);
  const confirmedPasswordInputRef = useRef<TextInputFieldHandles>(null);

  const tableThemeInputRef = useRef<CustomSelectHandles>(null);
  const tableHeaderColorInputRef = useRef<CustomSelectHandles>(null);
  const tableSpacingInputRef = useRef<CustomSelectHandles>(null);

  useImperativeHandle(userFormRef, () => ({
    validateUserForm: async () => {
      if (username === "") {
        enqueueSnackbar("Bitte gib den Benutzernamen ein!");
        usernameInputRef.current?.highlight();
        return null;
      }

      if (!utils.validation.validateUsername(username)) {
        enqueueSnackbar("Bitte gib einen gültigen Benutzernamen ein!");
        usernameInputRef.current?.highlight();
        return null;
      }

      if (firstName === "") {
        enqueueSnackbar("Bitte gib deinen Vornamen ein!");
        firstNameInputRef.current?.highlight();
        return null;
      }

      if (lastName === "") {
        enqueueSnackbar("Bitte gib den Nachnamen ein!");
        lastNameInputRef.current?.highlight();
        return null;
      }

      if (email === "") {
        enqueueSnackbar("Bitte wähle die Anrede aus!");
        emailInputRef.current?.highlight();
        return null;
      }

      if (!utils.validation.validateEmail(email)) {
        enqueueSnackbar("Bitte gib eine gültige E-Mail-Adresse ein!");
        emailInputRef.current?.highlight();
        return null;
      }

      if (tableTheme === null) {
        enqueueSnackbar("Bitte wähle das Tabellenthema aus!");
        tableThemeInputRef.current?.highlight();
        return null;
      }

      if (tableHeaderColor === null) {
        enqueueSnackbar("Bitte wähle die Tabellenkopffarbe aus!");
        tableHeaderColorInputRef.current?.highlight();
        return null;
      }

      if (tableSpacing === null) {
        enqueueSnackbar("Bitte wähle den Tabellenabstand aus!");
        tableSpacingInputRef.current?.highlight();
        return null;
      }

      if (!unitType) {
        enqueueSnackbar(
          "Bitte gib den Schwellwert für das Überschreiten der Gesamt-Liefermenge einer Lieferung bei Eingabe des Giesslaufs ein!",
        );
        unitTypeInputRef.current?.highlight();
        return null;
      }

      if (!salutation) {
        enqueueSnackbar("Bitte geben Sie eine Anrede an!");
        salutationInputRef.current?.highlight();
        return null;
      }

      if (userRoles.length === 0) {
        enqueueSnackbar("Bitte wähle die Nutzerrolle aus!");
        userRolesInputRef.current?.highlight();
        return null;
      }

      if (formIntent === "create" || changePassword) {
        if (password === "" || password.length > 200) {
          enqueueSnackbar("Bitte gib dein Passwort ein!");
          passwordInputRef.current?.highlight();
          return null;
        } else if (!utils.validation.validatePassword(password)) {
          enqueueSnackbar(
            "Bitte gib ein gültiges Passwort aus mind. 8 Zeichen mit Groß- und Kleinbuchstaben, Zahlen und folgenden Sonderzeichen: ^ $ * . [ ] { } ( ) ?  ! @ # % & / , > <  : ; | _ ~  ein!",
          );
          passwordInputRef.current?.highlight();
          return null;
        } else if (password !== confirmedPassword) {
          enqueueSnackbar("Bitte wiederhole dein Passwort!");
          confirmedPasswordInputRef.current?.highlight();
          return null;
        }
      }

      const userNameExists = await utils.identify.checkUsername(username);

      if ((!user || user.username !== username) && userNameExists) {
        enqueueSnackbar(
          "Der Benutzername ist bereits vergeben. Bitte verwende einen anderen.",
        );
        usernameInputRef.current?.highlight();
        return null;
      }

      const cognitoUserList = await getCognitoUserList();

      console.log("cognitoUserList: ", cognitoUserList);

      const emailExists = cognitoUserList.some(
        (cognitoUserItem) => cognitoUserItem?.email === email,
      );

      if ((!cognitoUser || cognitoUser.email !== email) && emailExists) {
        enqueueSnackbar(
          "Ein Cognito-Bernutzer mit dieser E-Mail-Adresse existiert bereits. Bitte verwende eine andere E-Mail-Adresse oder lösche den Cognito-Benutzer.",
        );
        emailInputRef.current?.highlight();
        return null;
      }

      const uploadedBild = await utils.images.uploadS3Resource(
        avatar,
        "user",
        setUploading,
        setUploadProgress,
      );

      const userFormInput: CreateUserInput = {
        isUserActive: true,
        email: email.trim().toLowerCase(),
        userSUB: user ? user.userSUB : "tobeset",
        username: username.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        salutation: salutation,
        avatar: uploadedBild,
        phone: normalizePhone(phonePrefix, phone),
        fax: normalizePhone(faxPrefix, fax),
        userSettings: {
          tableTheme,
          tableHeaderColor,
          tableSpacing,
          tableSticky,
          unitType: unitType.value,
        },
        lastActive: user
          ? user.lastActive
          : utils.dates.convertDateToAWSTimestampFormat(new Date()),
      };

      console.log("userFormInput: ", userFormInput);

      resetUserForm();

      return {
        userFormInput,
        userRoles: userRoles.map((userRole) => userRole.value),
        password,
        changePassword,
      };
    },
  }));

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={5}
        className={classes.gridRow}
      >
        <Grid item>
          <SalutationOption
            salutation={salutation}
            setSalutation={setSalutation}
            salutationInputRef={salutationInputRef}
            className={classes.customSelect}
          />
        </Grid>
        <Grid item>
          <TextInputField
            className={classes.formField}
            id="E-Mail"
            label="E-Mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase());
              setUsername(utils.identify.toURLsafeUsername(e.target.value));
            }}
            type="text"
            ref={emailInputRef}
            required={true}
            validate={(value) =>
              value.trim() !== "" && utils.validation.validateEmail(value)
            }
          />
        </Grid>
        <Grid item>
          <TextInputField
            className={classes.formField}
            id="Benutzername"
            label="Benutzername"
            description="(folgende Zeichen erlaubt: a-z0-9A-Z-_~)"
            value={username}
            onChange={(e) =>
              setUsername(utils.identify.toURLsafeUsername(e.target.value))
            }
            validate={(value) => value.trim() !== ""}
            ref={usernameInputRef}
            required={true}
            type="text"
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={5}
        className={classes.gridRow}
      >
        <Grid item>
          <TextInputField
            className={classes.formField}
            id="Vorname"
            label="Vorname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            ref={firstNameInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>
        <Grid item>
          <TextInputField
            className={classes.formField}
            id="Nachname"
            label="Nachname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            ref={lastNameInputRef}
            required={true}
            validate={(value) => value.trim() !== ""}
          />
        </Grid>{" "}
        <Grid item>
          {user && <LabeledTypography label="UserSUB" content={user.userSUB} />}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={5}
        className={classes.gridRow}
      >
        <Grid item>
          <Phone
            label="Telefon"
            phoneOnChange={(value) => setPhone(value)}
            phoneValue={phone}
            prefixOnChange={(value) => value && setPhonePrefix(value)}
            prefixValue={phonePrefix}
            className={classes.phoneNumber}
          />
        </Grid>
        <Grid item>
          <Phone
            label="Fax"
            phoneOnChange={(value) => setFax(value)}
            phoneValue={fax}
            prefixOnChange={(value) => value && setFaxPrefix(value)}
            prefixValue={faxPrefix}
            className={classes.phoneNumber}
          />
        </Grid>
      </Grid>

      {formIntent === "create" && (
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={5}
          className={classes.gridRow}
        >
          <Grid item>
            <TextInputField
              className={classes.formField}
              label="Temporäres Passwort"
              id="Passwort"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              required={true}
              ref={passwordInputRef}
            />
          </Grid>

          <Grid item>
            <TextInputField
              className={classes.formField}
              label="Temporäres Passwort wiederholen"
              id="PasswortRepeat"
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              type="text"
              required={true}
              ref={confirmedPasswordInputRef}
            />
          </Grid>

          <Grid item>
            <CustomButton
              text="Passwort generieren"
              size="small"
              align="center"
              onClick={() => {
                const generatedPassword = utils.identify.generatePassword();
                setPassword(generatedPassword);
                setConfirmedPassword(generatedPassword);
              }}
              rootClassName={classes.generatedPassword}
            />
          </Grid>
        </Grid>
      )}

      {formIntent === "edit" && (
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={5}
          className={classes.gridRow}
        >
          <Grid item>
            <Collapse in={!changePassword}>
              <CustomButton
                text="Passwort ändern"
                size="small"
                align="center"
                onClick={() => setChangePassword(true)}
                rootClassName={classes.generatedPassword}
              />
            </Collapse>
            <Collapse in={changePassword}>
              <TextInputField
                className={classes.formField}
                label="Neues Passwort"
                id="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                required={true}
                ref={passwordInputRef}
              />
            </Collapse>
          </Grid>

          <Grid item>
            <Collapse in={changePassword}>
              <TextInputField
                className={classes.formField}
                label="Neues Passwort wiederholen"
                id="PasswortRepeat"
                value={confirmedPassword}
                onChange={(e) => setConfirmedPassword(e.target.value)}
                type="text"
                required={true}
                ref={confirmedPasswordInputRef}
              />
            </Collapse>
          </Grid>

          <Grid item>
            <Collapse in={changePassword}>
              <CustomButton
                text="Passwort generieren"
                size="small"
                align="center"
                onClick={() => {
                  const generatedPassword = utils.identify.generatePassword();
                  setPassword(generatedPassword);
                  setConfirmedPassword(generatedPassword);
                }}
                rootClassName={classes.generatedPassword}
              />
              <CustomButton
                text="Passwort nicht ändern"
                size="small"
                align="center"
                color="flatRed"
                style="outlined"
                onClick={() => setChangePassword(false)}
                rootClassName={classes.generatedPassword}
              />
            </Collapse>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={5}
        className={classes.gridRow}
      >
        <Grid item>
          <Typography variant="h3">Nutzerrolle</Typography>

          <CustomAutoComplete
            options={utils.constants.USER_GROUPS_OPTIONS}
            value={userRoles}
            onChangeHandler={(values) => setUserRoles(values)}
            placeholder="Nutzerrolle"
            required={true}
            ref={userRolesInputRef}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <TableThemeOption
            tableTheme={tableTheme}
            setTableTheme={setTableTheme}
            tableThemeInputRef={tableThemeInputRef}
            className={classes.customSelect}
          />
        </Grid>
        <Grid item md={6}>
          <TableHeaderColorOption
            tableHeaderColor={tableHeaderColor}
            setTableHeaderColor={setTableHeaderColor}
            tableHeaderColorInputRef={tableHeaderColorInputRef}
            className={classes.customSelect}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <TableSpacingOption
            tableSpacing={tableSpacing}
            setTableSpacing={setTableSpacing}
            tableSpacingInputRef={tableSpacingInputRef}
            className={classes.customSelect}
          />
        </Grid>
        <Grid item md={6}>
          <CustomSwitch
            name="tableSticky"
            switchLabel="Tabellenkopf fixieren"
            checkedValue={tableSticky}
            onChange={(value) => setTableSticky(value.target.checked)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={5}
        className={classes.gridRow}
      >
        <Grid item>
          <Typography variant="h3">Benutzerbild</Typography>
          <AvatarDropZone
            dragActiveText="Bild hier ablegen..."
            dragInactiveText="Bild hier ablegen oder klicken..."
            uploading={uploading}
            avatar={avatar}
            setAvatar={setAvatar}
            maxSize={10000000}
            uploadProgress={uploadProgress}
          />
        </Grid>
        <Grid item>
          <CustomSelect<SelectOption>
            value={unitType}
            label="Bevorzugte Einheit"
            placeholder="Bitte auswählen"
            options={utils.constants.WEIGHT_OPTIONS}
            onChange={(value) => value && setUnitType(value)}
            className={classes.customSelect}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const UserForm = React.forwardRef(UserFormComponent);

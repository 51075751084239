import React from "react";
import { client } from "queryClient";
import { SelectOption, TableHeaderColor } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface TableHeaderColorOptionProps
  extends Partial<CustomSelectOptionProps<TableHeaderColor>> {
  tableHeaderColor: TableHeaderColor | null;
  setTableHeaderColor: (
    value: React.SetStateAction<TableHeaderColor | null>,
  ) => void;
  tableHeaderColorInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getTableHeaderColorName = (value: TableHeaderColor) => {
  switch (value) {
    case "default":
      return "Standard";
    case "grey":
      return "Grau";
  }
};

export const getTableHeaderColorEnumByName = (
  value: string,
): TableHeaderColor => {
  switch (value) {
    case "Standard":
      return "default";
    case "Grau":
      return "grey";
    default:
      return "default";
  }
};

export const getTableHeaderColorOptions = (): SelectOption[] => {
  console.log(client.enums);
  const values = client.enums.TableHeaderColor?.values() ?? [];

  return values.map((tableHeaderColor) => ({
    value: tableHeaderColor,
    label: getTableHeaderColorName(tableHeaderColor),
  }));
};

export const TableHeaderColorOption: React.FC<TableHeaderColorOptionProps> = ({
  tableHeaderColor,
  setTableHeaderColor,
  tableHeaderColorInputRef,
  ...rest
}) => {
  const options = getTableHeaderColorOptions();

  return (
    <CustomSelect<SelectOption>
      {...rest}
      label="Tabellenkopf-Farbe"
      options={options}
      onChange={(tableHeaderColorOption) =>
        setTableHeaderColor(tableHeaderColorOption.value as TableHeaderColor)
      }
      value={
        tableHeaderColor
          ? {
              value: tableHeaderColor,
              label: getTableHeaderColorName(tableHeaderColor),
            }
          : null
      }
      placeholder="Tabellenkopf-Farbe auswählen"
      getOptionValue={undefined}
      getOptionLabel={undefined}
      isOptionDisabled={undefined}
      isOptionSelected={undefined}
      ref={tableHeaderColorInputRef}
    />
  );
};

import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getCustomer } from "modules/customers/api";

export const useCustomerByIDRead = (customerID: string | null | undefined) => {
  console.log("Loading customer with ID: ", customerID);

  const { data: customer, isLoading } = useQuery({
    queryKey: [QueryKeys.Customers, customerID],
    queryFn: async () => {
      if (!customerID) return null;

      const customerResponse = await getCustomer(customerID);

      return customerResponse;
    },
  });

  return {
    customer: customer ?? null,
    isLoading,
  };
};

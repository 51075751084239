import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys, client, queryClient } from "queryClient";
import { User } from "types";
import { getUser } from "modules/users/api";

type UserDetailsParamsType = {
  userID: string;
};

export const useUserRead = () => {
  const authContext = useAuthContext();
  const { userID } = useParams<UserDetailsParamsType>();

  console.log("Loading user with ID: ", userID);

  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: [QueryKeys.Users, { id: userID }],
    queryFn: async () => {
      if (!userID) return null;

      const userFromAPI = await getUser(userID);

      if (userFromAPI) {
        authContext.setCurrentData({
          id: userFromAPI.id,
          name: userFromAPI.firstName + " " + userFromAPI.lastName,
        });
      }

      return userFromAPI as User | null;
    },
  });

  useEffect(() => {
    if (!userID) {
      return;
    }

    const updateSub = client.models.User.onUpdate({
      filter: {
        id: {
          eq: userID,
        },
      },
    }).subscribe({
      next: () =>
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Users],
        }),
      error: (error) => console.error(error),
    });

    return () => {
      updateSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user: user ? (user as User) : null,
    isLoading: isLoadingUser,
  };
};

import React from "react";
import { SelectOption } from "types";
import { CustomSelect, CustomSelectHandles } from "core";

type MonthOptionProps = {
  month: SelectOption | null;
  setMonth: (value: React.SetStateAction<SelectOption | null>) => void;
  monthInputRef?: React.RefObject<CustomSelectHandles>;
  required?: boolean;
};

export const monthOptionList = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "9",
    value: "9",
  },
  {
    label: "10",
    value: "10",
  },
  {
    label: "11",
    value: "11",
  },
  {
    label: "12",
    value: "12",
  },
];

export const MonthOption: React.FC<MonthOptionProps> = ({
  month = {
    label: "1",
    value: "1",
  },
  setMonth,
  monthInputRef,
  required,
}) => {
  return (
    <CustomSelect<SelectOption>
      label="Monat"
      options={monthOptionList}
      onChange={(monthOption) => setMonth(monthOption)}
      value={month}
      placeholder="Monat auswählen"
      ref={monthInputRef}
      required={required}
    />
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: 19,
    marginRight: 10,
    verticalAlign: "middle",
  },
}));

export default useStyles;

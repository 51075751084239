import { downloadData, getUrl } from "aws-amplify/storage";
import { S3Resource, SelectOption } from "types";

export const loadS3Resource = async (
  s3Resource: S3Resource | null | undefined,
) => {
  if (s3Resource) {
    const fileSrc = await getUrl({
      key: s3Resource.key,
    });

    s3Resource.preview =
      !fileSrc || typeof fileSrc !== "string" ? "/img/no-image.svg" : fileSrc;

    return s3Resource;
  } else {
    return null;
  }
};

export const getFormattedDocumentSize = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";

  const decimals = 2;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
      .toString()
      .replace(".", ",") +
    " " +
    sizes[i]
  );
};

export const getDocumentTypeOption = (fileType: string): SelectOption => {
  if (fileType.includes("image/")) {
    return {
      label: "Bild",
      value: "Bild",
    };
  } else if (fileType.includes("video/")) {
    return {
      label: "Video",
      value: "Video",
    };
  } else if (
    fileType.includes("application/pdf") ||
    fileType.includes("application/vnd.openxmlformats-officedocument") ||
    fileType.includes("application/vnd.ms-word") ||
    fileType.includes("application/msword") ||
    fileType.includes("application/vnd.oasis.opendocument.text") ||
    fileType.includes("text/")
  ) {
    return {
      label: "Dokument",
      value: "Dokument",
    };
  }

  return {
    label: "Sonstiges",
    value: "Sonstiges",
  };
};

export const getDocumentIconType = (fileType: string): string => {
  if (fileType.includes("image/")) {
    return "image";
  } else if (fileType.includes("video/")) {
    return "video";
  } else if (
    fileType.includes("audio/") ||
    fileType.includes("application/x-cdf")
  ) {
    return "audio";
  } else if (
    fileType.includes("application/x-freearc") ||
    fileType.includes("application/x-bzip") ||
    fileType.includes("application/x-bzip2") ||
    fileType.includes("application/gzip") ||
    fileType.includes("application/java-archive") ||
    fileType.includes("application/vnd.rar") ||
    fileType.includes("application/x-tar") ||
    fileType.includes("application/zip") ||
    fileType.includes("application/x-7z-compressed")
  ) {
    return "archive";
  } else if (
    fileType.includes("application/vnd.ms-excel") ||
    fileType.includes("application/msexcel") ||
    fileType.includes(
      "application/vnd.openxmlformats-officedocument.spreadsheetml",
    ) ||
    fileType.includes("application/vnd.oasis.opendocument.spreadsheet") ||
    fileType.includes("text/csv") ||
    fileType.includes("application/csv")
  ) {
    return "excel";
  } else if (
    fileType.includes("application/msword") ||
    fileType.includes("application/vnd.ms-word") ||
    fileType.includes(
      "application/vnd.openxmlformats-officedocument.wordprocessingml",
    ) ||
    fileType.includes("application/vnd.oasis.opendocument.text")
  ) {
    return "word";
  } else if (
    fileType.includes("application/mspowerpoint") ||
    fileType.includes("application/vnd.ms-powerpoint") ||
    fileType.includes(
      "application/vnd.openxmlformats-officedocument.presentationml",
    ) ||
    fileType.includes("application/vnd.oasis.opendocument.presentation")
  ) {
    return "powerpoint";
  } else if (fileType.includes("application/msaccess")) {
    return "access";
  } else if (
    fileType.includes("application/rtf") ||
    fileType.includes("text/rtf")
  ) {
    return "rtf";
  } else if (fileType.includes("application/pdf")) {
    return "pdf";
  } else if (
    fileType.includes("text/html") ||
    fileType.includes("application/xhtml+xml")
  ) {
    return "html";
  } else if (
    fileType.includes("application/xml") ||
    fileType.includes("application/atom+xml") ||
    fileType.includes("application/vnd.mozilla.xul+xml") ||
    fileType.includes("text/xml")
  ) {
    return "xml";
  } else if (
    fileType.includes("application/x-sh") ||
    fileType.includes("application/x-csh")
  ) {
    return "script";
  } else if (
    fileType.includes("application/octet-stream") ||
    fileType.includes("application/vnd.apple.installer+xml") ||
    fileType.includes("application/vnd.microsoft.portable-executable")
  ) {
    return "executable";
  } else if (
    fileType.includes("application/vnd.amazon.ebook") ||
    fileType.includes("application/epub+zip")
  ) {
    return "ebook";
  } else if (
    fileType.includes("text/javascript") ||
    fileType.includes("application/epub+zip")
  ) {
    return "javascript";
  } else if (
    fileType.includes("font/woff") ||
    fileType.includes("font/ttf") ||
    fileType.includes("font/otf") ||
    fileType.includes("application/vnd.ms-fontobject")
  ) {
    return "font";
  } else if (fileType.includes("application/vnd.visio")) {
    return "visio";
  } else if (fileType.includes("text/calendar")) {
    return "calendar";
  } else if (fileType.includes("text/css")) {
    return "css";
  } else if (fileType.includes("text/plain")) {
    return "plain";
  }

  return "unknown";
};

const downloadBlob = (blob: Blob, fileName: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const downloadS3Resource = async (
  s3ResourceKey: string,
  fileName: string,
): Promise<HTMLAnchorElement | null> => {
  try {
    console.log("Downloading file with s3ResourceKey: ", s3ResourceKey);

    const { result } = await downloadData({
      key: s3ResourceKey,
    });

    console.log("result is: ", result);

    const resultBody = (await result).body;

    const resultBlob = await resultBody.blob();

    return downloadBlob(resultBlob, fileName);
  } catch (err) {
    console.log("Error on downloading file from Storage: ", err);
    return null;
  }
};

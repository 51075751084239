import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  customSelect: {
    marginBottom: 30,
  },
  phoneNumber: {
    marginBottom: 30,
  },
  formField: {
    marginBottom: 10,
  },
  generatedPassword: {
    marginTop: 30,
    marginBottom: 25,
    textAlign: "center",
    width: "100%",
  },
}));

export default useStyles;

import { useEffect, useState } from "react";
import { useCustomerAllList, usePersistedState } from "hooks";
import { LexicalEditor, createEditor } from "lexical";
import {
  BacklinkStatus,
  Customer,
  Hoster,
  RuntimeEnvironment,
  System,
  Template,
  Website,
  WebsiteType,
} from "types";

export const useWebsiteForm = (
  formIntent: "create" | "edit",
  website: Website | null | undefined,
) => {
  const { customerList } = useCustomerAllList(false);

  const [url, setURL] = usePersistedState<string>(
    "website_url",
    "",
    formIntent,
  );
  const [websiteOwner, setWebsiteOwner] = usePersistedState<Customer | null>(
    "website_websiteOwner",
    null,
    formIntent,
  );
  const [websitePayer, setWebsitePayer] = usePersistedState<Customer | null>(
    "website_websitePayer",
    null,
    formIntent,
  );
  const [websiteType, setWebsiteType] = usePersistedState<WebsiteType | null>(
    "website_websiteType",
    null,
    formIntent,
  );
  const [system, setSystem] = usePersistedState<System | null>(
    "website_system",
    null,
    formIntent,
  );
  const [runtimeEnvironment, setRuntimeEnvironment] =
    usePersistedState<RuntimeEnvironment | null>(
      "website_runtimeEnvironment",
      null,
      formIntent,
    );
  const [template, setTemplate] = usePersistedState<Template | null>(
    "website_template",
    null,
    formIntent,
  );
  const [hoster, setHoster] = usePersistedState<Hoster | null>(
    "website_hoster",
    null,
    formIntent,
  );
  const [backlinkErstellung, setBacklinkErstellung] =
    usePersistedState<BacklinkStatus | null>(
      "website_backlinkErstellung",
      null,
      formIntent,
    );
  const [backlinkPflege, setBacklinkPflege] =
    usePersistedState<BacklinkStatus | null>(
      "website_backlinkPflege",
      null,
      formIntent,
    );
  const [formiloBacklink, setFormiloBacklink] =
    usePersistedState<BacklinkStatus | null>(
      "website_formiloBacklink",
      null,
      formIntent,
    );

  const [createdByUs, setCreatedByUs] = usePersistedState<boolean>(
    "website_createdByUs",
    true,
    formIntent,
  );
  const [currentlyResponsible, setCurrentlyResponsible] =
    usePersistedState<boolean>(
      "website_currentlyResponsible",
      true,
      formIntent,
    );
  const [rating, setRating] = usePersistedState<number | "">(
    "website_rating",
    1,
    formIntent,
  );

  const [phpVersion, setPHPVersion] = usePersistedState<string>(
    "website_phpVersion",
    "",
    formIntent,
  );

  const [description, setDescription] = useState<LexicalEditor>(createEditor());

  const resetWebsiteForm = () => {
    localStorage.removeItem("website_url");
    localStorage.removeItem("website_websiteOwner");
    localStorage.removeItem("website_websitePayer");
    localStorage.removeItem("website_websiteType");
    localStorage.removeItem("website_system");
    localStorage.removeItem("website_runtimeEnvironment");
    localStorage.removeItem("website_template");
    localStorage.removeItem("website_hoster");
    localStorage.removeItem("website_backlinkErstellung");
    localStorage.removeItem("website_backlinkPflege");
    localStorage.removeItem("website_formiloBacklink");
    localStorage.removeItem("website_createdByUs");
    localStorage.removeItem("website_currentlyResponsible");
    localStorage.removeItem("website_rating");
    localStorage.removeItem("website_phpVersion");
    localStorage.removeItem("website_description");
  };

  useEffect(() => {
    if (!website) {
      return;
    }

    setURL(website.url);

    const customerOwner = customerList.find(
      (customer) => customer.id === website.websiteOwner,
    );

    setWebsiteOwner(customerOwner ? (customerOwner as Customer) : null);

    const customerPayer = customerList.find(
      (customer) => customer.id === website.websitePayer,
    );

    setWebsitePayer(customerPayer ? (customerPayer as Customer) : null);

    setWebsiteType(website.websiteType);

    setSystem(website.system as System);

    setRuntimeEnvironment(website.runtimeEnvironment);

    setTemplate(website.template as Template);

    setHoster(website.hoster as Hoster);

    setBacklinkErstellung(website.backlinkErstellung);
    setBacklinkPflege(website.backlinkPflege);
    setFormiloBacklink(website.formiloBacklink);

    setCurrentlyResponsible(website.currentlyResponsible);
    setCreatedByUs(website.createdByUs);
    setRating(website.rating);
    setPHPVersion(website.phpVersion ?? "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [website, customerList]);

  return {
    url,
    setURL,
    websiteOwner,
    setWebsiteOwner,
    websitePayer,
    setWebsitePayer,
    websiteType,
    setWebsiteType,
    system,
    setSystem,
    runtimeEnvironment,
    setRuntimeEnvironment,
    template,
    setTemplate,
    hoster,
    setHoster,
    backlinkErstellung,
    setBacklinkErstellung,
    backlinkPflege,
    setBacklinkPflege,
    formiloBacklink,
    setFormiloBacklink,
    createdByUs,
    setCreatedByUs,
    currentlyResponsible,
    setCurrentlyResponsible,
    rating,
    setRating,
    phpVersion,
    setPHPVersion,
    description,
    setDescription,
    resetWebsiteForm,
  };
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  addButtonIcon: {
    marginRight: 7,
  },
  loading: {
    marginLeft: 30,
    marginBottom: 30,
    justifyContent: "center",
  },
  linkIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    marginTop: -5,
  },
  iconContainer: {
    marginLeft: 5,
  },
  iconLabelContainer: {
    width: 18,
    height: 18,
  },
  gridRow: {
    marginBottom: 70,
  },
  gridItem: {
    marginBottom: 30,
    "&:not(:last-child)": {
      marginRight: 100,
    },
  },
  notAvailable: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  sectionTitle: {
    marginBottom: 20,
  },
  assigneeName: {
    marginBottom: 10,
  },
  email: {
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  description: {
    fontSize: 16,
    "& a": {
      color: "#333",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export default useStyles;

import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { usePermanentTaskRoutes } from "hooks";
import { PermanentTask } from "types";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { PermanentTaskDeleteDialog } from "../../permanenttaskDelete/PermanentTaskDeleteDialog";
import utils from "utils";

type PermanentTaskListTableProps = {
  listIntent: "list" | "selection";
  permanentTaskList: PermanentTask[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  permanentTaskSelectionHandler?: (permanentTask: PermanentTask) => void;
};

export const PermanentTaskListTable: React.FC<PermanentTaskListTableProps> = ({
  listIntent,
  permanentTaskList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  permanentTaskSelectionHandler,
}) => {
  const { navigateToPermanentTaskPage, navigateToEditPermanentTaskPage } =
    usePermanentTaskRoutes();

  const [deletePermanentTaskDialogOpen, setDeletePermanentTaskDialogOpen] =
    useState<boolean>(false);
  const [permanentTaskToDelete, setPermanentTaskToDelete] = useState<
    PermanentTask | undefined
  >();

  const defaultColumns = React.useMemo<ColumnDef<PermanentTask>[]>(
    () => [
      {
        header: "Aufgaben-Typ",
        accessorKey: "taskTypeID",
        accessorFn: (row) => row.taskType.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Webseite",
        accessorKey: "websiteID",
        accessorFn: (row) => (
          <span style={{ whiteSpace: "nowrap" }}>{row.website.url}</span>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwand in m",
        accessorKey: "effort",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Referenz Datum",
        accessorKey: "referenceDate",
        accessorFn: (row) =>
          utils.dates.getDateInGermanFromAWSDateFormat(row.referenceDate),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Periode Typ",
        accessorKey: "periodType",
        accessorFn: (row) =>
          row.periodType === "day"
            ? "Tag"
            : row.periodType === "week"
              ? "Woche"
              : "Monat",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Periode Wert",
        accessorKey: "periodValue",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Frist in Tagen",
        accessorKey: "deadlineDays",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Abrechnungsart",
        accessorKey: "billType",
        accessorFn: (row) =>
          row.billType === "free"
            ? "Gratis"
            : row.billType === "maintenance"
              ? "Pflege"
              : "Rechnung",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<PermanentTask>
            row={row}
            listIntent={listIntent}
            openEditRowPage={navigateToEditPermanentTaskPage}
            setRowToDelete={setPermanentTaskToDelete}
            setDeleteRowDialogOpen={setDeletePermanentTaskDialogOpen}
            rowSelectionHandler={permanentTaskSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [
      navigateToEditPermanentTaskPage,
      permanentTaskSelectionHandler,
      listIntent,
    ],
  );

  const rowClickHandler = (_: SyntheticEvent, row: PermanentTask) => {
    if (permanentTaskSelectionHandler) {
      permanentTaskSelectionHandler(row);
    } else {
      navigateToPermanentTaskPage(row);
    }
  };

  return (
    <>
      <PermanentTaskDeleteDialog
        dialogOpen={deletePermanentTaskDialogOpen}
        setDialogOpen={setDeletePermanentTaskDialogOpen}
        permanentTask={permanentTaskToDelete}
      />
      <CustomReactTable<PermanentTask>
        data={permanentTaskList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

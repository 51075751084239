import { useEffect, useState } from "react";
import { GlobalSettings } from "types";

export const useGlobalSettingsForm = (globalSettings: GlobalSettings) => {
  const [currentVersion, setCurrentVersion] = useState<string>("");

  useEffect(() => {
    setCurrentVersion(globalSettings.currentVersion);
  }, [globalSettings]);

  return {
    currentVersion,
    setCurrentVersion,
  };
};

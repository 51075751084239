import React from "react";
import { Grid } from "@mui/material";
import { FilterRow, FilterWrapper } from "components";
import { DateFilter } from "modules/shared/filters/DateFilter";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";
import { TaskTypeFilter } from "modules/shared/filters/TaskTypeFilter";
import { WebsiteFilter } from "modules/shared/filters/WebsiteFilter";
import { getBillTypeOptions } from "modules/shared/options/BillTypeOption";
import { getPeriodTypeOptions } from "modules/shared/options/PeriodTypeOption";

export const PermanentTaskListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <TaskTypeFilter />
      </Grid>
      <Grid item>
        <WebsiteFilter />
      </Grid>
      <Grid item>
        <NumberFilter param="effort" paramName="Aufwand" />
      </Grid>
    </FilterRow>
    <FilterRow>
      <Grid item>
        <DateFilter param="referenceDate" paramName="Referenz Datum" />
      </Grid>
      <Grid item>
        <OptionsFilter
          param="periodType"
          paramName="Periode Typ"
          options={getPeriodTypeOptions()}
        />
      </Grid>
      <Grid item>
        <NumberFilter param="periodValue" paramName="Periode Wert" />
      </Grid>
    </FilterRow>
    <FilterRow>
      <Grid item>
        <NumberFilter param="deadlineDays" paramName="Frist in Tagen" />
      </Grid>
      <Grid item>
        <OptionsFilter
          param="billType"
          paramName="Abrechnungsart"
          options={getBillTypeOptions()}
        />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { InfiniteTicketList } from "types";
import { getAllTicketList, getTicketList } from "modules/tickets/api";
import { useTicketListFilterAndSort } from "../useTicketListFilterAndSort";

export const useTicketList = (filter = true) => {
  const filterAndSortTickets = useTicketListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<InfiniteTicketList>({
      queryKey: loadAll ? [QueryKeys.Tickets, "all"] : [QueryKeys.Tickets],
      queryFn: ({ pageParam = 0 }) =>
        loadAll
          ? getAllTicketList()
          : getTicketList(pageParam as number | null),
      getNextPageParam: (lastPage) => lastPage.nextFrom ?? undefined,
      initialPageParam: null,
      staleTime: 1000 * 60 * 15, // 15 minutes
      gcTime: 1000 * 60 * 60, // 1 hour
    });

  const ticketList = useMemo(() => {
    const tickets = data?.pages
      ? data.pages.flatMap((page) => page.ticketList)
      : [];

    return filterAndSortTickets(tickets);
  }, [data, filterAndSortTickets]);

  return {
    ticketList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};

import React from "react";
import { useTaskTypeList } from "hooks";
import { TaskType } from "types";
import { CustomDialog } from "core";
import { TaskTypeListFilter } from "../../tasktypelist/TaskTypeListFilter";
import { TaskTypeListTable } from "../../tasktypelist/TaskTypeListTable";

type TaskTypeSelectionDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  taskTypeSelectionHandler: (taskType: TaskType) => void;
};

export const TaskTypeSelectionDialog: React.FC<
  TaskTypeSelectionDialogProps
> = ({ dialogOpen, setDialogOpen, taskTypeSelectionHandler }) => {
  const { taskTypeList, isLoading, hasNextPage, fetchNextPage } =
    useTaskTypeList();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={false}
      titleText="Aufgaben-Typ auswählen"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={false}
      fullWidth={true}
      maxWidth="lg"
      contentPadding={0}
    >
      <TaskTypeListFilter />
      <TaskTypeListTable
        listIntent="selection"
        taskTypeList={taskTypeList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        taskTypeSelectionHandler={taskTypeSelectionHandler}
      />
    </CustomDialog>
  );
};

import { InfoIcon, TicketIcon } from "modules/icons";
import { TicketPage } from "modules/tickets/ticketDetails/TicketPage";
import { TicketListPage } from "modules/tickets/ticketlist/TicketListPage";
import { USER_GROUPS } from "utils/constants";

export const tickets = {
  key: "tickets",
  path: "/tickets",
  section: "verwaltung",
  title: "Tickets Verwalten",
  view: <TicketListPage />,
  icon: <TicketIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: true,
  isWithParam: false,
  subNavigations: {
    ticket: {
      key: "ticket",
      path: "/tickets/:ticketID",
      section: "ticket",
      title: "Ticket",
      view: <TicketPage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {},
    },
  },
};

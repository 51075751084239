import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Collapse, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useAuthContext } from "context";
import { ArrowIcon } from "modules/icons";
import { RouteArrayType, checkRouteIsSelected } from "routes";
import useStyles from "./styles";

type SidebarSubmenuListProps = {
  route: RouteArrayType;
  children: React.ReactNode;
};

export const SidebarSubmenuList: React.FC<SidebarSubmenuListProps> = ({
  route,
  children,
}) => {
  const authContext = useAuthContext();
  const { classes, cx } = useStyles();

  const { pathname } = useLocation();

  const [submenuOpen, setSubmenuOpen] = useState<boolean>(
    checkRouteIsSelected(pathname, route),
  );

  useEffect(() => {
    const routeIsSelected = checkRouteIsSelected(pathname, route);
    setSubmenuOpen(routeIsSelected);
  }, [pathname, route]);

  return (
    <>
      <ListItem
        // selected={submenuOpen}
        className={cx(
          classes.listItem,
          authContext.menuOpen
            ? null
            : submenuOpen
              ? classes.listItemClosedSubmenuOpen
              : classes.listItemClosed,
        )}
        onClick={() => setSubmenuOpen((prev) => !prev)}
        key={route.key}
      >
        {route.icon && (
          <ListItemIcon className={classes.listItemIconBefore}>
            {route.icon}
          </ListItemIcon>
        )}

        <ListItemText
          classes={{
            primary: cx(
              classes.listItemText,
              authContext.menuOpen ? null : classes.menuClosed,
            ),
          }}
        >
          {route.title}
        </ListItemText>
        <ListItemIcon
          className={cx(
            classes.dropDownIconWrapper,
            submenuOpen ? classes.dropDownIconExpanded : "",
          )}
        >
          <ArrowIcon className={classes.dropDownIcon} />
        </ListItemIcon>
      </ListItem>
      <Collapse in={submenuOpen}>{children}</Collapse>
    </>
  );
};

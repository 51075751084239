import { useEffect } from "react";
import { useAuthContext } from "context";
import { useUserUpdate } from "hooks";
import utils from "utils";

export const useUserActivityTracker = () => {
  const authContext = useAuthContext();

  const updateUserMutation = useUserUpdate();

  useEffect(() => {
    const userID = authContext.userData?.id;

    if (!userID) return;

    const updateActivity = () =>
      updateUserMutation.mutate({
        id: userID,
        lastActive: utils.dates.convertDateToAWSTimestampFormat(new Date()),
      });

    updateActivity();

    const interval = setInterval(updateActivity, 5 * 60 * 1000); // Aktualisiert alle 5 Minuten

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.userData]);
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  headerRightSide: {
    float: "right",
    width: "41%",
    display: "inline-block",
  },
}));

export default useStyles;

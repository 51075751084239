import React from "react";

export const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  try {
    window &&
      window.scrollTo({
        top: ref.current!.offsetTop - ref.current!.scrollHeight - 5,
        left: 0,
        behavior: "smooth",
      });
  } catch (error) {
    window && window.scrollTo(0, 0);
  }
};

export const scrollToTop = () => {
  window &&
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
};

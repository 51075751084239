import React from "react";
import { matchPath } from "react-router-dom";
import { BookingsNavigationPage } from "modules/bookings/BookingsNavigationPage";
import Dashboard from "modules/dashboard";
import Development from "modules/development/dev";
import GlobalSettingsPage from "modules/globalsettings/globalsettingsDetails/GlobalSettingsPage";
import GlobalSettingsFormPage from "modules/globalsettings/globalsettingsForm/GlobalSettingsFormPage";
import {
  CareBookingIcon,
  GlobalSettingsIcon,
  LogoutIcon,
  SettingsIcon,
  SpieleladenIcon,
  StatisticsIcon,
  TemplateIcon,
  TicketIcon,
  WebsiteIcon,
} from "modules/icons";
import { TemplatesNavigationPage } from "modules/templates/TemplatesNavigationPage";
import { TemplateStatisticsPage } from "modules/templates/templateStatistics/TemplateStatisticsPage";
import { TicketsNavigationPage } from "modules/tickets/TicketsNavigationPage";
import UserSettingsPage from "modules/usersettings/usersettingsDetails/UserSettingsPage";
import UserSettingsFormPage from "modules/usersettings/usersettingsForm/UserSettingsFormPage";
import { WebsitesNavigationPage } from "modules/websites/WebsitesNavigationPage";
import { WebsiteStatisticsPage } from "modules/websites/websiteStatistics/WebsiteStatisticsPage";
import { bookings } from "./moduleroutes/bookings";
import { customers } from "./moduleroutes/customers";
import { effortrules } from "./moduleroutes/effortrules";
// import { Placeholder } from "modules/development/placeholder";
import { hosters } from "./moduleroutes/hosters";
import { permanenttasks } from "./moduleroutes/permanenttasks";
import { systems } from "./moduleroutes/systems";
import { tasks } from "./moduleroutes/tasks";
import { tasktypes } from "./moduleroutes/tasktypes";
import { templates } from "./moduleroutes/templates";
import { tickets } from "./moduleroutes/tickets";
import { users } from "./moduleroutes/users";
import { websites } from "./moduleroutes/websites";
import { ConfirmForgotPassword } from "../authentication/confirmForgotPassword";
import ForgotPassword from "../authentication/forgotPassword";
import Login from "../authentication/login";
import Logout from "../authentication/logout";
// Authentication Views
// Other Views
import { USER_GROUPS } from "utils/constants";

export interface RouteType {
  key: string;
  path: string;
  section: string;
  title: string;
  view: React.ReactNode;
  icon: React.ReactNode;
  groups: string[];
  level: number;
  navigation: boolean;
  indented: boolean;
  isWithParam: boolean;
}

export interface RouteMapType extends RouteType {
  subNavigations: Record<string, RouteMapType>;
}

export interface RouteArrayType extends RouteType {
  subNavigations: RouteArrayType[];
}

export const Sections: Record<string, string> = {
  verwaltung: "Verwaltung",
  grunddaten: "Grunddaten",
  admin: "Admin",
  account: "Account",
  expansion: "Edition",
  ordering: "Auftragswesen",
  accounting: "Buchhaltung",
  logistics: "Logistik",
  qualitymanagement: "Qualitätsmanagement",
  production: "Produktion",
  finance: "Finanzwesen",
  addresses: "Adressen",
  subcontractor: "Subunternehmen",
  form: "Form",
  stock: "Bestand",
  zincstock: "Zinkbestand",
};

/**
 * Routes by default exports the paths NOT under authentication.
 *
 * (!) Warning: Non navigation routes should not have navigation: true set
 * since they lack needed information
 *
 * (!) Warning: The order here is important, since we map over possible navigations
 * to render sidebar items
 */
export const Routes = {
  start: {
    key: "start",
    path: "/",
    section: "general",
    title: "Dashboard",
    view: <Dashboard />,
    icon: <SpieleladenIcon />,
    groups: [
      USER_GROUPS.ADMIN,
      USER_GROUPS.OFFICE,
      USER_GROUPS.ACCOUNTING,
      USER_GROUPS.QUALITY_MANAGEMENT,
      USER_GROUPS.LOGISTICS,
      USER_GROUPS.PRODUCTION,
    ],
    level: 0,
    navigation: false,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  development: {
    key: "development",
    path: "/dev",
    section: "general",
    title: "Datentest",
    view: <Development />,
    icon: <SpieleladenIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: false,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
  ticketing: {
    key: "ticket",
    path: "/ticket",
    section: "verwaltung",
    title: "Tickets",
    view: <TicketsNavigationPage />,
    icon: <TicketIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    isDropdown: true,
    subNavigations: {
      tickets: tickets,
      ticketstatistics: {
        key: "ticketstatistics",
        path: "/ticket/statistics",
        section: "verwaltung",
        title: "Statistiken",
        view: <Dashboard />,
        icon: <StatisticsIcon />,
        groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
        level: 1,
        navigation: true,
        indented: true,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  customers: customers,
  tasks: tasks,
  permanenttasks: permanenttasks,
  carebookings: {
    key: "booking",
    path: "/booking",
    section: "verwaltung",
    title: "Pflegebuchugen",
    view: <BookingsNavigationPage />,
    icon: <CareBookingIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    isDropdown: true,
    subNavigations: {
      bookings: bookings,
      bookingstatistics: {
        key: "bookingstatistics",
        path: "/booking/statistics",
        section: "verwaltung",
        title: "Statistiken",
        view: <Dashboard />,
        icon: <StatisticsIcon />,
        groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
        level: 1,
        navigation: true,
        indented: true,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  websitemanagement: {
    key: "website",
    path: "/website",
    section: "verwaltung",
    title: "Webseiten",
    view: <WebsitesNavigationPage />,
    icon: <WebsiteIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    isDropdown: true,
    subNavigations: {
      websites: websites,
      websitestatistics: {
        key: "websitestatistics",
        path: "/website/statistics",
        section: "verwaltung",
        title: "Statistiken",
        view: <WebsiteStatisticsPage />,
        icon: <StatisticsIcon />,
        groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
        level: 1,
        navigation: true,
        indented: true,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  hosters: hosters,
  templatemanagement: {
    key: "template",
    path: "/template",
    section: "grunddaten",
    title: "Template Man.",
    view: <TemplatesNavigationPage />,
    icon: <TemplateIcon />,
    groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    isDropdown: true,
    subNavigations: {
      templates: templates,
      templatestatistics: {
        key: "templatestatistics",
        path: "/template/statistics",
        section: "grunddaten",
        title: "Statistiken",
        view: <TemplateStatisticsPage />,
        icon: <StatisticsIcon />,
        groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
        level: 1,
        navigation: true,
        indented: true,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  systems: systems,
  tasktypes: tasktypes,
  effortrules: effortrules,
  users: users,
  settings: {
    key: "settings",
    path: "/settings",
    section: "account",
    title: "Benutzer-Einstellungen",
    view: <UserSettingsPage />,
    icon: <SettingsIcon />,
    groups: [
      USER_GROUPS.ADMIN,
      USER_GROUPS.ACCOUNTING,
      USER_GROUPS.LOGISTICS,
      USER_GROUPS.OFFICE,
      USER_GROUPS.QUALITY_MANAGEMENT,
    ],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {
      settingsedit: {
        key: "settingsedit",
        path: "/settings/edit",
        section: "verwaltung",
        title: "Benutzer-Einstellungen bearbeiten",
        view: <UserSettingsFormPage />,
        icon: <SpieleladenIcon />,
        groups: [
          USER_GROUPS.ADMIN,
          USER_GROUPS.ACCOUNTING,
          USER_GROUPS.LOGISTICS,
          USER_GROUPS.OFFICE,
          USER_GROUPS.QUALITY_MANAGEMENT,
        ],
        level: 1,
        navigation: false,
        indented: false,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  globalsettings: {
    key: "globalsettings",
    path: "/globalsettings",
    section: "admin",
    title: "Globale Einstellungen",
    view: <GlobalSettingsPage />,
    icon: <GlobalSettingsIcon />,
    groups: [USER_GROUPS.ADMIN],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {
      globalsettingsedit: {
        key: "globalsettingsedit",
        path: "/globalsettings/edit",
        section: "admin",
        title: "Globale Einstellungen bearbeiten",
        view: <GlobalSettingsFormPage />,
        icon: <SpieleladenIcon />,
        groups: [USER_GROUPS.ADMIN],
        level: 1,
        navigation: false,
        indented: false,
        isWithParam: false,
        subNavigations: {},
      },
    },
  },
  logout: {
    key: "logout",
    path: "/logout",
    section: "account",
    title: "Ausloggen",
    view: <Logout />,
    icon: <LogoutIcon />,
    groups: [
      USER_GROUPS.ADMIN,
      USER_GROUPS.ACCOUNTING,
      USER_GROUPS.LOGISTICS,
      USER_GROUPS.OFFICE,
      USER_GROUPS.QUALITY_MANAGEMENT,
    ],
    level: 0,
    navigation: true,
    indented: false,
    isWithParam: false,
    subNavigations: {},
  },
};

export const getSubNavigationsAsArray = (
  routes: Record<string, RouteMapType>,
) => {
  const tempRoutes: RouteArrayType[] = [];

  for (const routeKey in routes) {
    if (Object.keys(routes[routeKey].subNavigations).length) {
      const subNavigations = getSubNavigationsAsArray(
        routes[routeKey].subNavigations,
      );

      const tempRoute = {
        ...routes[routeKey],
        subNavigations: subNavigations,
      } as RouteArrayType;

      tempRoutes.push(tempRoute);
    } else {
      const tempRoute = {
        ...routes[routeKey],
        subNavigations: [],
      } as RouteArrayType;

      tempRoutes.push(tempRoute);
    }
  }

  return tempRoutes;
};

export const RoutesArray = getSubNavigationsAsArray(Routes);

export const getBreadcrumbsFromRoute = (
  breadcrumbs: RouteType[],
  routePath: RouteType[],
  pathname: string,
  routesArray: RouteArrayType[],
) => {
  for (const route of routesArray) {
    if (route.subNavigations.length) {
      const isCurrentRoute = checkRoute(pathname, route);
      if (isCurrentRoute) {
        breadcrumbs.push(...routePath, route);
        return;
      }

      getBreadcrumbsFromRoute(
        breadcrumbs,
        [...routePath, route],
        pathname,
        route.subNavigations,
      );
    } else {
      const isCurrentRoute = checkRoute(pathname, route);
      if (isCurrentRoute) {
        breadcrumbs.push(...routePath);
        if (
          routePath.length === 0 ||
          routePath[routePath.length - 1].path !== route.path
        ) {
          breadcrumbs.push(route);
        }
        return;
      }
    }
  }
};

export const getBreadcrumbs = (pathname: string): RouteType[] => {
  const breadcrumbs: RouteType[] = [];

  getBreadcrumbsFromRoute(breadcrumbs, [], pathname, RoutesArray);

  return breadcrumbs;
};

export const getRouteParamName = (pathname: string) => {
  const pathParts = pathname.split("/");

  return pathParts[pathParts.length - 1];
};

export const getRouteLastParamName = (pathname: string) => {
  const routeParams = getRouteParams(pathname);

  return routeParams.length ? routeParams[routeParams.length - 1] : null;
};

export const getRouteParams = (pathname: string) => {
  const pathParts = pathname.split("/");
  const params = pathParts.filter((pathPart) => pathPart.charAt(0) === ":");
  return params;
};

export const getCurrentRoute = (pathname: string) => {
  for (const route of RoutesArray) {
    if (route.subNavigations.length) {
      for (const subRoute of route.subNavigations) {
        if (subRoute.subNavigations.length) {
          for (const subSubRoute of subRoute.subNavigations) {
            if (pathname.includes(subSubRoute.path)) {
              return subSubRoute;
            }
          }
        }
        if (pathname.includes(subRoute.path)) {
          return subRoute;
        }
      }
    }
    if (pathname.includes(route.path)) {
      return route;
    }
  }
};

export const getSubmenuListOpen = (pathname: string): string[] => {
  const submenuListOpen: string[] = RoutesArray.filter((route) =>
    route!.subNavigations
      ?.filter((subRoute) => subRoute.navigation)
      .some((subRoute) => pathname.includes(subRoute.path)),
  ).map((route) => route.key);

  return submenuListOpen;
};

export const checkCustomerRoute = (pathname: string) => {
  const customerListRoute = matchPath(
    {
      path: Routes.customers.path,
    },
    pathname,
  );

  const customerRoute = matchPath(
    {
      path: Routes.customers.subNavigations.customer.path + "/*",
    },
    pathname,
  );

  const customerBookingRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.customers.subNavigations.customer.subNavigations
              .customerbookings.subNavigations.customerbooking.path + "/*",
        },
        pathname,
      ),
  );

  const isCustomerRoute = Boolean(
    !customerListRoute && !customerBookingRoute && customerRoute,
  );

  return isCustomerRoute;
};

export const checkCustomerBookingRoute = (pathname: string) => {
  const customerBookingListRoute = matchPath(
    {
      path: Routes.customers.subNavigations.customer.subNavigations
        .customerbookings.path,
    },
    pathname,
  );

  const customerBookingCreateRoute = matchPath(
    {
      path: Routes.customers.subNavigations.customer.subNavigations
        .customerbookings.subNavigations.customerbookingcreate.path,
    },
    pathname,
  );

  const bookingRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.carebookings.subNavigations.bookings.subNavigations.booking
              .path + "/*",
        },
        pathname,
      ),
  );

  const customerBookingRoute = matchPath(
    {
      path:
        Routes.customers.subNavigations.customer.subNavigations.customerbookings
          .subNavigations.customerbooking.path + "/*",
    },
    pathname,
  );

  const isCustomerBookingRoute = Boolean(
    !customerBookingListRoute &&
      !customerBookingCreateRoute &&
      !bookingRoute &&
      customerBookingRoute,
  );

  return isCustomerBookingRoute;
};

export const checkCustomerWebsiteRoute = (pathname: string) => {
  const customerWebsiteListRoute = matchPath(
    {
      path: Routes.customers.subNavigations.customer.subNavigations
        .customerwebsites.path,
    },
    pathname,
  );

  const customerWebsiteCreateRoute = matchPath(
    {
      path: Routes.customers.subNavigations.customer.subNavigations
        .customerwebsites.subNavigations.customerwebsitecreate.path,
    },
    pathname,
  );

  const websiteRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.websitemanagement.subNavigations.websites.subNavigations
              .website.path + "/*",
        },
        pathname,
      ),
  );

  const customerWebsiteRoute = matchPath(
    {
      path:
        Routes.customers.subNavigations.customer.subNavigations.customerwebsites
          .subNavigations.customerwebsite.path + "/*",
    },
    pathname,
  );

  const isCustomerWebsiteRoute = Boolean(
    !customerWebsiteListRoute &&
      !customerWebsiteCreateRoute &&
      !websiteRoute &&
      customerWebsiteRoute,
  );

  return isCustomerWebsiteRoute;
};

export const checkSystemRoute = (pathname: string) => {
  const systemListRoute = matchPath(
    {
      path: Routes.systems.path,
    },
    pathname,
  );

  const systemCreateRoute = matchPath(
    {
      path: Routes.systems.subNavigations.systemcreate.path,
    },
    pathname,
  );

  const systemRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path: Routes.systems.subNavigations.system.path + "/*",
        },
        pathname,
      ),
  );

  const isSystemRoute = Boolean(
    !systemListRoute && !systemCreateRoute && systemRoute,
  );

  return isSystemRoute;
};

export const checkTemplateRoute = (pathname: string) => {
  const templateListRoute = matchPath(
    {
      path: Routes.templatemanagement.subNavigations.templates.path,
    },
    pathname,
  );

  const templateCreateRoute = matchPath(
    {
      path: Routes.templatemanagement.subNavigations.templates.subNavigations
        .templatecreate.path,
    },
    pathname,
  );

  const templateRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.templatemanagement.subNavigations.templates.subNavigations
              .template.path + "/*",
        },
        pathname,
      ),
  );

  const isTemplateRoute = Boolean(
    !templateListRoute && !templateCreateRoute && templateRoute,
  );

  return isTemplateRoute;
};

export const checkHosterRoute = (pathname: string) => {
  const hosterListRoute = matchPath(
    {
      path: Routes.hosters.path,
    },
    pathname,
  );

  const hosterCreateRoute = matchPath(
    {
      path: Routes.hosters.subNavigations.hostercreate.path,
    },
    pathname,
  );

  const hosterRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path: Routes.hosters.subNavigations.hoster.path + "/*",
        },
        pathname,
      ),
  );

  const isHosterRoute = Boolean(
    !hosterListRoute && !hosterCreateRoute && hosterRoute,
  );

  return isHosterRoute;
};

export const checkWebsiteRoute = (pathname: string) => {
  const websiteListRoute = matchPath(
    {
      path: Routes.websitemanagement.subNavigations.websites.path,
    },
    pathname,
  );

  const websiteCreateRoute = matchPath(
    {
      path: Routes.websitemanagement.subNavigations.websites.subNavigations
        .websitecreate.path,
    },
    pathname,
  );

  const websiteRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.websitemanagement.subNavigations.websites.subNavigations
              .website.path + "/*",
        },
        pathname,
      ),
  );

  const customerWebsiteRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.customers.subNavigations.customer.subNavigations
              .customerwebsites.subNavigations.customerwebsite.path + "/*",
        },
        pathname,
      ),
  );

  const isWebsiteRoute = Boolean(
    !websiteListRoute &&
      !websiteCreateRoute &&
      (websiteRoute || customerWebsiteRoute),
  );

  return isWebsiteRoute;
};

export const checkBookingRoute = (pathname: string) => {
  const bookingListRoute = matchPath(
    {
      path: Routes.carebookings.subNavigations.bookings.path,
    },
    pathname,
  );

  const bookingCreateRoute = matchPath(
    {
      path: Routes.carebookings.subNavigations.bookings.subNavigations
        .bookingcreate.path,
    },
    pathname,
  );

  const bookingRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.carebookings.subNavigations.bookings.subNavigations.booking
              .path + "/*",
        },
        pathname,
      ),
  );

  const customerBookingRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path:
            Routes.customers.subNavigations.customer.subNavigations
              .customerbookings.subNavigations.customerbooking.path + "/*",
        },
        pathname,
      ),
  );

  const isBookingRoute = Boolean(
    !bookingListRoute &&
      !bookingCreateRoute &&
      (bookingRoute || customerBookingRoute),
  );

  return isBookingRoute;
};

export const checkPermanentTaskRoute = (pathname: string) => {
  const permanentTaskListRoute = matchPath(
    {
      path: Routes.permanenttasks.path,
    },
    pathname,
  );

  const permanentTaskCreateRoute = matchPath(
    {
      path: Routes.permanenttasks.subNavigations.permanenttaskcreate.path,
    },
    pathname,
  );

  const permanentTaskRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path: Routes.permanenttasks.subNavigations.permanenttask.path + "/*",
        },
        pathname,
      ),
  );

  const isPermanentTaskRoute = Boolean(
    !permanentTaskListRoute && !permanentTaskCreateRoute && permanentTaskRoute,
  );

  return isPermanentTaskRoute;
};

export const checkTaskTypeRoute = (pathname: string) => {
  const taskTypeListRoute = matchPath(
    {
      path: Routes.tasktypes.path,
    },
    pathname,
  );

  const taskTypeCreateRoute = matchPath(
    {
      path: Routes.tasktypes.subNavigations.tasktypecreate.path,
    },
    pathname,
  );

  const taskTypeRoute = Boolean(
    !pathname.includes("/create") &&
      matchPath(
        {
          path: Routes.tasktypes.subNavigations.tasktype.path + "/*",
        },
        pathname,
      ),
  );

  const isTaskTypeRoute = Boolean(
    !taskTypeListRoute && !taskTypeCreateRoute && taskTypeRoute,
  );

  return isTaskTypeRoute;
};

export const checkRoute = (pathname: string, route: RouteType) => {
  const isRoute = matchPath(
    {
      path: route.path,
    },
    pathname,
  );

  const isCustomerRoute = Boolean(isRoute !== null && isRoute !== undefined);

  return isCustomerRoute;
};

export const checkRouteWithSubnavigations = (
  pathname: string,
  routeArray: RouteArrayType[],
): boolean => {
  if (!routeArray.length) {
    return false;
  }
  for (const route of routeArray) {
    const isRoute = matchPath(
      {
        path: route.path,
      },
      pathname,
    );

    const isRouteSelected = Boolean(isRoute !== null && isRoute !== undefined);

    if (isRouteSelected) {
      return true;
    }

    if (route.subNavigations.length) {
      const isSubRouteSelected = checkRouteWithSubnavigations(
        pathname,
        route.subNavigations,
      );
      if (isSubRouteSelected) {
        return true;
      }
    }
  }
  return false;
};

export const checkRouteIsSelected = (
  pathname: string,
  route: RouteArrayType,
) => {
  const routeMatch = matchPath(
    {
      path: route.path,
    },
    pathname,
  );

  const subRouteIsSelected = checkRouteWithSubnavigations(
    pathname,
    route.subNavigations,
  );

  const isRouteSelected = Boolean(
    routeMatch !== null && routeMatch !== undefined,
  );

  return isRouteSelected || subRouteIsSelected;
};

export const AuthRoutes = {
  login: "/login",
  register: "/register",
  verify: "/verify",
  resendVerify: "/resendverify",
  forgotPassword: "/forgotpassword",
  confirmForgotPassword: "/confirmForgotPassword",
  businessProfileSetup: "/businessProfileSetup",
};

export const isAuthRoute = (locationPathname: string) => {
  return Object.values(AuthRoutes).includes(locationPathname);
};

export const AuthRouting = [
  {
    path: AuthRoutes.login,
    view: <Login />,
  },
  {
    path: AuthRoutes.forgotPassword,
    view: <ForgotPassword />,
  },
  {
    path: AuthRoutes.confirmForgotPassword,
    view: <ConfirmForgotPassword />,
  },
];

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  customSelect: {
    marginBottom: 30,
  },
  formField: {
    marginBottom: 10,
  },
  phoneNumber: {
    marginBottom: 30,
  },
  gridItem: {
    marginBottom: 30,
    "&:not(:last-child)": {
      marginRight: 100,
    },
  },
  userSettingsButtons: {
    marginTop: 79,
  },
}));

export default useStyles;

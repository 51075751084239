import React from "react";
import { useAuthContext } from "context";
import useStyles from "./styles";

type HeaderContainerProps = {
  layoutType: "layout" | "authLayout";
  children: React.ReactNode;
};

export const HeaderContainer: React.FC<HeaderContainerProps> = ({
  layoutType,
  children,
}) => {
  const authContext = useAuthContext();
  const { classes, cx } = useStyles();
  return (
    <header
      className={cx(
        classes.headerContainer,
        layoutType === "authLayout"
          ? classes.headerContainerAuthLayout
          : authContext.menuOpen
            ? classes.headerContainerMenuOpen
            : classes.headerContainerMenuClosed,
      )}
    >
      <div className={classes.header}>
        <div className={classes.container}>{children}</div>
      </div>
    </header>
  );
};

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateTemplateInput, InfiniteTemplateData } from "types";
import { getTemplate } from "modules/templates/api";

export const useTemplateCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateTemplateInput) => {
      const { data: newTemplate } = await client.models.Template.create(input);

      if (!newTemplate) return null;

      const template = await getTemplate(newTemplate.id);

      return template;
    },
    // When mutate is called:
    onMutate: async (newTemplate) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.Templates] });

      // Snapshot the previous value
      const previousTemplates = queryClient.getQueryData([QueryKeys.Templates]);

      // Optimistically update to the new value
      if (previousTemplates) {
        queryClient.setQueryData(
          [QueryKeys.Templates],
          (old: InfiniteTemplateData) => {
            const oldData = old?.pages
              ? old.pages.flatMap((page) => page.templateList)
              : [];

            [...oldData, newTemplate];
          },
        );
      }

      // Return a context object with the snapshotted value
      return { previousTemplates };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newTemplate, context) => {
      console.error("Error saving record:", err, newTemplate);
      if (context?.previousTemplates) {
        queryClient.setQueryData(
          [QueryKeys.Templates],
          context.previousTemplates,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Templates] });
    },
  });

  return createMutation;
};

import React from "react";
import { Grid } from "@mui/material";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { useLoadWebsiteStatistics } from "modules/websites/hooks/useLoadWebsiteStatistics";
import { BacklinkStatistics } from "../BacklinkStatistics";
import { HosterStatistics } from "../HosterStatistics";
import { RelationStatistics } from "../RelationStatistics";
import { SystemStatistics } from "../SystemStatistics";
import { WebsiteListTable } from "../WebsiteListTable";

export const WebsiteStatisticsPage: React.FC = () => {
  const {
    websiteList,
    systemList,
    hosterList,
    websitesCreatedByUs,
    websitesCurrentlyResponsible,
    websitesBacklinkCreationOpen,
    websitesBacklinkMaintenanceOpen,
    websitesFormiloBacklinkOpen,
    isLoading,
  } = useLoadWebsiteStatistics();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Website-Statistiken">
        <Loading
          description="Bitte warten. Website-Statistiken werden geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item>
        <Grid container direction="row" spacing={3}>
          <Grid item md={6}>
            <RelationStatistics
              websiteList={websiteList}
              websitesCreatedByUs={websitesCreatedByUs}
              websitesCurrentlyResponsible={websitesCurrentlyResponsible}
            />
          </Grid>
          <Grid item md={6}>
            <SystemStatistics
              websiteList={websiteList}
              systemList={systemList}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={3}>
          <Grid item md={6}>
            <HosterStatistics
              websiteList={websiteList}
              hosterList={hosterList}
            />
          </Grid>
          <Grid item md={6}>
            <BacklinkStatistics websiteList={websiteList} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <WebsiteListTable
          tableTitle="GW Backlink Erstellung offen"
          websiteList={websitesBacklinkCreationOpen}
        />
      </Grid>
      <Grid item>
        <WebsiteListTable
          tableTitle="GW Backlink Pflege offen"
          websiteList={websitesBacklinkMaintenanceOpen}
        />
      </Grid>
      <Grid item>
        <WebsiteListTable
          tableTitle="Formilo Backlink offen"
          websiteList={websitesFormiloBacklinkOpen}
        />
      </Grid>
    </Grid>
  );
};

import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getCustomer } from "modules/customers/api";

type CustomerReadParamsType = {
  customerID: string;
};

export const useCustomerRead = () => {
  const authContext = useAuthContext();

  const { customerID } = useParams<CustomerReadParamsType>();

  console.log("Loading customer with ID: ", customerID);

  const { data: customer, isLoading } = useQuery({
    queryKey: [QueryKeys.Customers, customerID],
    queryFn: async () => {
      if (!customerID) return null;

      const customerResponse = await getCustomer(customerID);

      if (customerResponse) {
        authContext.setCustomer(customerResponse);

        authContext.setCurrentData({
          id: customerResponse.id,
          name: customerResponse.Account_Name,
        });
      }

      return customerResponse;
    },
  });

  return {
    customerID,
    customer: customer ?? null,
    isLoading,
  };
};

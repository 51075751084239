import React, { useMemo } from "react";
import { useEmployeeAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";
import utils from "utils";

export const EmployeeFilter: React.FC = () => {
  const { employeeList } = useEmployeeAllList(false);

  const employeeOptionList = useMemo(() => {
    const employeeListUnique = utils.general
      .filterUniques(employeeList)
      .sort((a, b) => a.firstName.localeCompare(b.firstName));

    return employeeListUnique.map((employeeOption) => {
      return {
        label: employeeOption.firstName + " " + employeeOption.lastName,
        value: employeeOption.id,
      };
    });
  }, [employeeList]);

  return (
    <OptionsFilter
      param="employee"
      paramName="Employee"
      options={employeeOptionList}
    />
  );
};

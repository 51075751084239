import React, { useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  CreateWebsiteInput,
  Customer,
  Hoster,
  System,
  Template,
  Website,
} from "types";
import {
  CustomButton,
  CustomButtonHandles,
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  CustomSwitch,
  Editor,
  LabeledTypography,
  SelectionButton,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { CustomerSelectionDialog } from "modules/customers/customerselection/CustomerSelectionDialog";
import { HosterSelectionDialog } from "modules/hosters/hosterselection/HosterSelectionDialog";
import {
  CompanyIcon,
  HosterIcon,
  SystemIcon,
  TemplateIcon,
} from "modules/icons";
import { BacklinkStatusOption } from "modules/shared/options/BacklinkStatusOption";
import { RuntimeEnvironmentOption } from "modules/shared/options/RuntimeEnvironmentOption";
import { WebsiteTypeOption } from "modules/shared/options/WebsiteTypeOption";
import { SystemSelectionDialog } from "modules/systems/systemselection/SystemSelectionDialog";
import { TemplateSelectionDialog } from "modules/templates/templateselection/TemplateSelectionDialog";
import { useWebsiteForm } from "../useWebsiteForm";
import utils from "utils";
import useStyles from "./styles";

type WebsiteFormProps = {
  formIntent: "create" | "edit";
  website: Website | null | undefined;
};

export type WebsiteFormHandles = {
  validateWebsiteForm(): CreateWebsiteInput | null;
};

const WebsiteFormComponent: React.ForwardRefRenderFunction<
  WebsiteFormHandles,
  WebsiteFormProps
> = ({ formIntent, website }, websiteFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    url,
    setURL,
    websiteOwner,
    setWebsiteOwner,
    websitePayer,
    setWebsitePayer,
    websiteType,
    setWebsiteType,
    system,
    setSystem,
    runtimeEnvironment,
    setRuntimeEnvironment,
    template,
    setTemplate,
    hoster,
    setHoster,
    backlinkErstellung,
    setBacklinkErstellung,
    backlinkPflege,
    setBacklinkPflege,
    formiloBacklink,
    setFormiloBacklink,
    createdByUs,
    setCreatedByUs,
    currentlyResponsible,
    setCurrentlyResponsible,
    rating,
    setRating,
    phpVersion,
    setPHPVersion,
    description,
    setDescription,
    resetWebsiteForm,
  } = useWebsiteForm(formIntent, website);

  const [selectWebsiteOwnerDialogOpen, setSelectWebsiteOwnerDialogOpen] =
    useState<boolean>(false);

  const websiteOwnerSelectionHandler = (websiteOwner: Customer) => {
    setWebsiteOwner(websiteOwner);
    setSelectWebsiteOwnerDialogOpen(false);
  };

  const [selectWebsitePayerDialogOpen, setSelectWebsitePayerDialogOpen] =
    useState<boolean>(false);

  const websitePayerSelectionHandler = (websitePayer: Customer) => {
    setWebsitePayer(websitePayer);
    setSelectWebsitePayerDialogOpen(false);
  };

  const [selectSystemDialogOpen, setSelectSystemDialogOpen] =
    useState<boolean>(false);

  const systemSelectionHandler = (system: System) => {
    setSystem(system);
    setSelectSystemDialogOpen(false);
  };

  const [selectHosterDialogOpen, setSelectHosterDialogOpen] =
    useState<boolean>(false);

  const hosterSelectionHandler = (hoster: Hoster) => {
    setHoster(hoster);
    setSelectHosterDialogOpen(false);
  };

  const [selectTemplateDialogOpen, setSelectTemplateDialogOpen] =
    useState<boolean>(false);

  const templateSelectionHandler = (template: Template) => {
    setTemplate(template);
    setSelectTemplateDialogOpen(false);
  };

  const urlInputRef = useRef<TextInputFieldHandles>(null);
  const websiteOwnerInputRef = useRef<CustomButtonHandles>(null);
  const websitePayerInputRef = useRef<CustomButtonHandles>(null);
  const websiteTypeInputRef = useRef<CustomSelectHandles>(null);
  const systemInputRef = useRef<CustomButtonHandles>(null);
  const runtimeEnvironmentInputRef = useRef<CustomSelectHandles>(null);
  const templateInputRef = useRef<CustomButtonHandles>(null);
  const hosterInputRef = useRef<CustomButtonHandles>(null);
  const backlinkErstellungInputRef = useRef<CustomSelectHandles>(null);
  const backlinkPflegeInputRef = useRef<CustomSelectHandles>(null);
  const formiloBacklinkInputRef = useRef<CustomSelectHandles>(null);
  const ratingInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(websiteFormRef, () => ({
    validateWebsiteForm: () => {
      if (url === "") {
        enqueueSnackbar("Bitte gib die URL der Webseite ein!");
        urlInputRef.current?.highlight();
        return null;
      }

      if (!websiteType) {
        enqueueSnackbar("Bitte wähle den Typ der Webseite aus!");
        websiteTypeInputRef.current?.highlight();
        return null;
      }

      if (!websiteOwner) {
        enqueueSnackbar("Bitte wähle den Besitzer der Webseite aus!");
        websiteOwnerInputRef.current?.highlight();
        return null;
      }

      if (!websitePayer) {
        enqueueSnackbar("Bitte wähle den Zahler der Webseite aus!");
        websitePayerInputRef.current?.highlight();
        return null;
      }

      if (!system) {
        enqueueSnackbar("Bitte wähle das System der Webseite aus!");
        systemInputRef.current?.highlight();
        return null;
      }

      if (!template) {
        enqueueSnackbar("Bitte wähle das Template der Webseite aus!");
        templateInputRef.current?.highlight();
        return null;
      }

      if (!hoster) {
        enqueueSnackbar("Bitte wähle den Hoster der Webseite aus!");
        hosterInputRef.current?.highlight();
        return null;
      }

      if (!backlinkErstellung) {
        enqueueSnackbar(
          "Bitte wähle eine Option für GW Backling Erstellung aus!",
        );
        backlinkErstellungInputRef.current?.highlight();
        return null;
      }

      if (!backlinkPflege) {
        enqueueSnackbar("Bitte wähle eine Option für GW Backling Pflege aus!");
        backlinkPflegeInputRef.current?.highlight();
        return null;
      }

      if (!formiloBacklink) {
        enqueueSnackbar("Bitte wähle eine Option für Formilo Backlink aus!");
        formiloBacklinkInputRef.current?.highlight();
        return null;
      }

      if (!runtimeEnvironment) {
        enqueueSnackbar("Bitte wähle die Laufzeitumgebung der Webseite aus!");
        runtimeEnvironmentInputRef.current?.highlight();
        return null;
      }

      if (rating === "") {
        enqueueSnackbar("Bitte wähle die Bewertung der Webseite ein!");
        ratingInputRef.current?.highlight();
        return null;
      }

      const websiteFormInput: CreateWebsiteInput = {
        url: url,
        websiteType: websiteType,
        websiteOwner: websiteOwner.id,
        websitePayer: websitePayer.id,
        systemID: system.id,
        templateID: template.id,
        hosterID: hoster.id,
        runtimeEnvironment: runtimeEnvironment,
        backlinkErstellung: backlinkErstellung,
        backlinkPflege: backlinkPflege,
        formiloBacklink: formiloBacklink,
        createdByUs: createdByUs,
        currentlyResponsible: currentlyResponsible,
        rating: rating,
        phpVersion: phpVersion,
        description: utils.wysiwyg.getStringFromEditorState(description),
      };

      resetWebsiteForm();

      return websiteFormInput;
    },
  }));

  return (
    <>
      <CustomerSelectionDialog
        dialogOpen={selectWebsiteOwnerDialogOpen}
        setDialogOpen={setSelectWebsiteOwnerDialogOpen}
        customerSelectionHandler={websiteOwnerSelectionHandler}
      />
      <CustomerSelectionDialog
        dialogOpen={selectWebsitePayerDialogOpen}
        setDialogOpen={setSelectWebsitePayerDialogOpen}
        customerSelectionHandler={websitePayerSelectionHandler}
      />
      <SystemSelectionDialog
        dialogOpen={selectSystemDialogOpen}
        setDialogOpen={setSelectSystemDialogOpen}
        systemSelectionHandler={systemSelectionHandler}
      />
      <HosterSelectionDialog
        dialogOpen={selectHosterDialogOpen}
        setDialogOpen={setSelectHosterDialogOpen}
        hosterSelectionHandler={hosterSelectionHandler}
      />
      <TemplateSelectionDialog
        dialogOpen={selectTemplateDialogOpen}
        setDialogOpen={setSelectTemplateDialogOpen}
        templateSelectionHandler={templateSelectionHandler}
      />

      <Grid container direction="row" spacing={4} className={classes.gridRow}>
        <Grid item xl={3} lg={4} md={4}>
          <TextInputField
            label="URL"
            value={url}
            onChange={(e) => setURL(e.target.value)}
            ref={urlInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          <WebsiteTypeOption
            websiteType={websiteType}
            setWebsiteType={setWebsiteType}
            websiteTypeInputRef={websiteTypeInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} className={classes.gridRow}>
        <Grid item xl={3} lg={4} md={4}>
          {websiteOwner ? (
            <SelectionButton
              label="Besitzer"
              name={websiteOwner.Account_Name}
              onClick={() => setSelectWebsiteOwnerDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Besitzer"
              content={
                <CustomButton
                  text="Besitzer auswählen"
                  onClick={() => setSelectWebsiteOwnerDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <CompanyIcon className={classes.selectButtonIcon} />
                  }
                  ref={websiteOwnerInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          {websitePayer ? (
            <SelectionButton
              label="Zahler"
              name={websitePayer.Account_Name}
              onClick={() => setSelectWebsitePayerDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Zahler"
              content={
                <CustomButton
                  text="Zahler auswählen"
                  onClick={() => setSelectWebsitePayerDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <CompanyIcon className={classes.selectButtonIcon} />
                  }
                  ref={websitePayerInputRef}
                />
              }
            />
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} className={classes.gridRow}>
        <Grid item xl={3} lg={4} md={4}>
          {system ? (
            <SelectionButton
              label="System"
              name={system.name}
              onClick={() => setSelectSystemDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="System"
              content={
                <CustomButton
                  text="System auswählen"
                  onClick={() => setSelectSystemDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <SystemIcon className={classes.selectButtonIcon} />
                  }
                  ref={systemInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          {template ? (
            <SelectionButton
              label="Template"
              name={template.name}
              onClick={() => setSelectTemplateDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Template"
              content={
                <CustomButton
                  text="Template auswählen"
                  onClick={() => setSelectTemplateDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <TemplateIcon className={classes.selectButtonIcon} />
                  }
                  ref={templateInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          {hoster ? (
            <SelectionButton
              label="Hoster"
              name={hoster.name}
              onClick={() => setSelectHosterDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Hoster"
              content={
                <CustomButton
                  text="Hoster auswählen"
                  onClick={() => setSelectHosterDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <HosterIcon className={classes.selectButtonIcon} />
                  }
                  ref={hosterInputRef}
                />
              }
            />
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} className={classes.gridRow}>
        <Grid item xl={3} lg={4} md={4}>
          <RuntimeEnvironmentOption
            runtimeEnvironment={runtimeEnvironment}
            setRuntimeEnvironment={setRuntimeEnvironment}
            runtimeEnvironmentInputRef={runtimeEnvironmentInputRef}
            required={true}
          />
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          <CustomNumberField
            label="Bewertung"
            number={rating}
            setNumber={setRating}
            minValue={1}
            maxValue={10}
            ref={ratingInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} className={classes.gridRow}>
        <Grid item xl={3} lg={4} md={4}>
          <BacklinkStatusOption
            label="GW Backlink Erstellung"
            backlinkStatus={backlinkErstellung}
            setBacklinkStatus={setBacklinkErstellung}
          />
        </Grid>
        <Grid item xl={3} lg={4} md={4}>
          <BacklinkStatusOption
            label="GW Backlink Pflege"
            backlinkStatus={backlinkPflege}
            setBacklinkStatus={setBacklinkPflege}
          />
        </Grid>
        <Grid item xl={3} lg={4} md={4}>
          <BacklinkStatusOption
            label="Formilo Backlink"
            backlinkStatus={formiloBacklink}
            setBacklinkStatus={setFormiloBacklink}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} className={classes.gridRow}>
        <Grid item xl={3} lg={4} md={4}>
          <CustomSwitch
            label="Von uns erstellt"
            switchLabel={createdByUs ? "JA" : "NEIN"}
            name="Von uns erstellt"
            checkedValue={createdByUs}
            onChange={(_e, checked) => setCreatedByUs(checked)}
          />
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          <CustomSwitch
            label="Aktuell Zuständig"
            switchLabel={currentlyResponsible ? "JA" : "NEIN"}
            name="Aktuell Zuständig"
            checkedValue={currentlyResponsible}
            onChange={(_e, checked) => setCurrentlyResponsible(checked)}
          />
        </Grid>

        <Grid item xl={3} lg={4} md={4}>
          <TextInputField
            label="PHP-Version"
            value={phpVersion}
            onChange={(e) => setPHPVersion(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={12}>
          <Editor
            label="Anmerkungen"
            setEditor={setDescription}
            initialValue={website?.description}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const WebsiteForm = React.forwardRef(WebsiteFormComponent);

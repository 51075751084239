import { useEffect, useState } from "react";

export function usePersistedStateWithDate<T>(
  key: string,
  defaultValue: T,
  formIntent: "create" | "edit"
) {
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return formIntent === "create" && storedValue !== null
      ? (JSON.parse(storedValue, dateReviver) as T)
      : (defaultValue as T);
  });

  useEffect(() => {
    if (formIntent === "create") {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [formIntent, key, state]);

  return [state, setState] as const;
}

// I am a JSON.parse() reviver that will parse serialized Date objects back into actual
// Date objects.
// --
// CAUTION: This gets called for every single value in the deserialized structure.
function dateReviver(_: string, value: any) {
  if (isSerializedDate(value)) {
    return new Date(value);
  }

  // If it's not a date-string, we want to return the value as-is. If we fail to return
  // a value, it will be omitted from the resultant data structure.
  return value;
}

// I determine if the given value is a string that matches the serialized-date pattern.
function isSerializedDate(value: any) {
  // Dates are serialized in TZ format, example: '1981-12-20T04:00:14.000Z'.
  const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  return isString(value) && datePattern.test(value);
}

// I determine if the given value is a String.
function isString(value: any) {
  return {}.toString.call(value) === "[object String]";
}

import React from "react";
import { useTaskTypeList, useTaskTypeRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { TaskTypeListFilter } from "../TaskTypeListFilter";
import { TaskTypeListTable } from "../TaskTypeListTable";

export const TaskTypeListPage: React.FC = () => {
  const { TaskTypeIcon, navigateToCreateTaskTypePage } = useTaskTypeRoutes();

  const { taskTypeList, isLoading, hasNextPage, fetchNextPage } =
    useTaskTypeList();

  return (
    <BoxHeadlineContainer
      boxTitle="Aufgaben-Typen"
      boxIcon={TaskTypeIcon}
      boxSubTitle={taskTypeList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neuer Aufgaben-Typ"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateTaskTypePage()}
        />
      }
    >
      <TaskTypeListFilter />
      <TaskTypeListTable
        listIntent="list"
        taskTypeList={taskTypeList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

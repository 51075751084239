import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useTaskDelete, useTaskRoutes } from "hooks";
import { Task } from "types";
import { DeleteDialog } from "components";
import { isTaskUsed } from "../../api";

type TaskDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  task: Task | undefined;
};

export const TaskDeleteDialog: React.FC<TaskDeleteDialogProps> = ({
  task,
  dialogOpen,
  setDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteTaskMutation = useTaskDelete();
  const { navigateToTaskListPage } = useTaskRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || task === undefined) {
    return null;
  }

  const deleteTaskRequest = async () => {
    setDeleteLoading(true);
    const taskIsUsed = await isTaskUsed(task);

    if (taskIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Diese Pauschalaufgabe kann nicht gelöscht werden, da sie in Verwendung ist!",
      );
      return;
    }

    let deletedTask = await deleteTaskMutation.mutateAsync(task);

    console.log("deletedTask: ", deletedTask);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigateToTaskListPage();
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteTaskRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Pauschalaufgabe Löschen"
      itemName={task.taskType.name}
      itemDeleteDescription="die Pauschalaufgabe"
    />
  );
};

import {
  wrapDocumentContent,
  DOCUMENT_FOOTER,
  DOCUMENT_HEADER,
  DOCUMENT_STYLES,
} from "../layout";
import { generatePDF } from "../api";

export const generateCareReportPDF = async (content: string, fileName: string) => {
  const careReportContent = wrapDocumentContent(content);

  return await generatePDF(
    DOCUMENT_HEADER,
    DOCUMENT_FOOTER,
    careReportContent,
    DOCUMENT_STYLES,
    fileName
  );
};

import { useNavigate } from "react-router-dom";
import { User } from "types";
import { Routes } from "routes";

export const useUserRoutes = () => {
  const navigate = useNavigate();

  const UserIcon = Routes.users.icon;

  const getCreateUserPageRoute = () =>
    Routes.users.subNavigations.usercreate.path;

  const getEditUserPageRoute = (user: User) =>
    Routes.users.subNavigations.user.subNavigations.useredit.path.replace(
      ":userID",
      user.id,
    );

  const getUserPageRoute = (user: User) =>
    Routes.users.subNavigations.user.path.replace(":userID", user.id);

  const getUserListPageRoute = () => Routes.users.path;

  const getEditUserSettingsPageRoute = () =>
    Routes.settings.subNavigations.settingsedit.path;

  const getUserSettingsPageRoute = () => Routes.settings.path;

  const navigateToCreateUserPage = () => {
    navigate(getCreateUserPageRoute());
  };

  const navigateToEditUserPage = (user: User) => {
    navigate(getEditUserPageRoute(user));
  };

  const navigateToUserPage = (user: User) => {
    navigate(getUserPageRoute(user));
  };

  const navigateToUserListPage = () => {
    navigate(getUserListPageRoute());
  };

  const navigateToEditUserSettingsPage = () => {
    navigate(getEditUserSettingsPageRoute());
  };

  const navigateToUserSettingsPage = () => {
    navigate(getUserSettingsPageRoute());
  };

  return {
    UserIcon,
    getCreateUserPageRoute,
    getEditUserPageRoute,
    getUserPageRoute,
    navigateToCreateUserPage,
    navigateToEditUserPage,
    navigateToUserPage,
    navigateToUserListPage,
    navigateToEditUserSettingsPage,
    navigateToUserSettingsPage,
  };
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  input: {
    '&[value=""]': {
      color: theme.palette.primary.light,
    },
    color: theme.palette.primary.main,
    textAlign: "left",
    fontSize: 14,
    width: "100%",
    height: "100%",
    padding: 5,
    paddingLeft: 7,
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    "::selection": {
      backgroundColor: "#dffff6",
    },
    "::-moz-selection": {
      backgroundColor: "#dffff6",
    },
    MozAppearance: "textfield",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
}));

export default useStyles;

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  cross: {
    width: 19,
    height: 19,
    color: theme.palette.red.main,
    "& g": {
      strokeWidth: "3",
    },
    verticalAlign: "middle",
  },
}));

export default useStyles;

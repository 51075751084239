import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useBookingDelete, useBookingRoutes } from "hooks";
import { Booking } from "types";
import { DeleteDialog } from "components";
import { isBookingUsed } from "../../api";

type BookingDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  booking: Booking | undefined;
  routeKey: "bookings" | "customerbookings";
};

export const BookingDeleteDialog: React.FC<BookingDeleteDialogProps> = ({
  booking,
  dialogOpen,
  setDialogOpen,
  routeKey,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteBookingMutation = useBookingDelete();
  const { navigateToBookingListPage } = useBookingRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || booking === undefined) {
    return null;
  }

  const deleteBookingRequest = async () => {
    setDeleteLoading(true);

    const bookingIsUsed = await isBookingUsed(booking.id);

    if (bookingIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Diese Pflegebuchung kann nicht gelöscht werden, da sie in Verwendung ist!",
      );
      return;
    }

    const deletedBooking = await deleteBookingMutation.mutateAsync(booking);

    console.log("deletedBooking: ", deletedBooking);
    setDeleteLoading(false);
    setDialogOpen(false);

    navigateToBookingListPage(routeKey);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteBookingRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Pflegebuchung Löschen"
      itemName={booking.month + "/" + booking.year}
      itemDeleteDescription="die Pflegebuchung"
    />
  );
};

import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useHosterRoutes } from "hooks";
import { Hoster } from "types";
import { CustomReactTable } from "core";
import { DescriptionFormatter } from "components";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { HosterDeleteDialog } from "../../hosterDelete/HosterDeleteDialog";

type HosterListTableProps = {
  listIntent: "list" | "selection";
  hosterList: Hoster[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  hosterSelectionHandler?: (hoster: Hoster) => void;
};

export const HosterListTable: React.FC<HosterListTableProps> = ({
  listIntent,
  hosterList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  hosterSelectionHandler,
}) => {
  const { navigateToHosterPage, navigateToEditHosterPage } = useHosterRoutes();

  const [deleteHosterDialogOpen, setDeleteHosterDialogOpen] =
    useState<boolean>(false);
  const [hosterToDelete, setHosterToDelete] = useState<Hoster | undefined>();

  const defaultColumns = React.useMemo<ColumnDef<Hoster>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Bewertung",
        accessorKey: "rating",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Beschreibung",
        accessorKey: "description",
        cell: ({ row }) => (
          <DescriptionFormatter description={row.original.description} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Hoster>
            row={row}
            listIntent={listIntent}
            openEditRowPage={(row) => navigateToEditHosterPage(row)}
            setRowToDelete={setHosterToDelete}
            setDeleteRowDialogOpen={setDeleteHosterDialogOpen}
            rowSelectionHandler={hosterSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditHosterPage, hosterSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Hoster) => {
    if (hosterSelectionHandler) {
      hosterSelectionHandler(row);
    } else {
      navigateToHosterPage(row);
    }
  };

  return (
    <>
      <HosterDeleteDialog
        dialogOpen={deleteHosterDialogOpen}
        setDialogOpen={setDeleteHosterDialogOpen}
        hoster={hosterToDelete}
      />
      <CustomReactTable<Hoster>
        data={hosterList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

import React, { useMemo } from "react";
import { useHosterAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";

export const HosterFilter: React.FC = () => {
  const { hosterList } = useHosterAllList(false);

  const hosterOptionList = useMemo(
    () =>
      hosterList.map((hosterOption) => {
        return {
          label: hosterOption.name,
          value: hosterOption.id,
        };
      }),
    [hosterList],
  );

  return (
    <OptionsFilter
      param="hosterID"
      paramName="Hoster"
      options={hosterOptionList}
    />
  );
};

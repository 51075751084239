import { useNavigate } from "react-router-dom";
import { Ticket } from "types";
import { Routes } from "routes";

export const useTicketRoutes = () => {
  const navigate = useNavigate();

  const TicketIcon = Routes.ticketing.icon;

  const TicketNavigationRoot = Routes.ticketing;

  const getTicketPageRoute = (ticket: Ticket) =>
    Routes.ticketing.subNavigations.tickets.subNavigations.ticket.path.replace(
      ":ticketID",
      ticket.id,
    );

  const getTicketListPageRoute = () =>
    Routes.ticketing.subNavigations.tickets.path;

  const navigateToTicketPage = (ticket: Ticket) => {
    navigate(getTicketPageRoute(ticket));
  };

  const navigateToTicketListPage = () => {
    const searchParams = sessionStorage.getItem(getTicketListPageRoute());

    navigate(
      searchParams
        ? getTicketListPageRoute() + "?" + searchParams
        : getTicketListPageRoute(),
    );
  };

  return {
    TicketIcon,
    TicketNavigationRoot,
    getTicketPageRoute,
    navigateToTicketPage,
    navigateToTicketListPage,
  };
};

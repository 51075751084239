import React from "react";
import { useEmployeeAllList } from "hooks";
import { Employee } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface EmployeeOptionProps extends CustomSelectOptionProps<Employee> {
  employee: Employee | null;
  setEmployee: (value: React.SetStateAction<Employee | null>) => void;
  employeeInputRef?: React.RefObject<CustomSelectHandles>;
}

export const EmployeeOption: React.FC<EmployeeOptionProps> = ({
  employee,
  setEmployee,
  employeeInputRef,
  ...rest
}) => {
  const { employeeList } = useEmployeeAllList(false);

  const filteredEmployeeList = employeeList.filter(
    (employeeItem) => employeeItem.status !== "DISABLED",
  );

  return (
    <CustomSelect<Employee>
      {...rest}
      options={filteredEmployeeList}
      onChange={(employeeOption) => setEmployee(employeeOption)}
      value={employee}
      placeholder="Mitarbeiter auswählen"
      getOptionLabel={(option) => option.firstName + " " + option.lastName}
      getOptionValue={(option) => option.id}
      isOptionDisabled={undefined}
      isOptionSelected={undefined}
      ref={employeeInputRef}
    />
  );
};

import React from "react";
import { Grid, Typography } from "@mui/material";
import { useAuthContext } from "context";
import { useUserRoutes } from "hooks";
import { CustomAvatar, CustomButton, LabeledTypography } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { EditPenIcon } from "modules/icons";
import { getTableHeaderColorName } from "modules/shared/options/TableHeaderColorOption";
import { getTableSpacingName } from "modules/shared/options/TableSpacingOption";
import { getTableThemeName } from "modules/shared/options/TableThemeOption";
import utils from "utils";
import useStyles from "./styles";

const UserSettingsPage: React.FC = () => {
  const { classes } = useStyles();
  const authContext = useAuthContext();

  const { navigateToEditUserSettingsPage } = useUserRoutes();

  const user = authContext.userData;

  if (!user) {
    return (
      <BoxHeadlineContainer boxTitle="Benutzer-Einstellungen">
        Beim Laden der Benutzer-Einstellungen ist ein Fehler aufgetreten!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle="Benutzer-Einstellungen"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Bearbeiten"
          iconBefore={<EditPenIcon />}
          onClick={() => navigateToEditUserSettingsPage()}
          size="small"
          color="blue"
          rootClassName={classes.editButton}
        />
      }
    >
      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Anrede"
            content={user.salutation === "Mrs" ? "Frau" : "Herr"}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Vorname" content={user.firstName} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Nachname" content={user.lastName} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography label="Telefon" content={user.phone} />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography label="Fax" content={user.fax} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Benutzerbild"
            content={
              <CustomAvatar
                showBadge={false}
                size="100px"
                s3Resource={user.avatar}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Rollen"
            content={user.cognitoUser?.groups
              .map((group) => group.GroupName)
              .join(", ")}
          />
        </Grid>
      </Grid>

      <Typography variant="h2">Benutzereinstellungen</Typography>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellen-Theme"
            content={getTableThemeName(user.userSettings.tableTheme)}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellenkopf-Farbe"
            content={getTableHeaderColorName(
              user.userSettings.tableHeaderColor,
            )}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellen-Abstand"
            content={getTableSpacingName(user.userSettings.tableSpacing)}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellenkopf fixieren"
            content={user.userSettings.tableSticky ? "Ja" : "Nein"}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item md={4}>
          <LabeledTypography
            label="Bevorzugte Einheit"
            content={
              utils.constants.WEIGHT_OPTIONS.find(
                (weightOption) =>
                  weightOption.value === user!.userSettings.unitType,
              )?.label
            }
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

export default UserSettingsPage;

import React, { useCallback, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { useAuthContext } from "context";
import utils from "utils";
import useStyles from "./styles";

const Logout: React.FC = () => {
  utils.logger.info("On Logging out...");
  const { classes } = useStyles();
  const authContext = useAuthContext();

  const logout = useCallback(async () => {
    await authContext.logoutHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <section>
      <Container maxWidth={"lg"}>
        <div className={classes.content}>
          <div className={classes.heading}>
            <Typography variant="h1" className={classes.title}>
              Ausloggen...
            </Typography>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Logout;

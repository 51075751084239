import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getTemplate } from "modules/templates/api";

type TemplateReadParamsType = {
  templateID: string;
};

export const useTemplateRead = () => {
  const authContext = useAuthContext();

  const { templateID } = useParams<TemplateReadParamsType>();

  console.log("Loading template with ID: ", templateID);

  const { data: template, isLoading } = useQuery({
    queryKey: [QueryKeys.Templates, templateID],
    queryFn: async () => {
      if (!templateID) return null;

      const templateFromAPI = await getTemplate(templateID);

      if (templateFromAPI) {
        authContext.setTemplate(templateFromAPI);

        authContext.setCurrentData({
          id: templateFromAPI.id,
          name: templateFromAPI.name,
        });
      }

      return templateFromAPI;
    },
  });

  return {
    templateID,
    template: template ?? null,
    isLoading,
  };
};

import React from "react";
import { Grid } from "@mui/material";
import { useSaveFilterParams } from "hooks";
import { ResetFilter } from "modules/shared/filters/ResetFilter";
import useStyles from "./styles";

type RowActionsProps = {
  children: React.ReactNode;
};

export const FilterWrapper: React.FC<RowActionsProps> = ({ children }) => {
  const { classes } = useStyles();
  useSaveFilterParams();

  return (
    <div className={classes.filterWrapper}>
      <Grid
        container
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>{children}</Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          md={3}
        >
          <ResetFilter />
        </Grid>
      </Grid>
    </div>
  );
};

export const FilterRow: React.FC<RowActionsProps> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Grid item container direction="row" className={classes.filterGrid}>
      {children}
    </Grid>
  );
};

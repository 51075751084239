import { SelectionSet } from "aws-amplify/api";
import { InfiniteData } from "@tanstack/react-query";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const HosterSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "name",
  "rating",
  "description",
  "websites.*",
] as const;

export type Hoster = SelectionSet<
  Schema["Hoster"]["type"],
  typeof HosterSelectionSet
>;

export type CreateHosterInput = Schema["Hoster"]["createType"];

export type UpdateHosterInput = Schema["Hoster"]["updateType"];

export type InfiniteHosterList = {
  hosterList: Hoster[];
  nextToken: string | null;
};

export type InfiniteHosterData =
  | InfiniteData<InfiniteHosterList, unknown>
  | undefined;

export const HosterTypeKeys: TypeKeysEnum<Hoster> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  name: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.name,
  },
  rating: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.rating.toString(),
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
  websites: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfHoster = (key: string): key is keyof Hoster =>
  key in HosterTypeKeys;

import * as Sentry from "@sentry/react";
import { AuthDataProps } from "context";

export const logToSentry = (
  message: string,
  contextInfo: string,
  error: any,
  authContext: AuthDataProps | null
) => {
  Sentry.withScope((scope) => {
    if (authContext && authContext.isAuth && authContext.cognitoUser) {
      scope.setUser({
        username: authContext.cognitoUser.username,
        email: authContext.cognitoUser.email,
        name:
          authContext.cognitoUser.firstName +
          " " +
          authContext.cognitoUser.lastName,
      });
      scope.setContext("User Data", authContext.userData);
      scope.setContext("User", authContext.cognitoUser);
    }
    scope.setContext("Context", {
      contextInfo: contextInfo,
      message: message,
      error: error,
    });
    scope.setContext("Environment", { environment: process.env.NODE_ENV });
    const exception =
      (error.error?.errors && error.error?.errors[0]) ||
      (error.errors && error.errors[0]) ||
      error.error ||
      error;
    const eventID = Sentry.captureException(exception);

    if (process.env.NODE_ENV === "development") {
      console.log("Captured Error with eventID: " + eventID, message, error);
    } else {
      console.log("Error occured: ", eventID);
    }
  });
};
export type LoginErrorType = {
  code?: string;
  stack?: string;
  errno?: number;
  message?: string;
};

import { useNavigate } from "react-router-dom";
import { Routes } from "routes";

export const useGlobalSettingsRoutes = () => {
  const navigate = useNavigate();

  const GlobalSettingsIcon = Routes.globalsettings.icon;

  const getEditGlobalSettingsPageRoute = () =>
    Routes.globalsettings.subNavigations.globalsettingsedit.path;

  const getGlobalSettingsPageRoute = () => Routes.globalsettings.path;

  const navigateToEditGlobalSettingsPage = () => {
    navigate(getEditGlobalSettingsPageRoute());
  };

  const navigateToGlobalSettingsPage = () => {
    navigate(getGlobalSettingsPageRoute());
  };

  return {
    GlobalSettingsIcon,
    getEditGlobalSettingsPageRoute,
    getGlobalSettingsPageRoute,
    navigateToEditGlobalSettingsPage,
    navigateToGlobalSettingsPage,
  };
};

import React from "react";
import { useWebsiteAllList, useWebsiteRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { WebsiteListFilter } from "../WebsiteListFilter";
import { WebsiteListTable } from "../WebsiteListTable";

type WebsiteListProps = {
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
};

export const WebsiteListPage: React.FC<WebsiteListProps> = ({ routeKey }) => {
  const { WebsiteIcon, navigateToCreateWebsitePage } = useWebsiteRoutes();

  const { websiteList, isLoading } = useWebsiteAllList();

  return (
    <BoxHeadlineContainer
      boxTitle="Webseiten"
      boxIcon={WebsiteIcon}
      boxSubTitle={websiteList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neue Webseite"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateWebsitePage(routeKey)}
        />
      }
    >
      <WebsiteListFilter />
      <WebsiteListTable
        routeKey={routeKey}
        listIntent="list"
        websiteList={websiteList}
        isLoading={isLoading}
      />
    </BoxHeadlineContainer>
  );
};

import { Table } from "@tanstack/react-table";
import * as ExcelJS from "exceljs";

export const tableListAsExcel = async (table: Table<any>, filename: string) => {
  const workbook = new ExcelJS.Workbook();

  workbook.creator = "Der andere Spieleladen";
  workbook.calcProperties.fullCalcOnLoad = true;

  const worksheet = workbook.addWorksheet("Preisliste ");
  worksheet.views = [{}];

  const headerGroups = table.getHeaderGroups();
  console.log("headerGroups: ", headerGroups);
  console.log("headerGroups.length: ", headerGroups.length);

  for (let i = 0; i < headerGroups.length; i++) {
    const row = worksheet.getRow(i + 1);
    const headerGroup = headerGroups[i];
    const headers = headerGroup.headers.filter(
      (header) =>
        header.column.id !== "select" &&
        header.column.columnDef.header !== "Aktionen",
    );

    let spans = 0;
    console.log("headerGroup.headers: ", headers);
    console.log("headerGroup.headers.length: ", headers.length);

    for (let j = 0; j < headers.length; j++) {
      const header = headers[j];
      console.log("header: ", header.column.id);
      if (header.column.id === "select") {
        continue;
      }
      const headerName = header.column.columnDef.header;

      console.log(
        "Merging cell: ",
        i +
          1 +
          ":" +
          (j + 1 + spans) +
          " to " +
          (i + 1) +
          ":" +
          (j + header.colSpan + spans),
      );
      worksheet.mergeCells([
        i + 1,
        j + 1 + spans,
        i + 1,
        j + header.colSpan + spans,
      ]);
      const cell = row.getCell(j + 1 + spans);
      spans += header.colSpan - 1;
      console.log("Writing to cell: ", headerName);
      cell.value = headerName ? (headerName as string) : "";
      row.height = 33;
      cell.alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      cell.font = {
        size: 14,
        bold: true,
      };
    }
  }

  const rows = table.getIsSomeRowsSelected()
    ? table.getRowModel().rows.filter((row) => row.getIsSelected())
    : table.getRowModel().rows;

  console.log("rows.length: ", rows.length);

  for (let i = 0; i < rows.length; i++) {
    const tableRow = worksheet.getRow(i + 1 + headerGroups.length);
    const row = rows[i];

    console.log("row: ", row);
    const cells = row.getVisibleCells();

    console.log("cells: ", cells);

    for (let j = 0; j < cells.length; j++) {
      const cell = cells[j];

      if (cell.column.id === "select") {
        continue;
      }

      console.log("cell: ", cell);
      const tableCell = tableRow.getCell(j + 1);
      tableCell.value = cell.column.columnDef.meta?.getRawValue
        ? cell.column.columnDef.meta?.getRawValue(row)
        : "";
    }
  }

  worksheet.columns.forEach((column) => {
    if (!column.values) {
      return;
    }
    const lengths = column.values.map((v) =>
      v ? v.toString().length + 10 : 0,
    );
    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength;
  });

  const data = await workbook.xlsx.writeBuffer();

  const pdfBlob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};

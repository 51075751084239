import React from "react";
import clsx from "clsx";
import { OpenBrowserIcon } from "modules/icons";
import useStyles from "./styles";

type OpenInBrowserButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  size?: "small" | "medium" | "big";
  title?: string;
};

export const OpenInBrowserButton: React.FC<OpenInBrowserButtonProps> = ({
  onClick,
  size = "medium",
  title,
}) => {
  const { classes } = useStyles();

  const sizeClass =
    size === "small"
      ? classes.buttonSmall
      : size === "big"
        ? classes.buttonBig
        : classes.buttonMedium;

  return (
    <button
      title={title}
      className={clsx(classes.deblockButton, classes.button, sizeClass)}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <span>
        <OpenBrowserIcon />
      </span>
    </button>
  );
};

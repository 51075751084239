import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  LexicalEditor,
} from "lexical";

export const convertToEditorState = (
  editor: LexicalEditor,
  editorContent: string,
) => {
  editor.update(() => {
    const root = $getRoot();
    root.clear();

    const parser = new DOMParser();
    const dom = parser.parseFromString(editorContent, "text/html");
    const nodes = $generateNodesFromDOM(editor, dom);

    // Check if value ws empty or text node
    if (!nodes.length || nodes[0].getType() === "text") {
      const p = $createParagraphNode();
      p.append($createTextNode(editorContent));
      root.append(p);
    } else {
      root.append(...nodes);
    }
  });

  return editor;
};

export const getStringFromEditorState = (editor: LexicalEditor) => {
  let stringFromEditorState = "";
  editor.read(() => {
    stringFromEditorState = $generateHtmlFromNodes(editor)
      .replace("<p></p>\n", "")
      .replace("<p></p>", "");
  });

  return stringFromEditorState;
};

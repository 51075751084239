import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useBookingCreate,
  useBookingRead,
  useBookingRoutes,
  useBookingUpdate,
  useReportEntryCreate,
  useReportEntryListByBooking,
  useTaskUpdate,
} from "hooks";
import ReactDOMServer from "react-dom/server";
import { Booking, CreateBookingInput, UpdateBookingInput } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { CheckIcon, CrossIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import {
  getBookingListByCustomerYearMonth,
  processBookingsReport,
} from "modules/bookings/api";
import {
  WebsiteReportType,
  getBookingsWebsiteReports,
} from "modules/carereport/api";
import { CareReportDeleteDialog } from "modules/carereport/carereportDelete/CareReportDeleteDialog";
import { ReportEntryListCard } from "modules/carereport/reportentrylist/ReportEntryListCard";
import WebsiteReportListCard from "modules/carereport/websitereportlist/WebsiteReportListCard";
import { DeleteIcon, EditPenIcon } from "modules/icons";
import { generateCareReportPDF } from "modules/pdf/carereport";
import { CareReportContent } from "modules/pdf/carereport/CareReportContent";
import { CareReportContentPreview } from "modules/pdf/carereport/CareReportContentPreview";
import { CareReportView } from "modules/pdf/carereport/CareReportView";
import { BookingDeleteDialog } from "../../bookingDelete/BookingDeleteDialog";
import utils from "utils";
import useStyles from "./styles";

type BookingPageProps = {
  routeKey: "bookings" | "customerbookings";
};

const BookingPage: React.FC<BookingPageProps> = ({ routeKey }) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { booking, customer, isLoading } = useBookingRead();
  const { navigateToEditBookingPage } = useBookingRoutes();
  const { reportEntryList } = useReportEntryListByBooking(false);

  const createBookingMutation = useBookingCreate();
  const updateBookingMutation = useBookingUpdate();

  const createReportEntryMutation = useReportEntryCreate();

  const updateTaskMutation = useTaskUpdate();

  const [deleteBookingDialogOpen, setDeleteBookingDialogOpen] =
    useState<boolean>(false);
  const [bookingToDelete, setBookingToDelete] = useState<Booking | undefined>();

  const [careReportPreviewOpen, setCareReportPreviewOpen] =
    useState<boolean>(false);

  const [bookingsWebsiteReports, setBookingsWebsiteReports] = useState<
    WebsiteReportType[]
  >([]);

  const [websiteReportsLoading, setWebsiteReportsLoading] =
    useState<boolean>(false);

  const [careReportCompletingLoading, setCareReportCompletingLoading] =
    useState<boolean>(false);

  const [careReportCompleted, setCareReportCompleted] =
    useState<boolean>(false);

  const [generateCareReportLoading, setGenerateCareReportLoading] =
    useState<boolean>(false);

  const [deleteCareReportDialogOpen, setDeleteCareReportDialogOpen] =
    useState<boolean>(false);

  const loadWebsiteReports = useCallback(async () => {
    if (!booking) {
      return;
    }
    setWebsiteReportsLoading(true);

    const websiteReports = await getBookingsWebsiteReports(booking);
    setBookingsWebsiteReports(websiteReports);
    setWebsiteReportsLoading(false);
  }, [booking]);

  useEffect(() => {
    loadWebsiteReports();
  }, [loadWebsiteReports]);

  const completeReport = async () => {
    if (!booking || !bookingsWebsiteReports.length) {
      return enqueueSnackbar(
        !booking
          ? "Die Pflegebuchung konnte nicht geladen werden!"
          : "Es konnten keine Webseiten-Einträge für diese Pflegebuchung geladen werden!",
      );
    }

    setCareReportCompletingLoading(true);

    const yearOfNewBooking =
      booking.month === 12 ? booking.year + 1 : booking.year;
    const monthOfNewBooking = booking.month === 12 ? 1 : booking.month + 1;

    const [createdReportEntries, bookingsOfNewMonth] = await Promise.all([
      processBookingsReport(
        booking,
        bookingsWebsiteReports,
        createReportEntryMutation.mutateAsync,
        updateTaskMutation.mutateAsync,
      ),
      getBookingListByCustomerYearMonth(
        booking.customerID,
        yearOfNewBooking,
        monthOfNewBooking,
      ),
    ]);

    const usedMinutes = createdReportEntries.reduce(
      (acc, reportEntry) => acc + reportEntry.effort,
      0,
    );

    const finalMinutes =
      booking.bookedMinutes + booking.previousMinutes - usedMinutes;

    const updatePreviousBookingInput: UpdateBookingInput = {
      id: booking.id,
      completedDate: utils.dates.convertDateToAWSDateFormat(new Date()),
      finalMinutes: finalMinutes,
    };

    if (bookingsOfNewMonth.length === 0) {
      const createNewBookingInput: CreateBookingInput = {
        customerID: booking.customerID,
        bookedMinutes: booking.bookedMinutes,
        previousMinutes: finalMinutes,
        finalMinutes: 0,
        month: monthOfNewBooking,
        year: yearOfNewBooking,
        paid: false,
      };

      console.log("createNewBookingInput: ", createNewBookingInput);

      await createBookingMutation.mutateAsync(createNewBookingInput);
    }

    await updateBookingMutation.mutateAsync(updatePreviousBookingInput);

    setCareReportCompleted(true);
    setCareReportCompletingLoading(false);
  };

  const generatePDF = async () => {
    if (!booking || !customer) {
      return;
    }

    setGenerateCareReportLoading(true);

    const careReportContent = ReactDOMServer.renderToString(
      <CareReportContent
        booking={booking!}
        customer={customer}
        reportEntryList={reportEntryList}
      />,
    );

    const careReportFileName = `Pflegebericht_${(
      "0" + customer.Kundennummer
    ).slice(-4)}_${booking.year}_${("0" + booking.month).slice(-2)}.pdf`;

    const careReportPDF = await generateCareReportPDF(
      careReportContent,
      careReportFileName,
    );

    setGenerateCareReportLoading(false);
    return careReportPDF;
  };

  useEffect(() => {
    if (isLoading || !booking) {
      return;
    }
    setCareReportCompleted(booking.completedDate !== null ? true : false);
  }, [isLoading, booking]);

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Pflegebuchung-Details">
        <Loading
          description="Bitte warten. Pflegebuchung wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (booking === undefined || booking === null || !customer) {
    return (
      <BoxHeadlineContainer boxTitle="Pflegebuchung-Details">
        Pflegebuchung nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <>
      <BoxHeadlineContainer
        boxTitle={
          !isLoading && booking
            ? booking.month + "/" + booking.year
            : "Pflegebuchung-Details"
        }
        marginTop={false}
        boxMenu={
          <>
            <CustomButton
              text="Bearbeiten"
              iconBefore={<EditPenIcon />}
              onClick={() => navigateToEditBookingPage(routeKey, booking)}
              size="small"
              color="blue"
              accessKey="e"
              rootClassName={classes.editButton}
            />
            <CustomButton
              text="Löschen"
              iconBefore={<DeleteIcon />}
              onClick={() => {
                setBookingToDelete(booking);
                setDeleteBookingDialogOpen(true);
              }}
              size="small"
              color="red"
              accessKey="d"
            />
          </>
        }
      >
        <BookingDeleteDialog
          dialogOpen={deleteBookingDialogOpen}
          setDialogOpen={setDeleteBookingDialogOpen}
          booking={bookingToDelete}
          routeKey={routeKey}
        />
        <Grid container direction="row" className={classes.gridRow}>
          <Grid item md={4}>
            <LabeledTypography label="Kunde" content={customer.Account_Name} />
          </Grid>

          <Grid item md={4}>
            <LabeledTypography label="Jahr" content={booking.year} />
          </Grid>

          <Grid item md={4}>
            <LabeledTypography label="Monat" content={booking.month} />
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item md={4}>
            <LabeledTypography
              label="Gebuchte Minuten"
              content={booking.bookedMinutes}
            />
          </Grid>

          <Grid item md={4}>
            <LabeledTypography
              label="Minuten aus Vormonat"
              content={booking.previousMinutes}
            />
          </Grid>

          <Grid item md={4}>
            <LabeledTypography
              label="Finaler Minutenstand"
              content={booking.finalMinutes}
            />
          </Grid>

          <Grid item md={4}>
            <LabeledTypography
              label="Bezahlt?"
              content={booking.paid ? <CheckIcon /> : <CrossIcon />}
            />
          </Grid>
        </Grid>
      </BoxHeadlineContainer>

      <Grid container direction="column" alignItems="center">
        <Grid item>
          {websiteReportsLoading && (
            <Loading
              size="33px"
              description="Berichtvorschau wird geladen..."
            />
          )}

          {careReportCompletingLoading && (
            <Loading size="33px" description="Bericht wird abgeschlossen..." />
          )}

          {!careReportCompleted && (
            <WebsiteReportListCard websiteReports={bookingsWebsiteReports} />
          )}

          <Collapse
            in={
              careReportPreviewOpen &&
              !careReportCompleted &&
              !careReportCompletingLoading
            }
          >
            <div className={classes.reportContainer}>
              <CareReportView>
                <CareReportContentPreview
                  booking={booking}
                  customer={customer}
                  bookingsWebsiteReports={bookingsWebsiteReports}
                />
              </CareReportView>
            </div>

            <CustomButton
              color="blue"
              style="outlined"
              text="Bericht Abschließen"
              accessKey="s"
              onClick={() => completeReport()}
              rootClassName={classes.careReportPreviewButton}
            />
          </Collapse>

          <Collapse in={careReportCompleted}>
            {careReportCompleted && (
              <>
                <CareReportDeleteDialog
                  booking={booking}
                  reportEntryList={reportEntryList}
                  dialogOpen={deleteCareReportDialogOpen}
                  setDialogOpen={setDeleteCareReportDialogOpen}
                />
                <ReportEntryListCard />
              </>
            )}
          </Collapse>

          <Grid
            container
            direction="row"
            spacing={5}
            justifyContent="center"
            className={classes.careReportCompletedButtons}
          >
            <Grid item>
              <Collapse in={careReportCompleted}>
                <CustomButton
                  color="blue"
                  style="outlined"
                  text="PDF herunterladen"
                  accessKey="s"
                  onClick={() => generatePDF()}
                  iconBefore={<i className="far fa-file-pdf"></i>}
                  loading={generateCareReportLoading}
                />
              </Collapse>
            </Grid>
            <Grid item>
              <Collapse in={careReportCompleted}>
                <CustomButton
                  color="red"
                  style="outlined"
                  text="Bericht löschen"
                  onClick={() => setDeleteCareReportDialogOpen(true)}
                  iconBefore={<i className="sl sl-icon-trash"></i>}
                />
              </Collapse>
            </Grid>
          </Grid>

          {!websiteReportsLoading &&
            !careReportPreviewOpen &&
            !careReportCompleted && (
              <CustomButton
                color="blue"
                style="outlined"
                text="Berichtvorschau"
                accessKey="s"
                onClick={() => setCareReportPreviewOpen(true)}
                rootClassName={classes.careReportPreviewButton}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default BookingPage;

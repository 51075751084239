import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getBooking } from "modules/bookings/api";
import { useCustomerAllList } from "../../customer/useCustomerAllList";

type BookingReadParamsType = {
  bookingID: string;
};

export const useBookingRead = () => {
  const authContext = useAuthContext();

  const { customerList, isLoading: isLoadingCustomer } =
    useCustomerAllList(false);

  const { bookingID } = useParams<BookingReadParamsType>();

  console.log("Loading booking with ID: ", bookingID);

  const { data: booking, isLoading } = useQuery({
    queryKey: [QueryKeys.Bookings, bookingID],
    queryFn: async () => {
      if (!bookingID) return null;

      const bookingFromAPI = await getBooking(bookingID);

      return bookingFromAPI;
    },
  });

  useEffect(() => {
    if (!booking || isLoadingCustomer) return;

    const bookingCustomer = customerList.find(
      (customerItem) => customerItem.id === booking.customerID,
    );

    authContext.setBooking(booking);

    authContext.setCurrentData({
      id: booking.id,
      name:
        (bookingCustomer ? bookingCustomer.Account_Name + " - " : "") +
        booking.month +
        "/" +
        booking.year,
    });
  }, [booking, isLoadingCustomer]);

  const customer = useMemo(
    () =>
      customerList.find(
        (customerItem) => customerItem.id === booking?.customerID,
      ) ?? null,
    [customerList, booking],
  );

  return {
    bookingID,
    booking: booking ?? null,
    isLoading: isLoading || isLoadingCustomer,
    customer,
  };
};

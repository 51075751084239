import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useTemplateDelete, useTemplateRoutes } from "hooks";
import { Template } from "types";
import { DeleteDialog } from "components";
import { isTemplateUsed } from "../../api";

type TemplateDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  template: Template | undefined;
};

export const TemplateDeleteDialog: React.FC<TemplateDeleteDialogProps> = ({
  template,
  dialogOpen,
  setDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteTemplateMutation = useTemplateDelete();
  const { navigateToTemplateListPage } = useTemplateRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || template === undefined) {
    return null;
  }

  const deleteTemplateRequest = async () => {
    setDeleteLoading(true);
    const templateIsUsed = await isTemplateUsed(template);

    if (templateIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Dieses Template kann nicht gelöscht werden, da es in Verwendung ist!",
      );
      return;
    }

    let deletedTemplate = await deleteTemplateMutation.mutateAsync(template);

    console.log("deletedTemplate: ", deletedTemplate);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigateToTemplateListPage();
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteTemplateRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Template Löschen"
      itemName={template.name}
      itemDeleteDescription="das Template"
    />
  );
};

import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAuthContext } from "context";
import {
  useGlobalSettingsRead,
  useGlobalSettingsRoutes,
  useGlobalSettingsUpdate,
} from "hooks";
import { CustomButton, Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import {
  GlobalSettingsForm,
  GlobalSettingsFormHandles,
} from "../GlobalSettingsForm";
import useStyles from "./styles";

const GlobalSettingsFormPage: React.FC = () => {
  const { classes } = useStyles();
  const authContext = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const { globalSettings, isLoading } = useGlobalSettingsRead();
  const { navigateToGlobalSettingsPage } = useGlobalSettingsRoutes();

  const updateGlobalSettingsMutation = useGlobalSettingsUpdate();

  const globalSettingsFormRequestDataRef =
    useRef<GlobalSettingsFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitGlobalSettings = async () => {
    const globalSettingsFormResult =
      globalSettingsFormRequestDataRef.current?.validateGlobalSettingsForm();

    if (!globalSettingsFormResult) {
      console.error("GlobalSettingsForm is invalid...");
      return false;
    }

    if (!authContext.user || !globalSettings) {
      enqueueSnackbar(
        `Beim Speichern der Einstellungen ist ein Fehler aufgetreten.`,
      );
      return false;
    }

    setSubmitLoading(true);

    await updateGlobalSettingsMutation.mutateAsync(globalSettingsFormResult);

    setSubmitLoading(false);

    navigateToGlobalSettingsPage();
    return true;
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Preisberechnung bearbeiten">
        <Loading
          description="Bitte warten. Einstellungen werden geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (!globalSettings) {
    return (
      <BoxHeadlineContainer boxTitle="Preisberechnung">
        Beim Laden der Einstellungen ist ein Fehler aufgetreten!
      </BoxHeadlineContainer>
    );
  }

  return (
    <>
      <GlobalSettingsForm
        globalSettings={globalSettings}
        ref={globalSettingsFormRequestDataRef}
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.globalSettingsButtons}
      >
        <Grid item className={classes.gridItem}>
          <CustomButton
            text="Einstellungen speichern"
            onClick={() => submitGlobalSettings()}
            loading={submitLoading}
            style="filled"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            onClick={() => navigateToGlobalSettingsPage()}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GlobalSettingsFormPage;

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  description: {
    "& > p": {
      margin: 0,
    },
  },
  notAvailable: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}));

export default useStyles;

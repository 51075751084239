import React, { useMemo } from "react";
import { useSystemAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";

export const SystemFilter: React.FC = () => {
  const { systemList } = useSystemAllList(false);

  const systemOptionList = useMemo(
    () =>
      systemList.map((systemOption) => {
        return {
          label: systemOption.name,
          value: systemOption.id,
        };
      }),
    [systemList],
  );

  return (
    <OptionsFilter
      param="systemID"
      paramName="System"
      options={systemOptionList}
    />
  );
};

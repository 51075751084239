import BookingPage from "modules/bookings/bookingDetails/BookingPage";
import { BookingFormPage } from "modules/bookings/bookingForm/BookingFormPage";
import { BookingListPage } from "modules/bookings/bookinglist/BookingListPage";
import { CareBookingIcon, EditIcon } from "modules/icons";
import { USER_GROUPS } from "utils/constants";

export const customerbookings = {
  key: "customerbookings",
  path: "/customers/:customerID/bookings",
  section: "customer",
  title: "Pflegebuchungen",
  view: <BookingListPage routeKey="customerbookings" />,
  icon: <CareBookingIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    customerbookingcreate: {
      key: "customerbookingcreate",
      path: "/customers/:customerID/bookings/create",
      section: "customer",
      title: "Pflegebuchung anlegen",
      view: <BookingFormPage formIntent="create" routeKey="customerbookings" />,
      icon: <CareBookingIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    customerbooking: {
      key: "customerbooking",
      path: "/customers/:customerID/bookings/:bookingID",
      section: "customer",
      title: "Pflegebuchung",
      view: <BookingPage routeKey="customerbookings" />,
      icon: <CareBookingIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        customerbookingedit: {
          key: "customerbookingedit",
          path: "/customers/:customerID/bookings/:bookingID/edit",
          section: "customer",
          title: "Pflegebuchung bearbeiten",
          view: (
            <BookingFormPage formIntent="edit" routeKey="customerbookings" />
          ),
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

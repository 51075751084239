import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    app?: string;
    view?: string;
    grey?: string;
    darkGrey?: string;
    orange?: string;
  }

  interface PaletteColor {
    C50?: string;
    C100?: string;
    C200?: string;
    C300?: string;
    C400?: string;
    C500?: string;
    C600?: string;
    C700?: string;
    C800?: string;
    C900?: string;
  }
  interface SimplePaletteColorOptions {
    C50?: string;
    C100?: string;
    C200?: string;
    C300?: string;
    C400?: string;
    C500?: string;
    C600?: string;
    C700?: string;
    C800?: string;
    C900?: string;
  }

  interface Palette {
    primaryGrey: PaletteColor;
    blueGrey: PaletteColor;
    accent: PaletteColor;
    red: PaletteColor;
  }

  interface PaletteOptions {
    primaryGrey?: PaletteColorOptions;
    blueGrey?: PaletteColorOptions;
    accent?: PaletteColorOptions;
    red?: PaletteColorOptions;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sectionTitle: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    sectionTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sectionTitle?: React.CSSProperties;
  }
}

const theme = createTheme({
  palette: {
    common: {
      black: "#282835",
    },
    primary: {
      main: "#CCA72F",
      dark: "#BF9432",
      light: "#E2C574",
      C50: "#FFF8EE",
      C100: "#F7E9D2",
      C200: "#EFD9AB",
      C300: "#EACF8C",
      C400: "#E2C574",
      C500: "#D6AE54",
      C600: "#CCA72F",
      C700: "#C4982C",
      C800: "#BF9432",
      C900: "#A57813",
    },
    primaryGrey: {
      main: "#CCA72F",
      dark: "#BF9432",
      light: "#E2C574",
      C50: "#F7F3F2",
      C100: "#F4EFED",
      C200: "#F2EBE7",
      C300: "#E9E2DE",
      C400: "#E2DAD5",
      C500: "#D6CBBF",
      C600: "#CEC3B6",
      C700: "#C1B8AA",
      C800: "#AEA79A",
      C900: "#A59D8E",
    },
    blueGrey: {
      main: "#bac7cc",
      light: "#bac7cc",
    },
    accent: {
      main: "#FF9900",
      dark: "#E58A00",
    },
    secondary: {
      main: "#F5F6FA",
      dark: "#9E9E9E",
    },
    red: {
      main: "#f91942",
      light: "#fa3055",
      dark: "#cc1d42",
    },
    error: {
      main: red.A400,
    },
    background: {
      app: "#21bddf",
      default: "#fff",
      view: "#f2f5f7",
      grey: "#606060",
      darkGrey: "#4B4B4B",
      orange: "#FF9900",
    },
    text: {
      secondary: "#B7B7B7",
      primary: "#333",
    },
  },

  typography: {
    h1: {
      textAlign: "center",
      fontSize: 27,
      fontWeight: 600,
      marginBottom: 19,
      color: "#343333",
    },
    h2: {
      fontSize: "1.3rem",
      fontWeight: 500,
      marginBottom: 19,
    },
    h3: {
      fontSize: "1.1rem",
      fontWeight: 500,
      marginTop: 50,
      marginBottom: 20,
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 500,
      marginBottom: 10,
    },
    subtitle1: {
      color: "#7D7D7D",
      fontSize: 15,
      textAlign: "center",
      fontWeight: 400,
      lineHeight: "23px",
      marginBottom: 33,
    },
    subtitle2: {},
    body1: {
      fontSize: 15,
      color: "#282835",
      textAlign: "left",
      fontWeight: 400,
      lineHeight: "29px",
    },
    body2: {
      fontSize: 14,
      marginBottom: 15,
    },
    caption: {},
    button: {},
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
    ].join(","),
  },

  components: {
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: "center",
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          style: {
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #F1F1F3",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: "28px",
          padding: "8px 16px",
          textTransform: "none",
          borderRadius: 5,
        },
        contained: {
          fontSize: 14,
          backgroundColor: "#47bfa8",
          fontWeight: 600,
          color: "#fff",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 5,
          whiteSpace: "nowrap",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
          fontSize: "1rem",
          fontWeight: 600,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 22,
          height: 22,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#47bfa8",
          border: "2px solid #FFFFFF",
        },
        dot: {
          height: 12,
          width: 12,
          borderRadius: 6,
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          "& > svg": {
            color: "#ffb400",
          },
        },
        icon: {
          "& > svg": {
            width: 16,
            height: 16,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F6FA",
          color: "#9E9E9E",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#47bfa8",
          padding: 5,

          "&.Mui-checked": {
            color: "#47bfa8 !important",
          },
        },
      },
    },
  },
});

theme.shadows[0] = "none";
theme.shadows[1] = "0px 0px 10px #00000026";

export default theme;

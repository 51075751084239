import React from "react";
import { Grid } from "@mui/material";
import { FilterRow, FilterWrapper } from "components";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { SystemFilter } from "modules/shared/filters/SystemFilter";
import { TaskTypeFilter } from "modules/shared/filters/TaskTypeFilter";

export const EffortRuleListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <SystemFilter />
      </Grid>
      <Grid item>
        <TaskTypeFilter />
      </Grid>
      <Grid item>
        <NumberFilter param="effort" paramName="Aufwand" />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

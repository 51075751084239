import React, { useImperativeHandle, useRef, useState } from "react";
import { FormControl, InputAdornment, InputBase, Slide } from "@mui/material";
import { nanoid } from "nanoid";
import { scroller } from "react-scroll";
import { CheckmarkDoneIcon, InfoRoundIcon } from "modules/icons";
import { CustomFormLabel, CustomFormLabelProps } from "../CustomFormLabel";
import { Loading } from "../Loading";
import useStyles from "./styles";

interface TextInputFieldProps extends CustomFormLabelProps {
  info?: boolean;
  infoContent?: React.ReactNode;
  infoTitle?: string;
  label?: string;
  description?: string;
  id?: string;
  className?: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  type?: "text" | "password";
  maxWidth?: number;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  rows?: number;
  marginBottom?: boolean;
  validate?: (value: string) => boolean;
  tabIndex?: number;
  autoFocus?: boolean;
  required?: boolean;
  showRequiredSymbol?: boolean;
  isLoading?: boolean;
  unitAdornment?: string | React.ReactNode;
}

export type TextInputFieldHandles = {
  highlight(scroll?: boolean): void;
};

const TextInputFieldComponent: React.ForwardRefRenderFunction<
  TextInputFieldHandles,
  TextInputFieldProps
> = (
  {
    className,
    value,
    onChange,
    type = "text",
    maxWidth,
    placeholder,
    disabled = false,
    multiline = false,
    rows = 5,
    marginBottom = false,
    validate,
    tabIndex = 0,
    autoFocus = false,
    required = false,
    isLoading = false,
    unitAdornment,

    // CustomFormLabelProps
    info,
    infoContent,
    infoTitle,
    label,
    description,
    id = nanoid(5),
    showRequiredSymbol,
    nodeBefore,
    nodeAfter,
  },
  textInputFieldRef,
) => {
  const { classes, cx } = useStyles();

  const [inputStatus, setInputStatus] = useState<
    "success" | "error" | "default"
  >("default");

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(textInputFieldRef, () => ({
    highlight: (scroll = true) => {
      setInputStatus("error");
      if (scroll) {
        scroller.scrollTo(id, {
          smooth: true,
          offset: -150,
          duration: 700,
        });
      }
      inputRef.current?.focus();
    },
  }));

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onChange(e);
    if (validate) {
      const isValid = validate(e.target.value);
      if (isValid) {
        setInputStatus("success");
      } else {
        setInputStatus("error");
      }
    } else {
      setInputStatus("default");
    }
  };

  return (
    <FormControl
      style={{ maxWidth: maxWidth ? maxWidth : "unset" }}
      classes={{
        root: cx(
          className || classes.formControlRoot,
          validate && inputStatus === "error" ? classes.inputError : null,
          validate && inputStatus === "success" ? classes.inputSuccess : null,
        ),
      }}
    >
      <CustomFormLabel
        info={info}
        infoContent={infoContent}
        infoTitle={infoTitle}
        label={label}
        description={description}
        id={id}
        showRequiredSymbol={required && showRequiredSymbol}
        nodeBefore={nodeBefore}
        nodeAfter={nodeAfter}
        errorLabel={validate && inputStatus === "error"}
      />

      <InputBase
        autoFocus={autoFocus}
        classes={{
          root: classes.root,
          input: cx(
            classes.input,
            marginBottom ? classes.inputMarginBottom : null,
          ),
          focused: classes.focusedInput,
        }}
        type={type}
        value={value}
        onChange={onChangeHandler}
        onBlur={onChangeHandler}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        name={id}
        id={id}
        inputRef={inputRef}
        // tabIndex={tabIndex}
        placeholder={placeholder}
        inputProps={{ tabIndex: tabIndex }}
        endAdornment={
          <InputAdornment
            position="start"
            style={{ marginRight: isLoading ? 8 : 0 }}
          >
            {isLoading ? <Loading size="20px" style={{ margin: 0 }} /> : null}

            {validate && inputStatus === "success" ? (
              <Slide
                direction="right"
                in={inputStatus === "success"}
                mountOnEnter
                unmountOnExit
                timeout={700}
              >
                <span style={{ lineHeight: 0, marginRight: 8 }}>
                  <CheckmarkDoneIcon className={classes.successIcon} />
                </span>
              </Slide>
            ) : null}
            {validate && inputStatus === "error" ? (
              <Slide
                direction="right"
                in={inputStatus === "error"}
                mountOnEnter
                unmountOnExit
                timeout={700}
              >
                <span style={{ lineHeight: 0, marginRight: 8 }}>
                  <InfoRoundIcon className={classes.errorIcon} />
                </span>
              </Slide>
            ) : null}
            {unitAdornment && (
              <span className={classes.unitAdornment}>{unitAdornment}</span>
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export const TextInputField = React.forwardRef(TextInputFieldComponent);

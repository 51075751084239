import React from "react";
import { useReportEntryListByBooking } from "hooks";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ReportEntryListTable } from "../ReportEntryListTable";

export const ReportEntryListCard: React.FC = () => {
  const { reportEntryList, ticketList, isLoading, isLoadingTickets } =
    useReportEntryListByBooking();

  return (
    <BoxHeadlineContainer
      boxTitle="Pflegebuchung-Einträge"
      paddingHorizontal={0}
      paddingVertical={0}
    >
      <ReportEntryListTable
        reportEntryList={reportEntryList}
        ticketList={ticketList}
        isLoading={isLoading || isLoadingTickets}
      />
    </BoxHeadlineContainer>
  );
};

export const setCurrentToken = (tokenType: string, token: string) => {
  const tokenObject = {
    expireDate: new Date(),
    token,
  };

  localStorage.setItem(tokenType, JSON.stringify(tokenObject));
};

export const getCurrentToken = (tokenType: string) => {
  const tokenStorage = localStorage.getItem(tokenType);

  if (!tokenStorage) {
    return null;
  }

  const tokenObject = JSON.parse(tokenStorage);
  const today = new Date();
  const expireDate = new Date(tokenObject.expireDate);

  if (Math.abs(today.getTime() - expireDate.getTime()) > 55 * 60 * 1000) {
    return null;
  }

  return tokenObject.token;
};

type QueryType = {
  [x: string]: {
    wildcard: string;
  };
};

type AndQueryType = {
  and: QueryType[];
};

type WildQueryType = QueryType | AndQueryType;

const prepareSearchText = (searchText: string) => {
  return decodeURIComponent(searchText)
    .trim()
    .toLowerCase()
    .replaceAll("-", " ")
    .replaceAll("@", " ")
    .trim();
};

export const getWildcardQuery = (searchText: string, fields: string[]) => {
  const queryArray: WildQueryType[] = [];
  const searchTextLowerCase = prepareSearchText(searchText);
  const searchTextParts = searchTextLowerCase.split(" ");

  for (const field of fields) {
    if (searchTextParts.length > 1) {
      const query = {
        and: searchTextParts.map((searchTextPart) => {
          return {
            [field]: {
              wildcard: "*" + searchTextPart + "*",
            },
          };
        }),
      };
      queryArray.push(query);
    } else {
      const query = {
        [field]: {
          wildcard: "*" + searchTextLowerCase + "*",
        },
      };
      queryArray.push(query);
    }
  }

  return queryArray;
};

export const getDefinedItems = <T>(
  listItems: (T | null | undefined)[] | undefined,
) => {
  return listItems
    ? listItems.filter((listItem): listItem is T => !!listItem)
    : [];
};

export const generateGraphQLFilter = (
  searchParams: URLSearchParams,
  filterFields: string[],
  searchTextParamName: string,
  searchFields: string[],
) => {
  const filter: any = {
    and: [],
  };

  for (const filterField of filterFields) {
    const filterFieldParam = searchParams.get(filterField);

    const comparisonParamName = "comparison_" + filterField;
    const comparisonParam = searchParams.get(comparisonParamName) ?? "eq";

    if (filterFieldParam && filterFieldParam !== "all") {
      filter.and.push({
        [filterField]: {
          [comparisonParam]:
            filterFieldParam === "true"
              ? true
              : filterFieldParam === "false"
                ? false
                : decodeURIComponent(filterFieldParam),
        },
      });
    }
  }

  if (filterFields.includes("aktiv")) {
    const activeParam = searchParams.get("active");

    if (!activeParam) {
      filter.and.push({
        aktiv: {
          eq: true,
        },
      });
    }
    if (activeParam === "false") {
      filter.and.push({
        aktiv: {
          eq: false,
        },
      });
    }
  }

  const searchTextParam = searchParams.get(searchTextParamName);

  if (searchTextParam) {
    filter.and.push({
      or: getWildcardQuery(searchTextParam, searchFields),
    });
  }

  return filter;
};

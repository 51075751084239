import { nanoid } from "nanoid";
import { getUserListByUsername } from "modules/users/api";

export const getUUID = () => {
  return nanoid();
};

export const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }

  return newUrl;
};

export const toURLsafe = (input: string) => {
  const lowercase = input
    .toLowerCase()
    .replace("ü", "ue")
    .replace("ö", "oe")
    .replace("ä", "ae")
    .replace("ß", "ss");
  const regex = /[^a-z0-9A-Z-_~]/gi;

  const converted = lowercase.replaceAll(regex, "-");

  return converted;
};

export const checkUsername = async (username: string) => {
  const userList = await getUserListByUsername(username);

  const usernameExists = userList.length > 0;

  return usernameExists;
};

export const toURLsafeUsername = (input: string) => {
  const inputBeforAtChar = input.split("@")[0];

  const lowercase = inputBeforAtChar
    .toLowerCase()
    .replace("ü", "ue")
    .replace("ö", "oe")
    .replace("ä", "ae")
    .replace("ß", "ss");
  const regex = /[^a-z0-9A-Z-_~]/gi;

  const converted = lowercase.replaceAll(regex, "-");

  return converted;
};

async function generateUsernameWithRandom(email: string): Promise<string> {
  const randomNo = ("000" + Math.floor(Math.random() * Math.floor(999))).slice(
    -3
  );
  const username = toURLsafeUsername(email) + randomNo;

  const usernameExists = await checkUsername(username);

  if (!usernameExists) {
    return username;
  } else {
    return await generateUsernameWithRandom(email);
  }
}

export const generateUsername = async (email: string) => {
  const username = toURLsafeUsername(email);

  const usernameExists = await checkUsername(username);

  if (!usernameExists) {
    return username;
  } else {
    return await generateUsernameWithRandom(email);
  }
};

export const generatePassword = (): string => {
  const length = 8;
  const string = "abcdefghjkmnopqrstuvwxyz"; //to upper
  const numeric = "0123456789";
  const punctuation = "!@#$%&+?=";
  const maxNumeric = 1;
  const maxPunctuation = 1;

  let numericCount = 0;
  let punctuationCount = 0;
  let password = "";
  let index = 0;

  // Add a numeric character to the password
  while (numericCount < maxNumeric) {
    index = Math.ceil(numeric.length * Math.random() * Math.random());
    password += numeric.charAt(index);
    numericCount++;
  }

  // Add a punctuation character to the password
  while (punctuationCount < maxPunctuation) {
    index = Math.ceil(punctuation.length * Math.random() * Math.random());
    password += punctuation.charAt(index);
    punctuationCount++;
  }

  // Add upper case and lower case string characters to the password
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    password += hold;
  }

  // Sort characters so the first character is not numeric or punctuation
  do {
    password = password
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
  } while (numeric.includes(password[0]) || punctuation.includes(password[0]));

  return password;
};

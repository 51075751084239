import { SelectionSet } from "aws-amplify/api";
import { InfiniteData } from "@tanstack/react-query";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import { Task } from "./Task.types";
import utils from "utils";

export const WebsiteSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "url",
  "websiteOwner",
  "websitePayer",
  "websiteType",
  "system.*",
  "systemID",
  "runtimeEnvironment",
  "template.*",
  "templateID",
  "hoster.*",
  "hosterID",
  "createdByUs",
  "currentlyResponsible",
  "rating",
  "phpVersion",
  "backlinkErstellung",
  "backlinkPflege",
  "formiloBacklink",
  "description",
  "effortDefinitions.*",
  "tasks.*",
  "permanentTasks.*",
  "reportEntries.*",
] as const;

export type Website = SelectionSet<
  Schema["Website"]["type"],
  typeof WebsiteSelectionSet
>;

export type LastRunWebsiteTask = {
  id: string;
  website: Website;
  task: Task | null;
};

export type CreateWebsiteInput = Schema["Website"]["createType"];

export type UpdateWebsiteInput = Schema["Website"]["updateType"];

export type BacklinkStatus = Schema["BacklinkStatus"]["type"];

export type RuntimeEnvironment = Schema["RuntimeEnvironment"]["type"];

export type WebsiteType = Schema["WebsiteType"]["type"];

export type InfiniteWebsiteList = {
  websiteList: Website[];
  nextToken: string | null;
};

export type InfiniteWebsiteData =
  | InfiniteData<InfiniteWebsiteList, unknown>
  | undefined;

export const WebsiteTypeKeys: TypeKeysEnum<Website> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  url: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.url,
  },
  websiteOwner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.websiteOwner,
  },
  websitePayer: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.websitePayer,
  },
  websiteType: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.websiteType,
  },
  system: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  systemID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.systemID,
  },
  runtimeEnvironment: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.runtimeEnvironment,
  },
  template: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  templateID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.templateID,
  },
  hoster: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  hosterID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.hosterID,
  },
  createdByUs: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.createdByUs ? "Ja" : "Nein"),
  },
  currentlyResponsible: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.currentlyResponsible ? "Ja" : "Nein"),
  },
  rating: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.rating.toString(),
  },
  phpVersion: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.phpVersion ?? "",
  },
  backlinkErstellung: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.backlinkErstellung,
  },
  backlinkPflege: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.backlinkPflege,
  },
  formiloBacklink: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.formiloBacklink,
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
  effortDefinitions: {
    type: "entity",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  tasks: {
    type: "entity",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  permanentTasks: {
    type: "entity",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  reportEntries: {
    type: "entity",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfWebsite = (key: string): key is keyof Website =>
  key in WebsiteTypeKeys;

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  flatRed: {
    color: "#c97282",
  },
  red: {
    color: "#ff1f00",
  },
  orange: {
    color: "#ff9100",
  },
  warning: {
    fontSize: 13,
    lineHeight: "21px",
    display: "block",
  },
  small: {
    fontSize: 13,
  },
  medium: {
    fontSize: 15,
  },
  large: {
    fontSize: 17,
  },
}));

export default useStyles;

export const formatNumber = (
  number: number | "",
  decimalScale = 2,
  trailingZeros = true
) =>
  number === ""
    ? ""
    : trailingZeros
    ? number.toFixed(decimalScale).replace(".", ",")
    : parseFloat(number.toFixed(decimalScale)).toString().replace(".", ",");

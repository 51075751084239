import React from "react";
import { Grid } from "@mui/material";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { BooleanFilter } from "modules/shared/filters/BooleanFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";

export const SystemListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <BooleanFilter param="updatesNeeded" paramName="Updates benötigt?" />
      </Grid>
      <Grid item>
        <SearchTextFilter
          searchTextParamName={SearchTextParamName.SearchSystem}
        />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { InfinitePermanentTaskList } from "types";
import {
  getAllPermanentTaskList,
  getPermanentTaskList,
} from "modules/permanenttasks/api";
import { usePermanentTaskListFilterAndSort } from "../usePermanentTaskListFilterAndSort";

export const usePermanentTaskList = (filter = true) => {
  const filterAndSortPermanentTasks = usePermanentTaskListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<InfinitePermanentTaskList>({
      queryKey: loadAll
        ? [QueryKeys.PermanentTasks, "all"]
        : [QueryKeys.PermanentTasks],
      queryFn: ({ pageParam }) =>
        loadAll
          ? getAllPermanentTaskList()
          : getPermanentTaskList(pageParam as string | null),
      getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
      initialPageParam: null,
      staleTime: 1000 * 60 * 15, // 15 minutes
      gcTime: 1000 * 60 * 60, // 1 hour
    });

  const permanentTaskList = useMemo(() => {
    const permanentTasks = data?.pages
      ? data.pages.flatMap((page) => page.permanentTaskList)
      : [];

    return filterAndSortPermanentTasks(permanentTasks);
  }, [data, filterAndSortPermanentTasks]);

  return {
    permanentTaskList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};

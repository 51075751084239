import React, { SyntheticEvent } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useCustomerRoutes } from "hooks";
import { Customer } from "types";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";

type CustomerListTableProps = {
  listIntent: "list" | "selection";
  customerList: Customer[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  customerSelectionHandler?: (customer: Customer) => void;
};

export const CustomerListTable: React.FC<CustomerListTableProps> = ({
  listIntent,
  customerList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  customerSelectionHandler,
}) => {
  const { navigateToCustomerPage } = useCustomerRoutes();

  const defaultColumns = React.useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        header: "#",
        accessorKey: "Kundennummer",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Kunde",
        accessorKey: "Account_Name",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Webseite",
        accessorKey: "Website",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "E-Mail-Adresse",
        accessorKey: "Firmen_E_Mail",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Telefonnummer",
        accessorKey: "Phone",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Mobilnummer",
        accessorKey: "Mobil",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Ort",
        accessorKey: "Billing_City",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "# Websites",
        accessorKey: "websiteAmount",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Customer>
            row={row}
            listIntent={listIntent}
            rowSelectionHandler={customerSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [customerSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Customer) => {
    if (customerSelectionHandler) {
      customerSelectionHandler(row);
    } else {
      navigateToCustomerPage(row);
    }
  };

  return (
    <CustomReactTable<Customer>
      data={customerList}
      columns={defaultColumns}
      rowClickHandler={rowClickHandler}
      isLoadingDataList={isLoading}
      hasNextData={hasNextPage}
      onClickLoadMore={() => fetchNextPage()}
      onClickLoadAll={() => fetchNextPage(true)}
      selectable={false}
    />
  );
};

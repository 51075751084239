import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useEffortRuleRoutes } from "hooks";
import { EffortRule } from "types";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { EffortRuleDeleteDialog } from "../../effortruleDelete/EffortRuleDeleteDialog";

type EffortRuleListTableProps = {
  routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules";
  listIntent: "list" | "selection";
  effortRuleList: EffortRule[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  effortRuleSelectionHandler?: (effortRule: EffortRule) => void;
};

export const EffortRuleListTable: React.FC<EffortRuleListTableProps> = ({
  routeKey,
  listIntent,
  effortRuleList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  effortRuleSelectionHandler,
}) => {
  const { navigateToEffortRulePage, navigateToEditEffortRulePage } =
    useEffortRuleRoutes();

  const [deleteEffortRuleDialogOpen, setDeleteEffortRuleDialogOpen] =
    useState<boolean>(false);
  const [effortRuleToDelete, setEffortRuleToDelete] = useState<
    EffortRule | undefined
  >();

  const defaultColumns = React.useMemo<ColumnDef<EffortRule>[]>(
    () => [
      {
        header: "Website-Typ",
        accessorKey: "websiteType",
        accessorFn: (row) =>
          row.websiteType === "website"
            ? "Webseite"
            : row.websiteType === "webshop"
              ? "Webshop"
              : row.websiteType === "application"
                ? "Anwendung"
                : "Server",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "System",
        accessorKey: "systemID",
        accessorFn: (row) => row.system.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwand in m",
        accessorKey: "effort",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<EffortRule>
            row={row}
            listIntent={listIntent}
            openEditRowPage={(row) =>
              navigateToEditEffortRulePage(routeKey, row)
            }
            setRowToDelete={setEffortRuleToDelete}
            setDeleteRowDialogOpen={setDeleteEffortRuleDialogOpen}
            rowSelectionHandler={effortRuleSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditEffortRulePage, effortRuleSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: EffortRule) => {
    if (effortRuleSelectionHandler) {
      effortRuleSelectionHandler(row);
    } else {
      navigateToEffortRulePage(routeKey, row);
    }
  };

  return (
    <>
      <EffortRuleDeleteDialog
        dialogOpen={deleteEffortRuleDialogOpen}
        setDialogOpen={setDeleteEffortRuleDialogOpen}
        effortRule={effortRuleToDelete}
        routeKey={routeKey}
      />
      <CustomReactTable<EffortRule>
        data={effortRuleList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

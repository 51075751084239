import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useAuthContext } from "context";
import {
  useGlobalSettingsRead,
  useGlobalSettingsRoutes,
  useGlobalSettingsUpdate,
} from "hooks";
import { CustomButton, Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { EditPenIcon } from "modules/icons";
import utils from "utils";
import useStyles from "./styles";

const GlobalSettingsPage: React.FC = () => {
  const { classes } = useStyles();

  const { userData } = useAuthContext();

  const { globalSettings, isLoading } = useGlobalSettingsRead();
  const { navigateToEditGlobalSettingsPage } = useGlobalSettingsRoutes();

  const updateGlobalSettingsMutation = useGlobalSettingsUpdate();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Preisberechnung">
        <Loading
          description="Bitte warten. Einstellungen werden geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (!globalSettings) {
    return (
      <BoxHeadlineContainer boxTitle="Preisberechnung">
        Beim Laden der Einstellungen ist ein Fehler aufgetreten!
      </BoxHeadlineContainer>
    );
  }

  const changeCurrentVersionHandler = async () => {
    setSubmitLoading(true);

    await updateGlobalSettingsMutation.mutateAsync({
      id: globalSettings.id,
      currentVersion: utils.identify.getUUID(),
    });

    setSubmitLoading(false);
  };

  console.log("userData: ", userData);

  return (
    <BoxHeadlineContainer
      boxTitle="Preisberechnung"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Bearbeiten"
          iconBefore={<EditPenIcon />}
          onClick={() => navigateToEditGlobalSettingsPage()}
          size="small"
          color="blue"
          rootClassName={classes.editButton}
        />
      }
    >
      {userData?.email === "oe-bayram@live.de" && (
        <Grid container direction="row">
          <Grid item className={classes.gridItem}>
            <CustomButton
              text="LocalStorage leeren"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
              size="small"
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <CustomButton
              text="Aktuelle Version ändern"
              onClick={() => changeCurrentVersionHandler()}
              size="small"
              loading={submitLoading}
            />
          </Grid>
        </Grid>
      )}
    </BoxHeadlineContainer>
  );
};

export default GlobalSettingsPage;

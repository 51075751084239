import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  listItem: {
    color: "#eee",
    borderLeft: "3px solid transparent",
    padding: "11px 11px 11px 27px !important",
    transition: "0.3s",
    lineHeight: "24px",
    marginBottom: 21,
    cursor: "pointer",

    "&:hover": {
      color: "#fff",
      borderColor: theme.palette.primary.main,
      backgroundColor: "rgba(255,255,255,0.025)",
    },
  },
  listItemClosed: {
    padding: "11px 11px 11px 19px !important",
  },
  listItemText: {
    color: "#eee",
    textAlign: "left",
    minWidth: 217,
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuClosed: {
    opacity: 0,
  },
  icon: {
    marginRight: 12,
    color: "#eee",
    minWidth: "unset",
    fontSize: 16,
    fontWeight: 400,
    "& svg": {
      width: 35,
      height: 35,
      marginLeft: -5,
    },
  },
}));

export default useStyles;

import React, { useImperativeHandle, useMemo, useRef, useState } from "react";
import { IconButton, InputAdornment, Slide } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { deDE } from "@mui/x-date-pickers/locales";
import moment, { Moment } from "moment";
import { nanoid } from "nanoid";
import { scroller } from "react-scroll";
import { CheckmarkDoneIcon, InfoRoundIcon, TimeIcon } from "modules/icons";
import { CustomFormLabel, CustomFormLabelProps } from "../CustomFormLabel";
import useStyles from "./styles";

interface CustomTimePickerProps extends CustomFormLabelProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  required?: boolean;
  disabled?: boolean;
  onlyHours?: boolean;
  marginBottom?: boolean;
  minutesStep?: number;
}

export type CustomTimePickerHandles = {
  highlight(scroll?: boolean): void;
};

const CustomTimePickerComponent: React.ForwardRefRenderFunction<
  CustomTimePickerHandles,
  CustomTimePickerProps
> = (
  {
    value,
    onChange,
    required = false,
    disabled = false,
    onlyHours = false,
    marginBottom = true,
    minutesStep,

    // CustomFormLabelProps
    info,
    infoContent,
    infoTitle,
    label,
    description,
    id = nanoid(5),
    showRequiredSymbol,
    nodeBefore,
    nodeAfter,
  },
  customTimePickerRef
) => {
  const { classes, cx } = useStyles();

  const [pickerStatus, setPickerStatus] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [inputStatus, setInputStatus] = useState<
    "success" | "error" | "default"
  >("default");

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(customTimePickerRef, () => ({
    highlight: (scroll = true) => {
      setInputStatus("error");
      if (scroll) {
        scroller.scrollTo(id, {
          smooth: true,
          offset: -200,
          duration: 700,
        });
      }
      inputRef.current?.focus();
    },
  }));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPickerStatus((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const onChangeHandler = (date: Moment | null) => {
    onChange(date ? date.toDate() : null);
    if (required) {
      if (
        date !== null &&
        date.toDate() instanceof Date &&
        !isNaN(date.toDate().getTime())
      ) {
        setInputStatus("success");
      } else {
        setInputStatus("error");
      }
    }
  };

  const CalendarInputAdornment = useMemo(
    () => () => (
      <InputAdornment position="start">
        {inputStatus === "success" ? (
          <Slide
            direction="right"
            in={inputStatus === "success"}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <span className={classes.successIconSpan}>
              <CheckmarkDoneIcon className={classes.successIcon} />
            </span>
          </Slide>
        ) : null}
        {inputStatus === "error" ? (
          <Slide
            direction="right"
            in={inputStatus === "error"}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <span className={classes.errorIconSpan}>
              <InfoRoundIcon className={classes.errorIcon} />
            </span>
          </Slide>
        ) : null}
        <IconButton
          className={cx(
            inputStatus === "default"
              ? classes.datePickerButtonSingle
              : classes.datePickerButton
          )}
          onClick={handleClick}
          disabled={disabled}
        >
          <TimeIcon
            className={cx(
              disabled ? classes.calendarIconDisabled : classes.calendarIcon
            )}
          />
        </IconButton>
      </InputAdornment>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, inputStatus]
  );

  return (
    <>
      <CustomFormLabel
        info={info}
        infoContent={infoContent}
        infoTitle={infoTitle}
        label={label}
        description={description}
        id={id}
        showRequiredSymbol={required && showRequiredSymbol}
        nodeBefore={nodeBefore}
        nodeAfter={nodeAfter}
        errorLabel={inputStatus === "error"}
      />

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <TimePicker<Moment>
          ampmInClock
          views={onlyHours ? ["hours"] : ["hours", "minutes"]}
          format="HH:mm"
          open={pickerStatus}
          onClose={() => setPickerStatus(false)}
          value={moment(value)}
          ampm={false}
          disableOpenPicker={disabled}
          minutesStep={minutesStep}
          onChange={onChangeHandler}
          className={cx(
            classes.datePicker,
            marginBottom ? classes.marginBottom : null,
            inputStatus === "error" ? classes.inputError : null,
            inputStatus === "success" ? classes.inputSuccess : null
          )}
          slots={{
            inputAdornment: CalendarInputAdornment,
          }}
          slotProps={{
            popper: {
              placement: "bottom-end",
              anchorEl: anchorEl,
            },
            textField: {
              variant: "standard",
              name: id,
              id: id,
              disabled: disabled,
              inputRef: inputRef,
              className: cx(
                classes.datePicker,
                inputStatus === "error" ? classes.inputError : null,
                inputStatus === "success" ? classes.inputSuccess : null
              ),
              InputProps: {
                disableUnderline: true,
                classes: {
                  focused: classes.focusedInput,
                  input: classes.input,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export const CustomTimePicker = React.forwardRef(CustomTimePickerComponent);

import React, { useMemo } from "react";
import { Booking, Customer } from "types";
import { WebsiteReportType } from "../../../carereport/api";
import { getWebsiteTypeName } from "../../../shared/options/WebsiteTypeOption";
import utils from "utils";

type CareReportContentPreviewProps = {
  booking: Booking;
  customer: Customer;
  bookingsWebsiteReports: WebsiteReportType[];
};

export const CareReportContentPreview: React.FC<
  CareReportContentPreviewProps
> = ({ booking, customer, bookingsWebsiteReports }) => {
  const getWebsiteTotalEffort = (report: WebsiteReportType) => {
    const taskListTotalEffort = report.taskList.reduce(
      (previousValue, currentValue) => previousValue + currentValue.effort,
      0,
    );

    const ticketListTotalEffort = report.ticketList.reduce(
      (previousValue, currentValue) =>
        previousValue +
        (currentValue.cf_aufwand_in_minuten
          ? parseInt(currentValue.cf_aufwand_in_minuten)
          : 0),
      0,
    );

    return taskListTotalEffort + ticketListTotalEffort;
  };

  const reportTotalEffort = useMemo(() => {
    var totalEffort = 0;

    for (let bookingsWebsiteReport of bookingsWebsiteReports) {
      totalEffort += getWebsiteTotalEffort(bookingsWebsiteReport);
    }

    return totalEffort;
  }, [bookingsWebsiteReports]);

  const startMinutes = booking.bookedMinutes + booking.previousMinutes;

  const newFinalMinutes = startMinutes - reportTotalEffort;

  return (
    <>
      <div id="title">
        <h1>Bericht für Website Pflege</h1>
        <div id="kunde">Kunde: {customer.Account_Name}</div>
        <div id="datum">
          Datum: {utils.dates.getDateInGermanFormat(new Date())}
        </div>
      </div>

      <div id="info">
        <div>
          <strong>Für Monat: </strong>
          {("0" + booking.month).slice(-2) + "/" + booking.year}
        </div>
        <div>
          <strong>Monat bezahlt: </strong>
          {booking.paid ? "ja" : "nein"}
        </div>
        <div>
          <strong>Weitere bezahlte Monate: </strong>0
        </div>
        <br />

        <div>
          <strong>In diesem Monat gebuchte Minuten: </strong>
          <span className="green">{booking.bookedMinutes}</span>
        </div>
        <div>
          <strong>Minuten aus Vormonat: </strong>
          <span className={booking.previousMinutes < 0 ? "red" : ""}>
            {booking.previousMinutes}
          </span>
        </div>
        <div>
          <strong>Resultierendes Startguthaben: </strong>
          <span className={startMinutes < 0 ? "red" : "green"}>
            {startMinutes}
          </span>
        </div>
      </div>

      {bookingsWebsiteReports.map((report) => {
        if (report.taskList.length < 1 && report.ticketList.length < 1) {
          return null;
        }
        return (
          <>
            <div className="website-title">
              <span>{report.website.url}</span> &nbsp; &nbsp;
              {"[" +
                getWebsiteTypeName(report.website.websiteType) +
                ", " +
                report.website.system?.name +
                "]"}
            </div>
            <div className="website-body">
              <table className="efforts">
                <tbody>
                  {report.taskList.map((task) => (
                    <tr>
                      <td>
                        <strong>{task.taskType!.name.trim() + ":"}</strong>{" "}
                        {task.taskType!.description}
                      </td>
                      <td>{"-" + task.effort + " min"}</td>
                    </tr>
                  ))}
                  {report.ticketList.map((ticket) => (
                    <tr>
                      <td>
                        <strong>
                          Ticket
                          {ticket.classification
                            ? " [" + ticket.classification + "]: "
                            : ":"}
                        </strong>
                        {ticket.subject}
                      </td>
                      <td>{"-" + ticket.cf_aufwand_in_minuten + " min"}</td>
                    </tr>
                  ))}
                  <tr className="site-totalEffort">
                    <td>Gesamtaufwand</td>
                    <td>{"-" + getWebsiteTotalEffort(report)} min</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      })}

      <table id="monthResult">
        <tbody>
          <tr>
            <td>Gesamtaufwand für alle Seiten</td>
            <td className={reportTotalEffort > 0 ? "red" : ""}>
              {reportTotalEffort > 0
                ? "-" + reportTotalEffort + " min"
                : reportTotalEffort + " min"}
            </td>
          </tr>
          <tr>
            <td>
              <strong>RESTMINUTEN / ÜBERTRAG FÜR FOLGEMONAT</strong>
            </td>
            <td className={newFinalMinutes < 0 ? "red" : "green"}>
              <strong>{newFinalMinutes + " min"}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

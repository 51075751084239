import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  appLogo: {
    height: "40vmin",
    pointerEvents: "none",
    animation: "rotating infinite 20s linear",
  },
  phoneNumber: {
    width: "100%",
    marginBottom: 25,
  },
}));

export default useStyles;

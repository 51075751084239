export const DOCUMENT_HEADER = `
<div id="header">
    <img src="src/img/goldweiss_logo.png" id="logo" />
    <p>Webseiten, Marketing, Print, Grafik, Texte, Konzeption</p>
</div>`;

export const DOCUMENT_FOOTER = `
<table id="footer">
    <tr>
        <td>Stargarder Str. 33<br />10437 Berlin</td>
        <td>Agentur: 030 208 85 828<br />Pflegeabteilung: 030 95 99 45 03</td>
        <td>www.agentur-goldweiss.de<br />kontakt@agentur-goldweiss.de</td>
        <td>Mo bis Fr<br />9-17 Uhr</td>
    </tr>
</table>`;

export const wrapDocumentContent = (content: string) => {
    return `
    <!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8" />
            <title>Agentur Goldweiss | Pflegebericht</title>
            <link
            href="https://fonts.googleapis.com/css?family=Titillium+Web&display=swap"
            rel="stylesheet"
            />
        </head>
        <body>
        ${content}
        </body>
    </html>
    `;
}

export const DOCUMENT_STYLES = `
body {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  color: #333;
}

.red {
  color: #ff0000;
}

.green {
  color: green !important;
}

#title {
  height: 150px;
}

h1 {
  font-size: 1.5em;
  padding-top: 25px;
  display: block;
}

.website-title {
  background: #f7e5a1;
  padding: 10px;
  border: solid 1px #f7c719;
  margin-top: 55px;
  page-break-inside: avoid;
}

.website-body {
  padding: 10px 10px 0 10px;
  border: solid 1px #f7c719;
}

.efforts {
  width: 100%;
  border-collapse: collapse;
}

.efforts tr {
  page-break-inside: avoid;
}

.efforts tr td p {
  display: inline-block;
  margin: 0;
  background-color: #ff0000;
}

.efforts tr td:nth-child(1) {
  width: 90%;
  padding-right: 10px;
}

.efforts tr td:nth-child(2) {
  width: 10%;
  text-align: center;
  color: red;
  vertical-align: top;
}

.site-totalEffort {
  page-break-inside: avoid;
}

.site-totalEffort td:nth-child(1) {
  text-align: right;
  font-weight: bold;
  padding-right: 25px;
}

.efforts tr td {
  border-top: solid 1px #f7c719;
  padding-top: 15px;
  padding-bottom: 10px;
}

.efforts tr:nth-child(1) td {
  border-top: none;
  padding-top: 0;
}

.website-title span {
  font-weight: bold;
  padding-right: 15px;
  display: inline-block;
}

#kunde {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 0;
}

#info {
  position: absolute;
  top: 140px;
  right: 50px;
}

#header {
  border-bottom: solid 1px #ffc800;
  padding-bottom: 15px;
}

#logo {
  width: 200px;
  float: left;
}

#header p {
  color: #777;
  text-align: right;
  padding-top: 35px;
  margin: 0;
}

#footer {
  width: 100%;
  border-collapse: collapse;
  border-top: solid 1px #ffc800;
}

#footer td {
  padding-top: 15px;
}

#footer tr td:nth-child(1) {
  width: 155px;
}

#footer tr td:nth-child(2) {
  width: 255px;
}

#footer tr td:nth-child(3) {
  width: 240px;
}

#info td:first-child {
  width: 70%;
}

#info td:last-child {
  width: 30%;
}

#monthResult {
  page-break-inside: avoid;
  width: 100%;
  margin-top: 50px;
}

#monthResult tr td:nth-child(1) {
  width: 90%;
  text-align: right;
  padding-right: 25px;
}
`;
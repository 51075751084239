import { useEffect } from "react";
import { useCustomerAllList, usePersistedState } from "hooks";
import { Booking, Customer, SelectOption } from "types";
import { monthOptionList } from "modules/shared/options/MonthOption";

export const useBookingForm = (
  formIntent: "create" | "edit",
  booking: Booking | null | undefined,
) => {
  const { customerList } = useCustomerAllList();

  const [customer, setCustomer] = usePersistedState<Customer | null>(
    "booking_customer",
    null,
    formIntent,
  );

  const [year, setYear] = usePersistedState<number | "">(
    "booking_year",
    new Date().getFullYear(),
    formIntent,
  );
  const [month, setMonth] = usePersistedState<SelectOption | null>(
    "booking_month",
    null,
    formIntent,
  );
  const [bookedMinutes, setBookedMinutes] = usePersistedState<number | "">(
    "booking_bookedMinutes",
    1,
    formIntent,
  );
  const [previousMinutes, setPreviousMinutes] = usePersistedState<number | "">(
    "booking_previousMinutes",
    1,
    formIntent,
  );
  const [finalMinutes, setFinalMinutes] = usePersistedState<number | "">(
    "booking_finalMinutes",
    1,
    formIntent,
  );
  const [paid, setPaid] = usePersistedState<boolean>(
    "booking_paid",
    false,
    formIntent,
  );

  const resetBookingForm = () => {
    localStorage.removeItem("booking_customer");
    localStorage.removeItem("booking_year");
    localStorage.removeItem("booking_month");
    localStorage.removeItem("booking_bookedMinutes");
    localStorage.removeItem("booking_previousMinutes");
    localStorage.removeItem("booking_finalMinutes");
    localStorage.removeItem("booking_paid");
  };

  useEffect(() => {
    if (!booking) {
      return;
    }

    const selectedCustomer = customerList.find(
      (customerItem) => customerItem.id === booking.customerID,
    );

    setCustomer(selectedCustomer ?? null);

    setYear(booking.year);

    setMonth(
      monthOptionList.find(
        (monthOption) => booking.month.toString() === monthOption.value,
      ) ?? null,
    );

    setBookedMinutes(booking.bookedMinutes);
    setPreviousMinutes(booking.previousMinutes);
    setFinalMinutes(booking.finalMinutes);

    setPaid(booking.paid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking, customerList]);

  return {
    customer,
    setCustomer,
    year,
    setYear,
    month,
    setMonth,
    bookedMinutes,
    setBookedMinutes,
    previousMinutes,
    setPreviousMinutes,
    finalMinutes,
    setFinalMinutes,
    paid,
    setPaid,
    resetBookingForm,
  };
};

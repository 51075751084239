import moment from "moment";
import { TypeKeysEnum } from "types";

export interface FilterField<T> {
  field: keyof T;
  value: string;
  comparisonType: "eq" | "gt" | "lt";
}

export const getComparisonType = (
  searchParams: URLSearchParams,
  field: string,
) => {
  const comparisonParamName = "comparison_" + field;
  const comparisonParamValue = searchParams.get(comparisonParamName);
  return comparisonParamValue === "gt" || comparisonParamValue === "lt"
    ? comparisonParamValue
    : "eq";
};

export const filterBySearchText = <T>(
  entityList: T[],
  fields: (keyof T)[],
  searchText: string,
) => {
  const searchTextLowerCase = searchText.toLowerCase();
  const searchTextParts = searchTextLowerCase.split(" ");

  return entityList.filter((entity) => {
    for (const field of fields) {
      const fieldValue = entity[field];
      if (typeof fieldValue !== "string") continue;

      const fieldValueLowerCase = fieldValue.toLowerCase();

      if (searchTextParts.length > 1) {
        const isMatch = searchTextParts.every((searchTextPart) =>
          fieldValueLowerCase.includes(searchTextPart),
        );

        if (isMatch) return true;
      } else {
        if (fieldValueLowerCase.includes(searchTextLowerCase)) return true;
      }
    }

    return false;
  });
};

export const filterByTextField = <T>(
  entityList: T[],
  field: keyof T,
  value: string,
) => entityList.filter((entity) => entity[field] === value);

export const filterByDateField = <T>(
  entityList: T[],
  field: keyof T,
  comparisonType: "eq" | "gt" | "lt",
  value: string,
) => {
  switch (comparisonType) {
    case "eq":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment(fieldValue).isSame(value, "day");
      });
    case "gt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment(fieldValue).isAfter(value);
      });
    case "lt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment(fieldValue).isBefore(value);
      });
    default:
      return entityList;
  }
};

export const filterByTimeField = <T>(
  entityList: T[],
  field: keyof T,
  comparisonType: "eq" | "gt" | "lt",
  value: string,
) => {
  switch (comparisonType) {
    case "eq":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment("2024-01-01T" + fieldValue + "Z").isSame(value, "hour");
      });
    case "gt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment("2024-01-01T" + fieldValue + "Z").isAfter(value);
      });
    case "lt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "string") return false;

        return moment("2024-01-01T" + fieldValue + "Z").isBefore(value);
      });
    default:
      return entityList;
  }
};

export const filterByBooleanField = <T>(
  entityList: T[],
  field: keyof T,
  value: boolean,
) => entityList.filter((entity) => entity[field] === value);

export const filterByNumberField = <T>(
  entityList: T[],
  field: keyof T,
  comparisonType: "eq" | "gt" | "lt",
  value: number,
) => {
  switch (comparisonType) {
    case "eq":
      return entityList.filter((entity) => entity[field] === value);
    case "gt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "number") return false;

        return fieldValue > value;
      });
    case "lt":
      return entityList.filter((entity) => {
        const fieldValue = entity[field];
        if (typeof fieldValue !== "number") return false;

        return fieldValue < value;
      });
    default:
      return entityList;
  }
};

export const filterEntities = <T>(
  entityList: T[],
  filterFields: FilterField<T>[],
  searchFields: (keyof T)[],
  searchText: string | null,
  filterByFieldFunction: (
    entityList: T[],
    filterField: FilterField<T>,
    entityTypeKeys: TypeKeysEnum<T>,
    customFilterFunctions: Partial<
      Record<keyof T, (entity: T, value: string) => boolean>
    >,
  ) => T[],
  entityTypeKeys: TypeKeysEnum<T>,
  customFilterFunctions: Partial<
    Record<keyof T, (entity: T, value: string) => boolean>
  >,
) => {
  const filteredBySearchText = searchText
    ? filterBySearchText<T>(entityList, searchFields, searchText)
    : entityList;

  return filterFields.reduce(
    (filteredEntities, filterField) =>
      filterField.value !== "all"
        ? filterByFieldFunction(
            filteredEntities,
            filterField,
            entityTypeKeys,
            customFilterFunctions,
          )
        : filteredEntities,
    filteredBySearchText,
  );
};

export const filterEntityByField = <T>(
  systemList: T[],
  filterField: FilterField<T>,
  entityTypeKeys: TypeKeysEnum<T>,
  customFilterFunctions: Partial<
    Record<keyof T | string, (entity: T, value: string) => boolean>
  >,
) => {
  const { field, comparisonType, value } = filterField;

  const fieldConstruct = entityTypeKeys[field];

  const customFilterFunction = customFilterFunctions[field];

  if (customFilterFunction) {
    return systemList.filter((entity) => customFilterFunction(entity, value));
  }

  if (!fieldConstruct) return systemList;

  switch (fieldConstruct.type) {
    case "id":
    case "string":
    case "enum":
      return filterByTextField(systemList, field, value);
    case "boolean":
      return filterByBooleanField(
        systemList,
        field,
        value === "true" ? true : false,
      );
    case "datetime":
    case "date":
      return filterByDateField(systemList, field, comparisonType, value);
    case "time":
      return filterByTimeField(systemList, field, comparisonType, value);
    case "integer":
    case "timestamp":
      return filterByNumberField(
        systemList,
        field,
        comparisonType,
        parseInt(value),
      );
    case "float":
      return filterByNumberField(
        systemList,
        field,
        comparisonType,
        parseFloat(value),
      );
    default:
      return systemList;
  }
};

import React from "react";
import { Typography } from "@mui/material";
import { useAuthContext } from "context";
import { useAuthRoutes } from "hooks";
import { CustomButton, Loading } from "core";
import utils from "utils";
import useStyles from "./styles";

type ErrorPageProps = {
  eventID?: string | null;
  errorTitle: string;
  errorMessage: string;
  errorDescription?: string;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({
  eventID,
  errorTitle,
  errorMessage,
  errorDescription,
}) => {
  utils.logger.info("eventID: ", eventID);
  const { classes } = useStyles();
  const authContext = useAuthContext();

  const { navigateToStartPage } = useAuthRoutes();

  return (
    <div className={classes.root}>
      {authContext.isLoading ? <Loading size="33px" /> : /*<MenuBar />*/ null}
      <main className={classes.content}>
        <Typography className={classes.headerText}>{errorTitle}</Typography>

        <Typography className={classes.errorText}>{errorMessage}</Typography>

        {errorDescription && (
          <Typography className={classes.errorDescription}>
            {errorDescription}
          </Typography>
        )}

        {eventID && (
          <Typography className={classes.sentryEventID}>
            {"Event-ID: " + eventID}
          </Typography>
        )}

        <CustomButton
          text="Zur Startseite"
          onClick={() => navigateToStartPage()}
          style="filled"
        />
      </main>
    </div>
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  filterResetButton: {
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      borderColor: theme.palette.primary.dark,
    },
  },
  filterResetIcon: {
    width: 25,
    height: 25,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;

import React from "react";
import useStyles from "./styles";

type BoxContainerFilterProps = {
  children: React.ReactNode;
};

export const BoxContainerFilter: React.FC<BoxContainerFilterProps> = ({
  children,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filter}>{children}</div>
    </div>
  );
};

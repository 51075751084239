import { client } from "queryClient";
import { Employee } from "types";
import utils from "utils";

export const getEmployeeList = async (): Promise<Employee[]> => {
  const { data, errors } = await client.queries.listEmployees({
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return [];
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  return data.employeeList;
};

import React from "react";
import { client } from "queryClient";
import { Salutation, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface SalutationOptionProps
  extends Partial<CustomSelectOptionProps<Salutation>> {
  salutation: Salutation | null;
  setSalutation: (value: React.SetStateAction<Salutation | null>) => void;
  salutationInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getSalutationName = (value: Salutation) => {
  switch (value) {
    case "Mrs":
      return "Frau";
    case "Mr":
      return "Herr";
  }
};

export const getSalutationEnumByValue = (value: string): Salutation => {
  switch (value) {
    case "mrs":
      return "Mrs";
    case "mr":
      return "Mr";
    default:
      return "Mr";
  }
};

export const getSalutationEnumByName = (value: string): Salutation => {
  switch (value) {
    case "Frau":
      return "Mrs";
    case "Herr":
      return "Mr";
    default:
      return "Mr";
  }
};

export const getSalutationOptions = (): SelectOption[] => {
  const values = client.enums.Salutation?.values() ?? [];

  return values.map((salutation) => ({
    value: salutation,
    label: getSalutationName(salutation),
  }));
};

export const SalutationOption: React.FC<SalutationOptionProps> = ({
  salutation,
  setSalutation,
  salutationInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Anrede"
    options={getSalutationOptions()}
    onChange={(salutationOption) =>
      setSalutation(salutationOption.value as Salutation)
    }
    value={
      salutation
        ? {
            value: salutation,
            label: getSalutationName(salutation),
          }
        : null
    }
    placeholder="Anrede auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={salutationInputRef}
  />
);

import React from "react";
import { useNavigate } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useAuthContext } from "context";
import useStyles from "./styles";

type SidebarSubmenuHeaderProps = {
  title: string | undefined;
  subTitle: string | undefined;
  icon: React.ReactNode;
  link: string;
};

export const SidebarSubmenuHeader: React.FC<SidebarSubmenuHeaderProps> = ({
  title,
  subTitle,
  icon,
  link,
}) => {
  const { classes, cx } = useStyles();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  return (
    <ListItem
      className={cx(
        classes.listItem,
        authContext.menuOpen ? null : classes.listItemClosed,
      )}
      onClick={() => navigate(link)}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        classes={{
          primary: cx(
            classes.listItemText,
            authContext.menuOpen ? null : classes.menuClosed,
          ),
        }}
      >
        <div
          className={cx(
            title && !authContext.isLoadingStateEntities
              ? classes.title
              : classes.titleLoading,
          )}
        >
          {authContext.isLoadingStateEntities ? "" : title}
        </div>
        <div
          className={cx(
            subTitle && !authContext.isLoadingStateEntities
              ? classes.subTitle
              : classes.subTitleLoading,
          )}
        >
          {authContext.isLoadingStateEntities ? "" : subTitle}
        </div>
      </ListItemText>
    </ListItem>
  );
};

import { useEffect, useState } from "react";
import { usePersistedState } from "hooks";
import { LexicalEditor, createEditor } from "lexical";
import { System, Template } from "types";

export const useTemplateForm = (
  formIntent: "create" | "edit",
  template: Template | null | undefined,
) => {
  const [name, setName] = usePersistedState<string>(
    "template_name",
    "",
    formIntent,
  );
  const [usability, setUsability] = usePersistedState<number | "">(
    "template_usability",
    1,
    formIntent,
  );
  const [beauty, setBeauty] = usePersistedState<number | "">(
    "template_beauty",
    1,
    formIntent,
  );
  const [purchasedLicences, setPurchasedLicences] = usePersistedState<
    number | ""
  >("template_purchasedLicences", 1, formIntent);
  const [providerURL, setProviderURL] = usePersistedState<string>(
    "template_providerURL",
    "",
    formIntent,
  );
  const [system, setSystem] = usePersistedState<System | null>(
    "template_system",
    null,
    formIntent,
  );

  const [description, setDescription] = useState<LexicalEditor>(createEditor());

  const resetTemplateForm = () => {
    localStorage.removeItem("template_name");
    localStorage.removeItem("template_usability");
    localStorage.removeItem("template_beauty");
    localStorage.removeItem("template_purchasedLicences");
    localStorage.removeItem("template_providerURL");
    localStorage.removeItem("template_system");
    localStorage.removeItem("hoster_description");
  };

  useEffect(() => {
    if (!template) {
      return;
    }

    setName(template.name);
    setUsability(template.usability);
    setBeauty(template.beauty);
    setPurchasedLicences(template.purchasedLicences);
    setProviderURL(template.providerURL);
    setSystem(template.system as System);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  return {
    name,
    setName,
    usability,
    setUsability,
    beauty,
    setBeauty,
    purchasedLicences,
    setPurchasedLicences,
    providerURL,
    setProviderURL,
    system,
    setSystem,
    description,
    setDescription,
    resetTemplateForm,
  };
};

import React from "react";
import { Grid } from "@mui/material";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";

export const HosterListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <NumberFilter param="rating" paramName="Bewertung" />
      </Grid>
      <Grid item>
        <SearchTextFilter
          searchTextParamName={SearchTextParamName.SearchHoster}
        />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateSystemInput } from "types";
import { getSystem } from "modules/systems/api";

export const useSystemUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (systemInput: UpdateSystemInput) => {
      const { data: updatedSystem } =
        await client.models.System.update(systemInput);

      if (!updatedSystem) return null;

      const system = await getSystem(updatedSystem.id);

      return system;
    },
    // When mutate is called:
    onMutate: async (newSystem) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Systems, newSystem.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Systems],
      });

      // Snapshot the previous value
      const previousSystem = queryClient.getQueryData([
        QueryKeys.Systems,
        newSystem.id,
      ]);

      // Optimistically update to the new value
      if (previousSystem) {
        queryClient.setQueryData(
          [QueryKeys.Systems, newSystem.id],
          /**
           * `newSystem` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousSystem, ...newSystem },
        );
      }

      // Return a context with the previous and new system
      return {
        previousSystem,
        newSystem,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newSystem, context) => {
      console.error("Error updating record:", err, newSystem);
      if (context?.previousSystem) {
        queryClient.setQueryData(
          [QueryKeys.Systems, context.newSystem.id],
          context.previousSystem,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newSystem) => {
      if (newSystem) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Systems, newSystem.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Systems],
        });
      }
    },
  });

  return updateMutation;
};

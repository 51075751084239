import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getPermanentTask } from "modules/permanenttasks/api";

type PermanentTaskReadParamsType = {
  permanentTaskID: string;
};

export const usePermanentTaskRead = () => {
  const authContext = useAuthContext();

  const { permanentTaskID } = useParams<PermanentTaskReadParamsType>();

  console.log("Loading permanentTask with ID: ", permanentTaskID);

  const { data: permanentTask, isLoading } = useQuery({
    queryKey: [QueryKeys.PermanentTasks, permanentTaskID],
    queryFn: async () => {
      if (!permanentTaskID) return null;

      const permanentTaskFromAPI = await getPermanentTask(permanentTaskID);

      if (permanentTaskFromAPI) {
        authContext.setPermanentTask(permanentTaskFromAPI);

        authContext.setCurrentData({
          id: permanentTaskFromAPI.id,
          name: permanentTaskFromAPI.taskType.name,
        });
      }

      return permanentTaskFromAPI;
    },
  });

  return {
    permanentTaskID,
    permanentTask: permanentTask ?? null,
    isLoading,
  };
};

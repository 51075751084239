import React from "react";
import { client } from "queryClient";
import { BillType, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface BillTypeOptionProps
  extends Partial<CustomSelectOptionProps<BillType>> {
  billType: BillType | null;
  setBillType: (value: React.SetStateAction<BillType | null>) => void;
  billTypeInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getBillTypeName = (value: BillType) => {
  switch (value) {
    case "free":
      return "Gratis";
    case "rework":
      return "Nachbesserung";
    case "maintenance":
      return "Pflege";
    case "invoice":
      return "Rechnungsstellung";
    case "sea":
      return "SEA";
    case "seo":
      return "SEO";
    case "socialmedia":
      return "Social Media";
  }
};

export const getBillTypeEnumByName = (value: string): BillType => {
  switch (value) {
    case "Gratis":
      return "free";
    case "Nachbesserung":
      return "rework";
    case "Pflege":
      return "maintenance";
    case "Rechnungsstellung":
      return "invoice";
    case "SEA":
      return "sea";
    case "SEO":
      return "seo";
    case "Social Media":
      return "socialmedia";
    default:
      return "invoice";
  }
};

export const getBillTypeOptions = (): SelectOption[] => {
  const values = client.enums.BillType?.values() ?? [];

  return values.map((billType) => ({
    value: billType,
    label: getBillTypeName(billType),
  }));
};

export const BillTypeOption: React.FC<BillTypeOptionProps> = ({
  billType,
  setBillType,
  billTypeInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Abrechnungsart"
    options={getBillTypeOptions()}
    onChange={(billTypeOption) => setBillType(billTypeOption.value as BillType)}
    value={
      billType
        ? {
            value: billType,
            label: getBillTypeName(billType),
          }
        : null
    }
    placeholder="Abrechnungsart auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={billTypeInputRef}
  />
);

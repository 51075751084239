import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTemplateRead, useTemplateRoutes } from "hooks";
import { Template } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { DescriptionFormatter } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { DeleteIcon, EditPenIcon } from "modules/icons";
import { TemplateDeleteDialog } from "../../templateDelete/TemplateDeleteDialog";
import useStyles from "./styles";

export const TemplatePage: React.FC = () => {
  const { classes } = useStyles();

  const { template, isLoading } = useTemplateRead();
  const { navigateToEditTemplatePage } = useTemplateRoutes();

  const [deleteTemplateDialogOpen, setDeleteTemplateDialogOpen] =
    useState<boolean>(false);
  const [templateToDelete, setTemplateToDelete] = useState<
    Template | undefined
  >();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Template Details">
        <Loading
          description="Bitte warten. Template wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (template === undefined || template === null) {
    return (
      <BoxHeadlineContainer boxTitle="Template Details">
        Template nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={!isLoading && template ? template.name : "Template-Details"}
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditPenIcon />}
            onClick={() => navigateToEditTemplatePage(template)}
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setTemplateToDelete(template);
              setDeleteTemplateDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <TemplateDeleteDialog
        dialogOpen={deleteTemplateDialogOpen}
        setDialogOpen={setDeleteTemplateDialogOpen}
        template={templateToDelete}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Name" content={template.name} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Benutzbarkeit"
            content={template.usability}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Schönheit" content={template.beauty} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Gekaufte Lizenzen"
            content={template.purchasedLicences}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item md={12}>
          <LabeledTypography
            label="Anbieter-URL"
            content={template.providerURL}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item>
          <Typography variant="h3">Beschreibung</Typography>
          <DescriptionFormatter description={template.description} />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

import React, { SyntheticEvent } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTicketRoutes } from "hooks";
import moment from "moment";
import { Ticket } from "types";
import { CustomTag } from "core";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";

type TicketListTableProps = {
  listIntent: "list" | "selection";
  ticketList: Ticket[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  ticketSelectionHandler?: (ticket: Ticket) => void;
};

export const TicketListTable: React.FC<TicketListTableProps> = ({
  listIntent,
  ticketList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  ticketSelectionHandler,
}) => {
  const { navigateToTicketPage } = useTicketRoutes();

  const defaultColumns = React.useMemo<ColumnDef<Ticket>[]>(
    () => [
      {
        header: "#",
        accessorKey: "ticketNumber",
        accessorFn: (row) => row.ticketNumber,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Kunde",
        accessorKey: "accountName",
        accessorFn: (row) => row.accountName,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Domain",
        accessorKey: "cf_domain",
        accessorFn: (row) => row.cf_domain,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Abschließdatum",
        accessorKey: "closedTime",
        accessorFn: (row) =>
          row.closedTime ? moment(row.closedTime).format("DD.MM.YYYY") : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Priorität",
        accessorKey: "priority",
        cell: ({ row }) =>
          row.original.priority ? (
            <CustomTag
              content={row.original.priority}
              tagSize="small"
              tagStyle={
                row.original.priority === "Hoch" ? "filled" : "outlined"
              }
              tagColor={
                row.original.priority === "Hoch"
                  ? "red"
                  : row.original.priority === "Mittel"
                    ? "orange"
                    : "blue"
              }
            />
          ) : (
            ""
          ),
        footer: (props) => props.column.id,
      },
      {
        header: "Abteilung",
        accessorKey: "departmentName",
        accessorFn: (row) => row.departmentName,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Typ",
        accessorKey: "classification",
        accessorFn: (row) => row.classification,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => (
          <CustomTag
            content={row.original.status}
            tagSize="small"
            tagStyle="outlined"
            tagColor={
              row.original.status === "Offen"
                ? "orange"
                : row.original.status === "In Bearbeitung"
                  ? "blue"
                  : row.original.status === "Blockiert"
                    ? "red"
                    : "green"
            }
          />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "min",
        accessorKey: "cf_aufwand_in_minuten",
        accessorFn: (row) => row.cf_aufwand_in_minuten,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Abrechnungsart",
        accessorKey: "cf_abrechnungsart",
        accessorFn: (row) => row.cf_abrechnungsart,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "€",
        accessorKey: "cf_abgerechnet",
        accessorFn: (row) =>
          row.cf_abgerechnet
            ? row.cf_abgerechnet === "true"
              ? "Ja"
              : "Nein"
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Betreff",
        accessorKey: "subject",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Ticket>
            row={row}
            listIntent={listIntent}
            rowSelectionHandler={ticketSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [listIntent, ticketSelectionHandler],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Ticket) => {
    if (ticketSelectionHandler) {
      ticketSelectionHandler(row);
    } else {
      navigateToTicketPage(row);
    }
  };

  return (
    <>
      <CustomReactTable<Ticket>
        data={ticketList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  linkIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    marginTop: -5,
  },
  iconContainer: {
    marginLeft: 5,
  },
  iconLabelContainer: {
    width: 18,
    height: 18,
  },
  assigneeName: {
    marginBottom: 10,
  },
  email: {
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;

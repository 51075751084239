import { EditIcon, InfoIcon, WebsiteIcon } from "modules/icons";
import { WebsitePage } from "modules/websites/websiteDetails/WebsitePage";
import { WebsiteFormPage } from "modules/websites/websiteForm/WebsiteFormPage";
import { WebsiteListPage } from "modules/websites/websitelist/WebsiteListPage";
import { USER_GROUPS } from "utils/constants";

export const websites = {
  key: "websites",
  path: "/websites",
  section: "verwaltung",
  title: "Webseiten",
  view: <WebsiteListPage routeKey="websites" />,
  icon: <WebsiteIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: true,
  isWithParam: false,
  subNavigations: {
    websitecreate: {
      key: "websitecreate",
      path: "/websites/create",
      section: "general",
      title: "Webseite anlegen",
      view: <WebsiteFormPage routeKey="websites" formIntent="create" />,
      icon: <WebsiteIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    website: {
      key: "website",
      path: "/websites/:websiteID",
      section: "website",
      title: "Webseite",
      view: <WebsitePage routeKey="websites" />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        websiteedit: {
          key: "websiteedit",
          path: "/websites/:websiteID/edit",
          section: "website",
          title: "Webseite bearbeiten",
          view: <WebsiteFormPage routeKey="websites" formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

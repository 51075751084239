import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  gridItem: {
    marginBottom: 30,
    "&:not(:last-child)": {
      marginRight: 100,
    },
  },
  editButton: {
    marginRight: 15,
  },
  machineOperatorTitle: {
    marginTop: 15,
    marginBottom: 15,
  },
  machineOperators: {
    "& td": {
      lineHeight: "23px",
    },
    "& th:first": {
      textAlign: "left",
    },
    "& td:not(:first-child)": {
      textAlign: "center",
    },
    "& th": {
      textAlign: "center",
    },
    "& td:first": {
      paddingRight: 20,
      textAlign: "left !important" as "left",
    },
  },
}));

export default useStyles;

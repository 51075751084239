import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllWebsiteList } from "modules/websites/api";
import { useWebsiteListFilterAndSort } from "../useWebsiteListFilterAndSort";

export const useWebsiteAllList = (filter = true) => {
  const filterAndSortWebsites = useWebsiteListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Websites, "all"],
    queryFn: async () => {
      const { websiteList } = await getAllWebsiteList();

      return websiteList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const websiteList = useMemo(
    () => filterAndSortWebsites(data ?? []),
    [data, filterAndSortWebsites],
  );

  return {
    websiteList,
    isLoading,
  };
};

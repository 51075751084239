import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateUserInput, User } from "types";

export const useUserCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateUserInput) => {
      const { data: newUser } = await client.models.User.create(input);
      return newUser;
    },
    // When mutate is called:
    onMutate: async (newUser) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.Users] });

      // Snapshot the previous value
      const previousUsers = queryClient.getQueryData([QueryKeys.Users]);

      // Optimistically update to the new value
      if (previousUsers) {
        queryClient.setQueryData([QueryKeys.Users], (old: User[]) => [
          ...(old ?? []),
          newUser,
        ]);
      }

      // Return a context object with the snapshotted value
      return { previousUsers };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newUser, context) => {
      console.error("Error saving record:", err, newUser);
      if (context?.previousUsers) {
        queryClient.setQueryData([QueryKeys.Users], context.previousUsers);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Users] });
    },
  });

  return createMutation;
};

import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  EffortDefinition,
  EffortDefinitionTypeKeys,
  SearchTextParamName,
  SortDirection,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<EffortDefinition>(
  EffortDefinitionTypeKeys,
);

export const useEffortDefinitionListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<EffortDefinition>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof EffortDefinition, string>> = {};

    const filters: FilterField<EffortDefinition>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof EffortDefinition,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<EffortDefinition>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof EffortDefinition,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof EffortDefinition, SortDirection>> =
      {
        taskTypeID: SortDirection.asc,
      };

    return getSortFields<EffortDefinition>(
      searchParams.get("sort"),
      defaultSorts,
    );
  }, [searchParams]);

  const filterAndSortEffortDefinitions = useCallback(
    (
      effortDefinitionList: EffortDefinition[],
      customSortFunctions: Partial<
        Record<
          keyof EffortDefinition,
          (
            a: EffortDefinition,
            b: EffortDefinition,
            direction: SortDirection,
          ) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<
          keyof EffortDefinition,
          (entity: EffortDefinition, value: string) => boolean
        >
      > = {},
    ) =>
      sortEntities<EffortDefinition>(
        filter
          ? filterEntities<EffortDefinition>(
              effortDefinitionList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchEffortDefinition),
              filterEntityByField<EffortDefinition>,
              EffortDefinitionTypeKeys,
              customFilterFunctions,
            )
          : effortDefinitionList,
        sortFields,
        sortEntitiesByField<EffortDefinition>,
        EffortDefinitionTypeKeys,
        customSortFunctions,
      ).map((effortDefinition) => ({
        ...effortDefinition,
        updatedAt: new Date(
          new Date(
            effortDefinition.updatedAt ?? new Date().getTime(),
          ).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortEffortDefinitions;
};

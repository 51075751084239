import { client } from "queryClient";
import {
  Booking,
  BookingSelectionSet,
  CreateReportEntryInput,
  ReportEntry,
  Task,
  Ticket,
  UpdateTaskInput,
  Website,
} from "types";
import { WebsiteReportType } from "modules/carereport/api";
import { setTicketsAsBilled } from "modules/tickets/api";
import utils from "utils";

export const getBookingList = async (
  nextTokenParam: string | null = null,
): Promise<{ bookingList: Booking[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Booking.list({
    limit: 300,
    nextToken: nextTokenParam,
    selectionSet: BookingSelectionSet,
  });

  return {
    bookingList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllBookingList = async (
  nextTokenParam: string | null = null,
  prevBookingList: Booking[] = [],
): Promise<{ bookingList: Booking[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Booking.list({
    limit: 300,
    nextToken: nextTokenParam,
    selectionSet: BookingSelectionSet,
  });

  const bookingList = [...prevBookingList, ...data];

  return nextToken
    ? getAllBookingList(nextToken, bookingList)
    : { bookingList, nextToken: null };
};

export const getBookingListByCustomerYearMonth = async (
  customerID: string,
  year: number,
  month: number,
): Promise<Booking[]> => {
  const { data } = await client.models.Booking.bookingsByCustomerYearMonth(
    {
      customerID: customerID,
      yearMonth: {
        eq: {
          year: year,
          month: month,
        },
      },
    },
    {
      selectionSet: BookingSelectionSet,
    },
  );

  return data ?? [];
};

export const getBookingListByYearByMonth = async (
  year: number,
  month: number,
  nextTokenParam: string | null = null,
  prevBookingList: Booking[] = [],
): Promise<{ bookingList: Booking[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Booking.bookingsByYearByMonth(
    {
      year: year,
      month: {
        eq: month,
      },
    },
    {
      limit: 300,
      nextToken: nextTokenParam,
      selectionSet: BookingSelectionSet,
    },
  );

  const bookingList = [...prevBookingList, ...data];

  return nextToken
    ? getBookingListByYearByMonth(year, month, nextToken, bookingList)
    : { bookingList, nextToken: null };
};

export const getBooking = async (
  bookingID: string,
): Promise<Booking | null> => {
  const { data } = await client.models.Booking.get(
    {
      id: bookingID,
    },
    {
      selectionSet: BookingSelectionSet,
    },
  );

  return data ?? null;
};

export const isBookingUsed = async (bookingID: string): Promise<boolean> => {
  const { data: reportEntryList } =
    await client.models.ReportEntry.reportEntriesbyBooking({
      bookingID,
    });

  console.log("isBookingUsed data: ", reportEntryList);

  return reportEntryList.length > 0;
};

export const processTaskOfBooking = async (
  booking: Booking,
  website: Website,
  task: Task,
  createReportEntryHandler: (
    input: CreateReportEntryInput,
  ) => Promise<ReportEntry | null>,
  updateTaskHandler: (input: UpdateTaskInput) => Promise<Task | null>,
) => {
  const createTaskReportEntryInput: CreateReportEntryInput = {
    originalType: "task",
    taskID: task.id,
    type: task.taskType.name,
    description: task.taskType.description ?? "",
    effort: task.effort,
    bookingID: booking.id,
    websiteID: website.id,
  };
  console.log("createTaskReportEntryInput: ", createTaskReportEntryInput);

  const updateTaskInput: UpdateTaskInput = {
    id: task.id,
    billed: true,
  };

  console.log("setTasksBilledValue with : ", task.id, true);

  const [createdReportEntry] = await Promise.all([
    createReportEntryHandler(createTaskReportEntryInput),
    updateTaskHandler(updateTaskInput),
  ]);

  return createdReportEntry;
};

export const processTicketOfBooking = async (
  booking: Booking,
  website: Website,
  ticket: Ticket,
  createReportEntryHandler: (
    input: CreateReportEntryInput,
  ) => Promise<ReportEntry | null>,
) => {
  const effort = ticket.cf_aufwand_in_minuten
    ? parseInt(ticket.cf_aufwand_in_minuten)
    : 0;

  const createTicketReportEntryInput: CreateReportEntryInput = {
    originalType: "ticket",
    ticketID: ticket.id,
    ticketSubject: ticket.subject,
    type: ticket.classification ?? "ticket",
    description: ticket.description ?? "",
    effort: effort,
    bookingID: booking.id,
    websiteID: website.id,
  };

  console.log("createTicketReportEntryInput: ", createTicketReportEntryInput);
  const createdReportEntry = await createReportEntryHandler(
    createTicketReportEntryInput,
  );

  return createdReportEntry;
};

export const processBookingsWebsiteReport = async (
  booking: Booking,
  bookingsWebsiteReport: WebsiteReportType,
  createReportEntryHandler: (
    input: CreateReportEntryInput,
  ) => Promise<ReportEntry | null>,
  updateTaskHandler: (input: UpdateTaskInput) => Promise<Task | null>,
) => {
  const processTaskOfBookingPromises = bookingsWebsiteReport.taskList.map(
    (task) =>
      processTaskOfBooking(
        booking,
        bookingsWebsiteReport.website,
        task,
        createReportEntryHandler,
        updateTaskHandler,
      ),
  );

  const processTicketOfBookingPromises = bookingsWebsiteReport.ticketList.map(
    (ticket) =>
      processTicketOfBooking(
        booking,
        bookingsWebsiteReport.website,
        ticket,
        createReportEntryHandler,
      ),
  );

  const ticketIDs = bookingsWebsiteReport.ticketList.map((ticket) => ticket.id);

  const createdTaskAndTicketsReportEntries = await Promise.all([
    ...processTaskOfBookingPromises,
    ...processTicketOfBookingPromises,
  ]);

  await setTicketsAsBilled(ticketIDs, true);

  const createdReportEntries = utils.graphql.getDefinedItems(
    createdTaskAndTicketsReportEntries,
  );

  return createdReportEntries;
};

export const processBookingsReport = async (
  booking: Booking,
  bookingsWebsiteReports: WebsiteReportType[],
  createReportEntryHandler: (
    input: CreateReportEntryInput,
  ) => Promise<ReportEntry | null>,
  updateTaskHandler: (input: UpdateTaskInput) => Promise<Task | null>,
) => {
  const processBookingsWebsiteReportPromises = bookingsWebsiteReports.map(
    (bookingsWebsiteReport) =>
      processBookingsWebsiteReport(
        booking,
        bookingsWebsiteReport,
        createReportEntryHandler,
        updateTaskHandler,
      ),
  );

  const createdReportEntries = await Promise.all(
    processBookingsWebsiteReportPromises,
  );

  return createdReportEntries.flat();
};

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useWebsiteDelete, useWebsiteRoutes } from "hooks";
import { Website } from "types";
import { DeleteDialog } from "components";
import { isWebsiteUsed } from "../../api";
import utils from "utils";

type WebsiteDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  website: Website | undefined;
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
};

export const WebsiteDeleteDialog: React.FC<WebsiteDeleteDialogProps> = ({
  website,
  dialogOpen,
  setDialogOpen,
  routeKey,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteWebsiteMutation = useWebsiteDelete();
  const { navigateToWebsiteListPage } = useWebsiteRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || website === undefined) {
    return null;
  }

  const deleteWebsiteRequest = async () => {
    setDeleteLoading(true);
    const websiteIsUsed = await isWebsiteUsed(website);

    if (websiteIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Diese Webseite kann nicht gelöscht werden, da sie in Verwendung ist!",
      );
      return;
    }

    let deletedWebsite = await deleteWebsiteMutation.mutateAsync(website);

    await utils.general.waitTime(3000);

    console.log("deletedWebsite: ", deletedWebsite);
    setDeleteLoading(false);
    setDialogOpen(false);

    navigateToWebsiteListPage(routeKey);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteWebsiteRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Webseite Löschen"
      itemName={website.url}
      itemDeleteDescription="die Webseite"
    />
  );
};

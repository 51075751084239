import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useTicketRead } from "hooks";
import moment from "moment";
import { CustomAvatar, CustomTag, LabeledTypography, Loading } from "core";
import { DescriptionFormatter } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import useStyles from "./styles";

export const TicketPage: React.FC = () => {
  const { classes } = useStyles();

  const { ticket, isLoading } = useTicketRead();

  if (isLoading) {
    return (
      <Loading
        description="Bitte warten. Ticket wird geladen... "
        size="25px"
      />
    );
  }

  if (!ticket) {
    return (
      <BoxHeadlineContainer boxTitle="Ticket Details">
        Ticket nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={!isLoading && ticket ? ticket.subject : "Ticket Details"}
      boxTitleSize="big"
    >
      <Typography variant="h3">Kunde</Typography>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Kunde" content={ticket.accountName} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Domain" content={ticket.cf_domain} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Kontaktperson"
            content={
              ticket.contact_firstName && ticket.contact_lastName
                ? ticket.contact_firstName + " " + ticket.contact_lastName
                : null
            }
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Telefonnummer"
            content={ticket.contact_phone}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Mobilnummer"
            content={ticket.contact_mobile}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="E-Mail-Adresse"
            content={ticket.contact_email}
          />
        </Grid>
      </Grid>

      <Typography variant="h3">Ticket-Informationen</Typography>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Ticketnummer"
            content={ticket.ticketNumber}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Ticket ID"
            content={
              <Typography
                onClick={() => navigator.clipboard.writeText(ticket.id)}
              >
                {ticket.id}
                <IconButton
                  classes={{
                    root: classes.iconContainer,
                    colorPrimary: classes.iconLabelContainer,
                  }}
                  onClick={() => window.open(ticket.webUrl, "_blank")}
                >
                  <i
                    className={`icon-feather-external-link ${classes.linkIcon}`}
                  ></i>
                </IconButton>
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridRow} container direction="row">
        <Grid item md={4}>
          <LabeledTypography
            label="Priorität"
            content={
              ticket.priority ? (
                <CustomTag
                  content={ticket.priority}
                  tagSize="small"
                  tagStyle={ticket.priority === "Hoch" ? "filled" : "outlined"}
                  tagColor={
                    ticket.priority === "Hoch"
                      ? "red"
                      : ticket.priority === "Mittel"
                        ? "orange"
                        : "blue"
                  }
                />
              ) : null
            }
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Abteilung"
            content={ticket.departmentName}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography label="Typ" content={ticket.classification} />
        </Grid>
      </Grid>

      <Grid className={classes.gridRow} container direction="row">
        <Grid item md={4}>
          <LabeledTypography
            label="Status"
            content={
              <CustomTag
                content={ticket.status}
                tagSize="small"
                tagStyle="outlined"
                tagColor={
                  ticket.status === "Offen"
                    ? "orange"
                    : ticket.status === "In Bearbeitung"
                      ? "blue"
                      : ticket.status === "Blockiert"
                        ? "red"
                        : "green"
                }
              />
            }
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Abschließdatum"
            content={
              ticket.closedTime !== null
                ? moment(ticket.closedTime).format("DD.MM.YYYY")
                : null
            }
          />
        </Grid>
      </Grid>

      <Typography variant="h3">Beschreibung</Typography>
      <Grid className={classes.gridRow} container direction="row">
        <Grid item md={6}>
          <DescriptionFormatter description={ticket.description} />
        </Grid>
      </Grid>

      <Typography variant="h3">Abrechnung</Typography>
      <Grid className={classes.gridRow} container direction="row">
        <Grid item md={4}>
          <LabeledTypography
            label="Aufwand in min"
            content={ticket.cf_aufwand_in_minuten}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Abrechnungsart"
            content={ticket.cf_abrechnungsart}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Abgerechnet"
            content={ticket.cf_abgerechnet === "true" ? "Ja" : "Nein"}
          />
        </Grid>
      </Grid>

      <Typography variant="h3">Bearbeiter</Typography>
      <Grid
        className={classes.gridRow}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {ticket.assignee_photoURL && (
          <Grid item>
            <CustomAvatar
              showBadge={false}
              size="100px"
              alternativeImage={ticket.assignee_photoURL}
            />
          </Grid>
        )}
        <Grid item justifyContent="flex-start">
          <Typography variant="h4" className={classes.assigneeName}>
            {ticket.assignee_firstName + " " + ticket.assignee_lastName}
          </Typography>
          <a className={classes.email} href={`mailto:${ticket.assignee_email}`}>
            {ticket.assignee_email}
          </a>
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

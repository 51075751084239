import React from "react";
import { useTemplateList } from "hooks";
import { Template } from "types";
import { CustomDialog } from "core";
import { TemplateListFilter } from "../../templatelist/TemplateListFilter";
import { TemplateListTable } from "../../templatelist/TemplateListTable";

type TemplateSelectionDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  templateSelectionHandler: (template: Template) => void;
};

export const TemplateSelectionDialog: React.FC<
  TemplateSelectionDialogProps
> = ({ dialogOpen, setDialogOpen, templateSelectionHandler }) => {
  const { templateList, isLoading, hasNextPage, fetchNextPage } =
    useTemplateList();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={false}
      titleText="Template auswählen"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={false}
      fullWidth={true}
      maxWidth="lg"
      contentPadding={0}
    >
      <TemplateListFilter />
      <TemplateListTable
        listIntent="selection"
        templateList={templateList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        templateSelectionHandler={templateSelectionHandler}
      />
    </CustomDialog>
  );
};

import React, { useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateTemplateInput, System, Template } from "types";
import {
  CustomButton,
  CustomButtonHandles,
  CustomNumberField,
  CustomNumberFieldHandles,
  Editor,
  LabeledTypography,
  SelectionButton,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { SystemIcon } from "modules/icons";
import { SystemSelectionDialog } from "modules/systems/systemselection/SystemSelectionDialog";
import { getWebsiteListByTemplate } from "modules/websites/api";
import { useTemplateForm } from "../useTemplateForm";
import utils from "utils";
import useStyles from "./styles";

type TemplateFormProps = {
  formIntent: "create" | "edit";
  template: Template | null | undefined;
};

export type TemplateFormHandles = {
  validateTemplateForm(): Promise<CreateTemplateInput | null>;
};

const TemplateFormComponent: React.ForwardRefRenderFunction<
  TemplateFormHandles,
  TemplateFormProps
> = ({ formIntent, template }, templateFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    name,
    setName,
    usability,
    setUsability,
    beauty,
    setBeauty,
    purchasedLicences,
    setPurchasedLicences,
    providerURL,
    setProviderURL,
    system,
    setSystem,
    description,
    setDescription,
    resetTemplateForm,
  } = useTemplateForm(formIntent, template);

  const [selectSystemDialogOpen, setSelectSystemDialogOpen] =
    useState<boolean>(false);

  const systemSelectionHandler = (system: System) => {
    setSystem(system);
    setSelectSystemDialogOpen(false);
  };

  const nameInputRef = useRef<TextInputFieldHandles>(null);
  const systemInputRef = useRef<CustomButtonHandles>(null);
  const usabilityInputRef = useRef<CustomNumberFieldHandles>(null);
  const beautyInputRef = useRef<CustomNumberFieldHandles>(null);
  const purchasedLicencesInputRef = useRef<CustomNumberFieldHandles>(null);
  const providerURLInputRef = useRef<TextInputFieldHandles>(null);

  useImperativeHandle(templateFormRef, () => ({
    validateTemplateForm: async () => {
      if (name === "") {
        enqueueSnackbar("Bitte gib den Namen des Templates ein!");
        nameInputRef.current?.highlight();
        return null;
      }

      if (usability === "") {
        enqueueSnackbar("Bitte gib die Benutzbarkeit ein!");
        usabilityInputRef.current?.highlight();
        return null;
      }

      if (beauty === "") {
        enqueueSnackbar("Bitte gib die Schönheit ein!");
        beautyInputRef.current?.highlight();
        return null;
      }

      if (purchasedLicences === "") {
        enqueueSnackbar("Bitte gib die Anzahl der gekauften Lizenzen ein!");
        purchasedLicencesInputRef.current?.highlight();
        return null;
      }

      if (providerURL === "") {
        enqueueSnackbar("Bitte gib die Anbieter-URL ein!");
        providerURLInputRef.current?.highlight();
        return null;
      }

      if (!system) {
        enqueueSnackbar("Bitte wähle das System des Templates aus!");
        systemInputRef.current?.highlight();
        return null;
      }

      const websitesByTemplate = template
        ? await getWebsiteListByTemplate(template.id)
        : [];
      const websitesCreatedByUs = websitesByTemplate.filter(
        (website) => website.createdByUs,
      );

      const templateFormInput: CreateTemplateInput = {
        name: name,
        usability: usability,
        beauty: beauty,
        purchasedLicences: purchasedLicences,
        usage: websitesCreatedByUs.length,
        providerURL: providerURL,
        systemID: system.id,
        description: utils.wysiwyg.getStringFromEditorState(description),
      };

      resetTemplateForm();

      return templateFormInput;
    },
  }));

  return (
    <>
      <SystemSelectionDialog
        dialogOpen={selectSystemDialogOpen}
        setDialogOpen={setSelectSystemDialogOpen}
        systemSelectionHandler={systemSelectionHandler}
      />

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <TextInputField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            ref={nameInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Benutzbarkeit"
            number={usability}
            setNumber={setUsability}
            minValue={1}
            maxValue={10}
            ref={usabilityInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Schönheit"
            number={beauty}
            setNumber={setBeauty}
            minValue={1}
            maxValue={10}
            ref={beautyInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomNumberField
            label="Gekaufte Lizenzen"
            number={purchasedLicences}
            setNumber={setPurchasedLicences}
            minValue={1}
            ref={purchasedLicencesInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          {system ? (
            <SelectionButton
              label="System"
              name={system.name}
              onClick={() => setSelectSystemDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="System"
              content={
                <CustomButton
                  text="System auswählen"
                  onClick={() => setSelectSystemDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <SystemIcon className={classes.selectButtonIcon} />
                  }
                  ref={systemInputRef}
                />
              }
            />
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={6}>
          <TextInputField
            label="Anbieter-URL"
            value={providerURL}
            onChange={(e) => setProviderURL(e.target.value)}
            ref={providerURLInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={12}>
          <Editor
            label="Anmerkungen"
            setEditor={setDescription}
            initialValue={template?.description}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const TemplateForm = React.forwardRef(TemplateFormComponent);

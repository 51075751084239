import React from "react";
import useStyles from "./styles";

type CareReportViewProps = {
  children: React.ReactNode;
};

export const CareReportView: React.FC<CareReportViewProps> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.reportContent} id="reportContent">
      <header className={classes.header}>
        <img
          src="/logos/goldweiss_logo.png"
          className={classes.logo}
          alt="Agentur Goldweiss"
        />
        <p>Webseiten, Marketing, Print, Grafik, Texte, Konzeption</p>
      </header>
      {children}
      <footer>
        <table className={classes.footer}>
          <tr>
            <td>
              Stargarder Str. 33
              <br />
              10437 Berlin
            </td>
            <td>
              Agentur: 030 208 85 828
              <br />
              Pflegeabteilung: 030 95 99 45 03
            </td>
            <td>
              www.agentur-goldweiss.de
              <br />
              kontakt@agentur-goldweiss.de
            </td>
            <td>
              Mo bis Fr
              <br />
              9-17 Uhr
            </td>
          </tr>
        </table>
      </footer>
    </div>
  );
};

import { HosterPage } from "modules/hosters/hosterDetails/HosterPage";
import { HosterFormPage } from "modules/hosters/hosterForm/HosterFormPage";
import { HosterListPage } from "modules/hosters/hosterlist/HosterListPage";
import { EditIcon, HosterIcon, InfoIcon } from "modules/icons";
import { USER_GROUPS } from "utils/constants";

export const hosters = {
  key: "hosters",
  path: "/hosters",
  section: "grunddaten",
  title: "Hosters",
  view: <HosterListPage />,
  icon: <HosterIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    hostercreate: {
      key: "hostercreate",
      path: "/hosters/create",
      section: "grunddaten",
      title: "Hoster anlegen",
      view: <HosterFormPage formIntent="create" />,
      icon: <HosterIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    hoster: {
      key: "hoster",
      path: "/hosters/:hosterID",
      section: "hoster",
      title: "Hoster",
      view: <HosterPage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        hosteredit: {
          key: "hosteredit",
          path: "/hosters/:hosterID/edit",
          section: "hoster",
          title: "Hoster bearbeiten",
          view: <HosterFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  selectButtonIcon: {
    width: 25,
    height: 25,
    marginLeft: 7,
    verticalAlign: "text-bottom",
  },
}));

export default useStyles;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { resetPassword } from "aws-amplify/auth";
import { useAuthRoutes } from "hooks";
import { CustomButton, TextInputField } from "core";
import { LoginErrorType } from "utils/errorHandling";
import utils from "utils";
import useStyles from "./styles";

const ForgotPassword: React.FC = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    getConfirmForgotPasswordPageRoute,
    navigateToConfirmForgotPasswordPage,
  } = useAuthRoutes();

  const [userName, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);

  const resetPasswordRequest = async () => {
    try {
      if (userName === "" || userName.length > 200) {
        return enqueueSnackbar("Bitte gib deine E-Mail-Adresse ein!");
      }

      setLoading(true);
      const { nextStep } = await resetPassword({
        username: userName,
      });

      setSuccess(true);
      console.log("nextStep: ", nextStep);
      if (nextStep.codeDeliveryDetails) {
        navigateToConfirmForgotPasswordPage();
      }
    } catch (err: unknown) {
      const error = err as LoginErrorType;

      console.log("err: ", err);
      if (error?.code === "UserNotFoundException") {
        enqueueSnackbar(
          "Benutzer nicht gefunden. Bitte überprüfe deine Eingabe!",
        );
      } else if (error?.code === "LimitExceededException") {
        enqueueSnackbar(
          "Das Versuchslimit wurde überschritten. Bitte versuche es nach einiger Zeit nochmal.",
        );
      } else {
        enqueueSnackbar(error?.message);
      }

      utils.errorHandling.logToSentry(
        "Error on resetPassword!",
        "Authentication -> forgotPassword",
        err,
        null,
      );
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.pageTitle} variant="h3">
          Passwort vergessen
        </Typography>
        <Typography className={classes.pageSubtitle} variant="subtitle1">
          Gib die deinem Konto zugeordnete E-Mail-Adresse ein. Wir senden dir
          dann einen Link zum Zurücksetzen deines Passworts per E-Mail.
        </Typography>
      </Grid>
      <Grid item className={classes.formContainer}>
        <TextInputField
          label="E-Mail-Adresse"
          value={userName}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
        />
      </Grid>

      <div className={classes.changePasswordTextContainer}>
        <Link
          className={classes.changePasswordTextLink}
          to={getConfirmForgotPasswordPageRoute()}
        >
          Ich habe bereits einen Code.
        </Link>
      </div>

      <Grid item className={classes.buttonsContainer}>
        <CustomButton
          text="Passwort zurücksetzen"
          onClick={resetPasswordRequest}
          loading={loading}
          success={success}
          style="filled"
        />
      </Grid>
    </>
  );
};

export default ForgotPassword;

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateWebsiteInput } from "types";
import { getWebsite } from "modules/websites/api";

export const useWebsiteUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (websiteInput: UpdateWebsiteInput) => {
      const { data: updatedWebsite } =
        await client.models.Website.update(websiteInput);

      if (!updatedWebsite) return null;

      const website = await getWebsite(updatedWebsite.id);

      return website;
    },
    // When mutate is called:
    onMutate: async (newWebsite) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Websites, newWebsite.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Websites],
      });

      // Snapshot the previous value
      const previousWebsite = queryClient.getQueryData([
        QueryKeys.Websites,
        newWebsite.id,
      ]);

      // Optimistically update to the new value
      if (previousWebsite) {
        queryClient.setQueryData(
          [QueryKeys.Websites, newWebsite.id],
          /**
           * `newWebsite` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousWebsite, ...newWebsite },
        );
      }

      // Return a context with the previous and new website
      return {
        previousWebsite,
        newWebsite,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newWebsite, context) => {
      console.error("Error updating record:", err, newWebsite);
      if (context?.previousWebsite) {
        queryClient.setQueryData(
          [QueryKeys.Websites, context.newWebsite.id],
          context.previousWebsite,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newWebsite) => {
      if (newWebsite) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Websites, newWebsite.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Websites],
        });
      }
    },
  });

  return updateMutation;
};

import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { BooleanFilter } from "modules/shared/filters/BooleanFilter";
import { CustomerFilter } from "modules/shared/filters/CustomerFilter";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";

export const BookingListFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (sessionStorage.getItem("initialFilterState")) return;
    if (searchParams.get("month") && searchParams.get("year")) return;

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;

    searchParams.set("month", currentMonth.toString());
    searchParams.set("year", currentYear.toString());

    sessionStorage.setItem("initialFilterState", searchParams.toString());
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <FilterWrapper>
      <FilterRow>
        <Grid item>
          <CustomerFilter param="customerID" paramName="Kunde" />
        </Grid>
        <Grid item>
          <NumberFilter param="bookedMinutes" paramName="Gebuchte Minuten" />
        </Grid>
        <Grid item>
          <NumberFilter
            param="previousMinutes"
            paramName="Minuten aus Vormonat"
          />
        </Grid>
        <Grid item>
          <NumberFilter param="finalMinutes" paramName="Finaler Minutenstand" />
        </Grid>
      </FilterRow>

      <FilterRow>
        <Grid item>
          <NumberFilter param="month" paramName="Monat" />
        </Grid>

        <Grid item>
          <NumberFilter param="year" paramName="Jahr" />
        </Grid>

        <Grid item>
          <BooleanFilter param="paid" paramName="Bezahlt" />
        </Grid>
        <Grid item>
          <SearchTextFilter
            searchTextParamName={SearchTextParamName.SearchCustomer}
          />
        </Grid>
      </FilterRow>
    </FilterWrapper>
  );
};

import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTaskRoutes } from "hooks";
import { Task } from "types";
import { CustomTag } from "core";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { EmployeeColumnFormatter } from "modules/shared/columnformatters/EmployeeColumnFormatter";
import { TaskDeleteDialog } from "../../taskDelete/TaskDeleteDialog";
import utils from "utils";

type TaskListTableProps = {
  listIntent: "list" | "selection";
  taskList: Task[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  taskSelectionHandler?: (task: Task) => void;
};

export const TaskListTable: React.FC<TaskListTableProps> = ({
  listIntent,
  taskList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  taskSelectionHandler,
}) => {
  const { navigateToTaskPage, navigateToEditTaskPage } = useTaskRoutes();

  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] =
    useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<Task | undefined>();

  const defaultColumns = React.useMemo<ColumnDef<Task>[]>(
    () => [
      {
        header: "Aufgaben-Typ",
        accessorKey: "taskTypeID",
        accessorFn: (row) => row.taskType.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Webseite",
        accessorKey: "websiteID",
        cell: ({ row }) => (
          <span style={{ whiteSpace: "nowrap" }}>
            {row.original.website.url}
          </span>
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwand in m",
        accessorKey: "effort",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Datum",
        accessorKey: "date",
        accessorFn: (row) =>
          row.date
            ? utils.dates.getDateInGermanFromAWSDateFormat(row.date)
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Uhrzeit",
        accessorKey: "time",
        accessorFn: (row) =>
          row.time
            ? utils.dates.getTimeInGermanFromAWSTimeFormat(row.time)
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Frist-Datum",
        accessorKey: "deadlineDate",
        accessorFn: (row) =>
          utils.dates.getDateInGermanFromAWSDateFormat(row.deadlineDate),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Frist-Uhrzeit",
        accessorKey: "deadlineTime",
        accessorFn: (row) =>
          utils.dates.getTimeInGermanFromAWSTimeFormat(row.deadlineTime),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Abrechnungsart",
        accessorKey: "billType",
        accessorFn: (row) =>
          row.billType === "free"
            ? "Gratis"
            : row.billType === "maintenance"
              ? "Pflege"
              : "Rechnung",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Abgerechnet",
        accessorKey: "billed",
        accessorFn: (row) => (row.billed ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => (
          <CustomTag
            content={
              row.original.status === "open"
                ? "Offen"
                : row.original.status === "inprogress"
                  ? "In Bearbeitung"
                  : "Fertig"
            }
            tagSize="small"
            tagStyle="outlined"
            tagColor={
              row.original.status === "open"
                ? "orange"
                : row.original.status === "inprogress"
                  ? "blue"
                  : "green"
            }
          />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Mitarbeiter",
        accessorKey: "employee",
        cell: ({ row }) => (
          <EmployeeColumnFormatter employeeID={row.original.employee} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Task>
            row={row}
            listIntent={listIntent}
            openEditRowPage={navigateToEditTaskPage}
            setRowToDelete={setTaskToDelete}
            setDeleteRowDialogOpen={setDeleteTaskDialogOpen}
            rowSelectionHandler={taskSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditTaskPage, taskSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Task) => {
    if (taskSelectionHandler) {
      taskSelectionHandler(row);
    } else {
      navigateToTaskPage(row);
    }
  };

  return (
    <>
      <TaskDeleteDialog
        dialogOpen={deleteTaskDialogOpen}
        setDialogOpen={setDeleteTaskDialogOpen}
        task={taskToDelete}
      />
      <CustomReactTable<Task>
        data={taskList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

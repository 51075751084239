import { EditIcon, InfoIcon, TaskTypeIcon } from "modules/icons";
import { TaskTypePage } from "modules/tasktypes/tasktypeDetails/TaskTypePage";
import { TaskTypeFormPage } from "modules/tasktypes/tasktypeForm/TaskTypeFormPage";
import { TaskTypeListPage } from "modules/tasktypes/tasktypelist/TaskTypeListPage";
import { WebsiteStatusListPage } from "modules/tasktypes/websitestatuslist/WebsiteStatusListPage";
import { tasktypeeffortrules } from "./tasktypes/tasktypeeffortrules";
import { USER_GROUPS } from "utils/constants";

export const tasktypes = {
  key: "tasktypes",
  path: "/tasktypes",
  section: "grunddaten",
  title: "Aufgaben-Typen",
  view: <TaskTypeListPage />,
  icon: <TaskTypeIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    tasktypecreate: {
      key: "tasktypecreate",
      path: "/tasktypes/create",
      section: "grunddaten",
      title: "Aufgaben-Typ anlegen",
      view: <TaskTypeFormPage formIntent="create" />,
      icon: <TaskTypeIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    tasktype: {
      key: "tasktype",
      path: "/tasktypes/:taskTypeID",
      section: "tasktype",
      title: "Aufgaben-Typ",
      view: <TaskTypePage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        tasktypeedit: {
          key: "tasktypeedit",
          path: "/tasktypes/:taskTypeID/edit",
          section: "tasktype",
          title: "Aufgaben-Typ bearbeiten",
          view: <TaskTypeFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
        tasktypeeffortrules: tasktypeeffortrules,
        websitestatus: {
          key: "tasktype",
          path: "/tasktypes/:taskTypeID/websitestatus",
          section: "taskType",
          title: "Webseiten-Status",
          view: <WebsiteStatusListPage />,
          icon: <InfoIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 1,
          navigation: true,
          indented: false,
          isWithParam: true,
          subNavigations: {},
        },
      },
    },
  },
};

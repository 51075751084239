import React from "react";
import { CustomTooltip } from "core";
import { TrashIcon } from "modules/icons";
import useStyles from "./styles";

type DeleteButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  size?: "small" | "medium" | "big";
  title?: string;
  disabled?: boolean;
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick,
  size = "medium",
  title,
  disabled = false,
}) => {
  const { classes, cx } = useStyles();

  const sizeClass =
    size === "small"
      ? classes.buttonSmall
      : size === "big"
      ? classes.buttonBig
      : classes.buttonMedium;

  return (
    <button
      title={title}
      className={cx(
        disabled ? classes.disabledButton : classes.deleteButton,
        classes.button,
        sizeClass
      )}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onClick(e);
      }}
      disabled={disabled}
    >
      {disabled ? (
        <CustomTooltip
          title="Keine Berechtigung zum Löschen"
          followCursor={true}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <TrashIcon />
          </span>
        </CustomTooltip>
      ) : (
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <TrashIcon />
        </span>
      )}
    </button>
  );
};

import { InfiniteData } from "@tanstack/react-query";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export type Ticket = Schema["Ticket"]["type"];

export type InfiniteTicketList = {
  ticketList: Ticket[];
  nextFrom: number | null;
};

export type InfiniteTicketData =
  | InfiniteData<InfiniteTicketList, unknown>
  | undefined;

export const TicketTypeKeys: TypeKeysEnum<Ticket> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.id,
  },
  ticketNumber: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.ticketNumber,
  },
  email: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.email,
  },
  phone: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.phone ?? "",
  },
  classification: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.classification ?? "",
  },
  cf_abgerechnet: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.cf_abgerechnet === "true" ? "Ja" : "Nein"),
  },
  cf_abrechnungsart: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.cf_abrechnungsart,
  },
  cf_abgeschlossen_am: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.cf_abgeschlossen_am ?? "",
  },
  cf_aufwand_in_minuten: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.cf_aufwand_in_minuten ?? "",
  },
  cf_domain: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.cf_domain ?? "",
  },
  cf_e_mail_versand_an_kunde: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.cf_e_mail_versand_an_kunde,
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
  subject: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.subject,
  },
  status: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.status,
  },
  statusType: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.statusType,
  },
  createdTime: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdTime),
  },
  priority: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.priority ?? "",
  },
  channel: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.channel,
  },
  dueDate: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      data.dueDate
        ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.dueDate)
        : "",
  },
  closedTime: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      data.closedTime
        ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
            data.closedTime,
          )
        : "",
  },
  onholdTime: {
    type: "datetime",
    isArray: true,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      data.onholdTime
        ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
            data.onholdTime,
          )
        : "",
  },
  departmentId: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.departmentId,
  },
  accountName: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.accountName ?? "",
  },
  departmentName: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.departmentName ?? "",
  },
  contactId: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.contactId,
  },
  contact_firstName: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.contact_firstName,
  },
  contact_lastName: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.contact_lastName,
  },
  contact_email: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.contact_email,
  },
  contact_phone: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.contact_phone ?? "",
  },
  contact_mobile: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.contact_mobile ?? "",
  },
  associatedChatDepartmentIds: {
    type: "string",
    isArray: true,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: () => "",
  },
  photoURL: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.photoURL ?? "",
  },
  name: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.name ?? "",
  },
  timeZone: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.timeZone ?? "",
  },
  channelExpert: {
    type: "string",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  langCode: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.langCode ?? "",
  },
  webUrl: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.webUrl,
  },
  isRead: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.isRead ? "Ja" : "Nein"),
  },
  assigneeId: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.assigneeId,
  },
  assignee_firstName: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.assignee_firstName,
  },
  assignee_lastName: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.assignee_lastName,
  },
  assignee_email: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.assignee_email ?? "",
  },
  assignee_photoURL: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.assignee_photoURL ?? "",
  },
};

export const isKeyOfTicket = (key: string): key is keyof Ticket =>
  key in TicketTypeKeys;

import { useEffect } from "react";
import { usePersistedState } from "hooks";
import { EffortRule, System, TaskType, WebsiteType } from "types";

export const useEffortRuleForm = (
  formIntent: "create" | "edit",
  effortRule: EffortRule | null | undefined,
) => {
  const [websiteType, setWebsiteType] = usePersistedState<WebsiteType | null>(
    "effortRule_websiteType",
    null,
    formIntent,
  );

  const [system, setSystem] = usePersistedState<System | null>(
    "effortRule_system",
    null,
    formIntent,
  );

  const [taskType, setTaskType] = usePersistedState<TaskType | null>(
    "effortRule_taskType",
    null,
    formIntent,
  );

  const [effort, setEffort] = usePersistedState<number | "">(
    "effortRule_effort",
    1,
    formIntent,
  );

  const resetEffortRuleForm = () => {
    localStorage.removeItem("effortRule_websiteType");
    localStorage.removeItem("effortRule_system");
    localStorage.removeItem("effortRule_taskType");
    localStorage.removeItem("effortRule_effort");
  };

  useEffect(() => {
    if (!effortRule) {
      return;
    }

    setWebsiteType(effortRule.websiteType);

    setEffort(effortRule.effort);

    setSystem(effortRule.system as System);

    setTaskType(effortRule.taskType as TaskType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effortRule]);

  return {
    websiteType,
    setWebsiteType,
    system,
    setSystem,
    taskType,
    setTaskType,
    effort,
    setEffort,
    resetEffortRuleForm,
  };
};

import React from "react";
import { client } from "queryClient";
import { SelectOption, TableTheme } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface TableThemeOptionProps
  extends Partial<CustomSelectOptionProps<TableTheme>> {
  tableTheme: TableTheme | null;
  setTableTheme: (value: React.SetStateAction<TableTheme | null>) => void;
  tableThemeInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getTableThemeName = (value: TableTheme) => {
  switch (value) {
    case "default":
      return "Standard";
    case "bordered":
      return "Gerahmt";
    case "striped":
      return "Gestreift";
  }
};

export const getTableThemeEnumByName = (value: string): TableTheme => {
  switch (value) {
    case "Standard":
      return "default";
    case "Gerahmt":
      return "bordered";
    case "Gestreift":
      return "striped";
    default:
      return "default";
  }
};

export const getTableThemeOptions = (): SelectOption[] => {
  const values = client.enums.TableTheme?.values() ?? [];

  return values.map((tableTheme) => ({
    value: tableTheme,
    label: getTableThemeName(tableTheme),
  }));
};

export const TableThemeOption: React.FC<TableThemeOptionProps> = ({
  tableTheme,
  setTableTheme,
  tableThemeInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Tabellen-Theme"
    options={getTableThemeOptions()}
    onChange={(tableThemeOption) =>
      setTableTheme(tableThemeOption.value as TableTheme)
    }
    value={
      tableTheme
        ? {
            value: tableTheme,
            label: getTableThemeName(tableTheme),
          }
        : null
    }
    placeholder="Tabellen-Theme auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={tableThemeInputRef}
  />
);

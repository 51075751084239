import { useCallback, useEffect, useState } from "react";
import { useSystemAllList, useTemplateAllList } from "hooks";
import { Template } from "types";

export const useLoadTemplateStatistics = () => {
  const { systemList } = useSystemAllList(false);
  const { templateList } = useTemplateAllList(false);

  const [templatesMostUsed, setTemplatesMostUsed] = useState<Template[]>([]);

  const [templatesFewUsed, setTemplatesFewUsed] = useState<Template[]>([]);

  const [templatesMuchUsed, setTemplatesMuchUsed] = useState<Template[]>([]);
  const [templatesWithoutUsage, setTemplatesWithoutUsage] = useState<
    Template[]
  >([]);
  const [templatesWithMissingLicences, setTemplatesWithMissingLicences] =
    useState<Template[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadTemplateStatistics = useCallback(() => {
    setIsLoading(true);
    const filteredTemplatesWithoutUsage = templateList.filter(
      (template) => template.usage === 0,
    );

    const filteredTemplatesWithMissingLicences = templateList.filter(
      (template) => template.usage - template.purchasedLicences > 0,
    );

    const filteredTemplatesMostUsed = templateList
      .sort((a, b) => b.usage - a.usage)
      .slice(0, 25);

    const filteredTemplatesFewUsed = templateList
      .filter((template) => template.purchasedLicences - template.usage > 0)
      .sort(
        (a, b) =>
          b.purchasedLicences - b.usage - (a.purchasedLicences - a.usage),
      );

    const filteredTemplatesMuchUsed = templateList
      .filter((template) => template.usage - template.purchasedLicences > 0)
      .sort(
        (a, b) =>
          b.usage - b.purchasedLicences - (a.usage - a.purchasedLicences),
      );

    setTemplatesWithoutUsage(filteredTemplatesWithoutUsage);
    setTemplatesWithMissingLicences(filteredTemplatesWithMissingLicences);
    setTemplatesMostUsed(filteredTemplatesMostUsed);
    setTemplatesFewUsed(filteredTemplatesFewUsed);
    setTemplatesMuchUsed(filteredTemplatesMuchUsed);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadTemplateStatistics();
  }, [loadTemplateStatistics]);

  return {
    templateList,
    systemList,
    templatesMostUsed,
    templatesWithoutUsage,
    templatesWithMissingLicences,
    templatesFewUsed,
    templatesMuchUsed,
    isLoading,
  };
};

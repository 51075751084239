import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useUserRead, useUserRoutes } from "hooks";
import { User } from "types";
import { CustomAvatar, CustomButton, LabeledTypography, Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { DeleteIcon, EditPenIcon } from "modules/icons";
import { getTableHeaderColorName } from "modules/shared/options/TableHeaderColorOption";
import { getTableSpacingName } from "modules/shared/options/TableSpacingOption";
import { getTableThemeName } from "modules/shared/options/TableThemeOption";
import { UserDeleteDialog } from "../../userDelete/UserDeleteDialog";
import utils from "utils";
import useStyles from "./styles";

const UserPage: React.FC = () => {
  const { classes } = useStyles();

  const { user, isLoading } = useUserRead();
  const { navigateToEditUserPage } = useUserRoutes();

  const [deleteUserDialogOpen, setDeleteUserDialogOpen] =
    useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<User>();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Benutzer Details">
        <Loading
          description="Bitte warten. Benutzer wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (!user) {
    return (
      <BoxHeadlineContainer boxTitle="Benutzer Details">
        User nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        !isLoading && user
          ? user.firstName + " " + user.lastName
          : "Benutzer-Details"
      }
      marginTop={false}
      boxWidth="lg"
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditPenIcon />}
            onClick={() => navigateToEditUserPage(user)}
            size="small"
            color="blue"
            rootClassName={classes.editButton}
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setUserToDelete(user);
              setDeleteUserDialogOpen(true);
            }}
            size="small"
            color="red"
          />
        </>
      }
    >
      <UserDeleteDialog
        dialogOpen={deleteUserDialogOpen}
        setDialogOpen={setDeleteUserDialogOpen}
        user={userToDelete}
      />
      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Anrede"
            content={user.salutation === "Mrs" ? "Frau" : "Herr"}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Vorname" content={user.firstName} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Nachname" content={user.lastName} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography label="E-Mail-Adresse" content={user.email} />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography label="Benutzername" content={user.username} />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography label="UserSUB" content={user.userSUB} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography label="Telefon" content={user.phone} />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography label="Fax" content={user.fax} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Benutzerbild"
            content={
              <CustomAvatar
                showBadge={false}
                size="100px"
                s3Resource={user.avatar}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Rollen"
            content={user.cognitoUser?.groups
              .map((group) => group.GroupName)
              .join(", ")}
          />
        </Grid>
      </Grid>

      <Typography variant="h2">Benutzereinstellungen</Typography>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellen-Theme"
            content={getTableThemeName(user.userSettings.tableTheme)}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellenkopf-Farbe"
            content={getTableHeaderColorName(
              user.userSettings.tableHeaderColor,
            )}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellen-Abstand"
            content={getTableSpacingName(user.userSettings.tableSpacing)}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Tabellenkopf fixieren"
            content={user.userSettings.tableSticky ? "Ja" : "Nein"}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow} spacing={5}>
        <Grid item md={4}>
          <LabeledTypography
            label="Bevorzugte Einheit"
            content={
              utils.constants.WEIGHT_OPTIONS.find(
                (weightOption) =>
                  weightOption.value === user!.userSettings.unitType,
              )?.label
            }
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

export default UserPage;

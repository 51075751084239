import React from "react";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import useStyles from "./styles";

type LabeledTypographyProps = {
  label: React.ReactNode;
  content: React.ReactNode | null | undefined;
  size?: "small" | "medium" | "big";
  color?:
    | "default"
    | "blue"
    | "white"
    | "red"
    | "orange"
    | "yellow"
    | "grey"
    | "green";
  accent?: "saturated" | "flat" | "pale";
  variant?: "inherit" | Variant | undefined;
  marginBottom?: boolean;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

export const LabeledTypography: React.FC<LabeledTypographyProps> = ({
  label,
  content,
  size = "medium",
  color = "default",
  accent = "pale",
  variant = "body1",
  marginBottom = true,
  bold = false,
  italic = false,
  underline = false,
}) => {
  const { classes, cx } = useStyles();

  const colorClass =
    color === "blue"
      ? classes.blue
      : color === "red"
      ? classes.red
      : color === "orange"
      ? classes.orange
      : color === "white"
      ? classes.white
      : color === "yellow"
      ? classes.yellow
      : color === "grey"
      ? classes.grey
      : color === "green"
      ? classes.green
      : classes.default;

  const accentClass =
    accent === "saturated"
      ? classes.saturated
      : accent === "flat"
      ? classes.flat
      : classes.pale;

  const labelClass =
    size === "small"
      ? classes.labelSmall
      : size === "big"
      ? classes.labelBig
      : classes.labelMedium;

  const labeledTypographyClass =
    size === "small"
      ? classes.labeledTypographySmall
      : size === "big"
      ? classes.labeledTypographyBig
      : classes.labeledTypographyMedium;

  return (
    <>
      <label
        className={cx(
          labelClass,
          marginBottom ? classes.labelMarginBottom : ""
        )}
      >
        {label}
      </label>
      <Typography
        variant={variant}
        className={cx(
          labeledTypographyClass,
          colorClass,
          accentClass,
          underline ? classes.underline : "",
          bold ? classes.bold : "",
          italic ? classes.italic : ""
        )}
      >
        {content === undefined || content === null || content === "" ? (
          <span className={classes.notAvailable}>N/A</span>
        ) : (
          content
        )}
      </Typography>
    </>
  );
};

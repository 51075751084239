import React from "react";
import { useWebsiteRoutes, useWebsiteStatusList } from "hooks";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { WebsiteListFilter } from "../WebsiteStatusListFilter";
import { WebsiteListTable } from "../WebsiteStatusListTable";

export const WebsiteStatusListPage: React.FC = () => {
  const { lastRunWebsiteTaskList, isLoading } = useWebsiteStatusList();
  const { WebsiteIcon } = useWebsiteRoutes();

  return (
    <BoxHeadlineContainer
      boxTitle="Webseiten-Status"
      boxIcon={WebsiteIcon}
      boxSubTitle={lastRunWebsiteTaskList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
    >
      <WebsiteListFilter />
      <WebsiteListTable
        lastRunWebsiteTaskList={lastRunWebsiteTaskList}
        isLoading={isLoading}
      />
    </BoxHeadlineContainer>
  );
};

import React from "react";
import { SelectOption } from "types";
import { CustomSelect, CustomSelectHandles } from "core";

type PriorityOptionProps = {
  priority: SelectOption | null;
  setPriority: (value: React.SetStateAction<SelectOption | null>) => void;
  priorityInputRef?: React.RefObject<CustomSelectHandles>;
  required?: boolean;
};

export const priorityOptionList = [
  {
    label: "Hoch",
    value: "Hoch",
  },
  {
    label: "Mittel",
    value: "Mittel",
  },
  {
    label: "Niedrig",
    value: "Niedrig",
  },
];

export const PriorityOption: React.FC<PriorityOptionProps> = ({
  priority = {
    label: "Mittel",
    value: "Mittel",
  },
  setPriority,
  priorityInputRef,
  required,
}) => {
  return (
    <CustomSelect<SelectOption>
      label="Priorität"
      options={priorityOptionList}
      onChange={(priorityOption) => setPriority(priorityOption)}
      placeholder="Priorität auswählen"
      value={priority}
      ref={priorityInputRef}
      required={required}
    />
  );
};

import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getWebsite } from "modules/websites/api";
import { useCustomerByIDRead } from "../../customer/useCustomerByIDRead";

type WebsiteReadParamsType = {
  websiteID: string;
};

export const useWebsiteRead = () => {
  const authContext = useAuthContext();

  const { websiteID } = useParams<WebsiteReadParamsType>();

  console.log("Loading website with ID: ", websiteID);

  const { data: website, isLoading } = useQuery({
    queryKey: [QueryKeys.Websites, websiteID],
    queryFn: async () => {
      if (!websiteID) return null;

      const websiteFromAPI = await getWebsite(websiteID);

      if (websiteFromAPI) {
        authContext.setWebsite(websiteFromAPI);

        authContext.setCurrentData({
          id: websiteFromAPI.id,
          name: websiteFromAPI.url,
        });
      }

      return websiteFromAPI;
    },
  });

  const { customer: websitePayer, isLoading: isLoadingWebsitePayer } =
    useCustomerByIDRead(website?.websitePayer);

  const { customer: websiteOwner, isLoading: isLoadingWebsiteOwner } =
    useCustomerByIDRead(website?.websiteOwner);

  return {
    websiteID,
    website: website ?? null,
    websitePayer,
    websiteOwner,
    isLoading: isLoadingWebsitePayer || isLoadingWebsiteOwner || isLoading,
  };
};

import React from "react";
import { Grid, Typography } from "@mui/material";
import { useEmployeeAllList } from "hooks";
import { CustomAvatar } from "core";
import useStyles from "./styles";

type EmployeeColumnFormatterProps = {
  employeeID: string;
};

export const EmployeeColumnFormatter: React.FC<
  EmployeeColumnFormatterProps
> = ({ employeeID }) => {
  const { classes } = useStyles();

  const { employeeList } = useEmployeeAllList(false);

  const employee = employeeList.find((employee) => employee.id === employeeID);

  return (
    <>
      {employee ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          {employee.photoURL && (
            <Grid item>
              <CustomAvatar
                showBadge={false}
                size="40px"
                alternativeImage={employee.photoURL}
                rootClass={classes.avatar}
              />
            </Grid>
          )}
          <Grid item justifyContent="flex-start">
            <Typography className={classes.employeeName}>
              {employee.firstName + " " + employee.lastName}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        employeeID
      )}
    </>
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  button: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: theme.palette.primaryGrey.C50,
    borderColor: theme.palette.primaryGrey.C50,
    padding: 0,
    outline: "none !important",
    verticalAlign: "middle",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    marginLeft: "0",
    fontSize: "0.925rem",
    border: "1px solid transparent",
    "&:hover svg g [fill]": {
      fill: "#FFFFFF !important",
      transition: "fill 0.3s ease",
    },
  },
  buttonSmall: {
    height: "calc(1em + 1.0rem)",
    width: "calc(1em + 1.0rem)",
    lineHeight: "1",
    borderRadius: "0.42rem",
    "&:not(:last-child)": {
      marginRight: "0.75rem !important",
    },
    "& svg": {
      width: 19,
      height: 19,
    },
  },
  buttonMedium: {
    height: "calc(1.35em + 1.1rem + 2px)",
    width: "calc(1.35em + 1.1rem + 2px)",
    lineHeight: "1.35",
    borderRadius: "0.42rem",
    "&:not(:last-child)": {
      marginRight: "0.75rem !important",
    },
  },
  buttonBig: {
    height: "calc(1.7em + 1.2rem + 4px)",
    width: "calc(1.7em + 1.2rem + 4px)",
    lineHeight: "1.7",
    borderRadius: "0.42rem",
    "&:not(:last-child)": {
      marginRight: "0.75rem !important",
    },
  },
  downloadButton: {
    "&:hover": {
      color: "#FFFFFF !important",
      backgroundColor: theme.palette.primary.main + " !important",
      borderColor: theme.palette.primary.main + " !important",
      "& svg": {
        color: "#FFFFFF !important",
      },
    },
    "& svg g [fill]": {
      fill: theme.palette.primary.main + " !important",
    },
  },
}));

export default useStyles;

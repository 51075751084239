import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  listItem: {
    color: "#eee",
    borderLeft: "3px solid " + theme.palette.primary.main,
    padding: "11px 15px 11px 15px !important",
    transition: "0.3s",
    lineHeight: "24px",
    borderColor: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.05)",
    "&:hover": {
      color: "#fff",
      backgroundColor: "rgba(255,255,255,0.025)",
    },
  },
  listItemClosed: {
    padding: "11px 11px 11px 10px !important",
  },
  title: {
    fontSize: 18,
    color: "#eee",
    textAlign: "left",
  },
  subTitle: {
    color: "#8b8b8b",
    textAlign: "left",
    fontSize: 16,
  },
  icon: {
    marginRight: 12,
    color: "#eee",
    minWidth: "unset",
    fontSize: 16,
    fontWeight: 400,
    "& svg": {
      width: 45,
      height: 45,
    },
  },
  listItemText: {
    textAlign: "left",
    minWidth: 210,
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuClosed: {
    opacity: 0,
  },
  titleLoading: {
    marginBottom: 5,
    "&:empty": {
      height: 28,
      width: "100%",
      cursor: "progress",
      display: "block",
      background:
        "linear-gradient(0.25turn, transparent, #7f7f7f, transparent), linear-gradient(#636363, #636363), radial-gradient(38px circle at 19px 19px, #7f7f7f 50%, transparent 51%), linear-gradient(#7f7f7f, #7f7f7f)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "315px 250px, 315px 180px, 100px 100px, 225px 30px",
      backgroundPosition: "-315px 0, 0 0, 0px 190px, 50px 195px",
      animation: "shimmer 1s infinite",
      content: '""',
    },
  },
  subTitleLoading: {
    "&:empty": {
      height: 25,
      width: 100,
      cursor: "progress",
      display: "block",
      background:
        "linear-gradient(0.25turn, transparent, #7f7f7f, transparent), linear-gradient(#4c4c4c, #4c4c4c), radial-gradient(38px circle at 19px 19px, #7f7f7f 50%, transparent 51%), linear-gradient(#7f7f7f, #7f7f7f)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "315px 250px, 315px 180px, 100px 100px, 225px 30px",
      backgroundPosition: "-315px 0, 0 0, 0px 190px, 50px 195px",
      animation: "shimmer 1s infinite",
      content: '""',
    },
  },
}));

export default useStyles;

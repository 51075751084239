import React from "react";
import { client } from "queryClient";
import { SelectOption, TaskStatus } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface TaskStatusOptionProps
  extends Partial<CustomSelectOptionProps<TaskStatus>> {
  taskStatus: TaskStatus | null;
  setTaskStatus: (value: React.SetStateAction<TaskStatus | null>) => void;
  taskStatusInputRef?: React.RefObject<CustomSelectHandles>;
  designStyle?: "solid" | "bordered";
}

export const getTaskStatusName = (value: TaskStatus) => {
  switch (value) {
    case "open":
      return "Offen";
    case "inprogress":
      return "In Bearbeitung";
    case "blocked":
      return "Blockiert";
    case "finished":
      return "Fertig";
  }
};

export const getTaskStatusEnumByName = (value: string): TaskStatus => {
  switch (value) {
    case "Offen":
      return "open";
    case "In Bearbeitung":
      return "inprogress";
    case "Blockiert":
      return "blocked";
    case "Fertig":
      return "finished";
    default:
      return "open";
  }
};

export const getTaskStatusOptions = (): SelectOption[] => {
  const values = client.enums.TaskStatus?.values() ?? [];

  return values.map((syncTaskStatus) => ({
    value: syncTaskStatus,
    label: getTaskStatusName(syncTaskStatus),
  }));
};

export const TaskStatusOption: React.FC<TaskStatusOptionProps> = ({
  taskStatus,
  setTaskStatus,
  taskStatusInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Status"
    options={getTaskStatusOptions()}
    onChange={(taskStatusOption) =>
      setTaskStatus(taskStatusOption.value as TaskStatus)
    }
    value={
      taskStatus
        ? {
            value: taskStatus,
            label: getTaskStatusName(taskStatus),
          }
        : null
    }
    placeholder="Status auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={taskStatusInputRef}
  />
);

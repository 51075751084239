import React from "react";
import { useEffortRuleList, useEffortRuleRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { EffortRuleListFilter } from "../EffortRuleListFilter";
import { EffortRuleListTable } from "../EffortRuleListTable";

type EffortRuleListProps = {
  routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules";
};

export const EffortRuleListPage: React.FC<EffortRuleListProps> = ({
  routeKey,
}) => {
  const { EffortRuleIcon, navigateToCreateEffortRulePage } =
    useEffortRuleRoutes();

  const { effortRuleList, isLoading, hasNextPage, fetchNextPage } =
    useEffortRuleList();

  return (
    <BoxHeadlineContainer
      boxTitle="Aufwandsregeln"
      boxIcon={EffortRuleIcon}
      boxSubTitle={effortRuleList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neue Aufwandsregel"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateEffortRulePage(routeKey)}
        />
      }
    >
      <EffortRuleListFilter />
      <EffortRuleListTable
        routeKey={routeKey}
        listIntent="list"
        effortRuleList={effortRuleList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

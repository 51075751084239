import React, { useMemo } from "react";
import { useCustomerAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";
import utils from "utils";

type CustomerFilterProps = {
  param: string;
  paramName: string;
};

export const CustomerFilter: React.FC<CustomerFilterProps> = ({
  param,
  paramName,
}) => {
  const { customerList } = useCustomerAllList(false);

  const customerOptionList = useMemo(() => {
    const customerListUnique = utils.general
      .filterUniques(customerList)
      .sort((a, b) => a.Account_Name.localeCompare(b.Account_Name));

    return customerListUnique.map((customerOption) => {
      return {
        label: customerOption.Account_Name,
        value: customerOption.id,
      };
    });
  }, [customerList]);

  return (
    <OptionsFilter
      param={param}
      paramName={paramName}
      options={customerOptionList}
    />
  );
};

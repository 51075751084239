import { client } from "queryClient";
import { Customer } from "types";
import utils from "utils";

type CustomerListResponse = {
  customerList: Customer[];
  hasNewNextData: boolean;
  nextToken: string | null;
};

export const getCustomerList = async (
  perPage: number,
  nextTokenParam: string | null | undefined,
  searchText?: string | null | undefined,
): Promise<CustomerListResponse> => {
  const { data, errors } = searchText
    ? await client.queries.searchCustomers({
        searchText,
        perPage,
        nextToken: nextTokenParam,
        token: utils.zoho.getCurrentToken("crmToken"),
      })
    : await client.queries.listCustomers({
        all: false,
        perPage,
        nextToken: nextTokenParam,
        token: utils.zoho.getCurrentToken("crmToken"),
      });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return {
      customerList: [],
      hasNewNextData: false,
      nextToken: null,
    };
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("crmToken", data.token);
  }

  const nextToken = data.newNextToken ?? null;
  const hasNewNextData = data.hasNewNextData ?? false;

  return {
    customerList: data.customerList,
    hasNewNextData,
    nextToken,
  };
};

export const getAllCustomerList = async (): Promise<{
  customerList: Customer[];
  hasNewNextData: boolean;
  nextToken: string | null;
}> => {
  const { data, errors } = await client.queries.listCustomers({
    all: true,
    perPage: 500,
    token: utils.zoho.getCurrentToken("crmToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return { customerList: [], hasNewNextData: false, nextToken: null };
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("crmToken", data.token);
  }

  return {
    customerList: data ? data.customerList : [],
    hasNewNextData: false,
    nextToken: null,
  };
};

export const getCustomer = async (
  customerID: string,
): Promise<Customer | null> => {
  const { data, errors } = await client.queries.getCustomer({
    customerID,
    token: utils.zoho.getCurrentToken("crmToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return null;
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("crmToken", data.token);
  }

  return data.customer ?? null;
};

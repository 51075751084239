import React, { SyntheticEvent } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useCustomerAllList, useWebsiteRoutes } from "hooks";
import { Website } from "types";
import { CustomReactTable } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { getBacklinkStatusName } from "modules/shared/options/BacklinkStatusOption";

type WebsiteListTableProps = {
  websiteList: Website[];
  tableTitle: string;
};

export const WebsiteListTable: React.FC<WebsiteListTableProps> = ({
  websiteList,
  tableTitle,
}) => {
  const { navigateToWebsitePage, openWebsiteInBrowserHandler } =
    useWebsiteRoutes();

  const { customerList } = useCustomerAllList(false);

  const rowClickHandler = (_: SyntheticEvent, website: Website) => {
    navigateToWebsitePage("websites", website);
  };

  const defaultColumns = React.useMemo<ColumnDef<Website>[]>(
    () => [
      {
        header: "URL",
        accessorKey: "url",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Besitzer",
        accessorKey: "websiteOwner",
        enableSorting: false,
        cell: ({ row }) =>
          customerList.find(
            (customerItem) => customerItem.id === row.original.websitePayer,
          )?.Account_Name,
        footer: (props) => props.column.id,
      },
      {
        header: "Zahler",
        accessorKey: "websitePayer",
        enableSorting: false,
        cell: ({ row }) =>
          customerList.find(
            (customerItem) => customerItem.id === row.original.websitePayer,
          )?.Account_Name,
        footer: (props) => props.column.id,
      },
      {
        header: "Website-Typ",
        accessorKey: "websiteType",
        accessorFn: (row) =>
          row.websiteType === "website"
            ? "Webseite"
            : row.websiteType === "webshop"
              ? "Webshop"
              : row.websiteType === "application"
                ? "Anwendung"
                : "Server",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "System",
        accessorKey: "systemID",
        accessorFn: (row) => row.system.name,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Hoster",
        accessorKey: "hosterID",
        accessorFn: (row) => row.hoster.name,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Template",
        accessorKey: "templateID",
        accessorFn: (row) => row.template.name,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Zuständig",
        accessorKey: "currentlyResponsible",
        accessorFn: (row) => (row.currentlyResponsible ? "Ja" : "Nein"),
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Von uns erstellt",
        accessorKey: "createdByUs",
        accessorFn: (row) => (row.createdByUs ? "Ja" : "Nein"),
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "GW Backlink Erstellung",
        accessorKey: "backlinkErstellung",
        accessorFn: (row) => getBacklinkStatusName(row.backlinkErstellung),
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "GW Backlink Pflege",
        accessorKey: "backlinkPflege",
        accessorFn: (row) => getBacklinkStatusName(row.backlinkPflege),
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Formilo Backlink",
        accessorKey: "formiloBacklink",
        accessorFn: (row) => getBacklinkStatusName(row.formiloBacklink),
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "PHP-Version",
        accessorKey: "phpVersion",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwandsdefinitionen",
        accessorKey: "effortDefinitions",
        accessorFn: (row) => row.effortDefinitions.length,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Website>
            row={row}
            openInBrowserHandler={openWebsiteInBrowserHandler}
            minWidth={150}
            listIntent="list"
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [customerList, openWebsiteInBrowserHandler],
  );

  return (
    <BoxHeadlineContainer boxTitle={tableTitle}>
      <CustomReactTable<Website>
        data={websiteList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        selectable={false}
      />
    </BoxHeadlineContainer>
  );
};

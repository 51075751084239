import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { InfiniteBookingList } from "types";
import {
  getAllBookingList,
  getBookingList,
  getBookingListByYearByMonth,
} from "modules/bookings/api";
import { useListQueryParams } from "../../../utility/useListQueryParams";
import { useCustomerAllList } from "../../customer/useCustomerAllList";
import { useBookingListFilterAndSort } from "../useBookingListFilterAndSort";

export const useBookingList = (filter = true) => {
  const searchParams = useListQueryParams();
  const filterAndSortBookings = useBookingListFilterAndSort(filter);

  const { customerList } = useCustomerAllList(false);

  const [loadAll, setLoadAll] = useState(false);

  const year = searchParams.get("year");
  const month = searchParams.get("month");

  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<InfiniteBookingList>({
      queryKey:
        year && month
          ? [QueryKeys.Bookings, { year, month }]
          : loadAll
            ? [QueryKeys.Bookings, "all"]
            : [QueryKeys.Bookings],
      queryFn: ({ pageParam }) =>
        year && month
          ? getBookingListByYearByMonth(parseInt(year), parseInt(month))
          : loadAll
            ? getAllBookingList()
            : getBookingList(pageParam as string | null),
      getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
      initialPageParam: null,
      staleTime: 1000 * 60 * 15, // 15 minutes
      gcTime: 1000 * 60 * 60, // 1 hour
    });

  const bookingList = useMemo(() => {
    const bookings = data?.pages
      ? data.pages.flatMap((page) => page.bookingList)
      : [];

    return filterAndSortBookings(bookings, {
      customerID: (a, b, direction) => {
        const customerA = customerList.find(
          (customer) => customer.id === a.customerID,
        );
        const customerB = customerList.find(
          (customer) => customer.id === b.customerID,
        );

        if (!customerA || !customerB) return 0;

        return direction === "asc"
          ? customerA.Account_Name.localeCompare(customerB.Account_Name)
          : customerB.Account_Name.localeCompare(customerA.Account_Name);
      },
    });
  }, [customerList, data, filterAndSortBookings]);

  return {
    bookingList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};

import React, { SyntheticEvent } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTemplateRoutes } from "hooks";
import { Template } from "types";
import { CustomReactTable } from "core";
import { DescriptionFormatter } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";

type MuchUsedTemplateListTableProps = {
  templateList: Template[];
};

export const MuchUsedTemplateListTable: React.FC<
  MuchUsedTemplateListTableProps
> = ({ templateList }) => {
  const { navigateToTemplatePage, openTemplateInBrowserHandler } =
    useTemplateRoutes();

  const rowClickHandler = (_: SyntheticEvent, template: Template) => {
    navigateToTemplatePage(template);
  };

  const defaultColumns = React.useMemo<ColumnDef<Template>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "System",
        accessorKey: "systemID",
        accessorFn: (row) => row.system.name,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Benutzbarkeit",
        accessorKey: "usability",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Schönheit",
        accessorKey: "beauty",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Gekaufte Lizenzen",
        accessorKey: "purchasedLicences",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Verwendung",
        accessorKey: "usage",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Zu viel",
        accessorKey: "muchUsed",
        accessorFn: (row) => row.usage - row.purchasedLicences,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Anbieter-URL",
        accessorKey: "providerURL",
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Beschreibung",
        accessorKey: "description",
        enableSorting: false,
        cell: ({ row }) => (
          <DescriptionFormatter description={row.original.description} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Template>
            row={row}
            openInBrowserHandler={openTemplateInBrowserHandler}
            minWidth={150}
            listIntent="list"
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  return (
    <BoxHeadlineContainer boxTitle="Zu viel verwendete Templates">
      <CustomReactTable<Template>
        data={templateList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        selectable={false}
      />
    </BoxHeadlineContainer>
  );
};

import { EditIcon, InfoIcon, TemplateIcon } from "modules/icons";
import { TemplatePage } from "modules/templates/templateDetails/TemplatePage";
import { TemplateFormPage } from "modules/templates/templateForm/TemplateFormPage";
import { TemplateListPage } from "modules/templates/templatelist/TemplateListPage";
import { templatewebsites } from "./templates/templatewebsites";
import { USER_GROUPS } from "utils/constants";

export const templates = {
  key: "templates",
  path: "/templates",
  section: "grunddaten",
  title: "Templates",
  view: <TemplateListPage />,
  icon: <TemplateIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: true,
  isWithParam: false,
  subNavigations: {
    templatecreate: {
      key: "templatecreate",
      path: "/templates/create",
      section: "grunddaten",
      title: "Template anlegen",
      view: <TemplateFormPage formIntent="create" />,
      icon: <TemplateIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    template: {
      key: "template",
      path: "/templates/:templateID",
      section: "template",
      title: "Template",
      view: <TemplatePage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        templateedit: {
          key: "templateedit",
          path: "/templates/:templateID/edit",
          section: "template",
          title: "Template bearbeiten",
          view: <TemplateFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
        templatewebsites: templatewebsites,
      },
    },
  },
};

import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllTaskTypeList } from "modules/tasktypes/api";
import { useTaskTypeListFilterAndSort } from "../useTaskTypeListFilterAndSort";

export const useTaskTypeAllList = (filter = true) => {
  const filterAndSortTaskTypes = useTaskTypeListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.TaskTypes, "all"],
    queryFn: async () => {
      const { taskTypeList } = await getAllTaskTypeList();

      return taskTypeList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const taskTypeList = useMemo(
    () => filterAndSortTaskTypes(data ?? []),
    [data, filterAndSortTaskTypes],
  );

  return {
    taskTypeList,
    isLoading,
  };
};

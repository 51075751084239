import { useEffect, useState } from "react";
import { usePersistedState } from "hooks";
import { LexicalEditor, createEditor } from "lexical";
import { Hoster } from "types";

export const useHosterForm = (
  formIntent: "create" | "edit",
  hoster: Hoster | null | undefined,
) => {
  const [name, setName] = usePersistedState<string>(
    "hoster_name",
    "",
    formIntent,
  );

  const [rating, setRating] = usePersistedState<number | "">(
    "hoster_rating",
    "",
    formIntent,
  );
  const [description, setDescription] = useState<LexicalEditor>(createEditor());

  const resetHosterForm = () => {
    localStorage.removeItem("hoster_name");
    localStorage.removeItem("hoster_rating");
    localStorage.removeItem("hoster_description");
  };

  useEffect(() => {
    if (!hoster) {
      return;
    }

    setName(hoster.name);
    setRating(hoster.rating);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoster]);

  return {
    name,
    setName,
    rating,
    setRating,
    description,
    setDescription,
    resetHosterForm,
  };
};

import React from "react";
import { Grid } from "@mui/material";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { useLoadTemplateStatistics } from "modules/templates/hooks/useLoadTemplateStatistics";
import { FewUsedTemplateListTable } from "../FewUsedTemplateListTable";
import { MostUsedTemplateListTable } from "../MostUsedTemplateListTable";
import { MuchUsedTemplateListTable } from "../MuchUsedTemplateListTable";
import { SystemStatistics } from "../SystemStatistics";
import { UsageStatistics } from "../UsageStatistics";

export const TemplateStatisticsPage: React.FC = () => {
  const {
    templateList,
    systemList,
    templatesMostUsed,
    templatesWithMissingLicences,
    templatesWithoutUsage,
    templatesFewUsed,
    templatesMuchUsed,
    isLoading,
  } = useLoadTemplateStatistics();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Template-Statistiken">
        <Loading
          description="Bitte warten. Template-Statistiken werden geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <>
      <Grid container direction="row" spacing={3}>
        <Grid item md={6}>
          <UsageStatistics
            templateList={templateList}
            templatesWithMissingLicences={templatesWithMissingLicences}
            templatesWithoutUsage={templatesWithoutUsage}
          />
        </Grid>
        <Grid item md={6}>
          <SystemStatistics
            templateList={templateList}
            systemList={systemList}
          />
        </Grid>
      </Grid>
      <FewUsedTemplateListTable templateList={templatesFewUsed} />
      <MuchUsedTemplateListTable templateList={templatesMuchUsed} />
      <MostUsedTemplateListTable templateList={templatesMostUsed} />
    </>
  );
};

import React from "react";
import { client } from "queryClient";
import { SelectOption, TableSpacing } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface TableSpacingOptionProps
  extends Partial<CustomSelectOptionProps<TableSpacing>> {
  tableSpacing: TableSpacing | null;
  setTableSpacing: (value: React.SetStateAction<TableSpacing | null>) => void;
  tableSpacingInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getTableSpacingName = (value: TableSpacing) => {
  switch (value) {
    case "xsmall":
      return "sehr klein";
    case "small":
      return "klein";
    case "medium":
      return "normal";
    case "large":
      return "groß";
  }
};

export const getTableSpacingEnumByName = (value: string): TableSpacing => {
  switch (value) {
    case "sehr klein":
      return "xsmall";
    case "klein":
      return "small";
    case "normal":
      return "medium";
    case "groß":
      return "large";
    default:
      return "medium";
  }
};

export const getTableSpacingOptions = (): SelectOption[] => {
  const values = client.enums.TableSpacing?.values() ?? [];

  return values.map((tableSpacing) => ({
    value: tableSpacing,
    label: getTableSpacingName(tableSpacing),
  }));
};

export const TableSpacingOption: React.FC<TableSpacingOptionProps> = ({
  tableSpacing,
  setTableSpacing,
  tableSpacingInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Tabellen-Abstand"
    options={getTableSpacingOptions()}
    onChange={(tableSpacingOption) =>
      setTableSpacing(tableSpacingOption.value as TableSpacing)
    }
    value={
      tableSpacing
        ? {
            value: tableSpacing,
            label: getTableSpacingName(tableSpacing),
          }
        : null
    }
    placeholder="Tabellen-Abstand auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={tableSpacingInputRef}
  />
);

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  reportContent: {
    fontFamily: "Arial, sans-serif",
    fontSize: 14,
    color: "#333",
    position: "relative",
    backgroundColor: "#fff",
    boxShadow: "0 0 12px 0 rgba(0,0,0,0.06)",
    borderRadius: 5,
    padding: 40,
    maxWidth: 800,
  },
  header: {
    borderBottom: "solid 1px #ffc800",
    paddingBottom: 15,
    "& p": {
      color: "#777",
      textAlign: "right",
      paddingTop: 35,
      margin: 0,
    },
  },
  logo: {
    width: 200,
    float: "left",
  },
  title: {
    height: 150,
    "& h1": {
      fontSize: "1.5em",
      paddingTop: 25,
      display: "block",
    },
  },
  red: {
    color: "#ff0000",
  },
  green: {
    color: "green !important",
  },
  websiteTitle: {
    background: "#f7e5a1",
    padding: 10,
    border: "solid 1px #f7c719",
    marginTop: 55,
    pageBreakInside: "avoid",
    "& span": {
      fontWeight: "bold",
      paddingRight: 15,
      display: "inline-block",
    },
  },
  websiteBody: {
    padding: "10px 10px 0 10px",
    border: "solid 1px #f7c719",
    pageBreakInside: "avoid",
  },
  efforts: {
    width: "100%",
    borderCollapse: "collapse",
    "& tr": {
      pageBreakInside: "avoid",
      pageBreakAfter: "always",
    },
    "& tr td": {
      pageBreakInside: "avoid",
      pageBreakAfter: "always",
      borderTop: "solid 1px #f7c719",
      paddingTop: 15,
      paddingBottom: 10,
    },

    "& tr td:nth-child(1)": {
      width: "90%",
      paddingRight: 10,
    },

    "& tr:nth-child(1) td": {
      borderTop: "none",
      paddingTop: 0,
    },

    "& tr td:nth-child(2)": {
      width: "10%",
      textAlign: "center",
      color: "red",
      verticalAlign: "top",
    },
  },
  siteTotalEffort: {
    pageBreakInside: "avoid",
    "& td:nth-child(1)": {
      textAlign: "right",
      fontWeight: "bold",
      paddingRight: 25,
    },
  },
  customer: {
    fontSize: "1.1em",
    fontWeight: "bold",
    marginBottom: 0,
  },
  info: {
    position: "absolute",
    top: 140,
    right: 50,
    width: "auto",
  },
  infoKey: {
    fontWeight: "bold",
  },
  infoValue: {
    marginLeft: 10,
  },
  monthResult: {
    pageBreakInside: "avoid",
    width: "100%",
    marginTop: 50,
    "& tr td:nth-child(1)": {
      width: "90%",
      textAlign: "right",
      paddingRight: 25,
    },
  },
  footer: {
    width: "100%",
    borderCollapse: "collapse",
    borderTop: "solid 1px #ffc800",
    marginTop: 50,
    "& td": {
      paddingTop: 15,
    },

    "& tr td:nth-child(1)": {
      width: 155,
    },

    "& tr td:nth-child(2)": {
      width: 255,
    },

    "& tr td:nth-child(3)": {
      width: 240,
    },
    "& tr td:nth-child(4)": {
      width: 70,
    },
  },
}));

export default useStyles;

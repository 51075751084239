import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  assigneeName: {
    marginBottom: 10,
  },
  email: {
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  editButton: {
    marginRight: 15,
  },
  icon: {
    fontSize: 19,
    marginRight: 10,
    verticalAlign: "middle",
  },
}));

export default useStyles;

import { EditIcon, InfoIcon, SpieleladenIcon, UsersIcon } from "modules/icons";
import UserPage from "modules/users/userDetails/UserPage";
import UserFormPage from "modules/users/userForm/UserFormPage";
import UserListPage from "modules/users/userlist/UserListPage";
import { USER_GROUPS } from "utils/constants";

export const users = {
  key: "users",
  path: "/users",
  section: "admin",
  title: "Benutzer",
  view: <UserListPage />,
  icon: <UsersIcon />,
  groups: [USER_GROUPS.ADMIN],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    usercreate: {
      key: "usercreate",
      path: "/users/create",
      section: "admin",
      title: "Benutzer anlegen",
      view: <UserFormPage formIntent="create" />,
      icon: <SpieleladenIcon />,
      groups: [USER_GROUPS.ADMIN],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    user: {
      key: "user",
      path: "/users/:userID",
      section: "admin",
      title: "Benutzer",
      view: <UserPage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        useredit: {
          key: "useredit",
          path: "/users/:userID/edit",
          section: "admin",
          title: "Benutzer bearbeiten",
          view: <UserFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

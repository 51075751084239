import React from "react";
import moment from "moment";
import { LastRunWebsiteTask } from "types";
import { CustomTag } from "core";

type LastRunColumnFormatterProps = {
  row: LastRunWebsiteTask;
};

export const LastRunColumnFormatter: React.FC<LastRunColumnFormatterProps> = ({
  row,
}) => {
  if (!row.task || !row.task.date) {
    return <CustomTag content="--" tagColor="red" />;
  }

  const pastDays = moment().diff(row.task.date, "days");

  return (
    <CustomTag
      content={pastDays.toString()}
      tagColor={
        pastDays < 30
          ? "green"
          : pastDays >= 30 && pastDays <= 90
            ? "yellow"
            : "red"
      }
    />
  );
};

import React, { useState } from "react";
import { usePermanentTaskDelete, usePermanentTaskRoutes } from "hooks";
import { PermanentTask } from "types";
import { DeleteDialog } from "components";

type PermanentTaskDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  permanentTask: PermanentTask | undefined;
};

export const PermanentTaskDeleteDialog: React.FC<
  PermanentTaskDeleteDialogProps
> = ({ permanentTask, dialogOpen, setDialogOpen }) => {
  const deletePermanentTaskMutation = usePermanentTaskDelete();
  const { navigateToPermanentTaskListPage } = usePermanentTaskRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || permanentTask === undefined) {
    return null;
  }

  const deletePermanentTaskRequest = async () => {
    setDeleteLoading(true);
    let deletedPermanentTask =
      await deletePermanentTaskMutation.mutateAsync(permanentTask);

    console.log("deletedPermanentTask: ", deletedPermanentTask);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigateToPermanentTaskListPage();
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deletePermanentTaskRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Daueraufgabe Löschen"
      itemName={permanentTask.taskType.name}
      itemDeleteDescription="die Daueraufgabe"
    />
  );
};

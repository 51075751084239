import React from "react";
import useStyles from "./styles";

type BoxContainerProps = {
  className?: string;
  children: React.ReactNode;
  inTab?: boolean;
  inBox?: boolean;
  boxWidth?: number | "full" | "xs" | "sm" | "md" | "lg" | "xl";
  boxMinWidth?: number | "full" | "xs" | "sm" | "md" | "lg" | "xl";
  boxAlignment?: "center" | "flex-start" | "flex-end";
  paddingHorizontal?: number;
  paddingVertical?: number;
  paddingTop?: boolean;
  style?: React.CSSProperties;
};

export const BoxContainer: React.FC<BoxContainerProps> = ({
  className,
  children,
  inTab = false,
  inBox = false,
  boxWidth = "full",
  boxMinWidth = "md",
  boxAlignment = "center",
  paddingHorizontal,
  paddingVertical,
  paddingTop = false,
  style,
}) => {
  const { classes, cx } = useStyles({
    inTab,
    inBox,
    boxWidth,
    boxMinWidth,
    boxAlignment,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
  });

  return (
    <div className={cx(classes.boxContainer, className)} style={style}>
      <div className={classes.boxContainerContent}>{children}</div>
    </div>
  );
};

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateEffortRuleInput, InfiniteEffortRuleData } from "types";
import { getEffortRule } from "modules/effortrules/api";

export const useEffortRuleCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateEffortRuleInput) => {
      const { data: newEffortRule } =
        await client.models.EffortRule.create(input);

      if (!newEffortRule) return null;

      const effortRule = await getEffortRule(newEffortRule.id);

      return effortRule;
    },
    // When mutate is called:
    onMutate: async (newEffortRule) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.EffortRules] });

      // Snapshot the previous value
      const previousEffortRules = queryClient.getQueryData([
        QueryKeys.EffortRules,
      ]);

      // Optimistically update to the new value
      if (previousEffortRules) {
        queryClient.setQueryData(
          [QueryKeys.EffortRules],
          (old: InfiniteEffortRuleData) => {
            const oldData = old?.pages
              ? old.pages.flatMap((page) => page.effortRuleList)
              : [];

            [...oldData, newEffortRule];
          },
        );
      }

      // Return a context object with the snapshotted value
      return { previousEffortRules };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newEffortRule, context) => {
      console.error("Error saving record:", err, newEffortRule);
      if (context?.previousEffortRules) {
        queryClient.setQueryData(
          [QueryKeys.EffortRules],
          context.previousEffortRules,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.EffortRules] });
    },
  });

  return createMutation;
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  messageIcon: {
    position: "absolute",
    width: 23,
    height: 23,
    marginRight: 10,
  },
  icon: {
    width: 23,
    height: 23,
  },
  closeButton: {
    position: "absolute",
    right: 15,
  },
  closeIcon: {
    width: 22,
    height: 22,
  },
  buttons: {
    marginRight: 30,
  },
  messageBox: {
    padding: "20px 30px 20px 20px",
    borderRadius: 7,
    marginBottom: 33,
    position: "relative",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.05),0px 6px 10px 0px rgba(0,0,0,0.1),0px 1px 18px 0px rgba(0,0,0,0.1)",
    "@media print": {
      display: "none",
    },
  },
  messageBoxSuccess: {
    backgroundColor: "#edf7ed",
    border: "1px solid #4caf50",
    color: "#4caf50",
  },
  messageBoxError: {
    backgroundColor: "#ffe9e9",
    border: "1px solid #d8afaf",
    color: "#992b2b",
  },
  messageBoxWarning: {
    backgroundColor: "#fff4e5",
    border: "1px solid #ff9800",
    color: "#ff9800",
  },
  messageBoxInfo: {
    backgroundColor: "#e8f4fd",
    border: "1px solid #3879ae",
    color: "#3879ae",
  },
  messageBody: {},
  marginLeft: {
    marginLeft: 40,
  },
}));

export default useStyles;

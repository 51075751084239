import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  videoWrapper: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  video: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export default useStyles;

import { CustomerPage } from "modules/customers/customerDetails/CustomerPage";
import { CustomerListPage } from "modules/customers/customerlist/CustomerListPage";
import { CompanyIcon } from "modules/icons";
import { customerbookings } from "./customers/customerbookings";
import { customerwebsites } from "./customers/customerwebsites";
import { USER_GROUPS } from "utils/constants";

export const customers = {
  key: "customers",
  path: "/customers",
  section: "verwaltung",
  title: "Kunden",
  view: <CustomerListPage />,
  icon: <CompanyIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    customer: {
      key: "customer",
      path: "/customers/:customerID",
      section: "customer",
      title: "Kunde",
      view: <CustomerPage />,
      icon: <CompanyIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        customerbookings: customerbookings,
        customerwebsites: customerwebsites,
      },
    },
  },
};

import React from "react";
import { useTemplateList, useTemplateRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { TemplateListFilter } from "../TemplateListFilter";
import { TemplateListTable } from "../TemplateListTable";

export const TemplateListPage: React.FC = () => {
  const { TemplateIcon, navigateToCreateTemplatePage } = useTemplateRoutes();

  const { templateList, isLoading, hasNextPage, fetchNextPage } =
    useTemplateList();

  return (
    <BoxHeadlineContainer
      boxTitle="Templates"
      boxIcon={TemplateIcon}
      boxSubTitle={templateList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neues Template"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateTemplatePage()}
        />
      }
    >
      <TemplateListFilter />
      <TemplateListTable
        listIntent="list"
        templateList={templateList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

import { generateClient } from "aws-amplify/data";
import { QueryClient } from "@tanstack/react-query";
import type { Schema } from "../amplify/data/resource";

export const queryClient = new QueryClient();

export const client = generateClient<Schema>();

export type { Schema } from "../amplify/data/resource";

export enum QueryKeys {
  Users = "users",
  Bookings = "bookings",
  Customers = "customers",
  Tickets = "tickets",
  Employees = "employees",
  Systems = "systems",
  Hosters = "hosters",
  TaskTypes = "taskTypes",
  Templates = "templates",
  Websites = "websites",
  ReportEntries = "reportEntries",
  Tasks = "tasks",
  PermanentTasks = "permanentTasks",
  EffortDefinitions = "effortDefinitions",
  EffortRules = "effortRules",
  CognitoUsers = "cognitoUsers",
  Expansions = "expansions",
  Products = "products",
  ProductNames = "productNames",
  ExpansionProducts = "expansionProducts",
  GlobalSettings = "globalSettings",
  CalcTasks = "calcTasks",
  StockTasks = "stockTasks",
  SyncTasks = "syncTasks",
  PriceCalcs = "priceCalcs",
}

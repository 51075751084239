import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { GlobalSettings, UpdateGlobalSettingsInput } from "types";
import { TextInputField, TextInputFieldHandles } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { useGlobalSettingsForm } from "../useGlobalSettingsForm";
import useStyles from "./styles";

type GlobalSettingsFormProps = {
  globalSettings: GlobalSettings;
};

export type GlobalSettingsFormHandles = {
  validateGlobalSettingsForm(): UpdateGlobalSettingsInput | null;
};

const GlobalSettingsFormComponent: React.ForwardRefRenderFunction<
  GlobalSettingsFormHandles,
  GlobalSettingsFormProps
> = ({ globalSettings }, calculationFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { currentVersion, setCurrentVersion } =
    useGlobalSettingsForm(globalSettings);

  const currentVersionInputRef = useRef<TextInputFieldHandles>(null);

  useImperativeHandle(calculationFormRef, () => ({
    validateGlobalSettingsForm: () => {
      if (currentVersion === "") {
        enqueueSnackbar("Bitte gib die aktuelle Version der Software ein!");
        currentVersionInputRef.current?.highlight();
        return null;
      }

      const updateGlobalSettingsInput: UpdateGlobalSettingsInput = {
        id: globalSettings.id,
        currentVersion: currentVersion,
      };

      return updateGlobalSettingsInput;
    },
  }));

  return (
    <BoxHeadlineContainer boxTitle="Preisberechnung" marginTop={false}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className={classes.gridRow}
      >
        <Grid item md={6}>
          <TextInputField
            label="Aktuelle Version"
            value={currentVersion}
            onChange={({ target: { value } }) => setCurrentVersion(value)}
            validate={(value) => value.trim() !== ""}
            required={true}
            ref={currentVersionInputRef}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

export const GlobalSettingsForm = React.memo(
  React.forwardRef(GlobalSettingsFormComponent),
);

import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ResetFilterIcon } from "modules/icons";
import useStyles from "./styles";

export const ResetFilter: React.FC = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();

  return (
    <IconButton
      onClick={() => {
        sessionStorage.setItem(pathname, "");
        setSearchParams([], {
          replace: true,
        });
      }}
      className={classes.filterResetButton}
    >
      <ResetFilterIcon className={classes.filterResetIcon} />
    </IconButton>
  );
};

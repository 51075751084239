import React from "react";
import { useSystemList } from "hooks";
import { System } from "types";
import { CustomDialog } from "core";
import { SystemListFilter } from "../../systemlist/SystemListFilter";
import { SystemListTable } from "../../systemlist/SystemListTable";

type SystemSelectionDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  systemSelectionHandler: (system: System) => void;
};

export const SystemSelectionDialog: React.FC<SystemSelectionDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  systemSelectionHandler,
}) => {
  const { systemList, isLoading, hasNextPage, fetchNextPage } = useSystemList();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={false}
      titleText="System auswählen"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={false}
      fullWidth={true}
      maxWidth="lg"
      contentPadding={0}
    >
      <SystemListFilter />
      <SystemListTable
        listIntent="selection"
        systemList={systemList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        systemSelectionHandler={systemSelectionHandler}
      />
    </CustomDialog>
  );
};

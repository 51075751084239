import React from "react";
import { useCustomerList, useCustomerRoutes } from "hooks";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { CustomerListFilter } from "../CustomerListFilter";
import { CustomerListTable } from "../CustomerListTable";

export const CustomerListPage: React.FC = () => {
  const { CustomerIcon } = useCustomerRoutes();

  const { customerList, isLoading, hasNextPage, fetchNextPage } =
    useCustomerList();

  return (
    <BoxHeadlineContainer
      boxTitle="Kunden Liste"
      boxIcon={CustomerIcon}
      boxSubTitle={customerList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
    >
      <CustomerListFilter />
      <CustomerListTable
        listIntent="list"
        customerList={customerList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

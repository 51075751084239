import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  activity: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 120,
      width: 3,
      top: 20,
      bottom: 30,
      backgroundColor: theme.palette.primary.C200,
    },
    "@media (min-width: 1920px)": {
      width: 1600,
    },
    "@media (min-width: 1600px) and (max-width: 1919.95px)": {
      width: 1440,
    },
    "@media (min-width: 1440px) and (max-width: 1599.95px)": {
      width: 1200,
    },
    "@media (min-width: 1280px) and (max-width: 1439.95px)": {
      width: 1024,
    },
    "@media (min-width: 1024px) and (max-width: 1279.95px)": {
      width: 800,
    },
    "@media (min-width: 768px) and (max-width: 1023.95px)": {
      width: 600,
    },
  },
  activityItem: {
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
    "&:not(:last-child)": {
      marginBottom: 75,
    },
  },
  activityItemLabel: {
    width: 110,
    flexShrink: 0,
    position: "relative",
    color: theme.palette.primary.dark,
    fontWeight: 600,
    lineHeight: "20px",
    marginTop: 15,
  },
  activityItemSubLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  activityItemIcon: {
    flexShrink: 0,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    position: "relative",
    marginTop: 15,
    marginLeft: "-0.5rem",
    padding: "5px !important",
    color: theme.palette.primary.dark,
    backgroundColor: "#edf2f1",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primaryGrey.C400,
    borderRadius: 30,
  },
  activityItemContent: {
    paddingLeft: "20px !important",
    width: "100%",
  },
  activityItemHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#edf2f1",
    borderRadius: 7,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primaryGrey.C400,
    padding: "5px 20px",
  },
  activityItemBody: {
    padding: "5px 20px",
  },
  bodyOpenButton: {
    // border: "1px double #b8c4c1",
    borderWidth: 1,
    borderStyle: "double",
    borderColor: theme.palette.primaryGrey.C700,
    color: theme.palette.primary.main,
    "& svg": {
      width: 29,
      height: 29,
      transition: "all 0.3s ease",
      marginTop: 1,
    },
  },
  bodyOpenButtonExpanded: {
    "& svg": {
      transform: "rotate(180deg)",
      marginTop: 0,
    },
  },
  loadMoreButton: {
    display: "flex !important",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 30,
  },
  loadAllButton: {
    marginTop: 33,
    marginBottom: 33,
    marginLeft: 33,
  },
  loadButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;

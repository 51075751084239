export const getPricePerKGFromPricePer100 = (
  pricePer100: number,
  weight: number
) => {
  const pricePerItem = pricePer100 / 100;
  const itemsTo1KG = 1000 / weight;
  return pricePerItem * itemsTo1KG;
};

export const getPricePer100FromPricePerKG = (
  pricePerKG: number,
  weight: number
) => {
  const itemsTo1KG = 1000 / weight;
  const pricePerItem = pricePerKG / itemsTo1KG;
  return pricePerItem * 100;
};

import { EditIcon, InfoIcon, TaskIcon } from "modules/icons";
import { TaskPage } from "modules/tasks/taskDetails/TaskPage";
import { TaskFormPage } from "modules/tasks/taskForm/TaskFormPage";
import { TaskListPage } from "modules/tasks/tasklist/TaskListPage";
import { USER_GROUPS } from "utils/constants";

export const tasks = {
  key: "tasks",
  path: "/tasks",
  section: "verwaltung",
  title: "Pauschalaufgaben",
  view: <TaskListPage />,
  icon: <TaskIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    taskcreate: {
      key: "taskcreate",
      path: "/tasks/create",
      section: "general",
      title: "Pauschalaufgabe anlegen",
      view: <TaskFormPage formIntent="create" />,
      icon: <TaskIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    task: {
      key: "task",
      path: "/tasks/:taskID",
      section: "task",
      title: "Pauschalaufgabe",
      view: <TaskPage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        taskedit: {
          key: "taskedit",
          path: "/tasks/:taskID/edit",
          section: "task",
          title: "Pauschalaufgabe bearbeiten",
          view: <TaskFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

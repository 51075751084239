import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllTaskList } from "modules/tasks/api";
import { useTaskListFilterAndSort } from "../useTaskListFilterAndSort";

export const useTaskAllList = (filter = true) => {
  const filterAndSortTasks = useTaskListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Tasks, "all"],
    queryFn: async () => {
      const { taskList } = await getAllTaskList();

      return taskList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const taskList = useMemo(
    () => filterAndSortTasks(data ?? []),
    [data, filterAndSortTasks],
  );

  return {
    taskList,
    isLoading,
  };
};

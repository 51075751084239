import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  deleteApproveText: {
    fontSize: 17,
    fontWeight: 500,
    marginBottom: 30,
    textAlign: "center",
  },
}));

export default useStyles;

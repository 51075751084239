import BookingPage from "modules/bookings/bookingDetails/BookingPage";
import { BookingFormPage } from "modules/bookings/bookingForm/BookingFormPage";
import { BookingListPage } from "modules/bookings/bookinglist/BookingListPage";
import { CareBookingIcon, EditIcon, InfoIcon } from "modules/icons";
import { USER_GROUPS } from "utils/constants";

export const bookings = {
  key: "bookings",
  path: "/bookings",
  section: "verwaltung",
  title: "Pflegebuchungen",
  view: <BookingListPage routeKey="bookings" />,
  icon: <CareBookingIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: true,
  isWithParam: false,
  subNavigations: {
    bookingcreate: {
      key: "bookingcreate",
      path: "/bookings/create",
      section: "general",
      title: "Pflegebuchung anlegen",
      view: <BookingFormPage formIntent="create" routeKey="bookings" />,
      icon: <CareBookingIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    booking: {
      key: "booking",
      path: "/bookings/:bookingID",
      section: "booking",
      title: "Pflegebuchung",
      view: <BookingPage routeKey="bookings" />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        bookingedit: {
          key: "bookingedit",
          path: "/bookings/:bookingID/edit",
          section: "booking",
          title: "Pflegebuchung bearbeiten",
          view: <BookingFormPage formIntent="edit" routeKey="bookings" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

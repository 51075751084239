import React from "react";
import { IconButton } from "@mui/material";
import { useAuthContext } from "context";
import { LockMenuIcon } from "modules/icons";
import useStyles from "./styles";

export const SidebarLockButton: React.FC = () => {
  const authContext = useAuthContext();
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(
        classes.sidebarLockButton,
        authContext.menuOpen ? null : classes.sidebarLockButtonClosed
      )}
    >
      <IconButton
        onClick={() => authContext.setMenuLocked(!authContext.menuLocked)}
        className={cx(
          classes.iconButton,
          authContext.menuOpen ? null : classes.menuClosed
        )}
      >
        <LockMenuIcon
          className={cx(
            classes.lockIcon,
            authContext.menuLocked ? classes.menuLocked : classes.menuUnlocked
          )}
        />
      </IconButton>
    </div>
  );
};

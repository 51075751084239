import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  sidebarLockButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 10,
    position: "fixed",
    top: 65,
    left: 260,
    zIndex: 20,
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarLockButtonClosed: {
    left: 30,
  },
  iconButton: {
    padding: 3,
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&:hover svg": {
      color: theme.palette.primary.C200,
    },
  },
  menuClosed: {
    opacity: 0,
  },
  lockIcon: {
    width: 25,
    height: 25,
  },
  menuLocked: {
    color: theme.palette.primary.main,
  },
  menuUnlocked: {
    color: theme.palette.blueGrey.main,
  },
}));

export default useStyles;

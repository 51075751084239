import React, { useMemo } from "react";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import {
  CustomReportEntryType,
  WebsiteReportType,
} from "modules/carereport/api";
import WebsiteReportListTable from "../WebsiteReportListTable";

type WebsiteReportListCardProps = {
  websiteReports: WebsiteReportType[];
};

const WebsiteReportListCard: React.FC<WebsiteReportListCardProps> = ({
  websiteReports,
}) => {
  const customReportEntryList = useMemo(() => {
    const ticketEntries: CustomReportEntryType[] = websiteReports
      .flatMap((report) => report.ticketList)
      .map((ticket) => {
        const ticketEntry: CustomReportEntryType = {
          entryType: "Ticket",
          ticket: ticket.subject,
          task: "",
          type: ticket.classification ?? "ticket",
          effort: ticket.cf_aufwand_in_minuten
            ? parseInt(ticket.cf_aufwand_in_minuten)
            : 0,
        };

        return ticketEntry;
      });

    const taskEntries: CustomReportEntryType[] = websiteReports
      .flatMap((report) => report.taskList)
      .map((task) => {
        const taskEntry: CustomReportEntryType = {
          entryType: "Pauschalaufgabe",
          ticket: "",
          task: task.website.url,
          type: task.taskType.name,
          effort: task.effort,
        };

        return taskEntry;
      });

    return [...ticketEntries, ...taskEntries];
  }, [websiteReports]);

  return (
    <BoxHeadlineContainer
      boxTitle="Zu buchende Pauschalaufgaben und Tickets"
      paddingHorizontal={0}
      paddingVertical={0}
    >
      <WebsiteReportListTable customReportEntryList={customReportEntryList} />
    </BoxHeadlineContainer>
  );
};

export default WebsiteReportListCard;

import { useLayoutEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export function useSaveFilterParams() {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    const savedFilterParamsFromStorage = sessionStorage.getItem(
      location.pathname,
    );

    if (savedFilterParamsFromStorage) {
      const newSearchParams = new URLSearchParams(savedFilterParamsFromStorage);
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Hoster,
  HosterTypeKeys,
  SearchTextParamName,
  SortDirection,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<Hoster>(HosterTypeKeys);

export const useHosterListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Hoster>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Hoster, string>> = {};

    const filters: FilterField<Hoster>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof Hoster,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<Hoster>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Hoster,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Hoster, SortDirection>> = {
      name: SortDirection.asc,
    };

    return getSortFields<Hoster>(searchParams.get("sort"), defaultSorts);
  }, [searchParams]);

  const filterAndSortHosters = useCallback(
    (
      hosterList: Hoster[],
      customSortFunctions: Partial<
        Record<
          keyof Hoster,
          (a: Hoster, b: Hoster, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof Hoster, (entity: Hoster, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<Hoster>(
        filter
          ? filterEntities<Hoster>(
              hosterList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchHoster),
              filterEntityByField<Hoster>,
              HosterTypeKeys,
              customFilterFunctions,
            )
          : hosterList,
        sortFields,
        sortEntitiesByField<Hoster>,
        HosterTypeKeys,
        customSortFunctions,
      ).map((hoster) => ({
        ...hoster,
        updatedAt: new Date(
          new Date(hoster.updatedAt ?? new Date().getTime()).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortHosters;
};

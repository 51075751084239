import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useAuthContext } from "context";
import { RouteArrayType, checkRouteIsSelected } from "routes";
import useStyles from "./styles";

type SidebarSubmenuLinkProps = {
  route: RouteArrayType;
  param1Name?: string;
  param1Value?: string;
  param2Name?: string;
  param2Value?: string;
  param3Name?: string;
  param3Value?: string;
  inSubmenuList: boolean;
};

const getPath = (
  initPath: string,
  param1Name?: string,
  param1Value?: string,
  param2Name?: string,
  param2Value?: string,
  param3Name?: string,
  param3Value?: string,
) => {
  let path = initPath;

  if (param1Name && param1Value) {
    path = path.replace(param1Name, param1Value);
  }
  if (param2Name && param2Value) {
    path = path.replace(param2Name, param2Value);
  }
  if (param3Name && param3Value) {
    path = path.replace(param3Name, param3Value);
  }

  const savedFilterParamsFromStorage = sessionStorage.getItem(path);

  if (savedFilterParamsFromStorage) {
    path = path + "?" + savedFilterParamsFromStorage;
  }

  return path;
};

export const SidebarSubmenuLink: React.FC<SidebarSubmenuLinkProps> = ({
  route,
  param1Name,
  param1Value,
  param2Name,
  param2Value,
  param3Name,
  param3Value,
  inSubmenuList,
}) => {
  const authContext = useAuthContext();
  const { classes, cx } = useStyles();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isSelected, setIsSelected] = useState<boolean>(
    checkRouteIsSelected(pathname, route),
  );

  useEffect(() => {
    const routeIsSelected = checkRouteIsSelected(pathname, route);
    setIsSelected(routeIsSelected);
  }, [pathname, route]);

  return (
    <ListItem
      className={cx(
        classes.listItem,
        isSelected ? classes.listItemSelected : null,
        route.indented ? classes.indented : null,
        authContext.menuOpen
          ? null
          : inSubmenuList
            ? classes.listItemClosedInSubmenu
            : classes.listItemClosed,
      )}
      onClick={() =>
        navigate(
          getPath(
            route.path,
            param1Name,
            param1Value,
            param2Name,
            param2Value,
            param3Name,
            param3Value,
          ),
        )
      }
      // key={route.key}
    >
      {route.icon && (
        <ListItemIcon className={classes.listItemIconBefore}>
          {route.icon}
        </ListItemIcon>
      )}

      <ListItemText
        classes={{
          primary: cx(
            classes.listItemText,
            authContext.menuOpen ? null : classes.menuClosed,
          ),
        }}
      >
        {route.title}
      </ListItemText>
    </ListItem>
  );
};

import React, { useState } from "react";
import { SelectOption } from "types";
import { CustomButton, CustomSelect } from "core";
import { LogoRotation } from "components";
import { BoxContainerFilter } from "layout/Container/BoxContainerFilter";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { ListContainer } from "layout/List/ListContainer";
import { ListItem } from "layout/List/ListItem";
import { SALUTATION_OPTIONS } from "utils/constants";
import useStyles from "./styles";

const Dashboard: React.FC = () => {
  const { classes } = useStyles();

  const [language, setLanguage] = useState<SelectOption | null>(null);

  return (
    <>
      <LogoRotation />
      <div className={classes.dashboardPage}>
        <BoxHeadlineContainer
          boxTitle="BoxTitledContainer"
          boxTitleSize="small"
          boxMenu={
            <>
              <BoxContainerFilter>
                <CustomSelect
                  id="test"
                  placeholder="Test"
                  options={SALUTATION_OPTIONS}
                  value={language}
                  onChange={(option) => setLanguage(option!)}
                />
              </BoxContainerFilter>
              <BoxContainerFilter>
                <CustomSelect
                  id="test"
                  placeholder="Test"
                  options={SALUTATION_OPTIONS}
                  value={language}
                  onChange={(option) => setLanguage(option!)}
                  selectStyle="solid"
                />
              </BoxContainerFilter>
              <BoxContainerFilter>
                <div className={classes.tag}>
                  Fee: <strong>15%</strong>
                </div>
              </BoxContainerFilter>
            </>
          }
        >
          Test
        </BoxHeadlineContainer>
      </div>
      <div className={classes.dashboardPage}>
        <BoxHeadlineContainer
          boxTitle="BoxTitledContainer"
          boxIcon={<i className="sl sl-icon-book-open"></i>}
          boxTitleSize="big"
          paddingHorizontal={0}
          paddingVertical={0}
          boxBackground={true}
          boxDisabled={true}
          boxDisabledText="Dieses Box ist deaktiviert, weil..."
          boxMenu={
            <>
              <BoxContainerFilter>
                <CustomSelect
                  id="test"
                  placeholder="Test"
                  options={SALUTATION_OPTIONS}
                  value={language}
                  onChange={(option) => setLanguage(option!)}
                />
              </BoxContainerFilter>
              <BoxContainerFilter>
                <CustomSelect
                  id="test"
                  placeholder="Test"
                  options={SALUTATION_OPTIONS}
                  value={language}
                  onChange={(option) => setLanguage(option!)}
                  selectStyle="solid"
                />
              </BoxContainerFilter>
              <BoxContainerFilter>
                <div className={classes.tag}>
                  Fee: <strong>15%</strong>
                </div>
              </BoxContainerFilter>
            </>
          }
        >
          <ListContainer>
            <ListItem
              onClick={() => {
                console.log("Clicked...");
              }}
              buttons={
                <>
                  <CustomButton
                    size="small"
                    color="grey"
                    hoverColor="red"
                    iconBefore={<i className="sl sl-icon-trash"></i>}
                    text="Hinzufügen"
                    onClick={() => {
                      console.log("Clicked flatRed");
                    }}
                  />
                  <CustomButton
                    size="small"
                    color="blue"
                    style="outlined"
                    hoverColor="blue"
                    iconBefore={<i className="sl sl-icon-close"></i>}
                    text="Hinzufügen"
                    onClick={() => {
                      console.log("Clicked blue");
                    }}
                  />
                  <CustomButton
                    size="small"
                    color="green"
                    style="outlined"
                    hoverColor="green"
                    iconBefore={<i className="sl sl-icon-close"></i>}
                    text="Hinzufügen"
                    onClick={() => {
                      console.log("Clicked green");
                    }}
                  />
                  <CustomButton
                    size="small"
                    color="flatRed"
                    style="outlined"
                    hoverColor="flatRed"
                    iconBefore={<i className="sl sl-icon-trash"></i>}
                    text="Hinzufügen"
                    onClick={() => {
                      console.log("Clicked flatRed");
                    }}
                  />
                </>
              }
            >
              Test
            </ListItem>
            <ListItem>Test</ListItem>
          </ListContainer>
        </BoxHeadlineContainer>
      </div>
    </>
  );
};

export default Dashboard;

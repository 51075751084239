import React from "react";
import { useHosterList } from "hooks";
import { Hoster } from "types";
import { CustomDialog } from "core";
import { HosterListFilter } from "../../hosterlist/HosterListFilter";
import { HosterListTable } from "../../hosterlist/HosterListTable";

type HosterSelectionDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  hosterSelectionHandler: (hoster: Hoster) => void;
};

export const HosterSelectionDialog: React.FC<HosterSelectionDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  hosterSelectionHandler,
}) => {
  const { hosterList, isLoading, hasNextPage, fetchNextPage } = useHosterList();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={false}
      titleText="Hoster auswählen"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={false}
      fullWidth={true}
      maxWidth="lg"
      contentPadding={0}
    >
      <HosterListFilter />
      <HosterListTable
        listIntent="selection"
        hosterList={hosterList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        hosterSelectionHandler={hosterSelectionHandler}
      />
    </CustomDialog>
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  checkmark: {
    width: 27,
    height: 27,
    color: "#79B702",
    verticalAlign: "middle",
  },
}));

export default useStyles;

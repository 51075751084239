import React, { useContext, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useTemplateCreate,
  useTemplateRead,
  useTemplateRoutes,
  useTemplateUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { TemplateForm, TemplateFormHandles } from "../TemplateForm";
import useStyles from "./styles";

type TemplateFormPageProps = {
  formIntent: "create" | "edit";
};

export const TemplateFormPage: React.FC<TemplateFormPageProps> = ({
  formIntent,
}) => {
  const { classes } = useStyles();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { template, isLoading } = useTemplateRead();
  const createTemplateMutation = useTemplateCreate();
  const updateTemplateMutation = useTemplateUpdate();
  const { navigateToTemplatePage, navigateToTemplateListPage } =
    useTemplateRoutes();

  const templateFormRef = useRef<TemplateFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitTemplate = async () => {
    const templateFormInput =
      await templateFormRef.current?.validateTemplateForm();
    if (!templateFormInput) {
      console.error("TemplateForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Templates ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("templateInput: ", templateFormInput);

    const mutateTemplateRequest = template
      ? await updateTemplateMutation.mutateAsync({
          ...templateFormInput,
          id: template.id,
        })
      : await createTemplateMutation.mutateAsync(templateFormInput);

    if (!mutateTemplateRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Templates ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutateTemplateRequest: ", mutateTemplateRequest);

    setSubmitLoading(false);
    navigateToTemplatePage(mutateTemplateRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Template " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Template wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Template " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <TemplateForm
        template={template}
        formIntent={formIntent}
        ref={templateFormRef}
      />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Template " + (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitTemplate()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => navigateToTemplateListPage()}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

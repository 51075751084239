import React from "react";
import { Breadcrumbs } from "../Breadcrumbs";
import { HeaderContainer } from "./HeaderContainer";
import { HeaderLeft } from "./HeaderLeft";
import { HeaderMenu } from "./HeaderMenu";
import { HeaderRight } from "./HeaderRight";

type HeaderProps = {
  layoutType: "layout" | "authLayout";
};

export const Header: React.FC<HeaderProps> = ({ layoutType }) => {
  return (
    <HeaderContainer layoutType={layoutType}>
      <HeaderLeft layoutType={layoutType}>
        <Breadcrumbs />
      </HeaderLeft>
      <HeaderRight>
        <HeaderMenu />
      </HeaderRight>
    </HeaderContainer>
  );
};

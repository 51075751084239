import React from "react";
import { S3Resource } from "types";
import { CustomDialog } from "core";
import useStyles from "./styles";

type DeleteImageDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  setImage?: (value: React.SetStateAction<S3Resource | null>) => void;
  setImages?: (value: React.SetStateAction<S3Resource[]>) => void;
  image: S3Resource;
  dialogTitle?: string;
};

export const DeleteImageDialog: React.FC<DeleteImageDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  setImage,
  setImages,
  image,
  dialogTitle = "Bild Löschen",
}) => {
  const { classes } = useStyles();

  if (!dialogOpen) {
    return null;
  }

  const deleteRequest = () => {
    setImages &&
      setImages((prev) =>
        prev.filter(
          (imageItem) => imageItem.s3ResourceID !== image.s3ResourceID,
        ),
      );
    setImage && setImage(null);
    setDialogOpen(false);
  };

  return (
    <CustomDialog
      confirmText="Löschen"
      dialogOpen={dialogOpen}
      positive={false}
      titleText={dialogTitle}
      setDialogOpen={setDialogOpen}
      confirmAction={() => deleteRequest()}
      showConfirm={true}
      showDecline={true}
      maxWidth="md"
    >
      {image.extension.includes("svg") && image.fromDatabase ? (
        <span
          dangerouslySetInnerHTML={{
            __html: "<svg" + image.preview.split("<svg")[1],
          }}
          className={classes.imgPreview}
        ></span>
      ) : (
        <img alt={""} src={image.preview} className={classes.imgPreview} />
      )}
    </CustomDialog>
  );
};

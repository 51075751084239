import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getHoster } from "modules/hosters/api";

type HosterReadParamsType = {
  hosterID: string;
};

export const useHosterRead = () => {
  const authContext = useAuthContext();

  const { hosterID } = useParams<HosterReadParamsType>();

  console.log("Loading hoster with ID: ", hosterID);

  const { data: hoster, isLoading } = useQuery({
    queryKey: [QueryKeys.Hosters, hosterID],
    queryFn: async () => {
      if (!hosterID) return null;

      const hosterFromAPI = await getHoster(hosterID);

      if (hosterFromAPI) {
        authContext.setHoster(hosterFromAPI);

        authContext.setCurrentData({
          id: hosterFromAPI.id,
          name: hosterFromAPI.name,
        });
      }

      return hosterFromAPI;
    },
  });

  return {
    hosterID,
    hoster: hoster ?? null,
    isLoading,
  };
};

import { EditIcon, InfoIcon, PermanentTaskIcon } from "modules/icons";
import { PermanentTaskPage } from "modules/permanenttasks/permanenttaskDetails/PermanentTaskPage";
import { PermanentTaskFormPage } from "modules/permanenttasks/permanenttaskForm/PermanentTaskFormPage";
import { PermanentTaskListPage } from "modules/permanenttasks/permanenttasklist/PermanentTaskListPage";
import { USER_GROUPS } from "utils/constants";

export const permanenttasks = {
  key: "permanenttasks",
  path: "/permanenttasks",
  section: "verwaltung",
  title: "Daueraufgaben",
  view: <PermanentTaskListPage />,
  icon: <PermanentTaskIcon />,
  groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
  level: 0,
  navigation: true,
  indented: false,
  isWithParam: false,
  subNavigations: {
    permanenttaskcreate: {
      key: "permanenttaskcreate",
      path: "/permanenttasks/create",
      section: "general",
      title: "Daueraufgabe anlegen",
      view: <PermanentTaskFormPage formIntent="create" />,
      icon: <PermanentTaskIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: false,
      subNavigations: {},
    },
    permanenttask: {
      key: "permanenttask",
      path: "/permanenttasks/:permanentTaskID",
      section: "permanenttask",
      title: "Daueraufgabe",
      view: <PermanentTaskPage />,
      icon: <InfoIcon />,
      groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
      level: 1,
      navigation: false,
      indented: false,
      isWithParam: true,
      subNavigations: {
        permanenttaskedit: {
          key: "permanenttaskedit",
          path: "/permanenttasks/:permanentTaskID/edit",
          section: "permanenttask",
          title: "Daueraufgabe bearbeiten",
          view: <PermanentTaskFormPage formIntent="edit" />,
          icon: <EditIcon />,
          groups: [USER_GROUPS.ADMIN, USER_GROUPS.OFFICE],
          level: 2,
          navigation: true,
          indented: false,
          isWithParam: false,
          subNavigations: {},
        },
      },
    },
  },
};

import React, { useState } from "react";
import { useEffortRuleDelete, useEffortRuleRoutes } from "hooks";
import { EffortRule } from "types";
import { DeleteDialog } from "components";

type EffortRuleDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  effortRule: EffortRule | undefined;
  routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules";
};

export const EffortRuleDeleteDialog: React.FC<EffortRuleDeleteDialogProps> = ({
  effortRule,
  dialogOpen,
  setDialogOpen,
  routeKey,
}) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const deleteEffortRuleMutation = useEffortRuleDelete();
  const { navigateToEffortRuleListPage } = useEffortRuleRoutes();

  if (!dialogOpen || effortRule === undefined) {
    return null;
  }

  const deleteEffortRuleRequest = async () => {
    setDeleteLoading(true);

    const deletedEffortRule =
      await deleteEffortRuleMutation.mutateAsync(effortRule);

    console.log("deletedEffortRule: ", deletedEffortRule);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigateToEffortRuleListPage(routeKey);
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteEffortRuleRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Aufwandsregel Löschen"
      itemName={effortRule.taskType.name}
      itemDeleteDescription="die Aufwandsregel"
    />
  );
};

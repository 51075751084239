import React from "react";
import { useTaskList, useTaskRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { TaskListFilter } from "../TaskListFilter";
import { TaskListTable } from "../TaskListTable";

export const TaskListPage: React.FC = () => {
  const { TaskIcon, navigateToCreateTaskPage } = useTaskRoutes();

  const { taskList, isLoading, hasNextPage, fetchNextPage } = useTaskList();

  return (
    <BoxHeadlineContainer
      boxTitle="Pauschalaufgaben"
      boxIcon={TaskIcon}
      boxSubTitle={taskList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neue Pauschalaufgabe"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateTaskPage()}
        />
      }
    >
      <TaskListFilter />
      <TaskListTable
        listIntent="list"
        taskList={taskList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

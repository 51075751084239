import React from "react";
import { Grid, Typography } from "@mui/material";
import { useCustomerRead } from "hooks";
import { LabeledTypography, Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import useStyles from "./styles";

export const CustomerPage: React.FC = () => {
  const { classes } = useStyles();

  const { customer, isLoading } = useCustomerRead();

  if (isLoading) {
    return (
      <Loading
        className={classes.loading}
        description="Bitte warten. Customer wird geladen... "
        size="25px"
      />
    );
  }

  if (customer === undefined || customer === null) {
    return (
      <BoxHeadlineContainer boxTitle="Customer Details">
        Customer nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        !isLoading && customer ? customer.Account_Name : "Kunden Details"
      }
      boxTitleSize="big"
    >
      <Typography className={classes.sectionTitle} variant="h3">
        Kunde
      </Typography>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item className={classes.gridItem}>
          <LabeledTypography label="Kunde" content={customer.Account_Name} />
        </Grid>

        <Grid item className={classes.gridItem}>
          <LabeledTypography
            label="Webseite"
            content={
              customer.Website ? (
                customer.Website
              ) : (
                <span className={classes.notAvailable}>N/A</span>
              )
            }
          />
        </Grid>

        <Grid item className={classes.gridItem}>
          <LabeledTypography
            label="Rechnungsempfänger"
            content={
              customer.Rechnungsempf_nger ? (
                customer.Rechnungsempf_nger
              ) : (
                <span className={classes.notAvailable}>N/A</span>
              )
            }
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridRow} container direction="row">
        <Grid item className={classes.gridItem}>
          <LabeledTypography
            label="Telefonnummer"
            content={
              customer.Phone ? (
                customer.Phone
              ) : (
                <span className={classes.notAvailable}>N/A</span>
              )
            }
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <LabeledTypography
            label="E-Mail-Adresse"
            content={
              customer.Firmen_E_Mail ? (
                customer.Firmen_E_Mail
              ) : (
                <span className={classes.notAvailable}>N/A</span>
              )
            }
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <LabeledTypography
            label="Ort"
            content={
              customer.Billing_City ? (
                customer.Billing_City
              ) : (
                <span className={classes.notAvailable}>N/A</span>
              )
            }
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

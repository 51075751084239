import React from "react";
import { useMediaQuery } from "@mui/material";
import useStyles from "./styles";

type RowActionsProps = {
  children: React.ReactNode;
  minWidth?: number | "unset";
  permanentVisible?: boolean;
  style?: React.CSSProperties;
};

export const RowActions: React.FC<RowActionsProps> = ({
  children,
  minWidth = 100,
  permanentVisible = false,
  style,
}) => {
  const { classes, cx } = useStyles();

  const isDesktop = useMediaQuery("@media (pointer: fine)");

  return (
    <div
      style={{ minWidth: minWidth, ...style }}
      className={cx(
        classes.rowActions,
        permanentVisible
          ? classes.permanentVisible
          : !isDesktop
          ? classes.permanentVisible
          : classes.visibleOnHover,
        "table-row-actions"
      )}
    >
      {children}
    </div>
  );
};

import { useEffect, useState } from "react";

export function usePersistedState<T>(
  key: string,
  defaultValue: T,
  formIntent: "create" | "edit"
) {
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return formIntent === "create" && storedValue !== null
      ? (JSON.parse(storedValue) as T)
      : (defaultValue as T);
  });

  useEffect(() => {
    if (formIntent === "create") {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [formIntent, key, state]);

  return [state, setState] as const;
}

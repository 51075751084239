import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateHosterInput, Hoster } from "types";
import {
  CustomNumberField,
  CustomNumberFieldHandles,
  Editor,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { useHosterForm } from "../useHosterForm";
import utils from "utils";
import useStyles from "./styles";

type HosterFormProps = {
  formIntent: "create" | "edit";
  hoster: Hoster | null | undefined;
};

export type HosterFormHandles = {
  validateHosterForm(): CreateHosterInput | null;
};

const HosterFormComponent: React.ForwardRefRenderFunction<
  HosterFormHandles,
  HosterFormProps
> = ({ formIntent, hoster }, hosterFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    name,
    setName,
    rating,
    setRating,
    description,
    setDescription,
    resetHosterForm,
  } = useHosterForm(formIntent, hoster);

  const nameInputRef = useRef<TextInputFieldHandles>(null);
  const ratingInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(hosterFormRef, () => ({
    validateHosterForm: () => {
      if (name === "") {
        enqueueSnackbar("Bitte gib einen Namen ein!");
        nameInputRef.current?.highlight();
        return null;
      }

      if (rating === "") {
        enqueueSnackbar("Bitte gib eine Bewertung ein!");
        ratingInputRef.current?.highlight();
        return null;
      }

      const hosterFormInput: CreateHosterInput = {
        name: name,
        rating: rating,
        description: utils.wysiwyg.getStringFromEditorState(description),
      };

      resetHosterForm();

      return hosterFormInput;
    },
  }));

  return (
    <>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <TextInputField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            ref={nameInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Bewertung"
            number={rating}
            setNumber={setRating}
            minValue={1}
            maxValue={10}
            ref={ratingInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={12}>
          <Editor
            label="Anmerkungen"
            setEditor={setDescription}
            initialValue={hoster?.description}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const HosterForm = React.forwardRef(HosterFormComponent);

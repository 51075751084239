import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getTicket } from "modules/tickets/api";

type TicketReadParamsType = {
  ticketID: string;
};

export const useTicketRead = () => {
  const { ticketID } = useParams<TicketReadParamsType>();

  console.log("Loading ticket with ID: ", ticketID);

  const { data: ticket, isLoading } = useQuery({
    queryKey: [QueryKeys.Tickets, ticketID],
    queryFn: async () => {
      if (!ticketID) return null;

      const ticketResponse = await getTicket(ticketID);

      return ticketResponse;
    },
  });

  return {
    ticketID,
    ticket: ticket ?? null,
    isLoading,
  };
};

import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useHosterRead, useHosterRoutes } from "hooks";
import { Hoster } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { DescriptionFormatter } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { DeleteIcon, EditPenIcon } from "modules/icons";
import { HosterDeleteDialog } from "../../hosterDelete/HosterDeleteDialog";
import useStyles from "./styles";

export const HosterPage: React.FC = () => {
  const { classes } = useStyles();

  const { hoster, isLoading } = useHosterRead();
  const { navigateToEditHosterPage } = useHosterRoutes();

  const [deleteHosterDialogOpen, setDeleteHosterDialogOpen] =
    useState<boolean>(false);
  const [hosterToDelete, setHosterToDelete] = useState<Hoster | undefined>();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Hoster Details">
        <Loading
          description="Bitte warten. Hoster wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (hoster === undefined || hoster === null) {
    return (
      <BoxHeadlineContainer boxTitle="Hoster Details">
        Hoster nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={!isLoading && hoster ? hoster.name : "Hoster-Details"}
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditPenIcon />}
            onClick={() => navigateToEditHosterPage(hoster)}
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setHosterToDelete(hoster);
              setDeleteHosterDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <HosterDeleteDialog
        dialogOpen={deleteHosterDialogOpen}
        setDialogOpen={setDeleteHosterDialogOpen}
        hoster={hosterToDelete}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Name" content={hoster.name} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Bewertung" content={hoster.rating} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item>
          <Typography variant="h3">Beschreibung</Typography>
          <DescriptionFormatter description={hoster.description} />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

import { useCallback, useEffect, useState } from "react";
import { useHosterAllList, useSystemAllList, useWebsiteAllList } from "hooks";
import { Website } from "types";

export const useLoadWebsiteStatistics = () => {
  const { systemList } = useSystemAllList(false);
  const { hosterList } = useHosterAllList(false);
  const { websiteList } = useWebsiteAllList(false);

  const [websitesCreatedByUs, setWebsitesCreatedByUs] = useState<Website[]>([]);
  const [websitesBacklinkCreationOpen, setWebsitesBacklinkCreationOpen] =
    useState<Website[]>([]);
  const [websitesBacklinkMaintenanceOpen, setWebsitesBacklinkMaintenanceOpen] =
    useState<Website[]>([]);
  const [websitesFormiloBacklinkOpen, setWebsitesFormiloBacklinkOpen] =
    useState<Website[]>([]);
  const [websitesCurrentlyResponsible, setWebsitesCurrentlyResponsible] =
    useState<Website[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadWebsiteStatistics = useCallback(() => {
    setIsLoading(true);

    const filteredWebsitesCreatedByUs = websiteList.filter(
      (website) => website.createdByUs,
    );

    const filteredWebsitesCurrentlyResponsible = websiteList.filter(
      (website) => website.currentlyResponsible,
    );

    const filteredWebsitesBacklinkCreationOpen = websiteList.filter(
      (website) => website.backlinkErstellung === "offen",
    );

    const filteredWebsitesBacklinkMaintenanceOpen = websiteList.filter(
      (website) => website.backlinkPflege === "offen",
    );

    const filteredWebsitesFormiloBacklinkOpen = websiteList.filter(
      (website) => website.formiloBacklink === "offen",
    );

    setWebsitesCreatedByUs(filteredWebsitesCreatedByUs);
    setWebsitesCurrentlyResponsible(filteredWebsitesCurrentlyResponsible);
    setWebsitesBacklinkCreationOpen(filteredWebsitesBacklinkCreationOpen);
    setWebsitesBacklinkMaintenanceOpen(filteredWebsitesBacklinkMaintenanceOpen);
    setWebsitesFormiloBacklinkOpen(filteredWebsitesFormiloBacklinkOpen);
    setIsLoading(false);
  }, [websiteList]);

  useEffect(() => {
    loadWebsiteStatistics();
  }, [loadWebsiteStatistics]);

  return {
    websiteList,
    systemList,
    hosterList,
    websitesCreatedByUs,
    websitesCurrentlyResponsible,
    websitesBacklinkCreationOpen,
    websitesBacklinkMaintenanceOpen,
    websitesFormiloBacklinkOpen,
    isLoading,
  };
};

import React from "react";
import { List, Typography } from "@mui/material";
import { useAuthContext } from "context";
import useStyles from "./styles";

type SidebarSubmenuProps = {
  submenuTitle: string;
  children: React.ReactNode;
};

export const SidebarSubmenu: React.FC<SidebarSubmenuProps> = ({
  submenuTitle,
  children,
}) => {
  const authContext = useAuthContext();

  const { classes, cx } = useStyles();
  return (
    <div className={classes.submenu}>
      <Typography
        className={cx(
          classes.submenuTitle,
          authContext.menuOpen ? null : classes.menuClosed
        )}
      >
        {submenuTitle}
      </Typography>
      <List className={classes.submenuList}>{children}</List>
    </div>
  );
};

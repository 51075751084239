import { useNavigate } from "react-router-dom";
import { Hoster } from "types";
import { Routes } from "routes";

export const useHosterRoutes = () => {
  const navigate = useNavigate();

  const HosterIcon = Routes.hosters.icon;

  const getCreateHosterPageRoute = () =>
    Routes.hosters.subNavigations.hostercreate.path;

  const getEditHosterPageRoute = (hoster: Hoster) =>
    Routes.hosters.subNavigations.hoster.subNavigations.hosteredit.path.replace(
      ":hosterID",
      hoster.id,
    );

  const getHosterPageRoute = (hoster: Hoster) =>
    Routes.hosters.subNavigations.hoster.path.replace(":hosterID", hoster.id);

  const getHosterListPageRoute = () => Routes.hosters.path;

  const navigateToCreateHosterPage = () => {
    navigate(getCreateHosterPageRoute());
  };

  const navigateToEditHosterPage = (hoster: Hoster) => {
    navigate(getEditHosterPageRoute(hoster));
  };

  const navigateToHosterPage = (hoster: Hoster) => {
    navigate(getHosterPageRoute(hoster));
  };

  const navigateToHosterListPage = () => {
    const searchParams = sessionStorage.getItem(getHosterListPageRoute());

    navigate(
      searchParams
        ? getHosterListPageRoute() + "?" + searchParams
        : getHosterListPageRoute(),
    );
  };

  return {
    HosterIcon,
    getCreateHosterPageRoute,
    getEditHosterPageRoute,
    getHosterPageRoute,
    navigateToCreateHosterPage,
    navigateToEditHosterPage,
    navigateToHosterPage,
    navigateToHosterListPage,
  };
};

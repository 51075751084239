import React from "react";
import { client } from "queryClient";
import { RuntimeEnvironment, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface RuntimeEnvironmentOptionProps
  extends Partial<CustomSelectOptionProps<RuntimeEnvironment>> {
  runtimeEnvironment: RuntimeEnvironment | null;
  setRuntimeEnvironment: (
    value: React.SetStateAction<RuntimeEnvironment | null>,
  ) => void;
  runtimeEnvironmentInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getRuntimeEnvironmentName = (value: RuntimeEnvironment) => {
  switch (value) {
    case "hosting":
      return "Hostingpaket";
    case "selfmanagedserver":
      return "Selbst verwalteter Server";
    case "hostermanagedserver":
      return "Vom Hoster verwalteter Server";
  }
};

export const getRuntimeEnvironmentEnumByName = (
  value: string,
): RuntimeEnvironment => {
  switch (value) {
    case "Hostingpaket":
      return "hosting";
    case "Selbst verwalteter Server":
      return "selfmanagedserver";
    case "Vom Hoster verwalteter Server":
      return "hostermanagedserver";
    default:
      return "hosting";
  }
};

export const getRuntimeEnvironmentOptions = (): SelectOption[] => {
  const values = client.enums.RuntimeEnvironment?.values() ?? [];

  return values.map((runtimeEnvironment) => ({
    value: runtimeEnvironment,
    label: getRuntimeEnvironmentName(runtimeEnvironment),
  }));
};

export const RuntimeEnvironmentOption: React.FC<
  RuntimeEnvironmentOptionProps
> = ({
  runtimeEnvironment,
  setRuntimeEnvironment,
  runtimeEnvironmentInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Laufzeitumgebung"
    options={getRuntimeEnvironmentOptions()}
    onChange={(runtimeEnvironmentOption) =>
      setRuntimeEnvironment(
        runtimeEnvironmentOption.value as RuntimeEnvironment,
      )
    }
    value={
      runtimeEnvironment
        ? {
            value: runtimeEnvironment,
            label: getRuntimeEnvironmentName(runtimeEnvironment),
          }
        : null
    }
    placeholder="Laufzeitumgebung auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={runtimeEnvironmentInputRef}
  />
);

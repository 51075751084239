import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.primaryGrey.C500,
    // "& svg": {
    //   color: theme.palette.primary.main,
    // },
  },
  checked: {
    color: theme.palette.primary.main,
  },
  label: {
    display: "inline-block",
    padding: 5,
  },
}));

export default useStyles;

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateHosterInput, InfiniteHosterData } from "types";
import { getHoster } from "modules/hosters/api";

export const useHosterCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateHosterInput) => {
      const { data: newHoster } = await client.models.Hoster.create(input);

      if (!newHoster) return null;

      const hoster = await getHoster(newHoster.id);

      return hoster;
    },
    // When mutate is called:
    onMutate: async (newHoster) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.Hosters] });

      // Snapshot the previous value
      const previousHosters = queryClient.getQueryData([QueryKeys.Hosters]);

      // Optimistically update to the new value
      if (previousHosters) {
        queryClient.setQueryData(
          [QueryKeys.Hosters],
          (old: InfiniteHosterData) => {
            const oldData = old?.pages
              ? old.pages.flatMap((page) => page.hosterList)
              : [];

            [...oldData, newHoster];
          },
        );
      }

      // Return a context object with the snapshotted value
      return { previousHosters };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newHoster, context) => {
      console.error("Error saving record:", err, newHoster);
      if (context?.previousHosters) {
        queryClient.setQueryData([QueryKeys.Hosters], context.previousHosters);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Hosters] });
    },
  });

  return createMutation;
};

import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllUserList, getCognitoUserList } from "modules/users/api";
import { useUserListFilterAndSort } from "../useUserListFilterAndSort";
import utils from "utils";

export const useUserList = (filter = true) => {
  const filterAndSortUsers = useUserListFilterAndSort(filter);

  const { data: userList, isLoading: isLoadingUserList } = useQuery({
    queryKey: [QueryKeys.Users],
    queryFn: async () => {
      const allUserList = await getAllUserList();

      return allUserList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const { data: cognitoUserList, isLoading: isLoadingCognitoUserList } =
    useQuery({
      queryKey: [QueryKeys.CognitoUsers],
      queryFn: async () => {
        const cognitoUsers = await getCognitoUserList();

        return cognitoUsers;
      },
      staleTime: 1000 * 60 * 15, // 15 minutes
      gcTime: 1000 * 60 * 60, // 1 hour
    });

  const filteredUsers = useMemo(
    () => (userList ? filterAndSortUsers(userList) : []),
    [userList, filterAndSortUsers],
  );

  const definedCognitoUserList = utils.graphql.getDefinedItems(cognitoUserList);

  return {
    userList: filteredUsers,
    cognitoUserList: definedCognitoUserList,
    isLoading: isLoadingUserList || isLoadingCognitoUserList,
  };
};

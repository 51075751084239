import axios from "axios";

export const generatePDF = async (
  header: string,
  footer: string,
  content: string,
  styles: string,
  filename: string,
) => {
  const params = new URLSearchParams();
  params.append("header", header);
  params.append("footer", footer);
  params.append("content", content);
  params.append("styles", styles);

  const getPDFResponse = await axios({
    method: "POST",
    url: "https://pdf.goldweiss.berlin/",
    data: params,
    responseType: "blob",
  });

  const pdfBlob = new Blob([getPDFResponse.data]);

  const url = window.URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();

  console.log("getPDFResponse: ", getPDFResponse);

  return pdfBlob;
};

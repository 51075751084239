import { useEffect } from "react";
import {
  useEmployeeAllList,
  usePersistedState,
  usePersistedStateWithDate,
} from "hooks";
import { BillType, Employee, Task, TaskStatus, TaskType, Website } from "types";
import utils from "utils";

export const useTaskForm = (
  formIntent: "create" | "edit",
  task: Task | null | undefined,
) => {
  const { employeeList } = useEmployeeAllList(false);

  const [taskType, setTaskType] = usePersistedState<TaskType | null>(
    "task_taskType",
    null,
    formIntent,
  );
  const [website, setWebsite] = usePersistedState<Website | null>(
    "task_website",
    null,
    formIntent,
  );

  const [effort, setEffort] = usePersistedState<number | "">(
    "task_effort",
    1,
    formIntent,
  );
  const [date, setDate] = usePersistedStateWithDate<Date | null>(
    "task_date",
    null,
    formIntent,
  );
  const [time, setTime] = usePersistedStateWithDate<Date | null>(
    "task_time",
    null,
    formIntent,
  );
  const [deadlineDate, setDeadlineDate] =
    usePersistedStateWithDate<Date | null>(
      "task_deadlineDate",
      null,
      formIntent,
    );
  const [deadlineTime, setDeadlineTime] =
    usePersistedStateWithDate<Date | null>(
      "task_deadlineTime",
      null,
      formIntent,
    );
  const [billType, setBillType] = usePersistedState<BillType | null>(
    "task_billType",
    null,
    formIntent,
  );
  const [billed, setBilled] = usePersistedState<boolean>(
    "task_billed",
    false,
    formIntent,
  );
  const [taskStatus, setTaskStatus] = usePersistedState<TaskStatus | null>(
    "task_taskStatus",
    null,
    formIntent,
  );
  const [employee, setEmployee] = usePersistedState<Employee | null>(
    "task_employee",
    null,
    formIntent,
  );

  const resetTaskForm = () => {
    localStorage.removeItem("task_taskType");
    localStorage.removeItem("task_website");
    localStorage.removeItem("task_effort");
    localStorage.removeItem("task_date");
    localStorage.removeItem("task_time");
    localStorage.removeItem("task_deadlineDate");
    localStorage.removeItem("task_deadlineTime");
    localStorage.removeItem("task_billType");
    localStorage.removeItem("task_billed");
    localStorage.removeItem("task_taskStatus");
    localStorage.removeItem("task_employee");
  };

  useEffect(() => {
    if (formIntent === "edit") {
      return;
    }

    const now = new Date();
    now.setHours(12, 0, 0, 0);

    setDate(now);
    setTime(now);

    setDeadlineDate(now);
    setDeadlineTime(now);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIntent]);

  useEffect(() => {
    if (formIntent === "edit" || !taskType) {
      return;
    }

    console.log("employeeList", employeeList);

    const employeeFromAPI = employeeList.find(
      (employeeItem) => employeeItem.id === taskType.employee,
    );

    setEmployee(employeeFromAPI ?? null);
  }, [employeeList, taskType, formIntent]);

  useEffect(() => {
    if (formIntent === "edit" || !taskType) {
      console.log("no task type");
      return;
    }

    if (!website) {
      setEffort(taskType.effort);
      console.log("no website setting effort to ", taskType.effort);
      return;
    }

    const effortRule = taskType.effortRules.find(
      (effortRule) =>
        effortRule.systemID === website.systemID &&
        effortRule.websiteType === website.websiteType,
    );

    if (!effortRule) {
      console.log("effortRule not found setting effort to ", taskType.effort);
      setEffort(taskType.effort);
      return;
    }
    console.log("effortRule", effortRule);

    setEffort(effortRule.effort);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskType, website]);

  useEffect(() => {
    if (!task) {
      return;
    }

    setTaskType(task.taskType as TaskType);
    setWebsite(task.website as Website);

    setEffort(task.effort);

    setDate(task.date ? utils.dates.getDateFromAWSDateFormat(task.date) : null);
    setTime(task.time ? utils.dates.getDateFromAWSTimeFormat(task.time) : null);

    setDeadlineDate(utils.dates.getDateFromAWSDateFormat(task.deadlineDate));
    setDeadlineTime(utils.dates.getDateFromAWSTimeFormat(task.deadlineTime));

    setBillType(task.billType);

    setBilled(task.billed);

    setTaskStatus(task.status);

    const employeeFromAPI = employeeList.find(
      (employeeItem) => employeeItem.id === task.employee,
    );

    setEmployee(employeeFromAPI ?? null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, employeeList]);

  return {
    taskType,
    setTaskType,
    website,
    setWebsite,
    effort,
    setEffort,
    date,
    setDate,
    time,
    setTime,
    deadlineDate,
    setDeadlineDate,
    deadlineTime,
    setDeadlineTime,
    billType,
    setBillType,
    billed,
    setBilled,
    taskStatus,
    setTaskStatus,
    employee,
    setEmployee,
    resetTaskForm,
  };
};

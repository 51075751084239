import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useSystemDelete, useSystemRoutes } from "hooks";
import { System } from "types";
import { DeleteDialog } from "components";
import { isSystemUsed } from "../../api";

type SystemDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  system: System | undefined;
};

export const SystemDeleteDialog: React.FC<SystemDeleteDialogProps> = ({
  system,
  dialogOpen,
  setDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteSystemMutation = useSystemDelete();
  const { navigateToSystemListPage } = useSystemRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || system === undefined) {
    return null;
  }

  const deleteSystemRequest = async () => {
    setDeleteLoading(true);
    const systemIsUsed = await isSystemUsed(system);

    if (systemIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Dieses System kann nicht gelöscht werden, da er in Verwendung ist!",
      );
      return;
    }

    let deletedSystem = await deleteSystemMutation.mutateAsync(system);

    console.log("deletedSystem: ", deletedSystem);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigateToSystemListPage();
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteSystemRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="System Löschen"
      itemName={system.name}
      itemDeleteDescription="das System"
    />
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  icon: {
    width: 23,
    height: 23,
    marginBottom: -4,
  },
}));

export default useStyles;

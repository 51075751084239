import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useTaskTypeDelete, useTaskTypeRoutes } from "hooks";
import { TaskType } from "types";
import { DeleteDialog } from "components";
import { isTaskTypeUsed } from "../../api";

type TaskTypeDeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  taskType: TaskType | undefined;
};

export const TaskTypeDeleteDialog: React.FC<TaskTypeDeleteDialogProps> = ({
  taskType,
  dialogOpen,
  setDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteTaskTypeMutation = useTaskTypeDelete();
  const { navigateToTaskTypeListPage } = useTaskTypeRoutes();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  if (!dialogOpen || taskType === undefined) {
    return null;
  }

  const deleteTaskTypeRequest = async () => {
    setDeleteLoading(true);

    const taskTypeIsUsed = await isTaskTypeUsed(taskType);

    if (taskTypeIsUsed) {
      setDeleteLoading(false);
      enqueueSnackbar(
        "Dieser Aufgaben-Typ kann nicht gelöscht werden, da er in Verwendung ist!",
      );
      return;
    }

    let deletedTaskType = await deleteTaskTypeMutation.mutateAsync(taskType);

    console.log("deletedTaskType: ", deletedTaskType);
    setDeleteLoading(false);
    setDialogOpen(false);
    navigateToTaskTypeListPage();
  };

  return (
    <DeleteDialog
      deleteLoading={deleteLoading}
      deleteRequest={deleteTaskTypeRequest}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogTitle="Aufgaben-Typ Löschen"
      itemName={taskType.name}
      itemDeleteDescription="den Aufgaben-Typ"
    />
  );
};

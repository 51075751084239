import React, { useImperativeHandle, useMemo, useRef, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Slide,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { deDE } from "@mui/x-date-pickers/locales";
import moment, { Moment } from "moment";
import { nanoid } from "nanoid";
import { scroller } from "react-scroll";
import { CheckmarkDoneIcon, InfoRoundIcon } from "modules/icons";
import { CalendarIcon, CloseDuoIcon } from "modules/icons";
import { CustomFormLabel, CustomFormLabelProps } from "../CustomFormLabel";
import utils from "utils";
import useStyles from "./styles";

interface CustomDatePickerProps extends CustomFormLabelProps {
  value: Date | null;
  onChange: (date: Date | null, value?: string | null | undefined) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  yearOnly?: boolean;
  monthAndYear?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  resettable?: boolean;
}

export type CustomDatePickerHandles = {
  highlight(scroll?: boolean): void;
};

export const CustomDatePickerComponent: React.ForwardRefRenderFunction<
  CustomDatePickerHandles,
  CustomDatePickerProps
> = (
  {
    value,
    onChange,
    minDate,
    maxDate,
    yearOnly,
    monthAndYear,
    required = false,
    autoFocus = false,
    disabled = false,
    resettable = false,

    // CustomFormLabelProps
    info,
    infoContent,
    infoTitle,
    label,
    description,
    id = nanoid(5),
    showRequiredSymbol,
    nodeBefore,
    nodeAfter,
  },
  customDatePickerRef,
) => {
  const { classes, cx } = useStyles();

  const isDesktop = useMediaQuery("@media (pointer: fine)");

  const [pickerStatus, setPickerStatus] = useState<boolean>(false);
  const [inputStatus, setInputStatus] = useState<
    "success" | "error" | "default"
  >("default");

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(customDatePickerRef, () => ({
    highlight: (scroll = true) => {
      setInputStatus("error");
      if (scroll) {
        scroller.scrollTo(id, {
          smooth: true,
          offset: -200,
          duration: 700,
        });
      }
      console.log("inputRef.current: ", inputRef.current);
      inputRef.current?.focus();
    },
  }));

  const onChangeHandler = (date: Moment | null) => {
    console.log("date: ", date);
    onChange(date ? date.toDate() : null);
    if (required) {
      if (date && utils.dates.isValidGermanDate(date.toDate())) {
        setInputStatus("success");
      } else {
        setInputStatus("error");
      }
    }
  };

  const CalendarInputAdornment = useMemo(
    () => () => (
      <InputAdornment position="start">
        {inputStatus === "success" ? (
          <Slide
            direction="right"
            in={inputStatus === "success"}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <span style={{ lineHeight: 0 }} className={classes.statusWrapper}>
              <CheckmarkDoneIcon className={classes.successIcon} />
            </span>
          </Slide>
        ) : null}
        {inputStatus === "error" ? (
          <Slide
            direction="right"
            in={inputStatus === "error"}
            mountOnEnter
            unmountOnExit
            timeout={700}
          >
            <span style={{ lineHeight: 0 }} className={classes.statusWrapper}>
              <InfoRoundIcon className={classes.errorIcon} />
            </span>
          </Slide>
        ) : null}
        {inputStatus === "default" && resettable && (
          <IconButton
            className={classes.resetButton}
            onClick={() => {
              // if (inputRef.current) {
              //   onChangeHandler(null);
              //   inputRef.current.value = "";
              //   const input =
              //     inputRef.current.querySelector("input");
              //   if (input) {
              //     input.value = "";
              //   }
              //   inputRef.current.querySelector("input")?.focus();
              // }
            }}
            tabIndex={-1}
          >
            <CloseDuoIcon className={classes.resetIcon} />
          </IconButton>
        )}
        <IconButton
          className={cx(
            inputStatus === "default"
              ? resettable
                ? classes.datePickerButtonResettable
                : classes.datePickerButtonSingle
              : classes.datePickerButton,
          )}
          onClick={() => setPickerStatus(true)}
          disabled={disabled}
          tabIndex={-1}
        >
          <CalendarIcon className={classes.calendarIcon} />
        </IconButton>
      </InputAdornment>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputStatus, resettable, disabled],
  );

  return (
    <>
      <CustomFormLabel
        info={info}
        infoContent={infoContent}
        infoTitle={infoTitle}
        label={label}
        description={description}
        id={id}
        showRequiredSymbol={required && showRequiredSymbol}
        nodeBefore={nodeBefore}
        nodeAfter={nodeAfter}
        errorLabel={inputStatus === "error"}
      />

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DatePicker<Moment>
          open={isDesktop ? pickerStatus : undefined}
          onClose={isDesktop ? () => setPickerStatus(false) : undefined}
          openTo="day"
          views={
            monthAndYear
              ? ["year", "month"]
              : yearOnly
                ? ["year"]
                : ["year", "month", "day"]
          }
          minDate={minDate ? moment(minDate) : undefined}
          maxDate={maxDate ? moment(maxDate) : undefined}
          // allowSameDateSelection={true}
          // disableCloseOnSelect={false}
          value={moment(value)}
          disabled={disabled}
          ref={inputRef}
          autoFocus={autoFocus}
          onChange={onChangeHandler}
          slots={{
            inputAdornment: isDesktop ? CalendarInputAdornment : undefined,
          }}
          slotProps={{
            textField: {
              id: id,
              variant: "standard",
              className: cx(
                classes.datePicker,
                inputStatus === "error" ? classes.inputError : null,
                inputStatus === "success" ? classes.inputSuccess : null,
              ),
              InputProps: {
                disableUnderline: true,
                classes: {
                  focused: classes.focusedInput,
                  input: classes.input,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export const CustomDatePicker = React.forwardRef(CustomDatePickerComponent);

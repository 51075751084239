import React, { useContext, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  usePermanentTaskCreate,
  usePermanentTaskRead,
  usePermanentTaskRoutes,
  usePermanentTaskUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import {
  PermanentTaskForm,
  PermanentTaskFormHandles,
} from "../PermanentTaskForm";
import useStyles from "./styles";

type PermanentTaskFormPageProps = {
  formIntent: "create" | "edit";
};

export const PermanentTaskFormPage: React.FC<PermanentTaskFormPageProps> = ({
  formIntent,
}) => {
  const { classes } = useStyles();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { permanentTask, isLoading } = usePermanentTaskRead();
  const createPermanentTaskMutation = usePermanentTaskCreate();
  const updatePermanentTaskMutation = usePermanentTaskUpdate();
  const { navigateToPermanentTaskPage, navigateToPermanentTaskListPage } =
    usePermanentTaskRoutes();

  const permanentTaskFormRef = useRef<PermanentTaskFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitPermanentTask = async () => {
    const permanentTaskFormInput =
      permanentTaskFormRef.current?.validatePermanentTaskForm();
    if (!permanentTaskFormInput) {
      console.error("PermanentTaskForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Daueraufgabe ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("permanentTaskInput: ", permanentTaskFormInput);

    const mutatePermanentTaskRequest = permanentTask
      ? await updatePermanentTaskMutation.mutateAsync({
          ...permanentTaskFormInput,
          id: permanentTask.id,
        })
      : await createPermanentTaskMutation.mutateAsync(permanentTaskFormInput);

    if (!mutatePermanentTaskRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Daueraufgabe ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutatePermanentTaskRequest: ", mutatePermanentTaskRequest);

    setSubmitLoading(false);
    navigateToPermanentTaskPage(mutatePermanentTaskRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Daueraufgabe " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Daueraufgabe wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Daueraufgabe " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <PermanentTaskForm
        permanentTask={permanentTask}
        formIntent={formIntent}
        ref={permanentTaskFormRef}
      />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Daueraufgabe " +
              (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitPermanentTask()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => navigateToPermanentTaskListPage()}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

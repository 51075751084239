import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateHosterInput } from "types";
import { getHoster } from "modules/hosters/api";

export const useHosterUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (hosterInput: UpdateHosterInput) => {
      const { data: updatedHoster } =
        await client.models.Hoster.update(hosterInput);

      if (!updatedHoster) return null;

      const hoster = await getHoster(updatedHoster.id);

      return hoster;
    },
    // When mutate is called:
    onMutate: async (newHoster) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Hosters, newHoster.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Hosters],
      });

      // Snapshot the previous value
      const previousHoster = queryClient.getQueryData([
        QueryKeys.Hosters,
        newHoster.id,
      ]);

      // Optimistically update to the new value
      if (previousHoster) {
        queryClient.setQueryData(
          [QueryKeys.Hosters, newHoster.id],
          /**
           * `newHoster` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousHoster, ...newHoster },
        );
      }

      // Return a context with the previous and new hoster
      return {
        previousHoster,
        newHoster,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newHoster, context) => {
      console.error("Error updating record:", err, newHoster);
      if (context?.previousHoster) {
        queryClient.setQueryData(
          [QueryKeys.Hosters, context.newHoster.id],
          context.previousHoster,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newHoster) => {
      if (newHoster) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Hosters, newHoster.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Hosters],
        });
      }
    },
  });

  return updateMutation;
};

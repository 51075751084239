import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getEffortRule } from "modules/effortrules/api";

type EffortRuleReadParamsType = {
  effortRuleID: string;
};

export const useEffortRuleRead = () => {
  const { effortRuleID } = useParams<EffortRuleReadParamsType>();

  console.log("Loading effortRule with ID: ", effortRuleID);

  const { data: effortRule, isLoading } = useQuery({
    queryKey: [QueryKeys.EffortRules, effortRuleID],
    queryFn: async () => {
      if (!effortRuleID) return null;

      const effortRuleFromAPI = await getEffortRule(effortRuleID);

      return effortRuleFromAPI;
    },
  });

  return {
    effortRuleID,
    effortRule: effortRule ?? null,
    isLoading,
  };
};

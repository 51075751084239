import { SelectionSet } from "aws-amplify/api";
import { InfiniteData } from "@tanstack/react-query";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const BookingSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "customerID",
  "year",
  "month",
  "bookedMinutes",
  "previousMinutes",
  "finalMinutes",
  "completedDate",
  "paid",
  "reportEntries.*",
] as const;

export type Booking = SelectionSet<
  Schema["Booking"]["type"],
  typeof BookingSelectionSet
>;

export type CreateBookingInput = Schema["Booking"]["createType"];

export type UpdateBookingInput = Schema["Booking"]["updateType"];

export type InfiniteBookingList = {
  bookingList: Booking[];
  nextToken: string | null;
};

export type InfiniteBookingData =
  | InfiniteData<InfiniteBookingList, unknown>
  | undefined;

export const BookingTypeKeys: TypeKeysEnum<Booking> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  customerID: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.customerID,
  },
  year: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.year.toString(),
  },
  month: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.month.toString(),
  },
  bookedMinutes: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.bookedMinutes.toString(),
  },
  previousMinutes: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.previousMinutes.toString(),
  },
  finalMinutes: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.finalMinutes.toString(),
  },
  completedDate: {
    type: "date",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      data.completedDate
        ? utils.dates.getDateInGermanFromAWSDateFormat(data.completedDate)
        : "",
  },
  paid: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.paid ? "Ja" : "Nein"),
  },
  reportEntries: {
    type: "entity",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfBooking = (key: string): key is keyof Booking =>
  key in BookingTypeKeys;

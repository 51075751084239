import React from "react";
import { Checkbox } from "@mui/material";
import useStyles from "./styles";

type SelectCheckboxProps = {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  indeterminate?: boolean;
};

export const SelectCheckbox: React.FC<SelectCheckboxProps> = ({
  onChange,
  checked,
  indeterminate = false,
}) => {
  const { classes } = useStyles();

  return (
    <Checkbox
      color="default"
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      checked={checked}
      classes={{ root: classes.root, checked: classes.checked }}
      indeterminate={!checked && indeterminate}
    />
  );
};

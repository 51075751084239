import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllCustomerList } from "modules/customers/api";
import { useCustomerListFilterAndSort } from "../useCustomerListFilterAndSort";

export const useCustomerAllList = (filter = true) => {
  const filterAndSortCustomers = useCustomerListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Customers, "all"],
    queryFn: async () => {
      const { customerList } = await getAllCustomerList();

      return customerList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const customerList = useMemo(
    () => filterAndSortCustomers(data ?? []),
    [data, filterAndSortCustomers],
  );

  return {
    customerList,
    isLoading,
  };
};

import React from "react";
import { Loading } from "core";
import { EyeIcon } from "modules/icons";
import useStyles from "./styles";

type ViewButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  size?: "small" | "medium" | "big";
  title?: string;
  isLoadingView?: boolean;
};

export const ViewButton: React.FC<ViewButtonProps> = ({
  onClick,
  size = "medium",
  title,
  isLoadingView,
}) => {
  const { classes, cx } = useStyles();

  const sizeClass =
    size === "small"
      ? classes.buttonSmall
      : size === "big"
      ? classes.buttonBig
      : classes.buttonMedium;

  return (
    <button
      title={title}
      className={cx(classes.viewButton, classes.button, sizeClass)}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        !isLoadingView && onClick(e);
      }}
    >
      <span className="svg-icon svg-icon-md svg-icon-danger">
        {isLoadingView && <Loading size="21px" style={{ margin: 0 }} />}
        <EyeIcon
          style={{
            display: isLoadingView ? "none" : "block",
            width: 33,
            height: 33,
          }}
        />
      </span>
    </button>
  );
};

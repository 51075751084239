import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { SelectOption } from "types";
import { CustomDatePicker, CustomSelect } from "core";
import utils from "utils";

type DateFilterProps = {
  param: string;
  paramName: string;
};

export const DateFilter: React.FC<DateFilterProps> = ({ param, paramName }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const comparisonParamName = useMemo(() => "comparison_" + param, [param]);

  const [date, setDate] = useState<Date | null>(null);

  const [comparisonNumber, setComparisonNumber] = useState<SelectOption | null>(
    {
      label: "Gleich",
      value: "eq",
    },
  );

  const comparisonOptionList = useMemo(
    () => [
      {
        label: "Gleich",
        value: "eq",
      },
      {
        label: "Nach",
        value: "gt",
      },
      {
        label: "Vor",
        value: "lt",
      },
    ],
    [],
  );

  useEffect(() => {
    const dateParam = searchParams.get(param);
    const dateParamInput =
      dateParam === null
        ? null
        : utils.dates.getDateFromAWSDateFormat(dateParam);
    setDate(dateParamInput);

    const comparisonParam = searchParams.get(comparisonParamName);

    if (!comparisonParam) {
      searchParams.set(comparisonParamName, "eq");
      sessionStorage.setItem(pathname, searchParams.toString());
      setSearchParams(searchParams, {
        replace: true,
      });
    }

    const selectedComparison = comparisonOptionList.find(
      (customerOption) => customerOption.value === comparisonParam,
    );

    const selectedComparisonOption =
      comparisonParam === null || !selectedComparison
        ? {
            label: "Gleich",
            value: "eq",
          }
        : selectedComparison;

    setComparisonNumber(selectedComparisonOption);
  }, [
    comparisonOptionList,
    comparisonParamName,
    pathname,
    param,
    searchParams,
    setSearchParams,
  ]);

  return (
    <Grid container direction="row" spacing={2} wrap="nowrap">
      <Grid item>
        <CustomSelect<SelectOption>
          label={paramName + " ist:"}
          options={comparisonOptionList}
          onChange={(comparisonBookedMinutesOption) => {
            setComparisonNumber(comparisonBookedMinutesOption);
            searchParams.set(
              comparisonParamName,
              comparisonBookedMinutesOption!.value,
            );
            sessionStorage.setItem(pathname, searchParams.toString());
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
          value={comparisonNumber}
          minWidth={130}
        />
      </Grid>
      <Grid item>
        <CustomDatePicker
          label="als"
          value={date}
          minDate={new Date(2000, 1, 1)}
          onChange={(date) => {
            console.log("date changed to ", date);
            const inputDate =
              date && date instanceof Date && !isNaN(date.getTime())
                ? new Date(
                    date!.getFullYear(),
                    date!.getMonth(),
                    date!.getDate(),
                  )
                : null;
            setDate(inputDate);
            if (!inputDate) {
              searchParams.delete(param);
            } else {
              searchParams.set(
                param,
                utils.dates.convertDateToAWSDateFormat(inputDate),
              );
            }
            sessionStorage.setItem(pathname, searchParams.toString());
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

import React, { useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  CreatePermanentTaskInput,
  PermanentTask,
  TaskType,
  Website,
} from "types";
import {
  CustomButton,
  CustomDatePicker,
  CustomDatePickerHandles,
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  LabeledTypography,
  SelectionButton,
} from "core";
import { TaskTypeIcon, WebsiteIcon } from "modules/icons";
import { BillTypeOption } from "modules/shared/options/BillTypeOption";
import { PeriodTypeOption } from "modules/shared/options/PeriodTypeOption";
import { TaskTypeSelectionDialog } from "modules/tasktypes/tasktypeselection/TaskTypeSelectionDialog";
import { WebsiteSelectionDialog } from "modules/websites/websiteselection/WebsiteSelectionDialog";
import { usePermanentTaskForm } from "../usePermanentTaskForm";
import utils from "utils";
import useStyles from "./styles";

type PermanentTaskFormProps = {
  formIntent: "create" | "edit";
  permanentTask: PermanentTask | null | undefined;
};

export type PermanentTaskFormHandles = {
  validatePermanentTaskForm(): CreatePermanentTaskInput | null;
};

const PermanentTaskFormComponent: React.ForwardRefRenderFunction<
  PermanentTaskFormHandles,
  PermanentTaskFormProps
> = ({ formIntent, permanentTask }, permanentTaskFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    taskType,
    setTaskType,
    website,
    setWebsite,
    effort,
    setEffort,
    referenceDate,
    setReferenceDate,
    periodType,
    setPeriodType,
    periodValue,
    setPeriodValue,
    deadlineDays,
    setDeadlineDays,
    billType,
    setBillType,
    resetPermanentTaskForm,
  } = usePermanentTaskForm(formIntent, permanentTask);

  const [selectTaskTypeDialogOpen, setSelectTaskTypeDialogOpen] =
    useState<boolean>(false);

  const taskTypeSelectionHandler = (taskType: TaskType) => {
    setTaskType(taskType);
    setSelectTaskTypeDialogOpen(false);
  };

  const [selectWebsiteDialogOpen, setSelectWebsiteDialogOpen] =
    useState<boolean>(false);

  const websiteSelectionHandler = (website: Website) => {
    setWebsite(website);
    setSelectWebsiteDialogOpen(false);
  };

  const taskTypeInputRef = useRef<CustomSelectHandles>(null);
  const websiteInputRef = useRef<CustomSelectHandles>(null);
  const effortInputRef = useRef<CustomNumberFieldHandles>(null);
  const referenceDateInputRef = useRef<CustomDatePickerHandles>(null);
  const periodTypeInputRef = useRef<CustomSelectHandles>(null);
  const periodValueInputRef = useRef<CustomNumberFieldHandles>(null);
  const deadlineDaysInputRef = useRef<CustomNumberFieldHandles>(null);
  const billTypeInputRef = useRef<CustomSelectHandles>(null);

  useImperativeHandle(permanentTaskFormRef, () => ({
    validatePermanentTaskForm: () => {
      if (!taskType) {
        enqueueSnackbar("Bitte wähle den Aufgaben-Typ der Daueraufgabe aus!");
        taskTypeInputRef.current?.highlight();
        return null;
      }

      if (!website) {
        enqueueSnackbar("Bitte wähle die Webseite der Daueraufgabe aus!");
        websiteInputRef.current?.highlight();
        return null;
      }

      if (effort === "") {
        enqueueSnackbar("Bitte gib den Aufwand in Minuten ein!");
        effortInputRef.current?.highlight();
        return null;
      }

      if (referenceDate === null) {
        enqueueSnackbar("Bitte gib das Referenz Datum der Daueraufgabe ein!");
        referenceDateInputRef.current?.highlight();
        return null;
      }

      if (!(referenceDate instanceof Date) || isNaN(referenceDate.getTime())) {
        enqueueSnackbar(
          "Bitte gib ein gültiges Datum für das Auftragsdatum ein!",
        );
        referenceDateInputRef.current?.highlight();
        return null;
      }

      if (!periodType) {
        enqueueSnackbar("Bitte wähle den Periode Typ der Daueraufgabe aus!");
        periodTypeInputRef.current?.highlight();
        return null;
      }

      if (periodValue === "") {
        enqueueSnackbar("Bitte gib die Periode Wert ein!");
        periodValueInputRef.current?.highlight();
        return null;
      }

      if (deadlineDays === "") {
        enqueueSnackbar("Bitte gib die Frist in Tagen ein!");
        deadlineDaysInputRef.current?.highlight();
        return null;
      }

      if (!billType) {
        enqueueSnackbar("Bitte wähle die Abrechnungsart der Daueraufgabe aus!");
        billTypeInputRef.current?.highlight();
        return null;
      }

      const permanentTaskFormInput: CreatePermanentTaskInput = {
        taskTypeID: taskType.id,
        websiteID: website.id,
        effort: effort,
        referenceDate: utils.dates.convertDateToAWSDateFormat(referenceDate),
        periodType: periodType,
        periodValue: periodValue,
        deadlineDays: deadlineDays,
        billType: billType,
      };

      resetPermanentTaskForm();

      return permanentTaskFormInput;
    },
  }));

  return (
    <>
      <TaskTypeSelectionDialog
        dialogOpen={selectTaskTypeDialogOpen}
        setDialogOpen={setSelectTaskTypeDialogOpen}
        taskTypeSelectionHandler={taskTypeSelectionHandler}
      />
      <WebsiteSelectionDialog
        dialogOpen={selectWebsiteDialogOpen}
        setDialogOpen={setSelectWebsiteDialogOpen}
        websiteSelectionHandler={websiteSelectionHandler}
        routeKey="websites"
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          {taskType ? (
            <SelectionButton
              label="Aufgaben-Typ"
              name={taskType.name}
              onClick={() => setSelectTaskTypeDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Aufgaben-Typ"
              content={
                <CustomButton
                  text="Aufgaben-Typ auswählen"
                  onClick={() => setSelectTaskTypeDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <TaskTypeIcon className={classes.selectButtonIcon} />
                  }
                  ref={taskTypeInputRef}
                />
              }
            />
          )}
        </Grid>

        <Grid item md={4}>
          {website ? (
            <SelectionButton
              label="Webseite"
              name={website.url}
              onClick={() => setSelectWebsiteDialogOpen(true)}
            />
          ) : (
            <LabeledTypography
              label="Webseite"
              content={
                <CustomButton
                  text="Webseite auswählen"
                  onClick={() => setSelectWebsiteDialogOpen(true)}
                  size="small"
                  style="outlined"
                  iconAfter={
                    <WebsiteIcon className={classes.selectButtonIcon} />
                  }
                  ref={websiteInputRef}
                />
              }
            />
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomNumberField
            label="Aufwand in m"
            number={effort}
            setNumber={setEffort}
            minValue={1}
            ref={effortInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomDatePicker
            value={referenceDate}
            label="Referenz Datum"
            minDate={new Date(2000, 1, 1)}
            ref={referenceDateInputRef}
            required={true}
            onChange={(date) =>
              setReferenceDate(
                date
                  ? new Date(
                      date!.getFullYear(),
                      date!.getMonth(),
                      date!.getDate(),
                    )
                  : null,
              )
            }
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <PeriodTypeOption
            periodType={periodType}
            setPeriodType={setPeriodType}
            periodTypeInputRef={periodTypeInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Periode Wert"
            number={periodValue}
            setNumber={setPeriodValue}
            minValue={1}
            ref={periodValueInputRef}
            required={true}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <CustomNumberField
            label="Frist in Tagen"
            number={deadlineDays}
            setNumber={setDeadlineDays}
            minValue={1}
            ref={deadlineDaysInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <BillTypeOption
            billType={billType}
            setBillType={setBillType}
            billTypeInputRef={billTypeInputRef}
            required={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const PermanentTaskForm = React.forwardRef(PermanentTaskFormComponent);

import React, { useMemo } from "react";
import { Booking, Customer, ReportEntry } from "types";
import { getWebsiteTypeName } from "../../../shared/options/WebsiteTypeOption";
import utils from "utils";

type CareReportContentProps = {
  booking: Booking;
  customer: Customer;
  reportEntryList: ReportEntry[];
};

export const CareReportContent: React.FC<CareReportContentProps> = ({
  booking,
  customer,
  reportEntryList,
}) => {
  const getWebsiteTotalEffort = (
    taskList: ReportEntry[],
    ticketList: ReportEntry[],
  ) => {
    const taskListTotalEffort = taskList.reduce(
      (previousValue, currentValue) => previousValue + currentValue.effort,
      0,
    );

    const ticketListTotalEffort = ticketList.reduce(
      (previousValue, currentValue) => previousValue + currentValue.effort,
      0,
    );

    return taskListTotalEffort + ticketListTotalEffort;
  };

  const reportTotalEffort = useMemo(() => {
    var totalEffort = 0;

    for (let reportEntry of reportEntryList) {
      totalEffort += reportEntry.effort;
    }

    return totalEffort;
  }, [reportEntryList]);

  const startMinutes = booking.bookedMinutes + booking.previousMinutes;

  const newFinalMinutes = startMinutes - reportTotalEffort;

  const websites: ReportEntry["website"][] = [];

  for (let reportEntry of reportEntryList) {
    const websiteExists = websites.some(
      (website) => website.id === reportEntry.websiteID,
    );
    if (!websiteExists) {
      websites.push(reportEntry.website);
    }
  }

  return (
    <>
      <div id="title">
        <h1>Bericht für Website Pflege</h1>
        <div id="kunde">Kunde: {customer.Account_Name}</div>
        <div id="datum">
          Datum: {utils.dates.getDateInGermanFormat(new Date())}
        </div>
      </div>

      <div id="info">
        <div>
          <strong>Für Monat: </strong>
          {("0" + booking.month).slice(-2) + "/" + booking.year}
        </div>
        <div>
          <strong>Monat bezahlt: </strong>
          {booking.paid ? "ja" : "nein"}
        </div>
        <div>
          <strong>Weitere bezahlte Monate: </strong>0
        </div>
        <br />

        <div>
          <strong>In diesem Monat gebuchte Minuten: </strong>
          <span className="green">{booking.bookedMinutes}</span>
        </div>
        <div>
          <strong>Minuten aus Vormonat: </strong>
          <span className={booking.previousMinutes < 0 ? "red" : ""}>
            {booking.previousMinutes}
          </span>
        </div>
        <div>
          <strong>Resultierendes Startguthaben: </strong>
          <span className={startMinutes < 0 ? "red" : "green"}>
            {startMinutes}
          </span>
        </div>
      </div>

      {websites.map((website) => {
        const taskList = reportEntryList.filter(
          (reportEntry) =>
            reportEntry.originalType === "task" &&
            reportEntry.websiteID === website.id,
        );

        const ticketList = reportEntryList.filter(
          (reportEntry) =>
            reportEntry.originalType === "ticket" &&
            reportEntry.websiteID === website.id,
        );

        if (taskList.length < 1 && ticketList.length < 1) {
          return null;
        }
        return (
          <>
            <div className="website-title">
              <span>{website.url}</span> &nbsp; &nbsp;
              {"[" +
                getWebsiteTypeName(website.websiteType) +
                ", " +
                website.system.name +
                "]"}
            </div>
            <div className="website-body">
              <table className="efforts">
                <tbody>
                  {taskList.map((reportEntryTask) => (
                    <tr>
                      <td>
                        <strong>{reportEntryTask.type + ":"}</strong>{" "}
                        {reportEntryTask.description}
                      </td>
                      <td>{"-" + reportEntryTask.effort + " min"}</td>
                    </tr>
                  ))}
                  {ticketList.map((reportEntryTicket) => (
                    <tr>
                      <td>
                        <strong>
                          Ticket
                          {" [" + reportEntryTicket.type + "]: "}
                        </strong>
                        {reportEntryTicket.ticketSubject ?? ""}
                        {/* {reportEntryTicket.description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: reportEntryTicket.description,
                            }}
                          ></div>
                        )} */}
                      </td>
                      <td>{"-" + reportEntryTicket.effort + " min"}</td>
                    </tr>
                  ))}
                  <tr className="site-totalEffort">
                    <td>Gesamtaufwand</td>
                    <td>
                      {"-" + getWebsiteTotalEffort(taskList, ticketList)} min
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      })}

      <table id="monthResult">
        <tbody>
          <tr>
            <td>Gesamtaufwand für alle Seiten</td>
            <td className={reportTotalEffort > 0 ? "red" : ""}>
              {reportTotalEffort > 0
                ? "-" + reportTotalEffort + " min"
                : reportTotalEffort + " min"}
            </td>
          </tr>
          <tr>
            <td>
              <strong>RESTMINUTEN / ÜBERTRAG FÜR FOLGEMONAT</strong>
            </td>
            <td className={newFinalMinutes < 0 ? "red" : "green"}>
              <strong>{newFinalMinutes + " min"}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

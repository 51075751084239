import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  icon: {
    marginRight: 10,
    width: 23,
    height: 23,
  },
  variantError: {
    color: "#66091b",
    backgroundColor: "#ffe7ec",
    "& i": {
      color: "#ff1744",
    },
  },
  variantSuccess: {
    color: "#1e4620",
    backgroundColor: "#edf7ed",
    "& i": {
      color: "#4caf50",
    },
  },
  variantInfo: {
    color: "#0d3c61",
    backgroundColor: "#e8f4fd",
    "& i": {
      color: "#2196f3",
    },
  },
  variantWarning: {
    color: "#663c00",
    backgroundColor: "#fff4e5",
    "& i": {
      color: "#ff9800",
    },
  },
  containerRoot: {
    top: 99,
    pointerEvents: "all !important" as "all",
  },
  messageRoot: {
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.05),0px 6px 10px 0px rgba(0,0,0,0.1),0px 1px 18px 0px rgba(0,0,0,0.1)",
    paddingRight: 20,
    "& div": {
      fontSize: 15,
    },
  },
  closeIcon: {
    width: 22,
    height: 22,
  },
}));

export default useStyles;

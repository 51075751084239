import React from "react";
import { useSystemList, useSystemRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { SystemListFilter } from "../SystemListFilter";
import { SystemListTable } from "../SystemListTable";

export const SystemListPage: React.FC = () => {
  const { SystemIcon, navigateToCreateSystemPage } = useSystemRoutes();

  const { systemList, isLoading, hasNextPage, fetchNextPage } = useSystemList();

  return (
    <BoxHeadlineContainer
      boxTitle="Systeme"
      boxIcon={SystemIcon}
      boxSubTitle={systemList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neues System"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateSystemPage()}
        />
      }
    >
      <SystemListFilter />
      <SystemListTable
        listIntent="list"
        systemList={systemList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

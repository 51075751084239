import React, { useMemo } from "react";
import { Ticket } from "types";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";

type CustomerFilterProps = {
  ticketList: Ticket[];
};

export const CustomerFilter: React.FC<CustomerFilterProps> = ({
  ticketList,
}) => {
  const customerOptionList = useMemo(() => {
    const customerOptionListUnique = new Set(
      ticketList
        .map((ticket) =>
          ticket?.accountName ? ticket.accountName : "- Leer -",
        )
        .sort((a, b) => {
          if (a === "- Leer -") {
            return -1;
          } else if (b === "- Leer -") {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        }),
    );

    return Array.from(customerOptionListUnique.values()).map(
      (customerOption) => {
        return {
          label: customerOption,
          value: encodeURIComponent(customerOption),
        };
      },
    );
  }, [ticketList]);

  return (
    <OptionsFilter
      param="customer"
      paramName="Kunde"
      options={customerOptionList}
    />
  );
};

import React from "react";
import { useCustomerAllList } from "hooks";
import useStyles from "./styles";

type CustomerColumnFormatterProps = {
  customerID: string;
};

export const CustomerColumnFormatter: React.FC<
  CustomerColumnFormatterProps
> = ({ customerID }) => {
  const { classes } = useStyles();

  const { customerList } = useCustomerAllList(false);

  const customer = customerList.find((customer) => customer.id === customerID);

  return (
    <span className={classes.customer}>
      {customer ? customer.Account_Name : customerID}
    </span>
  );
};

import React from "react";
import { SelectOption } from "types";
import { CustomSelect, CustomSelectHandles } from "core";

type TicketStatusOptionProps = {
  ticketStatus: SelectOption | null;
  setTicketStatus: (value: React.SetStateAction<SelectOption | null>) => void;
  ticketStatusInputRef?: React.RefObject<CustomSelectHandles>;
  required?: boolean;
};

export const ticketStatusOptionList = [
  {
    label: "Offen",
    value: "Offen",
  },
  {
    label: "In Bearbeitung",
    value: "In Bearbeitung",
  },
  {
    label: "Blockiert",
    value: "Blockiert",
  },
  {
    label: "Fertig",
    value: "Fertig",
  },
];

export const TicketStatusOption: React.FC<TicketStatusOptionProps> = ({
  ticketStatus = {
    label: "Offen",
    value: "Offen",
  },
  setTicketStatus,
  ticketStatusInputRef,
  required,
}) => {
  return (
    <CustomSelect<SelectOption>
      label="Ticket-Status"
      options={ticketStatusOptionList}
      onChange={(ticketStatusOption) => setTicketStatus(ticketStatusOption)}
      placeholder="Ticket-Status auswählen"
      value={ticketStatus}
      ref={ticketStatusInputRef}
      required={required}
    />
  );
};

import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getEmployeeList } from "modules/employees/api";
import { useEmployeeListFilterAndSort } from "../useEmployeeListFilterAndSort";

export const useEmployeeAllList = (filter = true) => {
  const filterAndSortEmployees = useEmployeeListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Employees],
    queryFn: async () => {
      const employeeList = await getEmployeeList();

      console.log("employeeList", employeeList);

      return employeeList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const employeeList = useMemo(
    () => filterAndSortEmployees(data ?? []),
    [data, filterAndSortEmployees],
  );

  return {
    employeeList,
    isLoading,
  };
};

import React from "react";
import { usePermanentTaskList, usePermanentTaskRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { PermanentTaskListFilter } from "../PermanentTaskListFilter";
import { PermanentTaskListTable } from "../PermanentTaskListTable";

export const PermanentTaskListPage: React.FC = () => {
  const { PermanentTaskIcon, navigateToCreatePermanentTaskPage } =
    usePermanentTaskRoutes();

  const { permanentTaskList, isLoading, hasNextPage, fetchNextPage } =
    usePermanentTaskList();

  return (
    <BoxHeadlineContainer
      boxTitle="Daueraufgaben"
      boxIcon={PermanentTaskIcon}
      boxSubTitle={permanentTaskList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neue Daueraufgabe"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreatePermanentTaskPage()}
        />
      }
    >
      <PermanentTaskListFilter />
      <PermanentTaskListTable
        listIntent="list"
        permanentTaskList={permanentTaskList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getTask } from "modules/tasks/api";
import { useEmployeeAllList } from "../../employee/useEmployeeAllList";

type TaskReadParamsType = {
  taskID: string;
};

export const useTaskRead = () => {
  const { taskID } = useParams<TaskReadParamsType>();

  const { employeeList } = useEmployeeAllList(false);

  console.log("Loading task with ID: ", taskID);

  const { data: task, isLoading } = useQuery({
    queryKey: [QueryKeys.Tasks, taskID],
    queryFn: async () => {
      if (!taskID) return null;

      const taskFromAPI = await getTask(taskID);

      return taskFromAPI;
    },
  });

  const employee = useMemo(
    () =>
      employeeList.find((employeeItem) => employeeItem.id === task?.employee),
    [employeeList, task],
  );

  return {
    taskID,
    task: task ?? null,
    employee: employee ?? null,
    isLoading,
  };
};

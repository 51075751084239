import { useNavigate } from "react-router-dom";
import { System } from "types";
import { Routes } from "routes";

export const useSystemRoutes = () => {
  const navigate = useNavigate();

  const SystemIcon = Routes.systems.icon;

  const getCreateSystemPageRoute = () =>
    Routes.systems.subNavigations.systemcreate.path;

  const getEditSystemPageRoute = (system: System) =>
    Routes.systems.subNavigations.system.subNavigations.systemedit.path.replace(
      ":systemID",
      system.id,
    );

  const getSystemPageRoute = (system: System) =>
    Routes.systems.subNavigations.system.path.replace(":systemID", system.id);

  const getSystemListPageRoute = () => Routes.systems.path;

  const navigateToCreateSystemPage = () => {
    navigate(getCreateSystemPageRoute());
  };

  const navigateToEditSystemPage = (system: System) => {
    navigate(getEditSystemPageRoute(system));
  };

  const navigateToSystemPage = (system: System) => {
    navigate(getSystemPageRoute(system));
  };

  const navigateToSystemListPage = () => {
    const searchParams = sessionStorage.getItem(getSystemListPageRoute());

    navigate(
      searchParams
        ? getSystemListPageRoute() + "?" + searchParams
        : getSystemListPageRoute(),
    );
  };

  return {
    SystemIcon,
    getCreateSystemPageRoute,
    getEditSystemPageRoute,
    getSystemPageRoute,
    navigateToCreateSystemPage,
    navigateToEditSystemPage,
    navigateToSystemPage,
    navigateToSystemListPage,
  };
};

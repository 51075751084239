import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useBookingRoutes } from "hooks";
import { Booking } from "types";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { CustomerColumnFormatter } from "modules/shared/columnformatters/CustomerColumnFormatter";
import { BookingDeleteDialog } from "../../bookingDelete/BookingDeleteDialog";

type BookingListTableProps = {
  routeKey: "bookings" | "customerbookings";
  listIntent: "list" | "selection";
  bookingList: Booking[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  bookingSelectionHandler?: (booking: Booking) => void;
};

export const BookingListTable: React.FC<BookingListTableProps> = ({
  routeKey,
  listIntent,
  bookingList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  bookingSelectionHandler,
}) => {
  console.log("Rerendered BookingListTable...");

  const { navigateToBookingPage, navigateToEditBookingPage } =
    useBookingRoutes();

  const [deleteBookingDialogOpen, setDeleteBookingDialogOpen] =
    useState<boolean>(false);
  const [bookingToDelete, setBookingToDelete] = useState<Booking | undefined>();

  const defaultColumns = React.useMemo<ColumnDef<Booking>[]>(
    () => [
      {
        header: "Kunde",
        accessorKey: "customerID",
        cell: ({ row }) => (
          <CustomerColumnFormatter customerID={row.original.customerID} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Jahr",
        accessorKey: "year",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Monat",
        accessorKey: "month",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Gebuchte Minuten",
        accessorKey: "bookedMinutes",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Minuten aus Vormonat",
        accessorKey: "previousMinutes",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Finaler Minutenstand",
        accessorKey: "finalMinutes",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Bezahlt",
        accessorKey: "paid",
        accessorFn: (row) => (row.paid ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Booking>
            row={row}
            listIntent={listIntent}
            openEditRowPage={(row) => navigateToEditBookingPage(routeKey, row)}
            setRowToDelete={setBookingToDelete}
            setDeleteRowDialogOpen={setDeleteBookingDialogOpen}
            rowSelectionHandler={bookingSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditBookingPage, bookingSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Booking) => {
    if (bookingSelectionHandler) {
      bookingSelectionHandler(row);
    } else {
      navigateToBookingPage(routeKey, row);
    }
  };

  return (
    <>
      <BookingDeleteDialog
        dialogOpen={deleteBookingDialogOpen}
        setDialogOpen={setDeleteBookingDialogOpen}
        booking={bookingToDelete}
        routeKey={routeKey}
      />

      <CustomReactTable<Booking>
        data={bookingList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

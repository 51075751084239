import React, { useContext, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useBookingCreate,
  useBookingRead,
  useBookingRoutes,
  useBookingUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { BookingForm, BookingFormHandles } from "../BookingForm";
import useStyles from "./styles";

type BookingFormPageProps = {
  formIntent: "create" | "edit";
  routeKey: "bookings" | "customerbookings";
};

export const BookingFormPage: React.FC<BookingFormPageProps> = ({
  formIntent,
  routeKey,
}) => {
  const { classes } = useStyles();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { booking, isLoading } = useBookingRead();

  const createBookingMutation = useBookingCreate();
  const updateBookingMutation = useBookingUpdate();
  const { navigateToBookingPage, navigateToBookingListPage } =
    useBookingRoutes();

  const bookingFormRef = useRef<BookingFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitBooking = async () => {
    const bookingFormInput = bookingFormRef.current?.validateBookingForm();
    if (!bookingFormInput) {
      console.error("BookingForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Pflegebuchung ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("bookingInput: ", bookingFormInput);

    const mutateBookingRequest = booking
      ? await updateBookingMutation.mutateAsync({
          ...bookingFormInput,
          id: booking.id,
        })
      : await createBookingMutation.mutateAsync(bookingFormInput);

    if (!mutateBookingRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Pflegebuchung ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutateBookingRequest: ", mutateBookingRequest);

    setSubmitLoading(false);

    navigateToBookingPage(routeKey, mutateBookingRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Pflegebuchung " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Pflegebuchung wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Pflegebuchung " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <BookingForm
        booking={booking}
        formIntent={formIntent}
        ref={bookingFormRef}
      />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Pflegebuchung " +
              (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitBooking()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => navigateToBookingListPage(routeKey)}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useEffortRuleRead, useEffortRuleRoutes } from "hooks";
import { EffortRule } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { DeleteIcon, EditPenIcon } from "modules/icons";
import { getWebsiteTypeName } from "modules/shared/options/WebsiteTypeOption";
import { EffortRuleDeleteDialog } from "../../effortruleDelete/EffortRuleDeleteDialog";
import useStyles from "./styles";

type EffortRulePageProps = {
  routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules";
};

export const EffortRulePage: React.FC<EffortRulePageProps> = ({ routeKey }) => {
  const { classes } = useStyles();

  const { effortRule, isLoading } = useEffortRuleRead();
  const { navigateToEditEffortRulePage } = useEffortRuleRoutes();

  const [deleteEffortRuleDialogOpen, setDeleteEffortRuleDialogOpen] =
    useState<boolean>(false);
  const [effortRuleToDelete, setEffortRuleToDelete] = useState<
    EffortRule | undefined
  >();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Aufwandsregel Details">
        <Loading
          description="Bitte warten. Aufwandsregel wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (effortRule === undefined || effortRule === null) {
    return (
      <BoxHeadlineContainer boxTitle="Aufwandsregel Details">
        Aufwandsregel nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        !isLoading && effortRule
          ? effortRule.taskType.name
          : "Aufwandsregel-Details"
      }
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditPenIcon />}
            onClick={() => navigateToEditEffortRulePage(routeKey, effortRule)}
            size="small"
            color="blue"
            accessKey="e"
            rootClassName={classes.editButton}
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setEffortRuleToDelete(effortRule);
              setDeleteEffortRuleDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <EffortRuleDeleteDialog
        dialogOpen={deleteEffortRuleDialogOpen}
        setDialogOpen={setDeleteEffortRuleDialogOpen}
        effortRule={effortRuleToDelete}
        routeKey={routeKey}
      />

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Website-Typ"
            content={getWebsiteTypeName(effortRule.websiteType)}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="System" content={effortRule.system.name} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Aufgaben-Typ"
            content={effortRule.taskType.name}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Aufwand in Minuten"
            content={effortRule.effort}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { ReportEntry } from "types";

export const useReportEntryDelete = () => {
  const deleteMutation = useMutation({
    mutationFn: async (reportEntry: ReportEntry) => {
      const { data } = await client.models.ReportEntry.delete({
        id: reportEntry.id,
      });

      if (!data) return null;

      return reportEntry;
    },
    // When mutate is called:
    onMutate: async (newReportEntry) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.ReportEntries, newReportEntry.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.ReportEntries],
      });

      // Snapshot the previous value
      const previousReportEntry = queryClient.getQueryData([
        QueryKeys.ReportEntries,
        newReportEntry.id,
      ]);

      // Optimistically update to the new value
      if (previousReportEntry) {
        queryClient.setQueryData(
          [QueryKeys.ReportEntries, newReportEntry.id],
          /**
           * `newReportEntry` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousReportEntry, ...newReportEntry },
        );
      }

      // Return a context with the previous and new reportEntry
      return {
        previousReportEntry,
        newReportEntry,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newReportEntry, context) => {
      console.error("Error updating record:", err, newReportEntry);
      if (context?.previousReportEntry) {
        queryClient.setQueryData(
          [QueryKeys.ReportEntries, context.newReportEntry.id],
          context.previousReportEntry,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newReportEntry) => {
      if (newReportEntry) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ReportEntries, newReportEntry.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ReportEntries],
        });
      }
    },
  });

  return deleteMutation;
};

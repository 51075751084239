import React from "react";
import useStyles from "./styles";

type WarningProps = {
  rootClassName?: string;
  content: React.ReactNode;
  color?: "orange" | "flatRed" | "red";
  size?: "small" | "medium" | "large";
  marginTop?: boolean;
  style?: React.CSSProperties;
};

export const Warning: React.FC<WarningProps> = ({
  content,
  color = "flatRed",
  rootClassName,
  marginTop = true,
  size = "small",
  style,
}) => {
  const { classes, cx } = useStyles();

  const colorClass =
    color === "orange"
      ? classes.orange
      : color === "flatRed"
      ? classes.flatRed
      : classes.red;

  const sizeClass =
    size === "large"
      ? classes.large
      : size === "medium"
      ? classes.medium
      : classes.small;

  return (
    <span
      style={{ marginTop: marginTop ? 18 : 0, ...style }}
      className={cx(classes.warning, colorClass, sizeClass, rootClassName)}
    >
      {content}
    </span>
  );
};

import React from "react";
import useStyles from "./styles";

type IconColumnFormatterProps = {
  icon: string;
};

export const IconColumnFormatter: React.FC<IconColumnFormatterProps> = ({
  icon,
}) => {
  const { classes, cx } = useStyles();

  return (
    <span>
      <i className={cx(icon, classes.icon)}></i>
      {icon}
    </span>
  );
};

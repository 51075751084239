import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "context";
import { useAuthRoutes } from "hooks";
import { Helmet } from "react-helmet";
import { ArrowIcon, HomeIcon } from "modules/icons";
import {
  RouteType,
  checkRoute,
  getBreadcrumbs,
  getRouteLastParamName,
  getRouteParamName,
} from "routes";
import useStyles from "./styles";

export const Breadcrumbs: React.FC = () => {
  const { classes, cx } = useStyles();
  const authContext = useAuthContext();
  const { pathname } = useLocation();

  const { getStartPageRoute } = useAuthRoutes();

  const breadcrumbs = getBreadcrumbs(pathname);

  const breadcrumbsContentRef = useRef<HTMLDivElement>(null);

  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  const [isTooLong, setIsTooLong] = useState<boolean>(false);

  useEffect(() => {
    setIsTooLong(
      breadcrumbsContentRef.current!.offsetWidth >
        breadcrumbsRef.current!.offsetWidth - 50,
    );
  }, [pathname]);

  const getPathOfRoute = (route: RouteType) => {
    let path = route.path
      .replace(":customerID", authContext.customer?.id ?? "")
      .replace(":systemID", authContext.system?.id ?? "")
      .replace(":templateID", authContext.template?.id ?? "")
      .replace(":hosterID", authContext.hoster?.id ?? "")
      .replace(":taskTypeID", authContext.taskType?.id ?? "")
      .replace(":websiteID", authContext.website?.id ?? "")
      .replace(":bookingID", authContext.booking?.id ?? "")
      .replace(":permanentTaskID", authContext.permanentTask?.id ?? "");

    if (route.isWithParam) {
      path = path.replace(
        getRouteParamName(route.path),
        authContext.currentData?.id ?? "",
      );
    }
    return path;
  };

  const getNameOfRouteWithParam = (route: RouteType) => {
    const routeLastParamName = getRouteLastParamName(route.path);

    if (!routeLastParamName) {
      return "";
    }

    if (routeLastParamName === ":customerID" && !checkRoute(pathname, route)) {
      return authContext.customer?.Account_Name;
    } else if (
      routeLastParamName === ":systemID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.system?.name;
    } else if (
      routeLastParamName === ":templateID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.template?.name;
    } else if (
      routeLastParamName === ":hosterID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.hoster?.name;
    } else if (
      routeLastParamName === ":taskTypeID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.taskType?.name;
    } else if (
      routeLastParamName === ":websiteID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.website?.url;
    } else if (
      routeLastParamName === ":bookingID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.booking
        ? authContext.booking.month + "/" + authContext.booking.year
        : "";
    } else if (
      routeLastParamName === ":permanentTaskID" &&
      !checkRoute(pathname, route)
    ) {
      return authContext.permanentTask?.taskType.name;
    } else {
      return authContext.currentData?.name;
    }
  };

  return (
    <div ref={breadcrumbsRef} className={classes.breadcrumbs}>
      <div ref={breadcrumbsContentRef} className={classes.breadcrumbsContent}>
        <Link
          className={cx(
            classes.breadcrumbLink,
            classes.breadcrumbLinkNormal,
            classes.homeLink,
          )}
          to={getStartPageRoute()}
        >
          <HomeIcon className={classes.homeIcon} />
        </Link>

        <ArrowIcon
          className={cx(
            classes.arrowIcon,
            isTooLong ? classes.arrowIconSmall : classes.arrowIconNormal,
          )}
        />

        {breadcrumbs.map((route, i) =>
          i + 1 === breadcrumbs.length ? (
            route.isWithParam ? (
              authContext.isLoadingCurrentData ||
              authContext.isLoadingStateEntities ? (
                <span key={i} className={classes.loadingName}></span>
              ) : (
                <span
                  className={cx(
                    isTooLong
                      ? classes.breadcrumbNameSmall
                      : classes.breadcrumbNameNormal,
                  )}
                  key={i}
                >
                  <Helmet>
                    <title>{getNameOfRouteWithParam(route)}</title>
                  </Helmet>
                  {getNameOfRouteWithParam(route)}
                </span>
              )
            ) : (
              <span
                className={cx(
                  isTooLong
                    ? classes.breadcrumbNameSmall
                    : classes.breadcrumbNameNormal,
                )}
                key={i}
              >
                <Helmet>
                  <title>{route.title}</title>
                </Helmet>
                {route.title}
              </span>
            )
          ) : (
            <>
              <Link
                className={cx(
                  classes.breadcrumbLink,
                  isTooLong
                    ? classes.breadcrumbLinkSmall
                    : classes.breadcrumbLinkNormal,
                )}
                to={getPathOfRoute(route)}
              >
                {route.isWithParam
                  ? getNameOfRouteWithParam(route)
                  : route.title}
              </Link>

              <ArrowIcon
                className={cx(
                  classes.arrowIcon,
                  isTooLong ? classes.arrowIconSmall : classes.arrowIconNormal,
                )}
              />
            </>
          ),
        )}
      </div>
    </div>
  );
};

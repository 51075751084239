import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";

export const useUserDelete = () => {
  const deleteMutation = useMutation({
    mutationFn: async (user: { id: string }) => {
      const { data: deletedUser } = await client.models.User.delete(user);
      return deletedUser;
    },
    // When mutate is called:
    onMutate: async (newUser) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Users, newUser.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Users],
      });

      // Snapshot the previous value
      const previousUser = queryClient.getQueryData([
        QueryKeys.Users,
        newUser.id,
      ]);

      // Optimistically update to the new value
      if (previousUser) {
        queryClient.setQueryData([QueryKeys.Users, newUser.id], newUser);
      }

      // Return a context with the previous and new user
      return { previousUser, newUser };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newUser, context) => {
      console.error("Error deleting record:", err, newUser);
      if (context?.previousUser) {
        queryClient.setQueryData(
          [QueryKeys.Users, context.newUser.id],
          context.previousUser,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newUser) => {
      if (newUser) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Users, newUser.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Users],
        });
      }
    },
  });

  return deleteMutation;
};

import { client } from "queryClient";
import { Hoster, HosterSelectionSet } from "types";

export const getHosterList = async (
  nextTokenParam: string | null = null,
): Promise<{ hosterList: Hoster[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Hoster.list({
    limit: 100,
    nextToken: nextTokenParam,
    selectionSet: HosterSelectionSet,
  });

  return {
    hosterList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllHosterList = async (
  nextTokenParam: string | null = null,
  prevHosterList: Hoster[] = [],
): Promise<{ hosterList: Hoster[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Hoster.list({
    limit: 500,
    nextToken: nextTokenParam,
    selectionSet: HosterSelectionSet,
  });

  const hosterList = [...prevHosterList, ...data];

  return nextToken
    ? getAllHosterList(nextToken, hosterList)
    : { hosterList, nextToken: null };
};

export const getHoster = async (id: string): Promise<Hoster | null> => {
  const { data } = await client.models.Hoster.get(
    {
      id,
    },
    {
      selectionSet: HosterSelectionSet,
    },
  );

  return data;
};

export const isHosterUsed = async (hoster: Hoster): Promise<boolean> => {
  return hoster.websites.length > 0;
};

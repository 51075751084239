import React from "react";
import { Grid } from "@mui/material";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";
import { SystemFilter } from "modules/shared/filters/SystemFilter";

export const TemplateListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <SystemFilter />
      </Grid>
      <Grid item>
        <SearchTextFilter
          searchTextParamName={SearchTextParamName.SearchTemplate}
        />
      </Grid>
    </FilterRow>
    <FilterRow>
      <Grid item>
        <NumberFilter param="usability" paramName="Benutzbarkeit" />
      </Grid>
      <Grid item>
        <NumberFilter param="beauty" paramName="Schönheit" />
      </Grid>
      <Grid item>
        <NumberFilter param="purchasedLicences" paramName="Gekaufte Lizenzen" />
      </Grid>
      <Grid item>
        <NumberFilter param="usage" paramName="Verwendung" />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

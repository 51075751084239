import moment from "moment";
import { SortDirection, TypeKeysEnum } from "types";

export interface SortField<T> {
  field: keyof T;
  direction: SortDirection;
}

export const sortByTextField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "string" || typeof bFieldValue !== "string") {
    return 0;
  }

  if (!aFieldValue && !bFieldValue) return 0;
  if (!aFieldValue) return 1;
  if (!bFieldValue) return -1;

  if (sortDirection === SortDirection.asc) {
    return aFieldValue.localeCompare(bFieldValue);
  }

  return bFieldValue.localeCompare(aFieldValue);
};

export const sortByDateField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "string" || typeof bFieldValue !== "string") {
    return 0;
  }

  if (!aFieldValue && !bFieldValue) return 0;
  if (!aFieldValue) return 1;
  if (!bFieldValue) return -1;

  if (sortDirection === SortDirection.asc) {
    return moment(bFieldValue).diff(moment(aFieldValue));
  }

  return moment(aFieldValue).diff(moment(bFieldValue));
};

export const sortByTimeField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "string" || typeof bFieldValue !== "string") {
    return 0;
  }

  if (!aFieldValue && !bFieldValue) return 0;
  if (!aFieldValue) return 1;
  if (!bFieldValue) return -1;

  if (sortDirection === SortDirection.asc) {
    return moment("2024-01-01T" + bFieldValue + "Z").diff(
      moment("2024-01-01T" + aFieldValue + "Z"),
    );
  }

  return moment("2024-01-01T" + aFieldValue + "Z").diff(
    moment("2024-01-01T" + bFieldValue + "Z"),
  );
};

export const sortByBooleanField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "boolean" || typeof bFieldValue !== "boolean") {
    return 0;
  }

  if (
    (aFieldValue === null || aFieldValue === undefined) &&
    (bFieldValue === null || bFieldValue === undefined)
  )
    return 0;
  if (aFieldValue === null || aFieldValue === undefined) return 1;
  if (bFieldValue === null || bFieldValue === undefined) return -1;

  if (sortDirection === SortDirection.asc) {
    return aFieldValue === bFieldValue ? 0 : aFieldValue ? 1 : -1;
  }

  return aFieldValue === bFieldValue ? 0 : aFieldValue ? -1 : 1;
};

export const sortByNumberField = <T>(
  a: T,
  b: T,
  field: keyof T,
  sortDirection: SortDirection,
) => {
  const aFieldValue = a[field];
  const bFieldValue = b[field];
  if (typeof aFieldValue !== "number" || typeof bFieldValue !== "number") {
    return 0;
  }

  if (
    (aFieldValue === null || aFieldValue === undefined) &&
    (bFieldValue === null || bFieldValue === undefined)
  )
    return 0;
  if (aFieldValue === null || aFieldValue === undefined) return 1;
  if (bFieldValue === null || bFieldValue === undefined) return -1;

  if (sortDirection === SortDirection.asc) {
    return aFieldValue - bFieldValue;
  }

  return bFieldValue - aFieldValue;
};

export const getSortFields = <T>(
  sortParam: string | null,
  defaultSorts: Partial<Record<keyof T, SortDirection>>,
): SortField<T>[] => {
  if (sortParam && sortParam.length > 0) {
    const sortFields = sortParam.split(",");
    const defaultSortFields = defaultSorts
      ? Object.entries(defaultSorts).filter(
          ([key]) => !sortFields.some((field) => field.startsWith(key)),
        )
      : [];
    sortFields.push(
      ...defaultSortFields.map(([key, value]) => `${key}:${value}`),
    );

    return sortFields.map((sortField) => {
      const [field, direction] = sortField.split(":");
      return {
        field: field as keyof T,
        direction:
          direction === "desc" ? SortDirection.desc : SortDirection.asc,
      };
    });
  } else if (defaultSorts && Object.keys(defaultSorts).length > 0) {
    return Object.entries(defaultSorts)
      .map(([key, value]) => `${key}:${value}`)
      .map((sortField) => {
        const [field, direction] = sortField.split(":");
        return {
          field: field as keyof T,
          direction:
            direction === "desc" ? SortDirection.desc : SortDirection.asc,
        };
      });
  }

  return [];
};

export const sortEntities = <T>(
  entityList: T[],
  sortFields: SortField<T>[],
  sortByFieldFunction: (
    a: T,
    b: T,
    sortField: SortField<T>,
    entityTypeKeys: TypeKeysEnum<T>,
    customSortFunctions: Partial<
      Record<keyof T, (a: T, b: T, direction: SortDirection) => number>
    >,
  ) => number,
  entityTypeKeys: TypeKeysEnum<T>,
  customSortFunctions: Partial<
    Record<keyof T, (a: T, b: T, direction: SortDirection) => number>
  >,
) => {
  if (sortFields.length > 0) {
    const sortedEntityList = entityList.sort((a, b) => {
      for (const sortField of sortFields) {
        const value = sortByFieldFunction(
          a,
          b,
          sortField,
          entityTypeKeys,
          customSortFunctions,
        );

        if (value !== 0) return value;
      }
      return 0;
    });

    return sortedEntityList;
  }

  return entityList;
};

export const sortEntitiesByField = <T>(
  a: T,
  b: T,
  sortField: SortField<T>,
  entityTypeKeys: TypeKeysEnum<T>,
  customSortFunctions: Partial<
    Record<keyof T, (a: T, b: T, direction: SortDirection) => number>
  >,
) => {
  const { field, direction } = sortField;

  const fieldConstruct = entityTypeKeys[field];

  if (customSortFunctions[field]) {
    return customSortFunctions[field](a, b, direction);
  }

  if (!fieldConstruct) return 0;

  switch (fieldConstruct.type) {
    case "id":
    case "string":
    case "enum":
      return sortByTextField(a, b, field, direction);
    case "boolean":
      return sortByBooleanField(a, b, field, direction);
    case "datetime":
    case "date":
      return sortByDateField(a, b, field, direction);
    case "time":
      return sortByTimeField(a, b, field, direction);
    case "integer":
    case "timestamp":
    case "float":
      return sortByNumberField(a, b, field, direction);
    default:
      return 0;
  }
};

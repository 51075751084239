import React, { useMemo } from "react";
import { useTaskTypeAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";

export const TaskTypeFilter: React.FC = () => {
  const { taskTypeList } = useTaskTypeAllList(false);

  const taskTypeOptionList = useMemo(
    () =>
      taskTypeList.map((taskTypeOption) => {
        return {
          label: taskTypeOption.name,
          value: taskTypeOption.id,
        };
      }),
    [taskTypeList],
  );

  return (
    <OptionsFilter
      param="taskTypeID"
      paramName="Aufgaben-Typ"
      options={taskTypeOptionList}
    />
  );
};

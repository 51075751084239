import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  employeeName: {
    whiteSpace: "nowrap",
    marginLeft: 5,
  },
  avatar: {
    margin: 0,
  },
}));

export default useStyles;

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  deleteApproveTitle: {
    fontSize: 19,
    fontWeight: 600,
    marginBottom: 30,
    lineHeight: 1.5,
    textAlign: "center",
  },
  deleteApproveText: {
    fontSize: 17,
    fontWeight: 500,
    marginBottom: 30,
    textAlign: "center",
  },
  deleteConfirmText: {
    marginBottom: 30,
  },
}));

export default useStyles;

import React, { useMemo } from "react";
import { useTemplateAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";

export const TemplateFilter: React.FC = () => {
  const { templateList } = useTemplateAllList(false);

  const templateOptionList = useMemo(
    () =>
      templateList.map((templateOption) => {
        return {
          label: templateOption.name,
          value: templateOption.id,
        };
      }),
    [templateList],
  );

  return (
    <OptionsFilter
      param="templateID"
      paramName="Template"
      options={templateOptionList}
    />
  );
};

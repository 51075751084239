import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateSystemInput, System } from "types";
import {
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSwitch,
  Editor,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { useSystemForm } from "../useSystemForm";
import utils from "utils";
import useStyles from "./styles";

type SystemFormProps = {
  formIntent: "create" | "edit";
  system: System | null | undefined;
};

export type SystemFormHandles = {
  validateSystemForm(): CreateSystemInput | null;
};

const SystemFormComponent: React.ForwardRefRenderFunction<
  SystemFormHandles,
  SystemFormProps
> = ({ formIntent, system }, systemFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    name,
    setName,
    rating,
    setRating,
    complexity,
    setComplexity,
    updatesNeeded,
    setUpdatesNeeded,
    description,
    setDescription,
    resetSystemForm,
  } = useSystemForm(formIntent, system);

  const nameInputRef = useRef<TextInputFieldHandles>(null);
  const ratingInputRef = useRef<CustomNumberFieldHandles>(null);
  const complexityInputRef = useRef<CustomNumberFieldHandles>(null);

  useImperativeHandle(systemFormRef, () => ({
    validateSystemForm: () => {
      if (name === "") {
        enqueueSnackbar("Bitte gib einen Namen ein!");
        nameInputRef.current?.highlight();
        return null;
      }

      if (rating === "") {
        enqueueSnackbar("Bitte gib eine Bewertung ein!");
        ratingInputRef.current?.highlight();
        return null;
      }

      if (complexity === "") {
        enqueueSnackbar("Bitte gib die Komplexität ein!");
        complexityInputRef.current?.highlight();
        return null;
      }

      const systemFormInput: CreateSystemInput = {
        name: name,
        rating: rating,
        complexity: complexity,
        updatesNeeded: updatesNeeded,
        description: utils.wysiwyg.getStringFromEditorState(description),
      };

      resetSystemForm();

      return systemFormInput;
    },
  }));

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.gridRow}
      >
        <Grid item md={4}>
          <TextInputField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            ref={nameInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Bewertung"
            number={rating}
            setNumber={setRating}
            minValue={1}
            maxValue={10}
            ref={ratingInputRef}
            required={true}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.gridRow}
      >
        <Grid item md={4}>
          <CustomNumberField
            label="Komplexität"
            number={complexity}
            setNumber={setComplexity}
            minValue={1}
            maxValue={10}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomSwitch
            label="Benötigt Updates?"
            switchLabel={updatesNeeded ? "JA" : "NEIN"}
            name="Benötigt Updates?"
            checkedValue={updatesNeeded}
            onChange={(_e, checked) => setUpdatesNeeded(checked)}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={12}>
          <Editor
            label="Anmerkungen"
            setEditor={setDescription}
            initialValue={system?.description}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const SystemForm = React.forwardRef(SystemFormComponent);

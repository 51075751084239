import { SelectionSet } from "aws-amplify/api";
import { InfiniteData } from "@tanstack/react-query";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const TaskTypeSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "name",
  "icon",
  "deadlineDays",
  "employee",
  "effort",
  "description",
  "effortRules.*",
  "effortDefinitions.*",
  "tasks.*",
  "permanentTasks.*",
] as const;

export type TaskType = SelectionSet<
  Schema["TaskType"]["type"],
  typeof TaskTypeSelectionSet
>;

export type CreateTaskTypeInput = Schema["TaskType"]["createType"];

export type UpdateTaskTypeInput = Schema["TaskType"]["updateType"];

export type InfiniteTaskTypeList = {
  taskTypeList: TaskType[];
  nextToken: string | null;
};

export type InfiniteTaskTypeData =
  | InfiniteData<InfiniteTaskTypeList, unknown>
  | undefined;

export const TaskTypeTypeKeys: TypeKeysEnum<TaskType> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  name: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.name,
  },
  icon: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.icon,
  },
  deadlineDays: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.deadlineDays.toString(),
  },
  employee: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.employee,
  },
  effort: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.effort.toString(),
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
  effortRules: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  effortDefinitions: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  tasks: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  permanentTasks: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfTaskType = (key: string): key is keyof TaskType =>
  key in TaskTypeTypeKeys;

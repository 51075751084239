import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  filterWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px 30px 20px 30px",
    "& > *:not(:last-child)": {
      marginRight: 20,
    },
    [theme.breakpoints.down("md")]: {
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
      background: "white",
      "& > div": {
        flexDirection: "column",
        alignItems: "center",
      },
    },
  },
  filterGrid: {
    flexWrap: "nowrap",
    "& > *:not(:last-child)": {
      marginRight: 20,
    },
    "&:not(:last-child)": {
      marginBottom: 20,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& > *:not(:last-child)": {
    marginRight: 20,
  },
}));

export default useStyles;

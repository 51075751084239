import { client } from "queryClient";
import { Website, WebsiteSelectionSet } from "types";

export const getWebsiteList = async (
  nextTokenParam: string | null = null,
): Promise<{ websiteList: Website[]; nextToken: string | null }> => {
  console.log("Called getWebsiteList with nextTokenParam: ", nextTokenParam);
  const { data, nextToken } = await client.models.Website.list({
    limit: 100,
    // nextToken: nextTokenParam,
    selectionSet: WebsiteSelectionSet,
  });

  console.log("getWebsiteList data: ", data);
  console.log("getWebsiteList nextToken: ", nextToken);

  return {
    websiteList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllWebsiteList = async (
  nextTokenParam: string | null = null,
  prevWebsiteList: Website[] = [],
): Promise<{ websiteList: Website[]; nextToken: string | null }> => {
  console.log("Called getAllWebsiteList with nextTokenParam: ", nextTokenParam);

  const { data, nextToken } = await client.models.Website.list({
    limit: 500,
    nextToken: nextTokenParam,
    selectionSet: WebsiteSelectionSet,
  });

  const websiteList = [...prevWebsiteList, ...data];

  return nextToken
    ? getAllWebsiteList(nextToken, websiteList)
    : { websiteList, nextToken: null };
};

export const getWebsiteListByCustomer = async (
  customerID: string | undefined,
  nextTokenParam: string | null = null,
  prevWebsiteList: Website[] = [],
): Promise<Website[]> => {
  if (!customerID) {
    return [];
  }

  const { data, nextToken } = await client.models.Website.websitesByPayer(
    {
      websitePayer: customerID,
    },
    {
      limit: 500,
      nextToken: nextTokenParam,
      selectionSet: WebsiteSelectionSet,
    },
  );

  const websiteList = [...prevWebsiteList, ...data];

  return nextToken
    ? getWebsiteListByCustomer(customerID, nextToken, websiteList)
    : websiteList;
};

export const getWebsiteListByTemplate = async (
  templateID: string | undefined,
  nextTokenParam: string | null = null,
  prevWebsiteList: Website[] = [],
): Promise<Website[]> => {
  if (!templateID) {
    return [];
  }

  const { data, nextToken } = await client.models.Website.websitesByTemplate(
    {
      templateID,
    },
    {
      limit: 500,
      nextToken: nextTokenParam,
      selectionSet: WebsiteSelectionSet,
    },
  );

  const websiteList = [...prevWebsiteList, ...data];

  return nextToken
    ? getWebsiteListByTemplate(templateID, nextToken, websiteList)
    : websiteList;
};

export const getWebsite = async (id: string): Promise<Website | null> => {
  const { data } = await client.models.Website.get(
    {
      id,
    },
    {
      selectionSet: WebsiteSelectionSet,
    },
  );

  return data;
};

export const isWebsiteUsed = async (website: Website) => {
  return (
    website.tasks.length > 0 ||
    website.effortDefinitions.length > 0 ||
    website.permanentTasks.length > 0 ||
    website.reportEntries.length > 0
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 30,
    },
  },
  editButton: {
    marginRight: 15,
  },
  careReportPreviewButton: {
    marginTop: 50,
    width: "100%",
    textAlign: "center",
  },
  careReportCompletedButtons: {
    marginTop: 50,
  },
  reportContainer: {
    marginTop: 100,
  },
}));

export default useStyles;

import React from "react";
import { Grid } from "@mui/material";
import { FilterRow, FilterWrapper } from "components";
import { BooleanFilter } from "modules/shared/filters/BooleanFilter";
import { DateFilter } from "modules/shared/filters/DateFilter";
import { EmployeeFilter } from "modules/shared/filters/EmployeeFilter";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";
import { TaskTypeFilter } from "modules/shared/filters/TaskTypeFilter";
import { TimeFilter } from "modules/shared/filters/TimeFilter";
import { WebsiteFilter } from "modules/shared/filters/WebsiteFilter";
import { getBillTypeOptions } from "modules/shared/options/BillTypeOption";
import { getTaskStatusOptions } from "modules/shared/options/TaskStatusOption";

export const TaskListFilter: React.FC = () => (
  <FilterWrapper>
    <FilterRow>
      <Grid item>
        <TaskTypeFilter />
      </Grid>
      <Grid item>
        <WebsiteFilter />
      </Grid>
      <Grid item>
        <NumberFilter param="effort" paramName="Aufwand" />
      </Grid>
    </FilterRow>
    <FilterRow>
      <Grid item>
        <DateFilter param="date" paramName="Datum" />
      </Grid>
      <Grid item>
        <TimeFilter param="time" paramName="Uhrzeit" />
      </Grid>
    </FilterRow>
    <FilterRow>
      <Grid item>
        <BooleanFilter param="billed" paramName="Abgerechnet" />
      </Grid>
      <Grid item>
        <OptionsFilter
          param="billType"
          paramName="Abrechnungsart"
          options={getBillTypeOptions()}
        />
      </Grid>
      <Grid item>
        <OptionsFilter
          param="status"
          paramName="Status"
          options={getTaskStatusOptions()}
        />
      </Grid>
      <Grid item>
        <EmployeeFilter />
      </Grid>
    </FilterRow>
  </FilterWrapper>
);

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  gridRow: {
    "&:not(:last-child)": {
      marginBottom: 33,
    },
  },
  gridItem: {
    marginBottom: 30,
    "&:not(:last-child)": {
      marginRight: 100,
    },
  },
  buttonsRow: {
    marginTop: 70,
  },
  editButton: {
    marginRight: 15,
  },
  icon: {
    height: 21,
    width: 21,
    verticalAlign: "text-bottom",
    marginRight: 7,
  },
}));

export default useStyles;

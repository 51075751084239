import React from "react";
import { useCustomerList } from "hooks";
import { Customer } from "types";
import { CustomDialog } from "core";
import { CustomerListFilter } from "../../customerlist/CustomerListFilter";
import { CustomerListTable } from "../../customerlist/CustomerListTable";

type CustomerSelectionDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  customerSelectionHandler: (customer: Customer) => void;
};

export const CustomerSelectionDialog: React.FC<
  CustomerSelectionDialogProps
> = ({ dialogOpen, setDialogOpen, customerSelectionHandler }) => {
  const { customerList, isLoading, hasNextPage, fetchNextPage } =
    useCustomerList();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={false}
      titleText="Kunden auswählen"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={false}
      fullWidth={true}
      maxWidth="lg"
      contentPadding={0}
    >
      <CustomerListFilter />
      <CustomerListTable
        listIntent="selection"
        customerList={customerList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        customerSelectionHandler={customerSelectionHandler}
      />
    </CustomDialog>
  );
};

import { useNavigate } from "react-router-dom";
import { AuthRoutes, Routes } from "routes";

export const useAuthRoutes = () => {
  const navigate = useNavigate();

  const getStartPageRoute = () => Routes.start.path;

  const getLoginPageRoute = () => AuthRoutes.login;

  const getVerifyRoute = () => AuthRoutes.verify;

  const getForgotPasswordPageRoute = () => AuthRoutes.forgotPassword;

  const getConfirmForgotPasswordPageRoute = () =>
    AuthRoutes.confirmForgotPassword;

  const getUserSettingsPageRoute = () => Routes.settings.path;

  const navigateToStartPage = () => {
    navigate(getStartPageRoute());
  };

  const navigateToLoginPage = () => {
    navigate(getLoginPageRoute());
  };

  const navigateToVerifyPage = () => {
    navigate(getVerifyRoute());
  };

  const navigateToForgotPasswordPage = () => {
    navigate(getForgotPasswordPageRoute());
  };

  const navigateToConfirmForgotPasswordPage = () => {
    navigate(getConfirmForgotPasswordPageRoute());
  };

  const navigateToUserSettingsPage = () => {
    navigate(getUserSettingsPageRoute());
  };

  return {
    getStartPageRoute,
    getLoginPageRoute,
    getVerifyRoute,
    getForgotPasswordPageRoute,
    getConfirmForgotPasswordPageRoute,
    navigateToStartPage,
    navigateToLoginPage,
    navigateToVerifyPage,
    navigateToForgotPasswordPage,
    navigateToConfirmForgotPasswordPage,
    navigateToUserSettingsPage,
  };
};

import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Website } from "types";
import { CustomButton } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import utils from "utils";

type RelationStatisticsProps = {
  websiteList: Website[];
  websitesCreatedByUs: Website[];
  websitesCurrentlyResponsible: Website[];
};

export const RelationStatistics: React.FC<RelationStatisticsProps> = ({
  websiteList,
  websitesCreatedByUs,
  websitesCurrentlyResponsible,
}) => {
  const [colors, setColors] = useState<string[]>(
    Array(3)
      .fill(1)
      .map(() => utils.colors.getRandomColor()),
  );

  const loadNewColors = () => {
    setColors(
      Array(3)
        .fill(1)
        .map(() => utils.colors.getRandomColor()),
    );
  };

  const usageStatisticsData = useMemo(
    () => [
      {
        name: "insgesamt",
        ja: websiteList.length,
        nein: 0,
        fill: colors[0],
      },
      {
        name: "Zuständig",
        ja: websitesCurrentlyResponsible.length,
        nein: websiteList.length - websitesCurrentlyResponsible.length,
      },
      {
        name: "Von uns erstellt",
        ja: websitesCreatedByUs.length,
        nein: websiteList.length - websitesCreatedByUs.length,
      },
    ],
    [
      websiteList.length,
      colors,
      websitesCreatedByUs.length,
      websitesCurrentlyResponsible.length,
    ],
  );

  console.log("usageStatisticsData: ", usageStatisticsData);

  return (
    <BoxHeadlineContainer
      boxTitle="Website-Statistiken"
      marginTop={false}
      boxMenu={
        <CustomButton
          text="Neue Farben"
          onClick={() => loadNewColors()}
          size="small"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={500} height={250} data={usageStatisticsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="ja" stackId="a" fill={colors[1]} />
          <Bar dataKey="nein" stackId="a" fill={colors[2]} />
        </BarChart>
      </ResponsiveContainer>
    </BoxHeadlineContainer>
  );
};

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  headerLeftSide: {
    float: "left",
    width: "59%",
    display: "inline-block",
  },
}));

export default useStyles;

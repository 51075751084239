import React from "react";
import { Grid } from "@mui/material";
import { useAuthContext } from "context";
import { client } from "queryClient";
import { Task } from "types";
import { CustomButton } from "core";
import { BoxContainer } from "layout/Container/BoxContainer";
import { getAllTaskList } from "modules/tasks/api";
import useStyles from "./styles";

const Development: React.FC = () => {
  const { classes } = useStyles();

  const authContext = useAuthContext();

  const updateTask = async (task: Task) => {
    const { data: updatedTask } = await client.models.Task.update({
      id: task.id,
      model: "Task",
    });

    return updatedTask;
  };

  const updateTasks = async () => {
    const { taskList } = await getAllTaskList();

    const updateTaskPromises = taskList.map((task) => updateTask(task));

    const updatedTasks = await Promise.all(updateTaskPromises);

    console.log("updatedTasks: ", updatedTasks);
  };

  return (
    <div className={classes.dashboardPage}>
      <BoxContainer>
        {authContext.userData?.email === "oe-bayram@live.de" && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item>
              <CustomButton
                text="Pauschalaufgaben aktualisieren"
                onClick={() => updateTasks()}
              />
            </Grid>
          </Grid>
        )}
      </BoxContainer>
    </div>
  );
};

export default Development;

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { Task } from "types";

export const useTaskDelete = () => {
  const deleteMutation = useMutation({
    mutationFn: async (task: Task) => {
      const { data } = await client.models.Task.delete({
        id: task.id,
      });

      if (!data) return null;

      return task;
    },
    // When mutate is called:
    onMutate: async (newTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Tasks, newTask.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Tasks],
      });

      // Snapshot the previous value
      const previousTask = queryClient.getQueryData([
        QueryKeys.Tasks,
        newTask.id,
      ]);

      // Optimistically update to the new value
      if (previousTask) {
        queryClient.setQueryData(
          [QueryKeys.Tasks, newTask.id],
          /**
           * `newTask` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousTask, ...newTask },
        );
      }

      // Return a context with the previous and new task
      return {
        previousTask,
        newTask,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newTask, context) => {
      console.error("Error updating record:", err, newTask);
      if (context?.previousTask) {
        queryClient.setQueryData(
          [QueryKeys.Tasks, context.newTask.id],
          context.previousTask,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newTask) => {
      if (newTask) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Tasks, newTask.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Tasks],
        });
      }
    },
  });

  return deleteMutation;
};

import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { CustomDialog, TextInputField } from "core";
import useStyles from "./styles";

type DeleteDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  confirmRequired?: boolean;
  deleteRequest: () => Promise<void>;
  dialogTitle: string;
  itemDeleteDescription?: string;
  itemName: string;
  deleteLoading?: boolean;
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  confirmRequired = true,
  deleteRequest,
  dialogTitle,
  itemDeleteDescription,
  itemName,
  deleteLoading,
}) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [approveText, setApproveText] = useState<string>("");

  if (!dialogOpen) {
    return null;
  }

  const deleteConfirmCheck = async () => {
    try {
      if (confirmRequired && approveText === "") {
        return enqueueSnackbar("Bitte geben Sie den Bestätigungstext ein!");
      }

      if (confirmRequired && approveText !== "Löschen") {
        return enqueueSnackbar(
          "Bitte geben Sie den Bestätigungstext korrekt ein!"
        );
      }

      await deleteRequest();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Beim Löschen von " + itemName + " ist ein Fehler aufgetreten!"
      );
    }
  };

  return (
    <CustomDialog
      confirmText="Löschen"
      dialogOpen={dialogOpen}
      positive={false}
      titleText={dialogTitle}
      setDialogOpen={setDialogOpen}
      confirmAction={() => deleteConfirmCheck()}
      showConfirm={true}
      showDecline={true}
      confirmButtonLoading={deleteLoading}
      contentPadding="40px 40px 20px 40px"
    >
      <Typography variant="h3" className={classes.deleteApproveText}>
        Sind Sie sicher, dass Sie {itemDeleteDescription}{" "}
        <strong>{itemName}</strong> löschen möchten?
      </Typography>

      {confirmRequired && (
        <>
          <Typography variant="body1" className={classes.deleteConfirmText}>
            Bitte geben Sie zur Bestätigung das Wort{" "}
            <strong>&quot;Löschen&quot;</strong> in das Eingabefeld ein!
          </Typography>
          <TextInputField
            label="Löschen Bestätigen"
            value={approveText}
            onChange={(e) => setApproveText(e.target.value)}
          />
        </>
      )}
    </CustomDialog>
  );
};

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateTemplateInput } from "types";
import { getTemplate } from "modules/templates/api";

export const useTemplateUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (templateInput: UpdateTemplateInput) => {
      const { data: updatedTemplate } =
        await client.models.Template.update(templateInput);

      if (!updatedTemplate) return null;

      const template = await getTemplate(updatedTemplate.id);

      return template;
    },
    // When mutate is called:
    onMutate: async (newTemplate) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Templates, newTemplate.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Templates],
      });

      // Snapshot the previous value
      const previousTemplate = queryClient.getQueryData([
        QueryKeys.Templates,
        newTemplate.id,
      ]);

      // Optimistically update to the new value
      if (previousTemplate) {
        queryClient.setQueryData(
          [QueryKeys.Templates, newTemplate.id],
          /**
           * `newTemplate` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousTemplate, ...newTemplate },
        );
      }

      // Return a context with the previous and new template
      return {
        previousTemplate,
        newTemplate,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newTemplate, context) => {
      console.error("Error updating record:", err, newTemplate);
      if (context?.previousTemplate) {
        queryClient.setQueryData(
          [QueryKeys.Templates, context.newTemplate.id],
          context.previousTemplate,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newTemplate) => {
      if (newTemplate) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates, newTemplate.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Templates],
        });
      }
    },
  });

  return updateMutation;
};

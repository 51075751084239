import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getTaskType } from "modules/tasktypes/api";
import { useEmployeeAllList } from "../../employee/useEmployeeAllList";

type TaskTypeReadParamsType = {
  taskTypeID: string;
};

export const useTaskTypeRead = () => {
  const authContext = useAuthContext();

  const { taskTypeID } = useParams<TaskTypeReadParamsType>();

  const { employeeList } = useEmployeeAllList(false);

  console.log("Loading taskType with ID: ", taskTypeID);

  const { data: taskType, isLoading } = useQuery({
    queryKey: [QueryKeys.TaskTypes, taskTypeID],
    queryFn: async () => {
      if (!taskTypeID) return null;

      const taskTypeFromAPI = await getTaskType(taskTypeID);

      if (taskTypeFromAPI) {
        authContext.setTaskType(taskTypeFromAPI);

        authContext.setCurrentData({
          id: taskTypeFromAPI.id,
          name: taskTypeFromAPI.name,
        });
      }

      return taskTypeFromAPI;
    },
  });

  const employee = useMemo(
    () =>
      employeeList.find(
        (employeeItem) => employeeItem.id === taskType?.employee,
      ),
    [employeeList, taskType],
  );

  return {
    taskTypeID,
    taskType: taskType ?? null,
    employee: employee ?? null,
    isLoading,
  };
};

import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTaskTypeRoutes } from "hooks";
import { TaskType } from "types";
import { CustomReactTable } from "core";
import { DescriptionFormatter } from "components";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { EmployeeColumnFormatter } from "modules/shared/columnformatters/EmployeeColumnFormatter";
import { IconColumnFormatter } from "modules/shared/columnformatters/IconColumnFormatter";
import { TaskTypeDeleteDialog } from "../../tasktypeDelete/TaskTypeDeleteDialog";

type TaskTypeListTableProps = {
  listIntent: "list" | "selection";
  taskTypeList: TaskType[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  taskTypeSelectionHandler?: (taskType: TaskType) => void;
};

export const TaskTypeListTable: React.FC<TaskTypeListTableProps> = ({
  listIntent,
  taskTypeList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  taskTypeSelectionHandler,
}) => {
  const { navigateToTaskTypePage, navigateToEditTaskTypePage } =
    useTaskTypeRoutes();

  const [deleteTaskTypeDialogOpen, setDeleteTaskTypeDialogOpen] =
    useState<boolean>(false);
  const [taskTypeToDelete, setTaskTypeToDelete] = useState<
    TaskType | undefined
  >();

  const defaultColumns = React.useMemo<ColumnDef<TaskType>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Icon",
        accessorKey: "icon",
        cell: ({ row }) => <IconColumnFormatter icon={row.original.icon} />,
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwand in m",
        accessorKey: "effort",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Standardfrist",
        accessorKey: "deadlineDays",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Mitarbeiter",
        accessorKey: "employee",
        cell: ({ row }) => (
          <EmployeeColumnFormatter employeeID={row.original.employee} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Beschreibung",
        accessorKey: "description",
        cell: ({ row }) => (
          <DescriptionFormatter description={row.original.description} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<TaskType>
            row={row}
            listIntent={listIntent}
            openEditRowPage={navigateToEditTaskTypePage}
            setRowToDelete={setTaskTypeToDelete}
            setDeleteRowDialogOpen={setDeleteTaskTypeDialogOpen}
            rowSelectionHandler={taskTypeSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditTaskTypePage, taskTypeSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: TaskType) => {
    if (taskTypeSelectionHandler) {
      taskTypeSelectionHandler(row);
    } else {
      navigateToTaskTypePage(row);
    }
  };

  return (
    <>
      <TaskTypeDeleteDialog
        dialogOpen={deleteTaskTypeDialogOpen}
        setDialogOpen={setDeleteTaskTypeDialogOpen}
        taskType={taskTypeToDelete}
      />
      <CustomReactTable<TaskType>
        data={taskTypeList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

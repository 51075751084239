import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { CreateTaskTypeInput, TaskType } from "types";
import {
  CustomNumberField,
  CustomNumberFieldHandles,
  CustomSelectHandles,
  Editor,
  TextInputField,
  TextInputFieldHandles,
} from "core";
import { EmployeeOption } from "modules/shared/modeloptions/EmployeeOption";
import { useTaskTypeForm } from "../useTaskTypeForm";
import utils from "utils";
import useStyles from "./styles";

type TaskTypeFormProps = {
  formIntent: "create" | "edit";
  taskType: TaskType | null | undefined;
};

export type TaskTypeFormHandles = {
  validateTaskTypeForm(): CreateTaskTypeInput | null;
};

const TaskTypeFormComponent: React.ForwardRefRenderFunction<
  TaskTypeFormHandles,
  TaskTypeFormProps
> = ({ formIntent, taskType }, taskTypeFormRef) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    name,
    setName,
    icon,
    setIcon,
    effort,
    setEffort,
    deadlineDays,
    setDeadlineDays,
    employee,
    setEmployee,
    description,
    setDescription,
    resetTaskTypeForm,
  } = useTaskTypeForm(formIntent, taskType);

  const nameInputRef = useRef<TextInputFieldHandles>(null);
  const iconInputRef = useRef<TextInputFieldHandles>(null);
  const effortInputRef = useRef<CustomNumberFieldHandles>(null);
  const deadlineDaysInputRef = useRef<CustomNumberFieldHandles>(null);
  const employeeInputRef = useRef<CustomSelectHandles>(null);

  useImperativeHandle(taskTypeFormRef, () => ({
    validateTaskTypeForm: () => {
      if (name === "") {
        enqueueSnackbar("Bitte gib den Namen des Aufgaben-Typs ein!");
        nameInputRef.current?.highlight();
        return null;
      }

      if (icon === "") {
        enqueueSnackbar("Bitte gib ein Icon für den Aufgaben-Typ ein!");
        iconInputRef.current?.highlight();
        return null;
      }

      if (effort === "") {
        enqueueSnackbar(
          "Bitte gib den Aufwand in Minuten für den Aufgaben-Typ ein!",
        );
        effortInputRef.current?.highlight();
        return null;
      }

      if (deadlineDays === "") {
        enqueueSnackbar(
          "Bitte gib den Standardfrist in Tagen für den Aufgaben-Typ ein!",
        );
        deadlineDaysInputRef.current?.highlight();
        return null;
      }

      if (!employee) {
        enqueueSnackbar(
          "Bitte wähle einen Standard-Mitarbeiter für den Aufgaben-Typ ein!",
        );
        employeeInputRef.current?.highlight();
        return null;
      }

      const taskTypeFormInput: CreateTaskTypeInput = {
        name: name,
        icon: icon,
        effort: effort,
        deadlineDays: deadlineDays,
        employee: employee.id,
        description: utils.wysiwyg.getStringFromEditorState(description),
      };

      resetTaskTypeForm();

      return taskTypeFormInput;
    },
  }));

  return (
    <>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <TextInputField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            ref={nameInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <TextInputField
            label="Icon"
            value={icon}
            onChange={(e) => setIcon(e.target.value)}
            ref={iconInputRef}
            validate={(value) => value.trim() !== ""}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Aufwand in m"
            number={effort}
            setNumber={setEffort}
            minValue={1}
            ref={effortInputRef}
            required={true}
          />
        </Grid>

        <Grid item md={4}>
          <CustomNumberField
            label="Standardfrist in Tagen"
            number={deadlineDays}
            setNumber={setDeadlineDays}
            minValue={1}
            ref={deadlineDaysInputRef}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item md={6}>
          <EmployeeOption employee={employee} setEmployee={setEmployee} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={12}>
          <Editor
            label="Anmerkungen"
            setEditor={setDescription}
            initialValue={taskType?.description}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const TaskTypeForm = React.forwardRef(TaskTypeFormComponent);

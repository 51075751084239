import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  noResults: {
    textAlign: "center",
    cursor: "default",
  },
}));

export default useStyles;

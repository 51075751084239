import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  spacing: {
    clear: "both",
    display: "block",
  },
}));

export default useStyles;

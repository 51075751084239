import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { SearchTextParamName } from "types";
import { FilterRow, FilterWrapper } from "components";
import { BooleanFilter } from "modules/shared/filters/BooleanFilter";
import { CustomerFilter } from "modules/shared/filters/CustomerFilter";
import { HosterFilter } from "modules/shared/filters/HosterFilter";
import { NumberFilter } from "modules/shared/filters/NumberFilter";
import { OptionsFilter } from "modules/shared/filters/OptionsFilter";
import { SearchTextFilter } from "modules/shared/filters/SearchTextFilter";
import { SystemFilter } from "modules/shared/filters/SystemFilter";
import { TemplateFilter } from "modules/shared/filters/TemplateFilter";
import { getBacklinkStatusOptions } from "modules/shared/options/BacklinkStatusOption";
import { getWebsiteTypeOptions } from "modules/shared/options/WebsiteTypeOption";

export const WebsiteListFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const sortField = searchParams.get("sortField");
    if (sortField === "lastRun") return;

    searchParams.set("sortField", "lastRun");
    sessionStorage.setItem(location.pathname, searchParams.toString());
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastRunOptionList = useMemo(
    () => [
      {
        label: "Nur Webseiten mit letzter Ausführung",
        value: "withLastRun",
      },
      {
        label: "Nur Webseiten ohne Ausführung",
        value: "withoutLastRun",
      },
    ],
    [],
  );

  return (
    <FilterWrapper>
      <FilterRow>
        <Grid item>
          <OptionsFilter
            param="lastRun"
            paramName="Ausführung"
            options={lastRunOptionList}
          />
        </Grid>
        <Grid item>
          <CustomerFilter param="websiteOwner" paramName="Besitzer" />
        </Grid>
        <Grid item>
          <CustomerFilter param="websitePayer" paramName="Zahler" />
        </Grid>
        <Grid item>
          <OptionsFilter
            param="websiteType"
            paramName="Website-Typ"
            options={getWebsiteTypeOptions()}
          />
        </Grid>
      </FilterRow>
      <FilterRow>
        <Grid item>
          <BooleanFilter param="currentlyResponsible" paramName="Zuständig" />
        </Grid>
        <Grid item>
          <BooleanFilter param="createdByUs" paramName="Von uns erstellt" />
        </Grid>
        <Grid item>
          <OptionsFilter
            param="backlinkErstellung"
            paramName="GW Backlink Erstellung"
            options={getBacklinkStatusOptions()}
          />
        </Grid>
        <Grid item>
          <OptionsFilter
            param="backlinkPflege"
            paramName="GW Backlink Pflege"
            options={getBacklinkStatusOptions()}
          />
        </Grid>
        <Grid item>
          <OptionsFilter
            param="formiloBacklink"
            paramName="Formilo Backlink"
            options={getBacklinkStatusOptions()}
          />
        </Grid>
      </FilterRow>
      <FilterRow>
        <Grid item>
          <SystemFilter />
        </Grid>
        <Grid item>
          <HosterFilter />
        </Grid>
        <Grid item>
          <TemplateFilter />
        </Grid>
        <Grid item>
          <NumberFilter param="rating" paramName="Bewertung" />
        </Grid>
        <Grid item>
          <SearchTextFilter
            searchTextParamName={SearchTextParamName.SearchWebsiteStatus}
          />
        </Grid>
      </FilterRow>
    </FilterWrapper>
  );
};

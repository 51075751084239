import React, { useMemo } from "react";
import { useWebsiteAllList } from "hooks";
import { OptionsFilter } from "../OptionsFilter";

export const WebsiteFilter: React.FC = () => {
  const { websiteList } = useWebsiteAllList(false);

  const websiteOptionList = useMemo(
    () =>
      websiteList.map((websiteOption) => {
        return {
          label: websiteOption.url,
          value: websiteOption.id,
        };
      }),
    [websiteList],
  );

  return (
    <OptionsFilter
      param="websiteID"
      paramName="Webseite"
      options={websiteOptionList}
    />
  );
};

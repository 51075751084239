export const getExpIcon = (iconId: string) => {
  const col = parseInt(iconId.slice(-1));
  const row = parseInt(iconId.slice(0, -1));

  const computedRow = row > 0 ? row * 21 * -1 : 0;
  const computedCol = col * 21 * -1;

  return {
    rowPixels: computedRow,
    colPixels: computedCol,
  };
};

type EntityModel = {
  id: string;
};

export const waitTime = (duration: number) => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve(null);
      }, duration);
    } catch (err) {
      reject(err);
    }
  });
};

export const sliceIntoChunks = <T>(arr: T[], chunkSize: number): T[][] => {
  const res: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const filterUniques = <T extends EntityModel>(arr: T[]) => {
  return arr.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id),
  );
};

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { UpdateBookingInput } from "types";
import { getBooking } from "modules/bookings/api";

export const useBookingUpdate = () => {
  const updateMutation = useMutation({
    mutationFn: async (bookingInput: UpdateBookingInput) => {
      const { data: updatedBooking } =
        await client.models.Booking.update(bookingInput);

      if (!updatedBooking) return null;

      const booking = await getBooking(updatedBooking.id);

      return booking;
    },
    // When mutate is called:
    onMutate: async (newBooking) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Bookings, newBooking.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.Bookings],
      });

      // Snapshot the previous value
      const previousBooking = queryClient.getQueryData([
        QueryKeys.Bookings,
        newBooking.id,
      ]);

      // Optimistically update to the new value
      if (previousBooking) {
        queryClient.setQueryData(
          [QueryKeys.Bookings, newBooking.id],
          /**
           * `newBooking` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousBooking, ...newBooking },
        );
      }

      // Return a context with the previous and new booking
      return {
        previousBooking,
        newBooking,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newBooking, context) => {
      console.error("Error updating record:", err, newBooking);
      if (context?.previousBooking) {
        queryClient.setQueryData(
          [QueryKeys.Bookings, context.newBooking.id],
          context.previousBooking,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newBooking) => {
      if (newBooking) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Bookings, newBooking.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Bookings],
        });
      }
    },
  });

  return updateMutation;
};

import { useEffect } from "react";
import { usePersistedState, usePersistedStateWithDate } from "hooks";
import { BillType, PeriodType, PermanentTask, TaskType, Website } from "types";
import utils from "utils";

export const usePermanentTaskForm = (
  formIntent: "create" | "edit",
  permanentTask: PermanentTask | null | undefined,
) => {
  const [taskType, setTaskType] = usePersistedState<TaskType | null>(
    "permanentTask_taskType",
    null,
    formIntent,
  );

  const [website, setWebsite] = usePersistedState<Website | null>(
    "permanentTask_website",
    null,
    formIntent,
  );

  const [effort, setEffort] = usePersistedState<number | "">(
    "permanentTask_effort",
    1,
    formIntent,
  );
  const [referenceDate, setReferenceDate] =
    usePersistedStateWithDate<Date | null>(
      "permanentTask_referenceDate",
      null,
      formIntent,
    );
  const [periodType, setPeriodType] = usePersistedState<PeriodType | null>(
    "permanentTask_periodType",
    null,
    formIntent,
  );
  const [periodValue, setPeriodValue] = usePersistedState<number | "">(
    "permanentTask_periodValue",
    1,
    formIntent,
  );
  const [deadlineDays, setDeadlineDays] = usePersistedState<number | "">(
    "permanentTask_deadlineDays",
    1,
    formIntent,
  );
  const [billType, setBillType] = usePersistedState<BillType | null>(
    "permanentTask_billType",
    null,
    formIntent,
  );

  const resetPermanentTaskForm = () => {
    localStorage.removeItem("permanentTask_taskType");
    localStorage.removeItem("permanentTask_website");
    localStorage.removeItem("permanentTask_effort");
    localStorage.removeItem("permanentTask_referenceDate");
    localStorage.removeItem("permanentTask_periodType");
    localStorage.removeItem("permanentTask_periodValue");
    localStorage.removeItem("permanentTask_deadlineDays");
    localStorage.removeItem("permanentTask_billType");
  };

  useEffect(() => {
    if (!permanentTask) {
      return;
    }

    setTaskType(permanentTask.taskType as TaskType);
    setWebsite(permanentTask.website as Website);

    setEffort(permanentTask.effort);
    setReferenceDate(
      utils.dates.getDateFromAWSDateFormat(permanentTask.referenceDate),
    );

    setPeriodType(permanentTask.periodType);

    setPeriodValue(permanentTask.periodValue);
    setDeadlineDays(permanentTask.deadlineDays);

    setBillType(permanentTask.billType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permanentTask]);

  return {
    taskType,
    setTaskType,
    website,
    setWebsite,
    effort,
    setEffort,
    referenceDate,
    setReferenceDate,
    periodType,
    setPeriodType,
    periodValue,
    setPeriodValue,
    deadlineDays,
    setDeadlineDays,
    billType,
    setBillType,
    resetPermanentTaskForm,
  };
};

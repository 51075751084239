import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { PermanentTask } from "types";

export const usePermanentTaskDelete = () => {
  const deleteMutation = useMutation({
    mutationFn: async (permanentTask: PermanentTask) => {
      const { data } = await client.models.PermanentTask.delete({
        id: permanentTask.id,
      });

      if (!data) return null;

      return permanentTask;
    },
    // When mutate is called:
    onMutate: async (newPermanentTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.PermanentTasks, newPermanentTask.id],
      });

      await queryClient.cancelQueries({
        queryKey: [QueryKeys.PermanentTasks],
      });

      // Snapshot the previous value
      const previousPermanentTask = queryClient.getQueryData([
        QueryKeys.PermanentTasks,
        newPermanentTask.id,
      ]);

      // Optimistically update to the new value
      if (previousPermanentTask) {
        queryClient.setQueryData(
          [QueryKeys.PermanentTasks, newPermanentTask.id],
          /**
           * `newPermanentTask` will at first only include updated values for
           * the record. To avoid only rendering optimistic values for updated
           * fields on the UI, include the previous values for all fields:
           */
          { ...previousPermanentTask, ...newPermanentTask },
        );
      }

      // Return a context with the previous and new permanentTask
      return {
        previousPermanentTask,
        newPermanentTask,
      };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newPermanentTask, context) => {
      console.error("Error updating record:", err, newPermanentTask);
      if (context?.previousPermanentTask) {
        queryClient.setQueryData(
          [QueryKeys.PermanentTasks, context.newPermanentTask.id],
          context.previousPermanentTask,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (newPermanentTask) => {
      if (newPermanentTask) {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.PermanentTasks, newPermanentTask.id],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.PermanentTasks],
        });
      }
    },
  });

  return deleteMutation;
};

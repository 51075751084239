import React from "react";
import { useNavigate } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useAuthContext } from "context";
import { ArrowCircleLeftIcon } from "modules/icons";
import useStyles from "./styles";

const getLinkPath = (link: string) => {
  const savedFilterParamsFromStorage = sessionStorage.getItem(link);

  if (savedFilterParamsFromStorage) {
    return link + "?" + savedFilterParamsFromStorage;
  }

  return link;
};

type SidebarBackLinkProps = {
  title: string;
  link: string;
};

export const SidebarBackLink: React.FC<SidebarBackLinkProps> = ({
  title,
  link,
}) => {
  const authContext = useAuthContext();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  return (
    <ListItem
      className={cx(
        classes.listItem,
        authContext.menuOpen ? null : classes.listItemClosed,
      )}
      onClick={() => navigate(getLinkPath(link))}
    >
      <ListItemIcon className={classes.icon}>
        <ArrowCircleLeftIcon />
      </ListItemIcon>

      <ListItemText
        classes={{
          primary: cx(
            classes.listItemText,
            authContext.menuOpen ? null : classes.menuClosed,
          ),
        }}
      >
        {title}
      </ListItemText>
    </ListItem>
  );
};

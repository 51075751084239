import React from "react";
import { Loading } from "core";
import { DownloadIcon } from "modules/icons";
import useStyles from "./styles";

type DownloadButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  size?: "small" | "medium" | "big";
  title?: string;
  isLoadingDownload?: boolean;
};

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  onClick,
  size = "medium",
  title,
  isLoadingDownload,
}) => {
  const { classes, cx } = useStyles();

  const sizeClass =
    size === "small"
      ? classes.buttonSmall
      : size === "big"
      ? classes.buttonBig
      : classes.buttonMedium;

  return (
    <button
      title={title}
      className={cx(classes.downloadButton, classes.button, sizeClass)}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        !isLoadingDownload && onClick(e);
      }}
    >
      <span className="svg-icon svg-icon-md svg-icon-danger">
        {isLoadingDownload && <Loading size="21px" style={{ margin: 0 }} />}
        <DownloadIcon
          style={{
            display: isLoadingDownload ? "none" : "block",
            width: 33,
            height: 33,
          }}
        />
      </span>
    </button>
  );
};

import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTemplateRoutes } from "hooks";
import { Template } from "types";
import { CustomReactTable } from "core";
import { DescriptionFormatter } from "components";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { TemplateDeleteDialog } from "../../templateDelete/TemplateDeleteDialog";

type TemplateListTableProps = {
  listIntent: "list" | "selection";
  templateList: Template[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  templateSelectionHandler?: (template: Template) => void;
};

export const TemplateListTable: React.FC<TemplateListTableProps> = ({
  listIntent,
  templateList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  templateSelectionHandler,
}) => {
  const {
    navigateToTemplatePage,
    navigateToEditTemplatePage,
    openTemplateInBrowserHandler,
  } = useTemplateRoutes();

  const [deleteTemplateDialogOpen, setDeleteTemplateDialogOpen] =
    useState<boolean>(false);
  const [templateToDelete, setTemplateToDelete] = useState<
    Template | undefined
  >();

  const defaultColumns = React.useMemo<ColumnDef<Template>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "System",
        accessorKey: "systemID",
        accessorFn: (row) => row.system.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Benutzbarkeit",
        accessorKey: "usability",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Schönheit",
        accessorKey: "beauty",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Gekaufte Lizenzen",
        accessorKey: "purchasedLicences",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Verwendung",
        accessorKey: "usage",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Anbieter-URL",
        accessorKey: "providerURL",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Beschreibung",
        accessorKey: "description",
        cell: ({ row }) => (
          <DescriptionFormatter description={row.original.description} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Template>
            row={row}
            listIntent={listIntent}
            openEditRowPage={navigateToEditTemplatePage}
            setRowToDelete={setTemplateToDelete}
            setDeleteRowDialogOpen={setDeleteTemplateDialogOpen}
            rowSelectionHandler={templateSelectionHandler}
            openInBrowserHandler={openTemplateInBrowserHandler}
            minWidth={150}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditTemplatePage, templateSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Template) => {
    if (templateSelectionHandler) {
      templateSelectionHandler(row);
    } else {
      navigateToTemplatePage(row);
    }
  };

  return (
    <>
      <TemplateDeleteDialog
        dialogOpen={deleteTemplateDialogOpen}
        setDialogOpen={setDeleteTemplateDialogOpen}
        template={templateToDelete}
      />
      <CustomReactTable<Template>
        data={templateList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

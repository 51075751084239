import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useSystemRoutes } from "hooks";
import { System } from "types";
import { CustomReactTable } from "core";
import { DescriptionFormatter } from "components";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { SystemDeleteDialog } from "../../systemDelete/SystemDeleteDialog";

type SystemListTableProps = {
  listIntent: "list" | "selection";
  systemList: System[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  systemSelectionHandler?: (system: System) => void;
};

export const SystemListTable: React.FC<SystemListTableProps> = ({
  listIntent,
  systemList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  systemSelectionHandler,
}) => {
  const { navigateToSystemPage, navigateToEditSystemPage } = useSystemRoutes();

  const [deleteSystemDialogOpen, setDeleteSystemDialogOpen] =
    useState<boolean>(false);
  const [systemToDelete, setSystemToDelete] = useState<System | undefined>();

  const defaultColumns = React.useMemo<ColumnDef<System>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Bewertung",
        accessorKey: "rating",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Komplexität",
        accessorKey: "complexity",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Updates Benötigt?",
        accessorKey: "updatesNeeded",
        accessorFn: (row) => (row.updatesNeeded ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Beschreibung",
        accessorKey: "description",
        cell: ({ row }) => (
          <DescriptionFormatter description={row.original.description} />
        ),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<System>
            row={row}
            listIntent={listIntent}
            openEditRowPage={navigateToEditSystemPage}
            setRowToDelete={setSystemToDelete}
            setDeleteRowDialogOpen={setDeleteSystemDialogOpen}
            rowSelectionHandler={systemSelectionHandler}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigateToEditSystemPage, systemSelectionHandler, listIntent],
  );

  const rowClickHandler = (_: SyntheticEvent, row: System) => {
    if (systemSelectionHandler) {
      systemSelectionHandler(row);
    } else {
      navigateToSystemPage(row);
    }
  };

  return (
    <>
      <SystemDeleteDialog
        dialogOpen={deleteSystemDialogOpen}
        setDialogOpen={setDeleteSystemDialogOpen}
        system={systemToDelete}
      />
      <CustomReactTable<System>
        data={systemList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};

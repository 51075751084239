import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  optionValue: {},
  placeholder: {
    display: "inline-block",
    minWidth: "max-content",
  },
}));

export default useStyles;

import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateSystemInput, InfiniteSystemData } from "types";
import { getSystem } from "modules/systems/api";

export const useSystemCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateSystemInput) => {
      const { data: newSystem } = await client.models.System.create(input);

      if (!newSystem) return null;

      const system = await getSystem(newSystem.id);

      return system;
    },
    // When mutate is called:
    onMutate: async (newSystem) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.Systems] });

      // Snapshot the previous value
      const previousSystems = queryClient.getQueryData([QueryKeys.Systems]);

      // Optimistically update to the new value
      if (previousSystems) {
        queryClient.setQueryData(
          [QueryKeys.Systems],
          (old: InfiniteSystemData) => {
            const oldData = old?.pages
              ? old.pages.flatMap((page) => page.systemList)
              : [];

            [...oldData, newSystem];
          },
        );
      }

      // Return a context object with the snapshotted value
      return { previousSystems };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newSystem, context) => {
      console.error("Error saving record:", err, newSystem);
      if (context?.previousSystems) {
        queryClient.setQueryData([QueryKeys.Systems], context.previousSystems);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Systems] });
    },
  });

  return createMutation;
};

import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { getAllTemplateList } from "modules/templates/api";
import { useTemplateListFilterAndSort } from "../useTemplateListFilterAndSort";

export const useTemplateAllList = (filter = true) => {
  const filterAndSortTemplates = useTemplateListFilterAndSort(filter);

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.Templates, "all"],
    queryFn: async () => {
      const { templateList } = await getAllTemplateList();

      return templateList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const templateList = useMemo(
    () => filterAndSortTemplates(data ?? []),
    [data, filterAndSortTemplates],
  );

  return {
    templateList,
    isLoading,
  };
};

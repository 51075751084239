import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { RouteMapType, getSubNavigationsAsArray } from "routes";
import useStyles from "./styles";

type NavigationPageItemProps = {
  navigationRoot: RouteMapType;
};

export const NavigationPageItems: React.FC<NavigationPageItemProps> = ({
  navigationRoot,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const subNavigations = getSubNavigationsAsArray(
    navigationRoot.subNavigations,
  );

  return (
    <Grid container direction="row" justifyContent="center">
      {subNavigations.map((subNavigation, index) => (
        <Grid key={index} item className={classes.gridItem}>
          <ListItem
            onClick={() => navigate(subNavigation.path)}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.navigationIcon}>
              {subNavigation.icon}
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }}>
              {subNavigation.title}
            </ListItemText>
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};

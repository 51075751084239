import { jsPDF } from "jspdf";

export const savePDFDocument = async (
  documentName: string,
  documentID: string
) => {
  const doc = new jsPDF("p", "pt", "a4");
  const pWidth = doc.internal.pageSize.getWidth();
  const source = window.document.getElementById(documentID);
  if (source) {
    await doc
      .html(source, {
        width: pWidth,
        windowWidth: 1000,
        html2canvas: {
          ignoreElements: (element: HTMLElement) =>
            typeof element.className === "string" &&
            element.className.includes("ignorePDF"),
        },
        // fontFaces: [
        //   {
        //     family: "Helvetica",
        //     src: [
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-Bold.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-Oblique.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-BoldOblique.ttf",
        //         format: "truetype",
        //       },
        //     ],
        //   },
        // ],
      })
      .save(documentName);
  }
};

export const getPDFDocumentAsBase64 = async (documentID: string) => {
  const doc = new jsPDF("p", "pt", "a4");
  const pWidth = doc.internal.pageSize.getWidth();
  const source = window.document.getElementById(documentID);
  if (source) {
    const dataurlstring = await doc
      .html(source, {
        width: pWidth,
        windowWidth: 1000,
        html2canvas: {
          ignoreElements: (element: HTMLElement) =>
            typeof element.className === "string" &&
            element.className.includes("ignorePDF"),
        },
        // fontFaces: [
        //   {
        //     family: "Helvetica",
        //     src: [
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-Bold.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-Oblique.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-BoldOblique.ttf",
        //         format: "truetype",
        //       },
        //     ],
        //   },
        // ],
      })
      .outputPdf("dataurlstring");

    const pdfBase64 = dataurlstring.replace(/^data:.+;base64,/, "");

    return pdfBase64;
  } else {
    return null;
  }
};

export const getPDFDocumentAsArrayBuffer = async (documentID: string) => {
  const doc = new jsPDF("p", "pt", "a4");
  const pWidth = doc.internal.pageSize.getWidth();
  const source = window.document.getElementById(documentID);
  if (source) {
    const arrayBuffer = await doc
      .html(source, {
        width: pWidth,
        windowWidth: 1000,
        html2canvas: {
          ignoreElements: (element: HTMLElement) =>
            typeof element.className === "string" &&
            element.className.includes("ignorePDF"),
        },
        // fontFaces: [
        //   {
        //     family: "Helvetica",
        //     src: [
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-Bold.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-Oblique.ttf",
        //         format: "truetype",
        //       },
        //       {
        //         url: "http://localhost:3000/webfonts/Helvetica-BoldOblique.ttf",
        //         format: "truetype",
        //       },
        //     ],
        //   },
        // ],
      })
      .outputPdf("arraybuffer");

    return arrayBuffer;
  } else {
    return null;
  }
};

import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Row } from "@tanstack/react-table";
import { ButtonColor, ButtonSize, CustomButton } from "core";
import {
  DeleteButton,
  DownloadButton,
  EditButton,
  OpenInBrowserButton,
  RowActions,
  ViewButton,
  Warning,
} from "components";

type ActionsColumnFormatterProps<DataType> = {
  row: Row<DataType>;
  listIntent: "list" | "selection";
  openEditRowPage?: (row: DataType) => void;
  setEditRowDialogOpen?: (value: React.SetStateAction<boolean>) => void;
  setRowToEdit?: (value: React.SetStateAction<DataType | undefined>) => void;
  setDeleteRowDialogOpen?: (value: React.SetStateAction<boolean>) => void;
  setRowToDelete?: (value: React.SetStateAction<DataType | undefined>) => void;
  deleteDisabled?: boolean;
  rowSelectionHandler?: (row: DataType) => void;
  resetSearchParamsOnRowSelection?: boolean;
  openInBrowserHandler?: (row: DataType) => void;
  viewHandler?: (row: DataType) => void;
  downloadHandler?: (row: DataType) => Promise<void>;
  isLoadingDownload?: boolean;
  buttonClickHandler?: (
    e: React.MouseEvent<HTMLElement>,
    row: DataType,
  ) => void;
  buttonText?: string;
  buttonIconBefore?: React.ReactNode;
  buttonIconAfter?: React.ReactNode;
  buttonColor?: ButtonColor;
  buttonSize?: ButtonSize;
  isLoadingButton?: boolean;
  preventButtonCondition?: boolean;
  preventButtonText?: string;
  preventSelectionCondition?: boolean;
  preventSelectionText?: string;
  permanentVisible?: boolean;
  minWidth?: number | "unset";
};

export const ActionsColumnFormatter: <DataType>(
  p: ActionsColumnFormatterProps<DataType>,
) => React.ReactElement<ActionsColumnFormatterProps<DataType>> = ({
  row,
  listIntent,
  openEditRowPage,
  setEditRowDialogOpen,
  setRowToEdit,
  setDeleteRowDialogOpen,
  setRowToDelete,
  deleteDisabled,
  rowSelectionHandler,
  resetSearchParamsOnRowSelection = true,
  openInBrowserHandler,
  viewHandler,
  downloadHandler,
  isLoadingDownload,
  buttonClickHandler,
  buttonText,
  buttonIconBefore,
  buttonIconAfter,
  buttonColor,
  buttonSize = "small",
  isLoadingButton,
  preventButtonCondition,
  preventButtonText,
  preventSelectionCondition,
  preventSelectionText,
  permanentVisible,
  minWidth,
}) => {
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();

  return (
    <RowActions
      permanentVisible={listIntent === "selection" || permanentVisible}
      minWidth={minWidth}
    >
      {listIntent === "list" ? (
        <>
          {openInBrowserHandler && (
            <OpenInBrowserButton
              onClick={() => openInBrowserHandler(row.original)}
            />
          )}
          {viewHandler && (
            <ViewButton onClick={() => viewHandler(row.original)} />
          )}
          {downloadHandler && (
            <DownloadButton
              onClick={() => downloadHandler(row.original)}
              isLoadingDownload={isLoadingDownload}
            />
          )}
          {openEditRowPage && (
            <EditButton onClick={() => openEditRowPage(row.original)} />
          )}
          {setRowToEdit && setEditRowDialogOpen && (
            <EditButton
              onClick={() => {
                setRowToEdit(row.original);
                setEditRowDialogOpen(true);
              }}
            />
          )}
          {setRowToDelete && setDeleteRowDialogOpen && (
            <DeleteButton
              onClick={() => {
                setRowToDelete(row.original);
                setDeleteRowDialogOpen(true);
              }}
              disabled={deleteDisabled}
            />
          )}
          {preventButtonCondition && preventButtonText ? (
            <Warning
              content={preventButtonText}
              color="red"
              marginTop={false}
            />
          ) : buttonClickHandler && buttonText ? (
            <CustomButton
              text={buttonText}
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                buttonClickHandler(e, row.original)
              }
              size={buttonSize}
              color={buttonColor}
              iconBefore={buttonIconBefore}
              iconAfter={buttonIconAfter}
              style="outlined"
              disabled={false}
              loading={isLoadingButton}
            />
          ) : null}
        </>
      ) : preventSelectionCondition && preventSelectionText ? (
        <Warning content={preventSelectionText} color="red" marginTop={false} />
      ) : (
        <CustomButton
          text="Auswählen"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            rowSelectionHandler && rowSelectionHandler(row.original);
            resetSearchParamsOnRowSelection &&
              sessionStorage.setItem(pathname, "");

            resetSearchParamsOnRowSelection &&
              setSearchParams([], {
                replace: true,
              });
          }}
          style="outlined"
          size="small"
          disabled={false}
        />
      )}
    </RowActions>
  );
};

import React from "react";
import { client } from "queryClient";
import { PeriodType, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface PeriodTypeOptionProps
  extends Partial<CustomSelectOptionProps<PeriodType>> {
  periodType: PeriodType | null;
  setPeriodType: (value: React.SetStateAction<PeriodType | null>) => void;
  periodTypeInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getPeriodTypeName = (value: PeriodType) => {
  switch (value) {
    case "day":
      return "Tag";
    case "week":
      return "Woche";
    case "month":
      return "Monat";
  }
};

export const getPeriodTypeEnumByName = (value: string): PeriodType => {
  switch (value) {
    case "Tag":
      return "day";
    case "Woche":
      return "week";
    case "Monat":
      return "month";
    default:
      return "day";
  }
};

export const getPeriodTypeOptions = (): SelectOption[] => {
  const values = client.enums.PeriodType?.values() ?? [];

  return values.map((periodType) => ({
    value: periodType,
    label: getPeriodTypeName(periodType),
  }));
};

export const PeriodTypeOption: React.FC<PeriodTypeOptionProps> = ({
  periodType,
  setPeriodType,
  periodTypeInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Periode Typ"
    options={getPeriodTypeOptions()}
    onChange={(periodTypeOption) =>
      setPeriodType(periodTypeOption.value as PeriodType)
    }
    value={
      periodType
        ? {
            value: periodType,
            label: getPeriodTypeName(periodType),
          }
        : null
    }
    placeholder="Periode Typ auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={periodTypeInputRef}
  />
);

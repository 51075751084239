import React from "react";
import { client } from "queryClient";
import { BacklinkStatus, SelectOption } from "types";
import {
  CustomSelect,
  CustomSelectHandles,
  CustomSelectOptionProps,
} from "core";

interface BacklinkStatusOptionProps
  extends Partial<CustomSelectOptionProps<BacklinkStatus>> {
  backlinkStatus: BacklinkStatus | null;
  setBacklinkStatus: (
    value: React.SetStateAction<BacklinkStatus | null>,
  ) => void;
  backlinkStatusInputRef?: React.RefObject<CustomSelectHandles>;
}

export const getBacklinkStatusName = (value: BacklinkStatus) => {
  switch (value) {
    case "offen":
      return "Offen";
    case "erledigt":
      return "Erledigt";
    case "wirwollennicht":
      return "Wir wollen nicht";
    case "kundewirdnichterlauben":
      return "Kunde wird nicht erlauben";
    case "kundehatabgelehnt":
      return "Kunde hat abgelehnt";
  }
};

export const getBacklinkStatusEnumByName = (value: string): BacklinkStatus => {
  switch (value) {
    case "Offen":
      return "offen";
    case "Erledigt":
      return "erledigt";
    case "Wir wollen nicht":
      return "wirwollennicht";
    case "Kunde wird nicht erlauben":
      return "kundewirdnichterlauben";
    case "Kunde hat abgelehnt":
      return "kundehatabgelehnt";
    default:
      return "offen";
  }
};

export const getBacklinkStatusOptions = (): SelectOption[] => {
  const values = client.enums.BacklinkStatus?.values() ?? [];

  return values.map((backlinkStatus) => ({
    value: backlinkStatus,
    label: getBacklinkStatusName(backlinkStatus),
  }));
};

export const BacklinkStatusOption: React.FC<BacklinkStatusOptionProps> = ({
  backlinkStatus,
  setBacklinkStatus,
  backlinkStatusInputRef,
  ...rest
}) => (
  <CustomSelect<SelectOption>
    {...rest}
    label="Status"
    options={getBacklinkStatusOptions()}
    onChange={(backlinkStatusOption) =>
      setBacklinkStatus(backlinkStatusOption.value as BacklinkStatus)
    }
    value={
      backlinkStatus
        ? {
            value: backlinkStatus,
            label: getBacklinkStatusName(backlinkStatus),
          }
        : null
    }
    placeholder="Status auswählen"
    getOptionValue={undefined}
    getOptionLabel={undefined}
    isOptionDisabled={undefined}
    isOptionSelected={undefined}
    ref={backlinkStatusInputRef}
  />
);
